import { useState } from 'react'
import classes from "./ResultsTableRow.module.css"

export interface Props {
  onClickRows?: (param?: any) => void
  item: any
  columns: { [key: string]: JSX.Element | string }
  rowIndex: number
  checkedRowsIdList?: string[]
  handleClick?: (e: React.ChangeEvent<HTMLInputElement>) => void
  checkboxColIndex?:number,
  checkboxIdStr?:string
}

const ResultsTableRow: React.FC<Props> = ({
  onClickRows,
  item, // Warning: should have an id field + a checkboxVisible field
  columns,
  rowIndex,
  checkedRowsIdList,
  handleClick,
  checkboxColIndex,
  checkboxIdStr
}) => {
  const [showToggle, setShowToggle] = useState(false)

  const toggle = () => {
    setShowToggle((prevState) => {
      return !prevState
    })
  }

  const onClickHandler = () => {
    if (onClickRows) {
      onClickRows(item)
    } else if (item.toggleComponent) {
      toggle()
    }
  }

  // See https://reactjs.org/docs/jsx-in-depth.html#user-defined-components-must-be-capitalized
  const ToggleComponent = item.toggleComponent

  const tdElements = Object.entries(columns).map(([title, jsx]: any) => { 
      return <td key={title}>{item[title]}</td>})
  if(checkedRowsIdList && handleClick){
    const tdToAdd = <td key="row-checkbow">
            {item.checkboxVisible && (
              <input
                className="form-check-input"
                type="checkbox"
                name="row-checkbox"
                id={checkboxIdStr?item[checkboxIdStr]:item.id.toString()}
                checked={checkedRowsIdList.includes(checkboxIdStr?item[checkboxIdStr]:item.id)}
                onChange={handleClick}
              />
            )}
          </td>
      if(typeof checkboxColIndex==="number"){
        tdElements.splice(checkboxColIndex, 0, tdToAdd)
      }else
      tdElements.push(tdToAdd)
  }
  return (
    <>
      <tr className={`${item.isHighlighted? classes.highlight:''} hover-green`}
        style={{
          cursor: onClickRows ? 'pointer' : 'inherit',
          '--bs-table-accent-bg':
            (rowIndex + 1) & 1 ? 'var(--bs-table-striped-bg)' : '',
        }}
        key={item.id}
        id={item.id}
        onClick={onClickHandler}
      >
        {tdElements}
      </tr>
      {showToggle && (
        <tr
          style={{
            '--bs-table-accent-bg':
              (rowIndex + 1) & 1 ? 'var(--bs-table-striped-bg)' : '',
          }}
        >
          <td colSpan={Object.keys(columns).length}>
            <ToggleComponent
              item={item}
              onCancel={() => {
                toggle()
              }}
            />
          </td>
        </tr>
      )}
    </>
  )
}

export default ResultsTableRow
