import './OrderCard.css'
import React, { useContext, useState } from 'react'
import HeaderTitle from 'components/HeaderTitle'
import OrderInfoModal from 'components/Modal/OrderInfoModal'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { BadgeCheckIcon, BanIcon } from '@heroicons/react/solid'
import Card from 'components/UI/Card'
import { Button, Form, Spinner } from 'react-bootstrap'
import BLACKLIST_CUSTOMER from 'graphql/mutations/BlacklistCustomer'
import { gql, useMutation, useQuery } from '@apollo/client'
import { Link, useParams } from 'react-router-dom'
import { ToastContext } from 'store/toast-context'
import { useTranslation } from 'react-i18next'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import DOModal from 'components/Modal/DOModal'
import DOButton from 'components/Modal/DOButton'
import { getLatestTotalAmount } from 'utils/functions'
import { constants } from 'utils/constants'
import OrderCosiumAllInModal from 'components/Modal/OrderCosiumAllInModal'
import MenuContext from 'store/menu-context'
import OrderBeneficiaryInfo from 'components/Modal/OrderBeneficiaryInfo'
import GET_ORDER_INFO from 'graphql/queries/getDOOrderInfo'

interface Props {}

const OrderInfo: React.FC<Props> = () => {
  const { toast } = useContext(ToastContext)
  const { t } = useTranslation()
  const { id } = useParams<ParamType>()
  const menuContext = useContext(MenuContext)

  const [showMoreInfoModal, setShowMoreInfoModal] = useState(false)
  const [showTotalAmountHistory, setShowTotalAmountHistory] = useState(false)
  const [showAddCosiumQuoteModal, setShowAddCosiumQuoteModal] = useState(false)
  const [dateLimitReturnEssdom, setDateLimitReturnEssdom] = useState('')

  const [blacklistCustomer, { loading: blacklistMutationLoading }] =
    useMutation(BLACKLIST_CUSTOMER, {
      onCompleted: (data) => {
        toast({
          title: 'Modifications enregistrées :',
          message: `Client ${
            data.updateDOCustomer.output.blacklisted ? '' : ' non '
          } blacklisté`,
        })

        if (data.updateDOCustomer.output.blacklisted)
          menuContext.setSideBarNewEventCount(
            ++menuContext.sideBarNewEventCount
          )
      },
    })

  const UPDATE_ORDER_DATE_RETURN_LIMIT_ESSDOM = gql`
    mutation UPDATE_ORDER_DATE_RETURN_LIMIT_ESSDOM(
      $id: Int!
      $dateLimitReturnEssdom: String!
    ) {
      updateDOOrder(
        id: $id
        input: { dateLimitReturnEssdom: $dateLimitReturnEssdom }
      ) {
        success
        output {
          id
          dateLimitReturnEssdom
        }
      }
    }
  `
  const [updateDateLimitReturnEssdom, { loading: updateDateReturnLoading }] =
    useMutation(UPDATE_ORDER_DATE_RETURN_LIMIT_ESSDOM, {
      onCompleted: () => {
        toast({
          title: 'Succès :',
          message: `Date de retour essDom modifiée`,
        })
        setDateLimitReturnEssdom('')
      },
    })

  const { loading, error, data } = useQuery(GET_ORDER_INFO, {
    variables: {
      id: +id,
    },
  })

  if (loading) return <SkeletonBase count={4} variant="px-4 pt-1 mt-4" />
  if (error) return <p>`Error! ${error.message}`</p>

  const order = data.CQgetOrder?.edges[0].node?.order
  const calculators = data.CQgetOrder?.edges[0].node?.calculators
  const orderBeneficiary = order.customer?.beneficiaries?.filter(
    (beneficiary: any) => {
      return +beneficiary.id === +calculators.beneficiaryId
    }
  )
  const orderBeneficiaryContactInfo = { ...order.customer?.contactInfo?.[0] }
  orderBeneficiaryContactInfo.email = order.customer?.login
  const blacklistCustomerSwitchHandler = async () => {
    try {
      //blacklist mutation
      await blacklistCustomer({
        variables: {
          id: parseInt(order.customer.id),
          blacklisted: !order.customer.blacklisted,
        },
      })
    } catch (e) {
      // NB : gestion erreur dans App.tsx
    }
  }

  const handleUpdateDateLimitReturnEssdom = async () => {
    try {
      //blacklist mutation
      await updateDateLimitReturnEssdom({
        variables: {
          id: +id,
          dateLimitReturnEssdom,
        },
      })
    } catch (e) {
      // NB : gestion erreur dans App.tsx
    }
  }

  return order ? (
    <div className="info p-4">
      <div
        data-cy="orderType"
        className={order.orderType === 'essdom' ? 'essdom' : 'panier'}
        style={{ display: 'hide' }}
      />
      <div className="d-flex justify-content-between">
        <HeaderTitle
          title={`Informations commande${
            order.orderType === 'essdom' ? ' - essDom' : ''
          }`}
          children={<InformationCircleIcon className="header-title-icon" />}
        />
        <div>
          <DOButton
            className="link-primary text-end"
            text="Voir plus d'informations"
            handleClick={() => setShowMoreInfoModal(true)}
          />
          <DOModal
            show={showMoreInfoModal}
            customSize="modal-647"
            closeModal={() => setShowMoreInfoModal(false)}
          >
            <OrderInfoModal order={order} />
          </DOModal>
        </div>
      </div>
      <table className="table-order">
        <tbody>
          <tr>
            <th>N° de commande :</th>
            <td>{order.key} <span className={`ms-2 badge text-dark ${order.workflowState}`}>{t(order.workflowState)}</span></td>
          </tr>
          
          <tr>
            <th>Prénom et nom :</th>

            <td>
              <Link
                to={{
                  pathname: `/bo/edition_client/${
                    order.customer ? order.customer.id : ''
                  }`,
                }}
                target="_blank"
              >
                {order.customer.contactName
                  ? order.customer.contactName[0].firstName +
                    ' ' +
                    order.customer.contactName[0].lastName
                  : ''}
              </Link>

              {order.customer.badge && (
                <>
                  <span>&nbsp;({t(order.customer.badge)})</span>
                  {order.customer.badge !== 'blacklist' ? (
                    <BadgeCheckIcon
                      className={`header-title-icon ${order.customer.badge}`}
                    />
                  ) : (
                    <BanIcon className="header-title-icon blacklist" />
                  )}
                </>
              )}
            </td>
          </tr>

          <tr>
            <th>Balance paiements :</th>
            <td>
              {(+calculators.totalPaidAmount).toFixed(2)} {constants.currency.EURO}
            </td>
          </tr>
          {order.orderType === 'panier' ? (
            <>
              <tr>
                <th>N° Devis Cosium :</th>
                <td>
                  <a
                    href="#/"
                    className="link-primary text-end"
                    onClick={(e: any) => {
                      e && e.preventDefault()
                      setShowAddCosiumQuoteModal(true)
                    }}
                  >
                    {calculators.cosiumId.length
                      ? calculators.cosiumId
                      : 'Ajouter un devis'}
                  </a>
                  <DOModal
                    show={showAddCosiumQuoteModal}
                    customSize="modal-647"
                    closeModal={() => setShowAddCosiumQuoteModal(false)}
                  >
                    <OrderCosiumAllInModal
                      order={order}
                      calculators={calculators}
                      isReportedInCosium={false}
                    />
                  </DOModal>
                </td>
              </tr>
              <tr>
                <th>Montant Cosium ({constants.currency.EURO}) :</th>
                <td>
                  <a
                    data-cy="cosiumAmountLink"
                    href="#/"
                    className="link-primary text-end"
                    onClick={(e: any) => {
                      e && e.preventDefault()
                      setShowTotalAmountHistory(true)
                    }}
                  >
                    {getLatestTotalAmount(order.totalAmountHistory)}
                  </a>
                  <DOModal
                    show={showTotalAmountHistory}
                    customSize="modal-647"
                    closeModal={() => setShowTotalAmountHistory(false)}
                  >
                    <OrderCosiumAllInModal
                      order={order}
                      calculators={calculators}
                      isReportedInCosium={false}
                    />
                  </DOModal>
                </td>
              </tr>
            </>
          ) : (
            <tr>
              <th>Montant ({constants.currency.EURO}) :</th>
              <td>{(+calculators.totalPaidAmount).toFixed(2)}</td>
            </tr>
          )}

          <tr>
            <th>{orderBeneficiary?.length ? 'Bénéficiaire' : 'Client'} :</th>
            <td>
              <div>
                {orderBeneficiary?.length ? (
                  <>
                    <a
                      href="#/"
                      className="link-primary text-end"
                      onClick={(e: any) => {
                        e && e.preventDefault()
                        setShowMoreInfoModal(true)
                      }}
                    >
                      {orderBeneficiary?.[0]?.name?.[0]?.firstName}{' '}
                      {orderBeneficiary?.[0]?.name?.[0]?.lastName}
                    </a>{'  '}
                    (
                    {orderBeneficiary?.[0]?.name?.[0]?.civility === 'monsieur'
                      ? 'M'
                      : 'F'}{' '}
                    -{' '}
                    {new Intl.DateTimeFormat('fr', {
                      dateStyle: 'short',
                    }).format(new Date(orderBeneficiary?.[0]?.birthDate))}
                    )
                  </>
                ) : (
                  <>
                    <a
                      href="#/"
                      className="link-primary text-end"
                      onClick={(e: any) => {
                        e && e.preventDefault()
                        setShowMoreInfoModal(true)
                      }}
                    >
                      {order?.customer?.contactName?.[0].firstName}{' '}
                      {order?.customer?.contactName?.[0].lastName}
                    </a>{'  '}
                    (
                    {order?.customer?.contactName?.[0].civility === 'monsieur'
                      ? 'M'
                      : 'F'}{' '}
                    -{' '}
                    {new Intl.DateTimeFormat('fr', {
                      dateStyle: 'short',
                    }).format(new Date(order?.customer?.birthdate))}
                    )
                  </>
                )}
                <DOModal
                  show={showMoreInfoModal}
                  customSize="modal-647"
                  closeModal={() => setShowMoreInfoModal(false)}
                >
                {/* {orderBeneficiary?.length ?  */}
                  <OrderBeneficiaryInfo
                    orderBeneficiaryBirthdate={orderBeneficiary?.length ? orderBeneficiary?.[0]?.birthDate : order?.customer?.birthdate}
                    orderBeneficiaryContactName={orderBeneficiary?.length ? orderBeneficiary?.[0]?.name?.[0] : order?.customer?.contactName?.[0]}
                    orderBeneficiaryContactInfo={orderBeneficiaryContactInfo} 
                    isTrueBeneficiary={orderBeneficiary?.length > 0}
                  /> 
                  {/* : 
                  <OrderCustomerInfo
                    customer={order?.customer}
                  />
                } */}
                </DOModal>
              </div>
            </td>
          </tr>

          <tr>
            <th>Client Blacklisté :</th>
            <td>
              {blacklistMutationLoading ? (
                <Spinner
                  variant="primary ms-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <div className="d-flex">
                  <Form>
                    <Form.Check
                      type="switch"
                      id={'blacklisted-' + order.id}
                      checked={order.customer.badge === 'blacklist'}
                      onChange={blacklistCustomerSwitchHandler}
                    />
                  </Form>
                  <Form.Label className="m-0">
                    {order.customer.badge === 'blacklist' ? 'Oui' : 'Non'}
                  </Form.Label>
                </div>
              )}
            </td>
          </tr>

          {order.orderType === 'essdom' && (
            <tr>
              <th>Date du retour :</th>
              <td>
                <Form className="d-flex">
                  <Form.Control
                    type="date"
                    className="d-inline"
                    placeholder="Date du retour"
                    defaultValue={order.dateLimitReturnEssdom?.slice(0, 10)}
                    onChange={(e) => {
                      setDateLimitReturnEssdom(e.target.value)
                    }}
                  />
                  &nbsp;
                  <Button
                    variant="primary btn-sm"
                    disabled={
                      updateDateReturnLoading || dateLimitReturnEssdom === ''
                    }
                    onClick={() => handleUpdateDateLimitReturnEssdom()}
                  >
                    {loading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    Modifier
                  </Button>
                </Form>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  ) : (
    <Card>
      <p>Aucune commande trouvée</p>
    </Card>
  )
}

export default OrderInfo
