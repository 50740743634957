import { useQuery } from '@apollo/client'
import { FastForwardIcon, LightningBoltIcon } from '@heroicons/react/outline'
import HeaderTitle from 'components/HeaderTitle'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import React, { SetStateAction, useState } from 'react'
import { Tab } from 'react-bootstrap'
import Tabs from 'react-bootstrap/Tabs'
import Card from 'components/UI/Card'
import EventShortcutList from 'components/EventsShortcuts/EventShortcutList'
import ToggleSwitch from 'components/ToggleSwitch'
import EventShortcutOther from './EventShortcutOther'
import { constants } from 'utils/constants'
import GET_DO_ORDER_EVENT_TYPE_GRP_LISTING from 'graphql/queries/getDOOrderEventTypeGrpListing'

interface Props {
  eventsInRightSideBar: string[]
}
const EventShortcutTabs: React.FC<Props> = ({ eventsInRightSideBar }) => {
  const [key, setKey] = useState('verification')
  const [displayOtherEventTab, setDisplayOtherEventTab] = useState(false)

  const { loading, error, data } = useQuery(GET_DO_ORDER_EVENT_TYPE_GRP_LISTING)

  if (loading)
    return (
      <Card>
        <SkeletonBase count={4} variant="px-4 pt-1 mt-4" />
      </Card>
    )
  if (error) return <p>`Error! ${error.message}`</p>

  const tabs = (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k as SetStateAction<string>)}
      className="mb-3"
    >
      {data.getDOOrderEventTypeGrpListing.edges.map(
        ({ node: eventTypeGrp }: { node: eventTypeGrpType }) => {
          // on affiche la liste des events du grp other par ordre alphabétique
          let sortEventTypeList = [...eventTypeGrp.eventTypes]
          sortEventTypeList.sort((a: eventTypeType, b: eventTypeType) => {
            if (a.label && b.label) {
              return a.label.localeCompare(b.label)
            } else return 0
          })
          if (
            eventTypeGrp.key ===
            constants.eventTypeKeys.DOORDER_EVENT_TYPE_GRP_OTHER
          ) {
            return (
              <Tab
                eventKey={eventTypeGrp.key}
                title={eventTypeGrp.label}
                key={`${eventTypeGrp.key} ${
                  displayOtherEventTab ? 'active' : 'inactive'
                }`}
                tabClassName={!displayOtherEventTab ? 'd-none' : ''}
              >
                <EventShortcutOther
                  eventTypes={sortEventTypeList}
                  key={eventTypeGrp.key}
                  eventsInRightSideBar={eventsInRightSideBar}
                />
              </Tab>
            )
          } else if (
            eventTypeGrp.key !==
            constants.eventTypeKeys.DOORDER_EVENT_TYPE_GRP_SPECIAL_EVENTS
          ) {
            return (
              <Tab
                eventKey={eventTypeGrp.key}
                title={eventTypeGrp.label}
                key={`${eventTypeGrp.key} ${
                  eventTypeGrp.key === key ? 'active' : 'inactive'
                }`}
              >
                <EventShortcutList
                  eventTypes={sortEventTypeList}
                  key={eventTypeGrp.key}
                  eventsInRightSideBar={eventsInRightSideBar}
                />
              </Tab>
            )
          } else return <React.Fragment key={eventTypeGrp.key}></React.Fragment> // dans le cas ou les events font partie du groupe SPECIAL_EVENTS on affiche rien
        }
      )}
    </Tabs>
  )

  const otherEventHandler = (checked: boolean) => {
    setDisplayOtherEventTab(checked)
    if (checked) {
      setKey(
        constants.eventTypeKeys
          .DOORDER_EVENT_TYPE_GRP_OTHER as SetStateAction<string>
      )
    } else {
      setKey(
        constants.eventTypeKeys
          .DOORDER_EVENT_TYPE_GRP_VERIFICATION as SetStateAction<string>
      )
    }
  }

  return (
    <div className="custom-card mx-auto overflow-hidden py-3 mb-4">
      <div className="px-3">
        <div className="d-flex justify-content-between">
          <HeaderTitle
            children={
              displayOtherEventTab ? (
                <LightningBoltIcon className="header-title-icon" />
              ) : (
                <FastForwardIcon className="header-title-icon" />
              )
            }
            title={
              displayOtherEventTab ? 'Passage statut' : 'Raccourcis statuts'
            }
          />
          <div className="d-flex justify-content-right">
            <span>Passer un autre statut&nbsp;&nbsp;</span>
            <ToggleSwitch
              id={`pass-other-events`}
              isCheckedByDefault={false}
              onToggleClick={otherEventHandler}
            />
          </div>
        </div>
      </div>
      <div className="px-3">{tabs}</div>
    </div>
  )
}

export default EventShortcutTabs
