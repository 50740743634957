import { gql } from '@apollo/client'

const BLACKLIST_CUSTOMER = gql`
  mutation BLACKLIST_CUSTOMER($id: Int!, $blacklisted: Boolean!) {
    updateDOCustomer(id: $id, input: { blacklisted: $blacklisted }) {
      success
      output {
        id
        __typename
        blacklisted
        badge
      }
    }
  }
`

export default BLACKLIST_CUSTOMER
