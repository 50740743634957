import Card from 'components/UI/Card'
import { constants } from 'utils/constants'
import PickingTabs from '../../components/PickingTabs'

interface Props {}
const PickingEssdom: React.FC<Props> = () => {
  return (
    <Card>
      <PickingTabs
        isHomeTrial={true}
        columns={[
          'orderKey',
          'frames',
          'lastEventDateFormatted',
          'since',
          'fullName',
          'email',
          'phone',
        ]}
        pickingEventTypeKeys={[
          constants.eventTypeKeys.ORDER_EVENT_TYPE_PICKING_KEHL_HOME_TRIAL,
          constants.eventTypeKeys
            .ORDER_EVENT_TYPE_HOME_TRIAL_PICKING_IN_PREPARATION,
        ]}
      />
    </Card>
  )
}

export default PickingEssdom
