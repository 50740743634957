import React, { useState } from 'react'
import DOModal from '../Modal/DOModal'

function confirmChange() {
  const { REACT_APP_PIMCORE_URL_DOMAIN } = process.env
  window.location.href = REACT_APP_PIMCORE_URL_DOMAIN + '/admin'
}

function PimcoreBtn() {
  const [showConfirmSwitchBOModal, setShowConfirmSwitchBOModal] = useState(false)

  return (
    <div className="PimcoreBtn">
      <button
        onClick={() => setShowConfirmSwitchBOModal(true)}
        className="d-block link-dark text-decoration-none no-btn"
      >
        <img
          src="/assets/pimcore-logo.png"
          alt="Pimcore logo"
          height="32"
          width="32"
          className="rounded-circle"
        />
      </button>
      {/* Modale de confirmation de déconnexion */}
      <DOModal
        show={showConfirmSwitchBOModal}
        children={
          <div className="modalConfirmSwitchBO">
            Voulez-vous vraiment basculer sur Pimcore ?
          </div>
        }
        confirmModal={confirmChange}
        closeModal={() => setShowConfirmSwitchBOModal(false)}
      />
    </div>
  )
}

export default PimcoreBtn
