import React, { useState } from 'react'
import Card from 'components/UI/Card'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import { getTokenFromLocalStorage } from '../../utils/functions'

interface Props {}

const EtatDesStocks: React.FC<Props> = () => {
  const {REACT_APP_PIMCORE_URL_DOMAIN} = process.env
  const [loading, setLoading] = useState<boolean>(false)

  const downloadHandler = async () => {

      try {
        const options = () => {
          return {
            method: 'POST',
            headers: {
              'Accept': 'text/csv',
            },
            body: new URLSearchParams({
              'token': getTokenFromLocalStorage() // Security check
            })
          }
        }

        setLoading(true)
        await fetch(`${REACT_APP_PIMCORE_URL_DOMAIN}/export-stock-produits`,options())
        .then(response => {
          if(response.status!==200) 
            throw new Error('Error fetching the csv file.')
          else
          {
            response.text().then( csv => {
              const byteArray = new Uint8Array(csv.split('').map( (c: string) => c.charCodeAt(0) & 0xff))
              const csvData = new Blob([byteArray], { type:'text/csv;charset=ANSI/Windows-1252;' })
              const a = document.createElement("a");
              a.href = URL.createObjectURL(csvData);
              const today = new Date()
              a.download = `etat-des-stocks-${today.toLocaleDateString().padStart(10, '0').replace(/\//g,'')}-${today.toLocaleTimeString().replace(/:/g,'')}.csv`;
              a.click();
              a.remove()
            })
            .catch( error => {
              throw new Error(error)
            })
            setLoading(false)
          }
        })
        .catch( error => {
          throw new Error(error)
        })

      } catch (err) {
        setLoading(false)
        console.log(err)
        alert('Une erreur est survenue')
      }
  }

  return (
    <div>
      <Card>
        <div className="container">
          <h2>Télécharger les données de l'état des stocks</h2>
          <div className="mt-3">
          <Button
          disabled={loading}
          variant="primary shadow zindex-2 btn-radius "
          type="button"
          onClick={downloadHandler}
        >
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              variant="light"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            'Télécharger'
          )}
        </Button>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default EtatDesStocks