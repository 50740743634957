import Card from 'components/UI/Card'
import React, { useReducer, useState } from 'react'
import BDRModal from 'components/gestionBDRTemplate/BDRModal'
import { GET_DO_VOUCHER_TEMPLATE_LISTING } from 'graphql/queries/getDOVoucherTemplateListing'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import { getFormattedDate, stringsToJSXs } from 'utils/functions'
import ResultsTable from 'components/UI/ResultsTable'
import { constants } from 'utils/constants'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'

interface Props {}
const GestionBDRTemplate: React.FC<Props> = () => {
  const { t } = useTranslation()

  const [showModal, setShowModal] = useState(false)
  const [templateToEdit, setTemplateToEdit] = useState({})

  const filteredTemplatesReducer = (state: any, action: any) => {
    let { templates, codeFilter, prefixFilter, labelFilter } = state

    let filteredTemplates = []

    if (action.type === 'UPDATE_TEMPLATES') {
      templates = action.value
      filteredTemplates = templates
    }

    if (
      [
        'UPDATE_LABEL_FILTER',
        'UPDATE_CODE_FILTER',
        'UPDATE_PREFIX_FILTER',
      ].includes(action.type)
    ) {
      if (action.type === 'UPDATE_LABEL_FILTER') labelFilter = action.value
      if (action.type === 'UPDATE_CODE_FILTER') codeFilter = action.value
      if (action.type === 'UPDATE_PREFIX_FILTER') prefixFilter = action.value

      filteredTemplates = templates.filter(
        ({
          clientLabel,
          genericCode,
          voucherPrefixedCode,
        }: {
          clientLabel: any
          voucherPrefixedCode: any
          genericCode: any
        }) => {
          return (
            (labelFilter === '' ||
              (clientLabel !== null &&
                clientLabel
                  .toLowerCase()
                  .includes(labelFilter.toLowerCase()))) &&
            (prefixFilter === '' ||
              (voucherPrefixedCode !== null &&
                voucherPrefixedCode
                  .toLowerCase()
                  .includes(prefixFilter.toLowerCase()))) &&
            (codeFilter === '' ||
              (genericCode !== null &&
                genericCode.toLowerCase().includes(codeFilter.toLowerCase())))
          )
        }
      )
    }

    return {
      templates,
      filteredTemplates,
      labelFilter,
      prefixFilter,
      codeFilter,
    }
  }

  const [filteredTemplatesState, dispatchFilteredTemplates] = useReducer(
    filteredTemplatesReducer,
    {
      templates: [],
      filteredTemplates: [],
      labelFilter: '',
      prefixFilter: '',
      codeFilter: '',
    }
  )

  const { data, error, loading } = useQuery(GET_DO_VOUCHER_TEMPLATE_LISTING, {
    variables: {
      withoutNewVoucherCode: true,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      const jsonData = JSON.parse(JSON.stringify(data))
      const formattedData: any = []

      jsonData.getDOVoucherTemplateListing?.edges?.forEach(
        ({ node: item }: { node: any }, index: number, orders: any) => {
          item.prettyInitialAmount = `${item?.initialAmount?.value.toFixed(
            2
          )} ${constants.currency.EURO}`
          item.prettyProductType = t(item?.productType)

          item.prettyDateEnd = item?.dateEnd
            ? getFormattedDate(item?.dateEnd)
            : ''

          item.dateBegin = item?.dateBegin?.slice(0, 10)
          item.dateEnd = item?.dateEnd?.slice(0, 10)
          item.active = item?.active ? 'oui' : 'non'
          item.brands = item?.brands?.map(({ id }: { id: any }) => id)

          formattedData.push(item)
        }
      )
      dispatchFilteredTemplates({
        type: 'UPDATE_TEMPLATES',
        value: formattedData,
      })
    },
  })

  const onClickRowsHandler = (item: any) => {
    setShowModal(true)
    setTemplateToEdit(item)
  }

  if (loading)
    return (
      <Card>
        <SkeletonBase count={5} />
      </Card>
    )
  if (error) return <p>`Error! ${error.message}`</p>

  return (
    <Card>
      <Container>
        <Row className="justify-content-center">
          <Col>
            {' '}
            <Form.Group className="mb-3" controlId="label">
              <Form.Control
                type="text"
                placeholder="Recherche par libellé"
                onChange={(e) => {
                  dispatchFilteredTemplates({
                    type: 'UPDATE_LABEL_FILTER',
                    value: e.target.value,
                  })
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            {' '}
            <Form.Group className="mb-3" controlId="prefix">
              <Form.Control
                type="text"
                placeholder="Recherche par préfixe"
                onChange={(e) => {
                  dispatchFilteredTemplates({
                    type: 'UPDATE_PREFIX_FILTER',
                    value: e.target.value,
                  })
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            {' '}
            <Form.Group className="mb-3" controlId="code">
              <Form.Control
                type="text"
                placeholder="Recherche par code Avantage"
                onChange={(e) => {
                  dispatchFilteredTemplates({
                    type: 'UPDATE_CODE_FILTER',
                    value: e.target.value,
                  })
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Button
              variant="primary"
              onClick={() => {
                setShowModal(true)
              }}
            >
              Ajouter BDR Template
            </Button>
            {showModal && (
              <BDRModal
                showModal={showModal}
                bdrTemplate={templateToEdit}
                closeModal={() => {
                  setShowModal(false)
                  setTemplateToEdit({})
                }}
              ></BDRModal>
            )}
          </Col>
        </Row>
      </Container>

      {filteredTemplatesState.filteredTemplates.length > 0 && (
        <ResultsTable
          items={filteredTemplatesState.filteredTemplates}
          queryType="Gestion BDR template"
          onClickRows={onClickRowsHandler}
          columns={stringsToJSXs(
            [
              'voucherPrefixedCode',
              'genericCode',
              'clientLabel',
              'prettyInitialAmount',
              'prettyProductType',
              'prettyDateEnd',
              'voucherType',
              'active',
            ],
            t
          )}
        ></ResultsTable>
      )}
    </Card>
  )
}

export default GestionBDRTemplate
