import { gql } from "@apollo/client";
import { DO_CONTACT_NAME_FC_FIELDS } from "graphql/fragments/DOContactNameFCFields";

export const GET_ORDER_DELIVERY_DETAILS = gql`
    ${DO_CONTACT_NAME_FC_FIELDS}
    query GET_ORDER_DELIVERY_DETAILS($id: Int!) {
      getDOOrder(id: $id) {
        id
        deliveryMode {
          ... on object_DODeliveryMode {
            label
            active
            id
            key
          }
        }
        deliveryName {
          ...DOContactNameFCFields
        }
        deliveryAddress {
          ... on fieldcollection_DOContactInfoFC {
            email
            address
            city
            postalCode
            additionalAddressDetails
            country
            mobilePhoneNumber
            landlinePhoneNumber
          }
        }
        deliveryStore {
          ... on object_DOStore {
            id
            label
            contactInfo {
              ... on fieldcollection_DOContactInfoFC {
                email
                address
                additionalAddressDetails
                postalCode
                city
                country
                mobilePhoneNumber
                landlinePhoneNumber
              }
            }
          }
        }
        pickupPointCode
      }
    }
  `