import { useQuery } from '@apollo/client'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import Card from 'components/UI/Card'
import ResultsTable from 'components/UI/ResultsTable'
import UniqueVoucherTemplateListToggle from 'components/UniqueVoucherTemplateListToggle'
import { GET_DO_VOUCHER_TEMPLATE_LISTING } from 'graphql/queries/getDOVoucherTemplateListing'
import React, { useReducer } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { constants } from 'utils/constants'
import { stringsToJSXs } from 'utils/functions'

interface Props {}
const GenerationCodesBDREnMasse: React.FC<Props> = () => {
  const { t } = useTranslation()

  const curDateWithoutTime = new Date().setHours(0, 0, 0, 0) / 1000 // We need to remove hours/minutes/seconds, else the component will call the query in infinite loop when refreshing results

  const filteredTemplatesReducer = (state: any, action: any) => {
    let { templates, prefixFilter, labelFilter } = state

    let filteredTemplates = []

    if (action.type === 'UPDATE_TEMPLATES') {
      templates = action.value
      filteredTemplates = templates
    }

    if (['UPDATE_LABEL_FILTER', 'UPDATE_PREFIX_FILTER'].includes(action.type)) {
      if (action.type === 'UPDATE_LABEL_FILTER') labelFilter = action.value
      if (action.type === 'UPDATE_PREFIX_FILTER') prefixFilter = action.value

      filteredTemplates = templates.filter(
        ({
          clientLabel,
          voucherPrefixedCode,
        }: {
          clientLabel: any
          voucherPrefixedCode: any
        }) => {
          return (
            (labelFilter === '' ||
              (clientLabel !== null &&
                clientLabel
                  .toLowerCase()
                  .includes(labelFilter.toLowerCase()))) &&
            (prefixFilter === '' ||
              (voucherPrefixedCode !== null &&
                voucherPrefixedCode
                  .toLowerCase()
                  .includes(prefixFilter.toLowerCase())))
          )
        }
      )
    }

    return {
      templates,
      filteredTemplates,
      labelFilter,
      prefixFilter,
    }
  }

  const [filteredTemplatesState, dispatchFilteredTemplates] = useReducer(
    filteredTemplatesReducer,
    {
      templates: [],
      filteredTemplates: [],
      labelFilter: '',
      prefixFilter: '',
    }
  )

  const { loading, error } = useQuery(GET_DO_VOUCHER_TEMPLATE_LISTING, {
    variables: {
      filter: `{ "$and": [
        {"voucherType": "${constants.voucherTypes.VOUCHER_TYPE_UNIQUE}"},
        { "$or": [{"dateBegin": null}, {"dateBegin": {"$lte":${curDateWithoutTime}}}] },
        { "$or": [{"dateEnd": null}, {"dateEnd": {"$gte":${curDateWithoutTime}}}] },
        {"active": true}
      ] }`,
    },
    onCompleted: (data) => {
      const jsonData = JSON.parse(JSON.stringify(data))

      const formattedData: any = []

      jsonData.getDOVoucherTemplateListing?.edges?.forEach(
        ({ node: item }: { node: any }) => {
          item.label = item?.clientLabel
          item.amount = `${item?.initialAmount?.value.toFixed(2)} ${
            constants.currency.EURO
          }`

          // Pass the toggle component itself
          item.toggleComponent = UniqueVoucherTemplateListToggle

          formattedData.push(item)
        }
      )
      dispatchFilteredTemplates({
        type: 'UPDATE_TEMPLATES',
        value: formattedData,
      })
    },
  })

  if (loading)
    return (
      <Card>
        <SkeletonBase count={5} />
      </Card>
    )
  if (error) return <p>`Error! ${error.message}`</p>

  return (
    <Card>
      <Container>
        <Row className="justify-content-center">
          <Col>
            {' '}
            <Form.Group className="mb-3" controlId="label">
              <Form.Control
                type="text"
                placeholder="Recherche par libellé"
                autoComplete="off"
                onChange={(e) => {
                  dispatchFilteredTemplates({
                    type: 'UPDATE_LABEL_FILTER',
                    value: e.target.value,
                  })
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            {' '}
            <Form.Group className="mb-3" controlId="prefix">
              <Form.Control
                type="text"
                placeholder="Recherche par préfixe"
                autoComplete="off"
                onChange={(e) => {
                  dispatchFilteredTemplates({
                    type: 'UPDATE_PREFIX_FILTER',
                    value: e.target.value,
                  })
                }}
              />
            </Form.Group>
          </Col>
        </Row>
      </Container>

      <ResultsTable
        items={filteredTemplatesState.filteredTemplates}
        queryType=""
        columns={stringsToJSXs(
          ['label', 'voucherPrefixedCode', 'amount', 'duration'],
          t
        )}
      ></ResultsTable>
    </Card>
  )
}

export default GenerationCodesBDREnMasse
