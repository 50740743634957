import { Route, NavLink } from 'react-router-dom'

import { MenuListInterface, PageInterface } from './LoginDataInterface'

import Components from './Components' // liste des composants du menu (lien avec les objets)
import GestionCommandeClient from '../containers/relation_client/GestionCommandeClient'
import GestionBDR from '../containers/bons_de_reduction/GestionBDR'
import EditCustomer from '../containers/EditCustomer'
import SearchResultsListing from '../containers/search/SearchResultsListing'

const buildUserRoutes = (menus: any) => {
  let aRoutes: JSX.Element[] = []
  menus.forEach((menu: MenuListInterface) =>
    menu!.pageList!.forEach((page: PageInterface) => {
      if (page.component != null) {
        aRoutes.push(
          <Route exact path={page.path} key={page.path}>
            <div className="breadcrumb">
              <NavLink
                to="/"
                className="breadcrumb-item link-dark rounded"
                activeStyle={{
                  color: '#007BFF',
                }}
              >
                Home
              </NavLink>
              <div className="breadcrumb-item">{menu.name}</div>
              <div className="breadcrumb-item active">{page.name}</div>
            </div>
            {Components(page.component)}
          </Route>
        )
      }
    })
  )
  aRoutes.push(
    <Route
      exact
      path="/bo/relation_client/gestion_commande_client/:id"
      key="gestion_commande_client_id"
    >
      <div className="breadcrumb">
        <NavLink
          to="/"
          className="breadcrumb-item link-dark rounded"
          activeStyle={{
            color: '#007BFF',
          }}
        >
          Home
        </NavLink>
        <div className="breadcrumb-item active">Gestion commande client</div>
      </div>
      <GestionCommandeClient />
    </Route>
  )
  aRoutes.push(
    <Route
      exact
      path="/bo/bons_de_reduction/gestion_bdr/:id"
      key="gestion_bdr_id"
    >
      <div className="breadcrumb">
        <NavLink
          to="/"
          className="breadcrumb-item link-dark rounded"
          activeStyle={{
            color: '#007BFF',
          }}
        >
          Home
        </NavLink>
        <div className="breadcrumb-item active">Gestion bon de réduction</div>
      </div>
      <GestionBDR />
    </Route>
  )

  aRoutes.push(
    <Route exact path="/bo/edition_client/:id" key="edition_client_id">
      <div className="breadcrumb">
        <NavLink
          to="/"
          className="breadcrumb-item link-dark rounded"
          activeStyle={{
            color: '#007BFF',
          }}
        >
          Home
        </NavLink>

        <div className="breadcrumb-item active">
          Édition des informations personnelles du client
        </div>
      </div>
      <EditCustomer />
    </Route>
  )
  aRoutes.push(
    <Route path="/search" key="search" component={SearchResultsListing}></Route>
  )

  return aRoutes
}

export default buildUserRoutes
