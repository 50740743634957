import { Button, Spinner } from 'react-bootstrap'
import { ToastContext } from 'store/toast-context'
import { useContext } from 'react'
import { useMutation } from '@apollo/client'
import GET_ORDER_EVENTS from 'graphql/queries/getDOOrderEvents'
import CM_CREATE_DO_ORDER_EVENT from 'graphql/mutations/CreateDOOrderEvent'

interface Props {
  orderId: number
  eventTypeKey: string
  closeModal?: () => void
  setIsMutationLoading?: React.Dispatch<React.SetStateAction<boolean>>
}

const OrderReturnToVerificationModal: React.FC<Props> = ({
    orderId,
    eventTypeKey,
    closeModal,
    setIsMutationLoading
  }) => {
  const { toast } = useContext(ToastContext)

  // mutation create event
  const [createDOOrderEvent, { loading }] = useMutation(
    CM_CREATE_DO_ORDER_EVENT,
      {
          variables: {
            orderId: orderId,
            eventTypeKey 
          },
          refetchQueries: [GET_ORDER_EVENTS],
          awaitRefetchQueries: true,
          onCompleted: () => {
            if(setIsMutationLoading)
              setIsMutationLoading(false)

            toast({
              title: 'Succès :',
              message: 'La commande est de nouveau en vérification',
              })
            if (closeModal) closeModal()
          },
          
        }
  )
    
  const clickConfirmHandler = async () => {
    try {
      if(setIsMutationLoading)
        setIsMutationLoading(true)
        
      await createDOOrderEvent()
    } catch (e) {
      console.log(e)
    }
  }

  const clickCancelSaveHandler = () => {
    if(closeModal) closeModal()
  }

  return (
  <>
    <div className="mt-4 mb-4 text-center">
      Êtes-vous sûr(e) de vouloir remettre la commande en vérification ?
    </div>
    <div className="mt-3 text-center">
      <Button
        className="m-2 btn-primary"
        disabled={loading}
        onClick={clickConfirmHandler}
      >
        {(loading) && (
          <Spinner
            variant="light"
            className="me-2"
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
        Confirmer
      </Button>
      <Button
        variant="outline-primary bg-white text-primary"
        className="m-2"
        onClick={clickCancelSaveHandler}
      >
        Annuler
      </Button>
    </div>
  </>)
}

export default OrderReturnToVerificationModal