import React, { Component } from "react";

export default class MoteurDeRecherche extends Component {
    render() {
        return (
            <div>
                <h1>MoteurDeRecherche</h1>
            </div>
        );
    }
}
