import React, { useState } from 'react'
import { generatePassword } from 'utils/functions'

const useForm = (validate: any, inputNamesTab: string[]) => {
  // source : https://github.com/briancodex/react-form-v1/blob/master/src/useForm.js

  //pour construire le state initial des 'values' du formulaire et savoir si un input 'isfocusOut'
  let newValues = {} as any
  let newIsFocusOut = {} as any
  inputNamesTab.forEach((inputName) => {
    if (inputName.match('date')) {
      newValues[inputName] = new Date(Date.now())
    } else {
      newValues[inputName] = ''
    }
    newIsFocusOut[inputName] = false
  })
  const [values, setValues] = useState(newValues)
  const [errors, setErrors] = useState({} as any)
  const [isFocusOut, setIsFocusOut] = useState(newIsFocusOut)

  //generate password
  const handleGeneratePassword = (name:string) =>  {
   //when generating password the form is updated so we update setIsFocusOut
    setIsFocusOut({
      ...isFocusOut,
      [name]: true,
    })
    //set password 
    setValues({
      ...values,
      [name]: generatePassword(),
    })}
  //copy password
  const handleCopyPassword = (name:string) => navigator.clipboard.writeText(values[name]) 
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value,
    })
  }
  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value,
    })
  }
  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement>,
    promise: () => Promise<void> //fonction qui sera appelée si tous les champs sont valides
  ) => {
    e.preventDefault()

    // si on touche au formulaire : isFocusOut aura au moins un objet avec value true
    if (Object.values(isFocusOut).find((value) => value === true)) {
      //form is submit
      return promise()
    }
  }

  // fonction qui permet de savoir si on a touché à un input
  const handleFocusOut = (e: React.FocusEvent<HTMLInputElement>) => {
    setErrors(validate(values))
    const { name } = e.target
    setIsFocusOut({
      ...isFocusOut,
      [name]: true,
    })
  }
  // fonction qui permet de savoir si on a touché à un select
  const handleFocusOutSelect = (e: React.FocusEvent<HTMLSelectElement>) => {
    setErrors(validate(values))
    const { name } = e.target
    setIsFocusOut({
      ...isFocusOut,
      [name]: true,
    })
  }
  // fonction qui permet de savoir si on a touché à un autre élément: radio, check
  const isFocusOutAndSetErrors = (name: string) => {
    setIsFocusOut({
      ...isFocusOut,
      [name]: true,
    })
    setErrors(validate(values))
  }

  return {
    handleChange,
    values,
    handleSubmit,
    errors,
    setValues,
    handleFocusOut,
    isFocusOut,
    setIsFocusOut,
    handleChangeSelect,
    handleFocusOutSelect,
    isFocusOutAndSetErrors,
    handleGeneratePassword,
    handleCopyPassword
  }
}

export default useForm
