import React, { useEffect, useRef } from 'react'
import { CSVLink } from 'react-csv'

interface Props {
  data: any
  enclosingCharacter: string
  separator: string
  filename: string
  target: string
}

const CSVDownload: React.FC<Props> = (props) => {
  const btnRef = useRef<any>(null)
  useEffect(() => btnRef.current?.click(), [btnRef])
  return (
    <CSVLink {...props} >
      <span ref={btnRef} />
    </CSVLink>
  )
}

export default CSVDownload