import React, { useState, useRef, useEffect } from 'react'
import './Searchbar.css'
import Radio from './Radio'
import Modal from 'react-bootstrap/Modal'
import { SearchCircleIcon, XCircleIcon } from '@heroicons/react/outline'
import SearchInputState, {
  defaultSearchInputStateValue,
} from '../../utils/SearchInputStateInterface'
import { validateCosiumId } from 'utils/functions'
import { useHistory } from 'react-router-dom'

// const validateEmail = (email: string) => {
//   const re =
//     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   return re.test(String(email).toLowerCase());
// };

const validatePimcoreKey = (id: string) => {
  return /^(C|E|c|e)[0-9]{8,8}$/.test(String(id))
}

// One query per checked radio button ?
const Searchbar = () => {
  const history = useHistory()
  // Bootstrap modal state
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  // Inputs values
  const searchInputRef = useRef<HTMLInputElement>(null)
  const searchInputFirstNameRef = useRef<HTMLInputElement>(null)
  const searchInputLastNameRef = useRef<HTMLInputElement>(null)

  const [isSearchInputValid, setIsSearchInputValid] =
    useState<SearchInputState>(defaultSearchInputStateValue)

  // Search filter state (Client, Bénéficiaire, Email)
  const [searchFilter, setSearchFilter] = useState<string>('commande')
  //remove spaces
  let searchInputQuery = searchInputRef?.current?.value.replace(/\s+/g, "") || ''
  const inputHasCosiumIdFormat = validateCosiumId(searchInputQuery)
  const inputHasPimcoreKeyFormat = validatePimcoreKey(searchInputQuery)
  // Submit search handler (Makes the GraphQL call)
  const searchHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const searchInputLastNameQuery =
      searchInputLastNameRef?.current?.value?.replace(/\s+/g, ' ').trim() || ''
    const searchInputFirstNameQuery =
      searchInputFirstNameRef?.current?.value?.replace(/\s+/g, ' ').trim() || '' 
    if (searchFilter === 'client') {
      if (searchInputLastNameQuery === '' && searchInputFirstNameQuery==='') {
        setIsSearchInputValid({
          valid: false,
          message: 'Le prénom ou nom est requis',
        })
        return
      }
    } else if (searchFilter === 'commande') {
      if (searchInputQuery === '') {
        setIsSearchInputValid({
          valid: false,
          message:
            'Le numéro de devis Cosium, de commande ou la clé Pimcore est obligatoire',
        })
        return
      }
    } else if (searchFilter === 'email') {
      if (searchInputQuery === '') {
        setIsSearchInputValid({
          valid: false,
          message: "L'email est obligatoire",
        })
        return
      }
    }

    let param = 'email'
    if (searchFilter === 'commande') {
      param = inputHasCosiumIdFormat
        ? 'cosiumId'
        : inputHasPimcoreKeyFormat
        ? 'key'
        : 'id'
    }

    handleClose()
    history.push(`/search?type=${searchFilter}&${param}=${inputHasPimcoreKeyFormat ? searchInputQuery.toUpperCase() : searchInputQuery}&firstName=${searchInputFirstNameQuery}&lastName=${searchInputLastNameQuery}`)
  }

  useEffect(() => {
    if(show)
    {
      if(searchFilter === 'client')
        searchInputLastNameRef.current?.focus()
      else
        searchInputRef.current?.focus()
    }
  }, [show, searchFilter])

  let searchInputContent = <p>Loading Search filter</p>

  if (searchFilter === 'client') {
    searchInputContent = (
      <form className="row mt-3" onSubmit={searchHandler}>
        <div className="col-md-4">
          <input
            type="text"
            className={`form-control rounded ${
              !isSearchInputValid.valid ? 'is-invalid' : ''
            }`}
            placeholder="Prénom"
            onChange={() => setIsSearchInputValid({ valid: true, message: '' })}
            ref={searchInputFirstNameRef}
          />
        </div>
        <div className="col-md-4">
          <input
            type="text"
            className={`form-control rounded ${
              !isSearchInputValid.valid ? 'is-invalid' : ''
            }`}
            placeholder="Nom"
            onChange={() => setIsSearchInputValid({ valid: true, message: '' })}
            ref={searchInputLastNameRef}
          />
          {!isSearchInputValid.valid && (
            <div className="invalid-feedback">{isSearchInputValid.message}</div>
          )}
        </div>
        <div className="col-md-4 ps-0">
          <button className="border-0 bg-transparent ps-0" type="submit">
            <SearchCircleIcon
              style={{ width: '2rem' }}
              className="custom-blue-link lh-5"
            />
          </button>
        </div>
      </form>
    )
  } else {
    searchInputContent = (
      <form className="row mt-3" onSubmit={searchHandler}>
        <div
          className={`${searchFilter === 'email' ? 'col-md-6' : 'col-md-8'}`}
        >
          <div className="input-group has-validation">
            <input
              className={`form-control rounded-0 rounded-start border-end-0 ${
                !isSearchInputValid.valid ? 'is-invalid' : ''
              }`}
              type="text"
              placeholder={`Chercher ${
                searchFilter === 'email'
                  ? 'un client par email'
                  : 'par N° de commande ou N° de devis Cosium'
              }`}
              onChange={() => setIsSearchInputValid({ valid: true, message: '' })}
              ref={searchInputRef}
            />

            <button
              type="submit"
              className="input-group-text border-start-0 bg-transparent"
            >
              <SearchCircleIcon
                className="custom-blue-link"
                style={{ width: '2rem' }}
              />
            </button>
            {!isSearchInputValid.valid && (
              <div className="invalid-feedback">
                {isSearchInputValid.message}
              </div>
            )}
          </div>
        </div>
      </form>
    )
  }

  return (
    <>
      <div className="input-group">
        <input
          className="search-input form-control me-2 input-field"
          type="search"
          aria-label="Search"
          placeholder="Rechercher une commande, un client, un e-mail"
          onClick={handleShow}
          readOnly
        />
        <button className="border-icon custom-blue-link" type="submit">
          <SearchCircleIcon style={{ width: '2rem' }} onClick={handleShow} />
        </button>
      </div>

      <Modal size="lg" show={show} backdrop="static" keyboard={false}>
        <Modal.Body>
          <XCircleIcon
            onClick={handleClose}
            style={{ width: '2rem', top: '0', right: '0' }}
            className="position-absolute custom-blue-link pt-2 pe-2"
          />
          <Radio
            onChangeSearchFilter={(e) => {
              setSearchFilter(e.target.value)
              setIsSearchInputValid({ valid: true, message: '' })
            }}
            chosenFilter={searchFilter}
          />
          {searchInputContent}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Searchbar
