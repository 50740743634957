import { useContext } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap'
import { CQ_GET_ORDER } from 'graphql/queries/getOrder'
import { ToastContext } from 'store/toast-context'

interface Props {
  orderId: number
  isModification: boolean
  setDisplayAddSecondPairDiscount: React.Dispatch<React.SetStateAction<boolean>>
}

const AddSecondPairDiscountForm: React.FC<Props> = ({
  orderId,
  isModification,
  setDisplayAddSecondPairDiscount
}) => {

  const { toast } = useContext(ToastContext)

  const ADD_SECOND_PAIR_DISCOUNT = gql`
    mutation ADD_SECOND_PAIR_DISCOUNT($orderId: Int!) {
      addSecondPairDiscount(orderId: $orderId)
      {
        success
      }
    }
  `

  const [addSecondPaidDiscount, {loading: mutationLoading}] = useMutation(
    ADD_SECOND_PAIR_DISCOUNT, {
    onCompleted: (data) => {
      toast({
        title: data.success ? 'Succès :' : 'Erreur:',
        message: data.success ? 'Remise 2nde paire ajoutée|modifiée.' : 'Aucune modification apportée sur la commande.'
      })
      setDisplayAddSecondPairDiscount(false)
    }}
  )

  const confirmationHandler = async () => {
    try {
      await addSecondPaidDiscount({
        variables: {
          orderId: orderId
        },
        refetchQueries: [CQ_GET_ORDER]
      })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Container className="mt-3 mb-3">
        <Row>
          <Col className="d-flex text-nowrap justify-content-end align-items-center">Êtes-vous sûr(e) de vouloir {isModification ? `modifier`: `ajouter`} la remise 2nde paire ?</Col>
          <Col>
            <Button className="me-2" onClick={() => {
              setDisplayAddSecondPairDiscount(false)
            }}>Retour</Button>
            <Button className="btn btn-success" onClick={() => {
              confirmationHandler()
            }}>Confirmer{mutationLoading ? <Spinner
              variant="light"
              className="ms-2"
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />: ``}</Button>
          </Col>
        </Row>

      </Container>
    </>
  )
}

export default AddSecondPairDiscountForm