import React, { useState, useRef } from 'react'
import './Searchbar.css'
import Modal from 'react-bootstrap/Modal'
import { SearchCircleIcon, XCircleIcon } from '@heroicons/react/outline'
import SearchInputState, {
  defaultSearchInputStateValue,
} from '../../utils/SearchInputStateInterface'

const SearchbarStoreOptician = () => {
  // Bootstrap modal state
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  // Inputs values
  const searchInputRef = useRef<HTMLInputElement>(null)

  const [isSearchInputValid, setIsSearchInputValid] =
    useState<SearchInputState>(defaultSearchInputStateValue)

  // Submit search handler (Makes the GraphQL call)
  const searchHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const searchInputQuery = searchInputRef?.current?.value || ''

    if (searchInputQuery === '') {
      setIsSearchInputValid({
        valid: false,
        message: 'Veuillez saisir un bon de réduction',
      })

      return
    }

    handleClose()
    window.open(`/search?type=bon_reduction&code=${searchInputQuery}`, '_blank')
  }

  let searchInputContent = <p>Loading Search filter</p>

  searchInputContent = (
    <form className="row mt-3" onSubmit={searchHandler}>
      <div className="col-md-6">
        <div className="input-group has-validation">
          <input
            className={`form-control rounded-0 rounded-start border-end-0 ${
              !isSearchInputValid.valid ? 'is-invalid' : ''
            }`}
            type="text"
            placeholder={`Bon de réduction`}
            onChange={() => setIsSearchInputValid({ valid: true, message: '' })}
            ref={searchInputRef}
          />

          <button
            type="submit"
            className="input-group-text border-start-0 bg-transparent"
          >
            <SearchCircleIcon
              className="custom-blue-link"
              style={{ width: '2rem' }}
            />
          </button>
          {!isSearchInputValid.valid && (
            <div className="invalid-feedback">{isSearchInputValid.message}</div>
          )}
        </div>
      </div>
    </form>
  )

  return (
    <>
      <div className="input-group">
        <input
          className="search-input form-control me-2 input-field"
          type="search"
          aria-label="Search"
          placeholder="Rechercher un bon"
          onClick={handleShow}
        />
        <button className="border-icon" type="submit">
          <SearchCircleIcon
            style={{ width: '2rem' }}
            className="custom-blue-link lh-5"
          />
        </button>
      </div>

      <Modal size="lg" show={show} backdrop="static" keyboard={false}>
        <Modal.Body>
          <XCircleIcon
            onClick={handleClose}
            style={{ width: '2rem', top: '0', right: '0' }}
            className="position-absolute custom-blue-link pt-2 pe-2"
          />
          {searchInputContent}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SearchbarStoreOptician
