import { Redirect, Route } from 'react-router-dom'
import Login from '../containers/Login'

const LoginRoute = () => {
  return (
    <>
      <Route path="/login" component={Login} exact></Route>
      <Redirect to="/login" />
    </>
  )
}

export default LoginRoute
