import { gql } from '@apollo/client'

export const DO_VISION_CORRECTION_FC_FIELDS = gql`
  fragment DOVisionCorrectionFCFields on fieldcollection_DOVisionCorrectionFC {
    pupillaryDistance
    sphere
    cylinder
    axis
    addition
    iobase
    ioprism
    udbase
    udprism
  }
`
