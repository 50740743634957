import { ClipboardListIcon } from '@heroicons/react/outline'
import React, { useState } from 'react'
import HeaderTitle from 'components/HeaderTitle'
import OrderHistoryTable from 'components/OrderCard/OrderHistoryTable'
import './OrderCard.css'
import { gql, useQuery } from '@apollo/client'
import { DO_CONTACT_NAME_FC_FIELDS } from 'graphql/fragments/DOContactNameFCFields'
import { useParams } from 'react-router-dom'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import DOButton from 'components/Modal/DOButton'
import DOModal from 'components/Modal/DOModal'

const History: React.FC = () => {
  const { id } = useParams<ParamType>()

  const [showOrderHistoryModal, setShowOrderHistoryModal] = useState(false)

  const GET_CUSTOMER_ORDERS_HISTORY = gql`
    ${DO_CONTACT_NAME_FC_FIELDS}
    query GET_CUSTOMER_ORDERS_HISTORY($id: Int!) {
      getDOOrder(id: $id) {
        id
        customer {
          ... on object_DOCustomer {
            id
            orders {
              ... on object_DOOrder {
                id
                key
                orderType
                creationDate
                modificationDate
                workflowState
                orderLines {
                  ... on object_DOOrderLine {
                    id
                    prescription {
                      ... on object_DOPrescription {
                        beneficiary {
                          name {
                            ...DOContactNameFCFields
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            ordersOldSite {
              ... on object_DOOrderOldSite {
                creationDateOrderOldSite
                orderType
                orderCode
                beneficiaries
              }
            }
            ordersPaidCount
          }
        }
      }
    }
  `

  const { loading, error, data } = useQuery(GET_CUSTOMER_ORDERS_HISTORY, {
    variables: {
      id: +id,
    },
  })

  if (loading) return <SkeletonBase count={2} variant="px-4 pb-3" />

  if (error) return <p>`Error! ${error.message}`</p>

  const order = data.getDOOrder

  let homeTrials = 0

  order.customer.orders.map((elem: any) => {
    if (elem.orderType === 'essdom') {
      return homeTrials++
    }
    return homeTrials
  })

  return (
    <div className="history bg-white p-3">
      <div className="d-flex justify-content-between">
        <HeaderTitle
          title="Historique commandes clients"
          children={<ClipboardListIcon className="header-title-icon" />}
        />
        <div>
          <DOButton
            className="link-primary text-end"
            text="Afficher l'historique des commandes"
            handleClick={() => setShowOrderHistoryModal(true)}
          />
          <DOModal
            show={showOrderHistoryModal}
            size="lg"
            closeModal={() => setShowOrderHistoryModal(false)}
          >
            <OrderHistoryTable customer={order.customer} orderId={id} />
          </DOModal>
        </div>
      </div>
      <table className="table-order">
        <tbody>
          <tr>
            <th>Commande(s)</th>
            {order.customer.orders.length >= 0 ? (
              <td>
                {`${
                  order.customer.orders.length >= 0
                    ? order.customer.orders.length
                    : ''
                } dont ${order.customer.ordersPaidCount} payée(s) `}
              </td>
            ) : (
              <td>0</td>
            )}
          </tr>
          <tr>
            <th>Essais à Dom.:</th>
            <td>{homeTrials}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default History
