import { gql } from '@apollo/client'

export const DO_CONTACT_INFO_FC_FIELDS = gql`
  fragment DOContactInfoFCFields on fieldcollection_DOContactInfoFC {
    address
    city
    postalCode
    additionalAddressDetails
    country
    mobilePhoneNumber
    landlinePhoneNumber
    email
  }
`
