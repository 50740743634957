export const constants = {
  workflow: {
    transitions: {
      CONSIDERED: 'considered',
      DELIVERED: 'delivered',
      PREPARED: 'prepared',
    },
    state: {
      CREATED: 'created',
      AWAITING_PAYMENT: 'awaiting_payment',
      COMPLETED: 'completed',
      SAV: 'aftersales_check',
    },

    labelColor: {
      created: { label: 'Création de la commande', color: '#DDDDDD' },
      awaiting_payment: { label: 'En attente de paiement', color: '#B0C4DE' },
      in_verification: { label: 'En vérification', color: '#ADD8E6' },
      awaiting_preparation: {
        label: 'En attente de préparation',
        color: '#87CEEB',
      },
      in_preparation: { label: 'En préparation', color: '#6495ED' },
      delivery_in_progress: {
        label: 'En cours de livraison',
        color: '#00BFFF',
      },
      completed: { label: 'Terminée', color: '#0cd100' },
      cancelled: { label: 'Annulée', color: '#ff7d7a' },
      trial_in_progress: { label: 'Essdom en cours', color: '#00BFFF' },
    } as any,
  },
  eventTypeKeys: {
    ORDER_EVENT_TYPE_ARRIVED_AT_STORE: 'ArrivedAtStore',
    ORDER_EVENT_TYPE_PICKED_UP_BY_CUSTOMER_IN_STORE:
      'PickedUpByCustomerInStore',
    ORDER_EVENT_TYPE_TO_SHIP: 'ToShip',
    ORDER_EVENT_TYPE_KEHL_READY_TO_ASSEMBLE:
      'KehlWorkshop_ReadyToAssemble',
    ORDER_EVENT_TYPE_RETURN_TO_VERIFICATION:'ReturnToVerification',
    ORDER_EVENT_TYPE_IN_PREPARATION_AT_KEHL:
      'InPreparationAtKehl',
    //attention si on change la valeur des constantes ne pas oublier de changer la traduction dans i18n de Alert et AssemblyInformation
    ORDER_EVENT_TYPE_ALERT: 'Alert',
    ORDER_EVENT_TYPE_ASSEMBLY_INFORMATION: 'AssemblyInformation',
    ORDER_EVENT_TYPE_TO_REFUND: 'ToRefund',
    ORDER_EVENT_TYPE_CUSTOMER_MUST_PAY_EXTRA:
      'CustomerMustPayExtra',
    ORDER_EVENT_TYPE_EXTRA_AMOUNT_PAID_BY_CHEQUE:
      'ExtraAmountPaidByCheque',
    ORDER_EVENT_TYPE_EXTRA_AMOUNT_PAID_BY_CB:
      'ExtraAmountPaidByCB',
    ORDER_EVENT_TYPE_EXTRA_AMOUNT_PAID_BY_PAYPAL:
      'ExtraAmountPaidByPaypal',
    ORDER_EVENT_TYPE_EXTRA_AMOUNT_PAID_BY_BANK_TRANSFER:
      'ExtraAmountPaidByBankTransfer',
    ORDER_EVENT_TYPE_REFUNDED_BY_CHEQUE: 'RefundedByCheque',
    ORDER_EVENT_TYPE_REFUNDED_BY_CB: 'RefundedByCB',
    ORDER_EVENT_TYPE_REFUNDED_BY_PAYPAL: 'RefundedByPaypal',
    ORDER_EVENT_TYPE_REFUNDED_BY_BANK_TRANSFER:
      'RefundedByBankTransfer',
    ORDER_EVENT_TYPE_PARTIALLY_REFUNDED_BY_CHEQUE:
      'PartiallyRefundedByCheque',
    ORDER_EVENT_TYPE_PARTIALLY_REFUNDED_BY_CB:
      'PartiallyRefundedByCB',
    ORDER_EVENT_TYPE_PARTIALLY_REFUNDED_BY_PAYPAL:
      'PartiallyRefundedByPaypal',
    ORDER_EVENT_TYPE_PARTIALLY_REFUNDED_BY_BANK_TRANSFER:
      'PartiallyRefundedByBankTransfer',
    DOORDER_EVENT_TYPE_GRP_VERIFICATION: 'verification',
    DOORDER_EVENT_TYPE_GRP_OTHER: 'other',
    DOORDER_EVENT_TYPE_GRP_SPECIAL_EVENTS: 'specialEvents',
    ORDER_EVENT_TYPE_HOME_TRIAL_PICKING_IN_PREPARATION:
      'HomeTrialPicking_InPreparation',
    ORDER_EVENT_TYPE_PICKING_KEHL_HOME_TRIAL:
      'PickingKehl_HomeTrial',
    ORDER_EVENT_TYPE_AWAITING_PAYMENT_BY_CB:
      'AwaitingPaymentByCB',
    ORDER_EVENT_TYPE_AWAITING_PAYMENT_BY_PAYPAL:
      'AwaitingPaymentByPaypal',
    ORDER_EVENT_TYPE_AWAITING_PAYMENT_BY_CHEQUE:
      'AwaitingPaymentByCheque',
    ORDER_EVENT_TYPE_AWAITING_PAYMENT_BY_BANK_TRANSFER:
      'AwaitingPaymentByBankTransfer',
    ORDER_EVENT_TYPE_ORDER_AWAITING_PAYMENT_REMINDER:'OrderAwaitingPayment_reminder',
    ORDER_EVENT_TYPE_SHIPPED_BY_COLISSIMO: 'ShippedByColissimo',
    ORDER_EVENT_TYPE_SHIPPED_TO_POINT_RETRAIT:
      'ShippedToPointRetrait',
    ORDER_EVENT_TYPE_SHIPPED_TO_STORE: 'ShippedToStore',
    ORDER_EVENT_TYPE_SHIPPED_BY_COLISSIMO_EXPERT:
      'ShippedByColissimoExpert',
    ORDER_EVENT_TYPE_AFTERSALESTASK: 'AftersalesTask',
    ORDER_EVENT_TYPE_PAID_BY_CHEQUE: 'PaidByCheque',
    ORDER_EVENT_TYPE_PAID_BY_CB: 'PaidByCB',
    ORDER_EVENT_TYPE_PAID_BY_PAYPAL: 'PaidByPaypal',
    ORDER_EVENT_TYPE_PAID_BY_BANK_TRANSFER: 'PaidByBankTransfer',
    ORDER_EVENT_TYPE_ORDER_TO_CREATE_IN_COSIUM:
      'OrderToCreateInCosium',
    ORDER_EVENT_TYPE_SAV_FRAME_TRANSFER_TO_KEHL:
      'FrameTransferSAVNantesToKehl_LensesToBePrepared',
    ORDER_EVENT_TYPE_CUSTOMER_FRAME_TRANSFER_TO_KEHL:
      'FrameTransferCustomerNantesToKehl_LensesToBePrepared',
    ORDER_EVENT_TYPE_CUSTOMER_SERVICE_AFTERSALES_INFORMATION:
      'CustomerServiceAftersalesInformation',
    ORDER_EVENT_TYPE_WIP_PRODUCT_TO_PUT_PHYSICALLY_BACK_TO_STOCK:
      'WIPProductToPutPhysicallyBackToStock',
    ORDER_EVENT_TYPE_CANCELLED_ORDER:
      'CancelledOrder'
  },

  visionTypeOptionsList: [
    { value: 'plus_tard', key: 'Plus tard' },
    { value: 'vl', key: 'VL' },
    { value: 'vp', key: 'VP' },
    { value: 'vl_vp', key: 'VL + VP' },
  ],
  pupillaryDistanceSourceList: [
    { value: 'via_logiciel', key: 'Via logiciel' },
    { value: 'ordo_scannee', key: 'Ordonnance scannée' },
    { value: 'ancienne_paire', key: 'Ancienne paire de lunettes' },
    { value: 'connu', key: 'Connu' },
    { value: 'magasin_partenaire', key: 'Magasin partenaire' },
    { value: 'deja_commande', key: 'Déjà commandé' },
    { value: 'photo', key: 'Photo' },
    { value: 'plus_tard', key: 'Plus tard' },
  ],
  prescriptionSourceList: [
    { value: 'envoi', key: 'Envoi' },
    { value: 'opticien', key: 'Opticien' },
    { value: 'saisie', key: 'Saisie' },
    { value: 'telecharge', key: 'Téléchargé' },
    { value: 'ordo_precedente', key: 'Utiliser ordonnance précédente' },
  ],

  eventTypeToColor() {
    return {
      [this.eventTypeKeys.ORDER_EVENT_TYPE_ALERT]: 'alert-bg',
      [this.eventTypeKeys.ORDER_EVENT_TYPE_ASSEMBLY_INFORMATION]: 'danger-bg',
      [this.eventTypeKeys.ORDER_EVENT_TYPE_TO_REFUND]: 'success-bg',
      [this.eventTypeKeys.ORDER_EVENT_TYPE_CUSTOMER_MUST_PAY_EXTRA]:
        'danger-bg',
      [this.eventTypeKeys
        .ORDER_EVENT_TYPE_WIP_PRODUCT_TO_PUT_PHYSICALLY_BACK_TO_STOCK]:
        'violet-bg',
    }
  },
  currency: {
    EURO: '€',
  },

  voucherTypes: {
    VOUCHER_TYPE_PERSONNEL: 'personnel',
    VOUCHER_TYPE_UNIQUE: 'unique',
  },

  deliveryModeKeys: {
    STORE: 'magasin_direct_optic',
    PICKUP_POINT: 'point_retrait'
  },

  orderLineItemTypes: {
    GENERIC_ITEM: 'fieldcollection_DOOLItemGenericFC',
    LENS_ITEM: 'fieldcollection_DOOLItemLensFC'
  }
}
