import './AccordionMenu.css'
import { NavLink, useLocation } from 'react-router-dom'
import { Accordion } from 'react-bootstrap'
import { PageInterface } from 'utils/LoginDataInterface'
import AuthContext from 'store/auth-context'
import { useContext, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { GET_DO_ORDER_LISTING } from 'graphql/queries/getDOOrderListing'
import { checkRetraitParLeClient } from 'utils/functions'
import GET_ORDER_PICKING_LISTING from 'graphql/queries/getOrderPickingListing'
import { constants } from 'utils/constants'
import MenuContext from 'store/menu-context'

const AccordionMenu: React.FC = () => {
  const authCtx = useContext(AuthContext)
  const { totalOrderToPick, setTotalOrderToPick } = useContext(MenuContext);
  const menus = authCtx.menus

  const location = useLocation()

  // Si on rafraichit la page / arrive d'une redirection, on veut que le bon menu déroulant soit ouvert par défaut
  let defaultActiveKey: string = '0'
  menus.forEach((m, index) => {
    if (m.pageList.find((p: PageInterface) => p.path === location.pathname)) {
      defaultActiveKey = index.toString()
    }
  })

  const storeId = parseInt(authCtx.user.storeId) || null

  const { data: ordersToWithdrawData } = useQuery(GET_DO_ORDER_LISTING, {
    skip: !storeId,
    variables: {
      filter: `{ "$and": [{"deliveryStore__id": "${storeId}"}, {"workflowState":{"$notlike":"completed"}}]}`,
    },
  })

  const { data: ordersEssdomToPick } = useQuery(
    GET_ORDER_PICKING_LISTING,
    {
      variables: {
        orderEventTypeKeys: [
          constants.eventTypeKeys.ORDER_EVENT_TYPE_PICKING_KEHL_HOME_TRIAL,
        ],
      },
    }
  )
  const { data: ordersPanierToPick } = useQuery(
    GET_ORDER_PICKING_LISTING,
    {
      variables: {
        orderEventTypeKeys: [
          constants.eventTypeKeys.ORDER_EVENT_TYPE_KEHL_READY_TO_ASSEMBLE,
        ],
      },
    }
  )
  useEffect(() => {
    if(ordersPanierToPick)
      setTotalOrderToPick(ordersPanierToPick.getOrderPickingListing.totalCount)
  }, [ordersPanierToPick, setTotalOrderToPick])


  return (
    <div className="accordion-width">
      <Accordion
        className="do-menu-accordion"
        defaultActiveKey={defaultActiveKey}
        flush
      >
        {Array.isArray(menus) &&
          menus.map((menu, index) => {
            return (
              <Accordion.Item eventKey={index.toString()} key={menu.name}>
                <Accordion.Header>{menu.name}</Accordion.Header>
                <Accordion.Body>
                  <ul className="list-unstyled fw-normal p-1 small">
                    {menu!.pageList!.map((page: PageInterface) => {
                      return (
                        <li key={page.name}>
                          <NavLink
                            to={page.path}
                            className="link-dark menu-link"
                            activeStyle={{
                              color: '#007BFF',
                            }}
                            title={page.name}
                          >
                            {page.name}
                            {page.path ===
                              '/bo/reception_commande/retrait_par_le_client' &&
                              storeId && (
                                <>
                                  &nbsp;({!ordersToWithdrawData && '0'}
                                  {ordersToWithdrawData &&
                                    ordersToWithdrawData.getDOOrderListing.edges.filter(
                                      (item: any) => {
                                        return checkRetraitParLeClient(
                                          item.node
                                        )
                                      }
                                    ).length}
                                  )
                                </>
                              )}
                            {page.path ===
                              '/bo/logistique_kehl/pickings_clients' && (
                              <>
                                ({totalOrderToPick})
                              </>
                            )}
                            {page.path ===
                              '/bo/logistique_kehl/picking_essdom' && (
                              <>
                                ({!ordersEssdomToPick && '0'}
                                {ordersEssdomToPick &&
                                  ordersEssdomToPick
                                    .getOrderPickingListing
                                    .totalCount}
                                )
                              </>
                            )}
                          </NavLink>
                        </li>
                      )
                    })}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            )
          })}
      </Accordion>
    </div>
  )
}

export default AccordionMenu
