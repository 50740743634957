import gql from 'graphql-tag'
import { DO_VOUCHER_FIELDS } from 'graphql/fragments/DOVoucherFields'

const GET_DO_VOUCHER = gql`
  ${DO_VOUCHER_FIELDS}
  query GET_DO_VOUCHER($id: Int!, $withoutNewVoucherCode: Boolean = true) {
    getDOVoucher(id: $id) {
      ...DOVoucherFields
    }
  }
`

export default GET_DO_VOUCHER
