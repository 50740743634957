import gql from 'graphql-tag'

const GET_PRODUCTS_DETAILS = gql`
  query GET_PRODUCTS_DETAILS {
    getProductsDetails{
        totalCount
        edges{
            node {
                id  #frame id
                frameName
                salePrice
                progressiveCompatible
                availableHomeTrial
                sale
                destocking
                withSolarClip
                gender
                shape
                primaryColors
                material
                lensWidth
                totalWidth
                style
                gtin
                imageUrls
            }
        }
    }
}`
export default GET_PRODUCTS_DETAILS