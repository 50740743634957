import { gql } from '@apollo/client'
import { DO_CONTACT_NAME_FC_FIELDS } from 'graphql/fragments/DOContactNameFCFields'
import { DO_CONTACT_INFO_FC_FIELDS } from 'graphql/fragments/DOContactInfoFCFields'

export const UPDATE_DO_ORDER_DELIVERY_MODE = gql`
  ${DO_CONTACT_NAME_FC_FIELDS}
  ${DO_CONTACT_INFO_FC_FIELDS}
  mutation updateDOOrderDeliveryMode(
    $id: Int!,
    $firstName: String,
    $lastName: String,
    $address: String,
    $additionalAddressDetails: String,
    $postalCode: String,
    $city: String,
    $country: String,
    $mobilePhoneNumber: String,
    $landlinePhoneNumber: String,
    $newDeliveryId: Int,
    $newStoreId: Int,
    $newPickupPointCode: String,
  ){
    updateDOOrderDeliveryMode(
      id: $id
      firstName: $firstName
      lastName: $lastName
      address: $address
      additionalAddressDetails: $additionalAddressDetails
      postalCode: $postalCode
      city: $city
      country: $country
      mobilePhoneNumber: $mobilePhoneNumber
      landlinePhoneNumber: $landlinePhoneNumber
      newDeliveryId: $newDeliveryId
      newStoreId: $newStoreId
      newPickupPointCode: $newPickupPointCode
    ) {
      success
      output {
        order {
          id
          deliveryMode {
            ... on object_DODeliveryMode {
              label
              active
              id
            }
          }
          deliveryName {
            ...DOContactNameFCFields
          }
          deliveryAddress {
            ...DOContactInfoFCFields
          }
          deliveryStore {
            ... on object_DOStore {
              id
              contactInfo {
                ...DOContactInfoFCFields
              }
            }
          }
          pickupPointCode
        }
      }
    }
  }
`