import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import ResultsTableRow from './ResultsTableRow'

export interface Props {
  items: any // Warning: all items should have an id field (either in root, either in node) + a checkboxVisible field
  queryType: string //affiché quand il n'y a aucun résultat
  columns: { [key: string]: JSX.Element | string }
  title?: string //titre de SearchResultsTable
  onClickRows?: (param?: any) => void
  checkboxColIndex?:number
  tableHasCheckboxSelectAll?: boolean // Defines whether the table header contains a checkbox that will select all the table rows
  checkedIdListState?: {
  checkedIdList: string[]
  setCheckedIdList: Dispatch<SetStateAction<string[]>>
  },
  checkboxId?: string,
  checkableList?: string[]
}

const ResultsTable: React.FC<Props> = ({
  items,
  queryType,
  columns,
  title,
  onClickRows,
  tableHasCheckboxSelectAll,
  checkedIdListState,
  checkboxColIndex,
  checkboxId,
  checkableList
}) => {
  const [isCheckAll, setIsCheckAll] = useState(false)
  const checkboxIdStr = checkboxId?checkboxId: "id"

  useEffect(() => {
    tableHasCheckboxSelectAll &&
      checkedIdListState &&
      items &&
      setIsCheckAll(checkedIdListState.checkedIdList.length>0 && checkedIdListState.checkedIdList.length === (checkableList? checkableList.length :items.length))
  }, [tableHasCheckboxSelectAll, checkedIdListState, items, setIsCheckAll, checkableList])

  if (!items || items.length === 0 || items.edges?.length === 0) {
    return (
      <div className="no-result">
        <h2>Résultats pour {queryType}</h2>
        <p>Aucun résultat trouvé</p>
      </div>
    )
  }

  if ('edges' in items) {
    items = items.edges
  }
  let firstItem = items[0]
  if ('node' in firstItem) firstItem = firstItem.node

  // NB: handleSelectAll and handleClick are used only if setCheckedIdList && checkedIdList are defined
  //(un)select all checkboxes
  const handleSelectAll = () => {
    setIsCheckAll((prevIsCheckAll) => !prevIsCheckAll)
    checkedIdListState &&
      checkedIdListState.setCheckedIdList(
        !isCheckAll
          ? (checkableList?checkableList:items).map((item: any) => ('node' in item ? item.node[checkboxIdStr] : item[checkboxIdStr]))
          : []
      )
  }
  //(un)select specific checkbox
  const handleClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target
    if (checkedIdListState)
      if (checked)
        checkedIdListState.setCheckedIdList((prevCheckedIdList) => [
          ...prevCheckedIdList,
          id,
        ])
      else
        checkedIdListState.setCheckedIdList(
          checkedIdListState.checkedIdList.filter((item: string) => item !== id)
        )
  }

  const headers = Object.entries(columns).map(([title, jsx]: any) => {
    return <th key={title}>{jsx}</th>
  })
if(tableHasCheckboxSelectAll && checkedIdListState){
   const thToAdd = <th key="checkbox-all">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="row-checkbox"
                      name="row-checkbox"
                      checked={isCheckAll}
                      onChange={handleSelectAll}
                    />
                  </th>
  if(typeof checkboxColIndex==="number"){
    headers.splice(checkboxColIndex, 0, thToAdd)
  }else
  headers.push(thToAdd)
}
  return (
    <div className="results">
      {title && <h2>{title}</h2>}
      <Table borderless hover>
        <thead>
          <tr>
            {items && headers}
          </tr>
        </thead>
        <tbody className="small bg-white">
          {items && 
            items.map((item: any, index: number) => {
              if ('node' in item) item = item.node
              if('calculators' in item) {
                item = {...item.order, ...item.calculators}
              }
                return (
                  <ResultsTableRow
                    onClickRows={onClickRows}
                    item={item}
                    columns={columns}
                    key={item.id}
                    rowIndex={index}
                    checkedRowsIdList={checkedIdListState?.checkedIdList}
                    handleClick={checkedIdListState ? handleClick : undefined}
                    checkboxColIndex={checkboxColIndex}
                    checkboxIdStr={checkboxIdStr}
                  />
                )
            })}
        </tbody>
      </Table>
    </div>
  )
}

export default ResultsTable
