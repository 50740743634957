import gql from 'graphql-tag'

const SEARCH_CUSTOMERS = gql`
  query SEARCH_CUSTOMERS(
    $firstName: String
    $lastName: String
    $email: String
  ) {
    CQgetDOCustomerCustomListing(
      firstName: $firstName
      lastName: $lastName
      email: $email
    ) {
      edges {
        node {
          id
          firstName
          lastName
          email
        }
      }
      totalCount
    }
  }
`
export default SEARCH_CUSTOMERS
