import React, { useEffect, useRef, useState, useCallback } from 'react'
import { Button, Form, Container, Row, Col, Accordion } from 'react-bootstrap'
import { Loader } from '@googlemaps/js-api-loader'
import classes from './PickupPointDelivery.module.css'
import { getTokenFromLocalStorage } from '../utils/functions'

interface Props {
  pickupPoint: PickupPoint
  setPickupPoint: React.Dispatch<React.SetStateAction<PickupPoint>>
}

const PickupPointDelivery: React.FC<Props> = ({
  pickupPoint,
  setPickupPoint,
}) => {
  const { REACT_APP_GOOGLE_API_KEY, REACT_APP_PIMCORE_URL_DOMAIN } = process.env

  // https://developers.google.com/maps/documentation/javascript/react-map
  const mapRef = useRef<HTMLDivElement>(null)
  const pickupPointRefs = useRef<HTMLLIElement[]>([])
  const [pickupPointList, setPickupPointList] = useState<PickupPoint[]>([])
  const [postalCode, setPostalCode] = useState<string>(pickupPoint.zipcode)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [markers, setMarkers] = useState<google.maps.Marker[]>([])
  const [currentPickupPointId, setCurrentPickupPointId] = useState<
    string | null
  >(null)

  const fetchPickupPointListHandler = useCallback(() => {
    const fetchPickupPointList = async () => {
      const options = () => {
        return {
          method: 'POST',
          body: new URLSearchParams({
            token: getTokenFromLocalStorage(), // Security check
          }),
        }
      }
      const response = await fetch(
        `${REACT_APP_PIMCORE_URL_DOMAIN}/colissimo-pickup-list-bo/${postalCode}/${pickupPoint.id}`,
        options()
      )
      if (response.status === 200) {
        const data = await response.json()
        return data
      }
      return []
    }

    setIsLoading(true)
    fetchPickupPointList().then((data) => {
      setPickupPointList(data.pickupPoints)
      if (data.pickupPoint) setPickupPoint(data.pickupPoint)
    })
  }, [REACT_APP_PIMCORE_URL_DOMAIN, postalCode, setPickupPoint, pickupPoint.id])

  const fetchMapHandler = useCallback(() => {
    const loader = REACT_APP_GOOGLE_API_KEY
      ? new Loader({
          apiKey: REACT_APP_GOOGLE_API_KEY,
          version: 'weekly',
          libraries: ['places'],
        })
      : null

    const fetchMap = async () => {
      setIsLoading(true)
      if (loader && mapRef.current) {
        const google = await loader.load()
        let map = new google.maps.Map(mapRef.current, {
          mapTypeControl: false,
        })

        if (map) {
          if (pickupPointList.length) {
            const bounds: google.maps.LatLngBounds =
              new google.maps.LatLngBounds()
            let previousInfoWindow: google.maps.InfoWindow | null = null
            let markerList: any = []
            pickupPointList.forEach((location: PickupPoint) => {
              const latLang = new google.maps.LatLng(
                location.latitude,
                location.longitude
              )
              const marker = new google.maps.Marker({
                position: latLang,
                map: map,
              })
              bounds.extend(latLang)

              // Button on the map side, to be added if deemed necessary
              // <div style="display:flex; justify-content:center; margin-top:5px;">
              //                 <button class="btn-primary" style="border-radius: 5px; font-size: 12px; height: 24px;" type="button" data-delivery-point-choice>Choisir</button>
              //             </div>

              const contentString = `<div>
                    <h6>${location.name}</h6>
                    <div>${location.address[0]}<br/> ${location.zipcode} ${location.city}</div>
                </div>`

              const infowindow = new google.maps.InfoWindow({
                content: contentString,
              })

              marker.addListener('click', () => {
                if (previousInfoWindow) {
                  previousInfoWindow.close()
                }

                previousInfoWindow = infowindow
                infowindow.open({
                  anchor: marker,
                  shouldFocus: false,
                })
                setCurrentPickupPointId(location.id)
              })
              markerList[1 + location.id] = marker

              map.fitBounds(bounds)
            })
            setMarkers(markerList)
          } else {
            setCurrentPickupPointId(null)
            new google.maps.Map(mapRef.current, {
              mapTypeControl: false,
              zoom: 5, // Zoomed in so it fits the country with some room on each cardinal front
              center: new google.maps.LatLng(46.52863469527167, 2.43896484375), // Centered on france
            })
            setMarkers([])
          }
        }
        setIsLoading(false)
      }
    }
    if (!+pickupPoint.id) fetchMap()
  }, [pickupPointList, pickupPoint.id, REACT_APP_GOOGLE_API_KEY])

  const getProperty = <T, K extends keyof T>(o: T, propertyName: K): T[K] => {
    return o[propertyName] // o[propertyName] is of type T[K]
  }

  const handleChangePickupPoint = async (event: any) => {
    setPickupPoint({
      id: '0',
      name: '',
      address: [],
      zipcode: pickupPoint.zipcode,
      city: '',
      distanceInMeters: 0,
      holidays: [],
      workingHours: {},
      latitude: 0,
      longitude: 0,
    })
  }

  const handleSubmitPickupPoint = async (pickupPointId: string) => {
    // The final pickup point is not necessarily the one which was selected in the first place
    // The one chosen at the moment of the click is the most reliable, hence the parameter
    let currentPickPoint = pickupPointList.find(
      (pickupPoint: PickupPoint) => +pickupPoint.id === +pickupPointId
    )
    if (currentPickPoint) setPickupPoint(currentPickPoint)
  }

  useEffect(() => {
    fetchPickupPointListHandler()
  }, [fetchPickupPointListHandler])

  useEffect(() => {
    fetchMapHandler()
  }, [fetchMapHandler])

  useEffect(() => {
    if (currentPickupPointId) {
      const marker = markers[+(1 + currentPickupPointId)]
      if (marker) {
        google.maps.event.trigger(marker, 'click')
      }
      pickupPointRefs.current[+currentPickupPointId]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      })
    }
  }, [currentPickupPointId, markers])

  return (
    <>
      {pickupPoint && (
        <>
          {!+pickupPoint.id && (
            <div className={classes.pickupPointContainer}>
              <div className={classes.pickupPointList}>
                <ul>
                  {pickupPointList?.map((pickupPoint: PickupPoint) => {
                    return (
                      <li
                        className={
                          currentPickupPointId &&
                          +pickupPoint.id === +currentPickupPointId
                            ? classes.pickupPointSelected
                            : ''
                        }
                        key={pickupPoint.id}
                        onClick={() => setCurrentPickupPointId(pickupPoint.id)}
                        ref={(el: HTMLLIElement) =>
                          (pickupPointRefs.current[+pickupPoint.id] = el)
                        }
                      >
                        <label>
                          <strong>{pickupPoint.name}</strong>
                        </label>
                        <div>{pickupPoint.address[0]}</div>
                        <div>
                          {pickupPoint.zipcode} {pickupPoint.city}
                        </div>
                        <div className={classes.pickupPointListButtonWrapper}>
                          <button
                            className="btn-primary"
                            type="button"
                            onClick={() =>
                              handleSubmitPickupPoint(pickupPoint.id)
                            }
                          >
                            Choisir
                          </button>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
              <div className={classes.pickupPointMapCorner}>
                <div className={classes.pickupPointMapPostalCode}>
                  <Form.Control
                    type="text"
                    placeholder="Code postal"
                    maxLength={5}
                    name="lastname"
                    value={postalCode}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (/^\d*$/.test(e.target.value))
                        setPostalCode(e.target.value)
                    }}
                  />
                  <>
                    {!!isLoading && <span>Chargement en cours...</span>}
                    {!isLoading && (
                      <>
                        {!(pickupPointList && pickupPointList.length) && (
                          <span>Aucune entrée pour ce code postal</span>
                        )}
                        {!!(pickupPointList && pickupPointList.length) && (
                          <span>
                            {pickupPointList.length} points retraits trouvés
                          </span>
                        )}
                      </>
                    )}
                  </>
                  {/* <Button variant="primary" disabled={isMapLoading} onClick={handleSubmitNewPostalCode}>
                Envoyer
              </Button> */}
                </div>
                <div className={classes.pickupPointMap} ref={mapRef}></div>
              </div>
            </div>
          )}
          {!!+pickupPoint.id && !!pickupPoint.name.length && (
            <Container fluid className={classes.pickupPointCardContainer}>
              <Row>
                <Col xs={7} className={classes.pickupPointCardAddress}>
                  <Row className={classes.pickupPointCardTitle}>
                    {pickupPoint.name}
                  </Row>
                  <Row>{pickupPoint.address[0]}</Row>
                  <Row>
                    {pickupPoint.zipcode} {pickupPoint.city}
                  </Row>
                </Col>
                <Col>
                  <Button
                    variant={`outline-secondary text-center mt-4 w-100  btn-sm`}
                    onClick={handleChangePickupPoint}
                  >
                    Changer le lieu de retrait
                  </Button>
                </Col>
              </Row>
              {!!Object.keys(pickupPoint.workingHours).length && (
                <Row className={classes.pickupPointCardSchedule}>
                  <Col xs={7}>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>HORAIRES</Accordion.Header>
                        <Accordion.Body>
                          {[
                            'lundi',
                            'mardi',
                            'mercredi',
                            'jeudi',
                            'vendredi',
                            'samedi',
                            'dimanche',
                          ].map((day: any, index: number) => {
                            return (
                              <Row className="small" key={index}>
                                <Col xs={4}>{day} :</Col>
                                <Col>
                                  {getProperty(
                                    pickupPoint.workingHours,
                                    day
                                  )?.map((element: string, index: number) => {
                                    return <span key={index}>{element} </span>
                                  })}
                                </Col>
                              </Row>
                            )
                          })}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                  <Col xs={5}>
                    {!!Object.keys(pickupPoint.holidays).length && (
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>VACANCES</Accordion.Header>
                          <Accordion.Body>
                            {pickupPoint.holidays.map(
                              (holiday: any, index: number) => {
                                return (
                                  <Row className="small" key={index}>
                                    <Col>{holiday}</Col>
                                  </Row>
                                )
                              }
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    )}
                  </Col>
                </Row>
              )}
            </Container>
          )}
        </>
      )}
    </>
  )
}

export default PickupPointDelivery
