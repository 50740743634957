import React from 'react'
import OrderAddress from './OrderAddress'
import OrderHistory from './OrderHistory'
import OrderInfo from './OrderInfo'

interface Props {}

const OrderCard: React.FC<Props> = () => {
  return (
    <div className="custom-card mx-auto overflow-hidden">
      <OrderInfo />
      <OrderAddress />
      <OrderHistory />
    </div>
  )
}

export default OrderCard
