import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import HeaderTitle from './HeaderTitle';
import { ClockIcon } from '@heroicons/react/outline';
import { DotsCircleHorizontalIcon } from '@heroicons/react/solid';
import ResultsTable from 'components/UI/ResultsTable';
import SkeletonBase from 'components/Skeleton/SkeletonBase';
import Card from './UI/Card';
import GET_ORDER_EVENTS from 'graphql/queries/getDOOrderEvents';
import GET_ORDER_INFO from 'graphql/queries/getDOOrderInfo';
import DOModal from 'components/Modal/DOModal';
import DOButton from 'components/Modal/DOButton';
import ModalCloseStatus from './Modal/ModalCloseStatus';
import { stringsToJSXs } from 'utils/functions';
import classes from './EventsTable.module.css';
import { Tooltip  } from 'react-tooltip'

interface Props {}

const EventsTable: React.FC<Props> = () => {
  const { id } = useParams<ParamType>();
  const { t } = useTranslation();

  const columnsTitles = [
    'creation',
    'closure',
    'status',
    'comment',
    'mailInfo',
    'sentEmail',
  ];

  const [checkedIdList, setCheckedIdList] = useState<string[]>([]);
  const [showConfirmCloseEventsModal, setShowConfirmCloseEventsModal] = useState(false);
  const [showMailInfoModal, setShowMailInfoModal] = useState(false);
  const [currentMailInfo, setCurrentMailInfo] = useState<string | null>(null);
  const [tableLimit, setTableLimit] = useState(20);
  const [modalData, setModalData] = useState<{
    creationDate: string;
    userLoginCreate: string | null;
    label: string;
  } | null>(null);

  const handleCloseConfirmModal = () => setShowConfirmCloseEventsModal(false);
  const handleShowMailInfoModal = ({
    mailInfo,
    creationDate,
    userLoginCreate,
    label
  }: {
    mailInfo: string;
    creationDate: string;
    userLoginCreate: string | null;
    label: string;
  }) => {
    setCurrentMailInfo(mailInfo);
    setModalData({ creationDate, userLoginCreate, label });
    setShowMailInfoModal(true);
  };
  const handleCloseMailInfoModal = () => {
    setCurrentMailInfo(null);
    setShowMailInfoModal(false);
    setModalData(null);
  };

  const { loading: orderLoading, error: orderError, data: orderData } = useQuery(GET_ORDER_INFO, {
    variables: {
      id: +id,
    },
  })

  const { loading, error, data } = useQuery(GET_ORDER_EVENTS, {
    variables: {
      id: +id,
    },
  });

  if (loading || orderLoading)
    return (
      <Card>
        <SkeletonBase count={5} />
      </Card>
    );
  if (error) return <p>`Error! ${error.message}`</p>;
  if (orderError) return <p>`Error! ${orderError.message}`</p>;

  const order = orderData.CQgetOrder?.edges[0].node?.order

  const events = data.CQgetOrder.edges[0].node.order.events
    .filter((event: orderEvent) => event)
    .sort(
      (eventA: eventTypeType, eventB: eventTypeType) =>
        +eventA.id! - +eventB.id!
    );
  const jsonData = JSON.parse(JSON.stringify(events));
  jsonData.map((event: any) => {
    event.status = event.label;
    event.sentEmail = event.sentEmail ? 'oui' : 'NON';
    event.creation =
      new Intl.DateTimeFormat('fr', {
        dateStyle: 'short',
        timeStyle: 'short',
      }).format(new Date(event.creationDate * 1000)) +
      (event.userLoginCreate ? ' par ' + event.userLoginCreate : '');

    event.closure = event.closureDate
      ? event.closureDate +
        (event.userLoginClosure ? ' par ' + event.userLoginClosure : '')
      : '';

    let mailInfo = event.mailInfo;
    let creation = new Intl.DateTimeFormat('fr', {
      dateStyle: 'short',
      timeStyle: 'short',
    }).format(new Date(event.creationDate * 1000))

    event.truncatedMailInfo =
    event.mailInfo && event.mailInfo.length > 180 ? (
      <td
        data-tooltip-id={`tooltip-${event.id}`}
      >
        {event.mailInfo.slice(0, 180)}
        <DotsCircleHorizontalIcon onClick={() =>
            handleShowMailInfoModal({
              mailInfo: mailInfo,
              creationDate: creation,
              userLoginCreate: event.userLoginCreate,
              label: event.label,
            })} 
            className={classes.iconSize}>
        </DotsCircleHorizontalIcon>
        <Tooltip 
          id={`tooltip-${event.id}`}
          place="top"
          content={mailInfo}
          className={`${classes.tooltipMailInfo}`}
        />
      </td>
    ) : (
      <td
        data-tooltip-id={`tooltip-${event.id}`}
      >
        {event.mailInfo}
        <Tooltip 
          id={`tooltip-${event.id}`}
          place="top"
          content={mailInfo}
          className={`${classes.tooltipMailInfo}`}
        />
      </td>
    );
  
  

    event.mailInfo = event.truncatedMailInfo;
    event.isHighlighted = !event.closureDate;
    event.checkboxVisible =
      !event.closureDate &&
      !event.eventType.workflowTransition &&
      event.eventType.eventStage === 'etape';
    event.isLastOpen = event.eventType.isLastOpen;
    return event;
  });

  const isLastOpenList = jsonData.filter(
    (event: any) => event.isLastOpen && !event.closureDate
  );

  const isAuthorisedToClose = isLastOpenList.length
    ? !isLastOpenList.every((event: any) =>
        checkedIdList.some((checkedId) => checkedId === event.id)
      )
    : true;

  return (
    <div data-cy="eventsHistory" className={`custom-card py-3 mb-4 ${classes.container}`}>
      <div className="px-3">
        <HeaderTitle
          children={<ClockIcon className="header-title-icon" />}
          title="Historique des statuts"
        />
      </div>

      <div className="text-center">
        <DOButton
          className={`btn-primary btn-radius ${jsonData.length <= 20 ? 'disabled' : ''}`}
          text={`${jsonData.length > tableLimit ? '⊕ Voir statuts précédents' : ' ⊖ Voir moins'}`}
          handleClick={() => jsonData.length > 20 && setTableLimit(tableLimit === 20 ? jsonData.length : 20)}
        />
      </div>

      <ResultsTable
        queryType="Statuts"
        items={jsonData.slice(
          jsonData.length > tableLimit ? jsonData.length - tableLimit : 0,
          jsonData.length
        )}
        columns={stringsToJSXs(columnsTitles, t)}
        checkedIdListState={{ checkedIdList, setCheckedIdList }}
      />

      <DOModal
        show={showConfirmCloseEventsModal}
        customSize="modal-647"
        closeModal={handleCloseConfirmModal}
      >
        <ModalCloseStatus
          id={+id}
          checkedIdList={checkedIdList}
          setCheckedIdList={setCheckedIdList}
          closeModal={handleCloseConfirmModal}
          authorisedToClose={isAuthorisedToClose}
        />
      </DOModal>

      <DOModal
        show={showMailInfoModal}
        customSize="modal-647"
        closeModal={handleCloseMailInfoModal}
      >
        <div>
          {modalData && (
            <>
              <h5>🛈 {order.key} - {modalData.label}</h5>
              <p>
                <strong>Date : </strong>{modalData.creationDate}
              </p>
              <p><strong>Par : </strong>{modalData.userLoginCreate ?? 'Inconnu'}</p>
              <p><strong>Indications : </strong>{currentMailInfo}</p>
            </>
          )}
        </div>
      </DOModal>



      <div className={`mt-2 d-flex justify-content-end px-3 ${classes.stickyButtonContainer}`}>
        <Button
          disabled={checkedIdList.length === 0}
          onClick={() => setShowConfirmCloseEventsModal(true)}
        >
          Clore les statuts sélectionnés
        </Button>
      </div>
    </div>
  );
};

export default EventsTable;
