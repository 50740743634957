import React from 'react'
import Skeleton from 'react-loading-skeleton'
interface Props {
  titleCount?: number
  count?: number
  align?: 'left'
  variant?: string
}
const SkeletonBase: React.FC<Props> = ({
  titleCount,
  count,

  variant,
}) => {
  return (
    <div className={`${variant}`}>
      <h3>
        <Skeleton count={titleCount || 1} />
      </h3>

      <p>
        <Skeleton count={count || 1} />
      </p>
    </div>
  )
}

export default SkeletonBase
