export const schema = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Generated schema for Root',
  type: 'object',
  properties: {
    name: {
      type: 'string',
    },
    saleId: {
      type: 'integer',
    },
    parentCategoryId: {
      type: 'integer',
    },
    rimLabel: {
      type: 'string',
    },
    purchasePrice: {
      type: 'number',
    },
    weight: {
      type: 'number',
    },
    descriptionVariant1: {
      type: 'string',
    },
    supplierName: {
      type: 'string',
    },
    MARQUE: {
      type: 'string',
    },
    DOAttribute_matiere: {
      type: 'array',
      items: {
        type: 'integer',
      },
    },
    DOAttribute_style: {
      type: 'array', //same as type any
    },
    branchLength: {
      type: 'number',
    },
    lensWidth: {
      type: 'number',
    },
    bridgeWidth: {
      type: 'number',
    },
    totalWidth: {
      type: 'number',
    },
    lensHeight: {
      type: 'number',
    },
    DOAttribute_forme: {
      type: 'array',
      items: {
        type: 'integer',
      },
    },
    DOAttribute_plus_produit: {
      type: 'array',
      items: {
        type: 'integer',
      },
    },
    manufacturingCountry: {
      type: 'integer',
    },
    parentColorId: {
      type: 'array',
      items: {
        type: 'integer',
      },
    },
    colorLabel: {
      type: 'string',
    },
    supplierColorCode: {
      type: 'string',
    },
    descriptionVariant2: {
      type: 'string',
    },
    stockLocation: {
      type: 'string',
    },
    gtin: {
      type: 'string',
    },
    DOAttribute_genre: {
      type: 'array',
      items: {
        type: 'integer',
      },
    },
    sku: {
      type: 'string',
    },
    basePrice: {
      type: 'number',
    },
    salePrice: {
      type: 'number',
    },
    CODE_RAYON: {
      type: 'string',
    },
    transferPrice: {
      type: 'number',
    },
    targetQuantity: {
      type: 'integer',
    },
    imageUrls: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          url: {
            type: 'string',
          },
          order: {
            type: 'integer',
          },
        },
      },
    },
    idmc: {
      type: 'integer',
    },
  },
  required: [
    'name',
    'saleId',
    'parentCategoryId',
    'rimLabel',
    'supplierName',
    'DOAttribute_matiere', //mandatory list of integer
    // 'DOAttribute_style', is ignored
    'DOAttribute_forme',//mandatory list of integer
    'manufacturingCountry',
    'parentColorId',//mandatory list of integer
    'colorLabel',
    'gtin',
    'DOAttribute_genre', //mandatory list of integer
    'basePrice',
    'branchLength',
    'lensWidth',
    'bridgeWidth',
    'totalWidth',
    'lensHeight'
  ],
}

export const headers: string[] = [
  'name',
  'saleId',
  'parentCategoryId',
  'rimLabel',
  'purchasePrice',
  'weight',
  'descriptionVariant1',
  'supplierName',
  'MARQUE',
  'DOAttribute_matiere',
  'DOAttribute_style',
  'branchLength',
  'lensWidth',
  'bridgeWidth',
  'totalWidth',
  'lensHeight',
  'DOAttribute_forme',
  'DOAttribute_plus_produit',
  'manufacturingCountry',
  'parentColorId',
  'colorLabel',
  'supplierColorCode',
  'descriptionVariant2',
  'stockLocation',
  'gtin',
  'DOAttribute_genre',
  'sku',
  'basePrice',
  'salePrice',
  'CODE_RAYON',
  'transferPrice',
  'targetQuantity',
  'imageUrls',
  'idmc',
]

export const headersTypeNumber: string[] = [
  'saleId',
  'parentCategoryId',
  'purchasePrice',
  'weight',
  'branchLength',
  'lensWidth',
  'bridgeWidth',
  'totalWidth',
  'lensHeight',
  'manufacturingCountry',
  'basePrice',
  'salePrice',
  'transferPrice',
  'targetQuantity',
  'idmc',
]

export const headersTypeArray: string[] = [
  'DOAttribute_genre',
  'DOAttribute_matiere',
  'DOAttribute_style',
  'parentColorId',
  'DOAttribute_forme',
  'DOAttribute_plus_produit',
]
