import Card from 'components/UI/Card'
import { constants } from 'utils/constants'
import PickingTabs from '../../components/PickingTabs'

interface Props {}
const PickingClients: React.FC<Props> = () => {
  return (
    <Card>
      <PickingTabs
        columns={[
          'cosiumId',
          'lastEventDateFormatted',
          'since',
          'fullName',
          'email',
          'phone',
        ]}
        pickingEventTypeKeys={[
          constants.eventTypeKeys.ORDER_EVENT_TYPE_KEHL_READY_TO_ASSEMBLE,
          constants.eventTypeKeys.ORDER_EVENT_TYPE_IN_PREPARATION_AT_KEHL,
        ]}
      />
    </Card>
  )
}

export default PickingClients
