import { gql } from '@apollo/client'

export const GET_DO_STOCK_TRACKING_LISTING_BY_ORDER = gql`
  query getDOStockTrackingListingByOrder($orderKey: String!) {
    getDOStockTrackingListingByOrder(orderKey: $orderKey) {
        totalCount
        edges {
            node {
                id
                name
                gtin
                stockTracking {
                    id
                    creationDate
                    comment
                    origin
                    destination
                    availableQuantity {
                        ... on fieldcollection_DOStockTrackingFC {
                            before
                            after
                            quantity
                        }
                    }
                    reservedQuantity {
                        ... on fieldcollection_DOStockTrackingFC {
                            before
                            after
                            quantity
                        }
                    }
                    wipQuantity {
                        ... on fieldcollection_DOStockTrackingFC {
                            before
                            after
                            quantity
                        }
                    }
                    essdomQuantity {
                        ... on fieldcollection_DOStockTrackingFC {
                            before
                            after
                            quantity
                        }
                    }
                    user {
                        ... on object_DOUser {
                            key
                        }
                    }
                }
            }
        }
    }
  }
`