import { gql } from '@apollo/client'
import { DO_ORDER_EVENT_FIELDS } from 'graphql/fragments/DOOrderEventFields'

const CM_CREATE_DO_ORDER_EVENT = gql`
  ${DO_ORDER_EVENT_FIELDS}
  mutation CM_CREATE_DO_ORDER_EVENT(
    $comment: String
    $mailInfo: String
    $closureDate: String
    $orderId: Int!
    $eventTypeKey: String!
    $sentEmail: Boolean
  ) {
    CMCreateDOOrderEvent(
      input: {
        comment: $comment
        mailInfo: $mailInfo
        closureDate: $closureDate
        orderId: $orderId
        eventTypeKey: $eventTypeKey
        sentEmail: $sentEmail
      }
    ) {
      success
      output {
        orderEvent {
          ...DOOrderEventFields
        }
      }
    }
  }
`

export default CM_CREATE_DO_ORDER_EVENT
