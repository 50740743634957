import { gql } from '@apollo/client'

export const ORDER_TOTAL_AMOUNT_FIELDS = gql`
  fragment DOOrderTotalAmountFCFields on fieldcollection_DOOrderTotalAmountFC
  {
    entryDate
    totalAmount {
      value
    }
  }
`