import { gql, useLazyQuery, useMutation } from '@apollo/client'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import { DO_VOUCHER_FIELDS } from 'graphql/fragments/DOVoucherFields'
import SEARCH_VOUCHERS from 'graphql/queries/searchVouchers'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import VoucherDetails from './VoucherDetails'
import { CQ_GET_ORDER } from 'graphql/queries/getOrder'

interface Props {
  btnLabel: string
  confirmMessage: string
  toastTitle: string
  customerId?: number
  orderId?: number
  managesGenericVouchers?: boolean
}
const VoucherSearchAndUpdate: React.FC<Props> = ({
  btnLabel,
  confirmMessage,
  toastTitle,
  customerId,
  orderId,
  managesGenericVouchers,
}) => {
  const [inputVoucherCode, setInputVoucherCode] = useState<string>('')

  // Query to retrieve all vouchers (depending on searched code)
  const [searchVouchers, { loading: vouchersLoading, data: vouchersData }] =
    useLazyQuery(SEARCH_VOUCHERS)

  // Query to retrieve customer vouchers (depending on searched code)
  const SEARCH_CUSTOMER_VOUCHERS = gql`
    ${DO_VOUCHER_FIELDS}
    query (
      $voucherCode: String!
      $customerId: Int!
      $withoutNewVoucherCode: Boolean = true
    ) {
      getDOVoucherCustomListing(
        voucherCode: $voucherCode
        customerId: $customerId
      ) {
        edges {
          node {
            ...DOVoucherFields
          }
        }
        totalCount
        isVoucherAlreadyUsedByUser
      }
    }
  `
  const [
    searchCustomerVouchers,
    { loading: customerVouchersLoading, data: customerVouchersData },
  ] = useLazyQuery(SEARCH_CUSTOMER_VOUCHERS)

  /** Disabling voucher */
  const DISABLE_VOUCHER = gql`
    ${DO_VOUCHER_FIELDS}
    mutation DISABLE_VOUCHER(
      $id: Int!
      $withoutNewVoucherCode: Boolean = true
    ) {
      updateDOVoucher(id: $id, input: { voucherState: "desactive" }) {
        success
        output {
          ...DOVoucherFields
        }
      }
    }
  `
  const [disableVoucher] = useMutation(DISABLE_VOUCHER)

  /** Use voucher for order/customer */
  const ADD_VOUCHER_TO_ORDER = gql`
    ${DO_VOUCHER_FIELDS}
    mutation ADD_VOUCHER_TO_ORDER(
      $voucherId: Int!
      $orderId: Int!
      $withoutNewVoucherCode: Boolean = true
    ) {
      addVoucherToOrder(voucherId: $voucherId, orderId: $orderId) {
        success
        output {
          voucher {
            ...DOVoucherFields
          }
        }
      }
    }
  `
  const [applyVoucherForOrder] = useMutation(ADD_VOUCHER_TO_ORDER, {
    refetchQueries: [CQ_GET_ORDER],
    awaitRefetchQueries: true,
  })

  // Defining in a variable voucher to display (because we have two queries and only one is called depending on if we want to target vouchers for a specific customer or not)
  let voucherFound: voucherType | null = null
  let isVoucherAlreadyUsedByUser = false
  if (vouchersData && vouchersData.getDOVoucherListing.totalCount > 0) {
    voucherFound = vouchersData.getDOVoucherListing.edges[0].node
  }
  if (
    customerVouchersData &&
    customerVouchersData.getDOVoucherCustomListing.totalCount > 0
  ) {
    voucherFound = customerVouchersData.getDOVoucherCustomListing.edges[0].node
    isVoucherAlreadyUsedByUser =
      customerVouchersData.getDOVoucherCustomListing.isVoucherAlreadyUsedByUser
  }

  const customMutationFunction = async () => {
    if (!orderId)
      await disableVoucher({
        variables: {
          id: +voucherFound!.id,
        },
      })
    else
      await applyVoucherForOrder({
        variables: {
          voucherId: +voucherFound!.id,
          orderId: +orderId,
        },
      })
  }

  return (
    <>
      <form
        className="row my-3 ms-1"
        onSubmit={(e) => {
          e.preventDefault()

          if (customerId)
            searchCustomerVouchers({
              variables: {
                voucherCode: inputVoucherCode,
                customerId: +customerId,
              },
            })
          else
            searchVouchers({
              variables: {
                filter: `{"voucherCode": {"$like" :"${inputVoucherCode}"}}`,
              },
            })
        }}
      >
        <div className="col-md-4">
          <input
            type="text"
            name="voucher-code"
            autoComplete="off"
            value={inputVoucherCode}
            className="form-control rounded"
            placeholder="Rechercher un code bon"
            onChange={(e) => setInputVoucherCode(e.target.value.replace(/\s+/g, ""))}
          />
        </div>
        <div className="col-md-4">
          <Button type="submit" disabled={inputVoucherCode.length === 0}>
            Afficher
          </Button>
        </div>
      </form>

      {(vouchersLoading || customerVouchersLoading) && (
        <SkeletonBase count={6} />
      )}

      {(vouchersData || customerVouchersData) && !voucherFound && (
        <>Aucun élément trouvé</>
      )}

      {voucherFound && (
        <>
          {!customerId && (
            // We don't display title if we are on the order details page (where we know the customer and want to apply the voucher to him)
            <h3>Informations sur le bon {voucherFound.voucherCode}</h3>
          )}
          <VoucherDetails
            voucher={voucherFound}
            mutationFunction={customMutationFunction}
            btnLabel={btnLabel}
            confirmMessage={confirmMessage}
            toastTitle={toastTitle}
            managesGenericVouchers={managesGenericVouchers}
            isVoucherAlreadyUsedByUser={isVoucherAlreadyUsedByUser}
          />
        </>
      )}
    </>
  )
}

export default VoucherSearchAndUpdate
