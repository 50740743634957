import React, { Component } from "react";

export default class VisualisationReassort extends Component {
    render() {
        return (
            <div>
                <h1>VisualisationReassort</h1>
            </div>
        );
    }
}
