import Form from 'react-bootstrap/Form'

import React, { useState } from 'react'
interface Props {
  isCheckedByDefault: boolean
  id: string
  disabled?: boolean
  onToggleClick?: (checked: boolean) => void
  onToggleChange?: () => void //this function is executed when click on toggle
}

const ToggleSwitch: React.FC<Props> = ({
  isCheckedByDefault,
  id,
  disabled,
  onToggleClick,
  onToggleChange,
}) => {
  const [checked, setChecked] = useState(isCheckedByDefault)
  const handleToggleSwitch = () => {
    if (onToggleClick) onToggleClick(!checked)
    if (onToggleChange) onToggleChange()
    return setChecked(!checked)
  }
  return (
    <Form>
      <Form.Check
        type="switch"
        id={id}
        checked={checked}
        onChange={handleToggleSwitch}
        disabled={disabled}
      />
    </Form>
  )
}

export default ToggleSwitch
