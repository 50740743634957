import { gql } from '@apollo/client'
import { DO_VOUCHER_TEMPLATE_FIELDS } from './DOVoucherTemplateFields'

export const DO_VOUCHER_FIELDS = gql`
  ${DO_VOUCHER_TEMPLATE_FIELDS}
  fragment DOVoucherFields on object_DOVoucher {
    id
    voucherCode
    dateBegin
    dateEnd
    voucherState
    totalAmount {
      value
      toString
    }
    voucherTemplate {
      ...DOVoucherTemplateFields
    }
    isGeneric
    voucherUsage {
      ... on fieldcollection_DOVoucherUsageFC {
        additionDate
        usageDate
        amount {
          value
          unit {
            abbreviation
          }
        }
      }
    }
  }
`
