import { gql } from '@apollo/client'

export const CM_DELETE_LENSES = gql`
    mutation CM_DELETE_LENSES($orderLineId: Int!) {
      deleteLenses(orderLineId: $orderLineId) {
        output {
          errorMessage
          order {
            ... on object_DOOrder {
              id
            }
          }
        }
      }
    }
`