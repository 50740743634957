import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_DO_BENEFICIARY } from 'graphql/queries/getDOBeneficiary'
import { GET_ORDER_BENEFICIARIES } from 'graphql/queries/getOrderBeneficiaries'
import React, { useEffect } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router-dom'
import { getFormattedDate } from 'utils/functions'
import NewBeneficiaryForm from './NewBeneficiaryForm'

interface Props {
  beneficiary?: beneficiaryType
  selectedBeneficiary: any
  setSelectedBeneficiary: React.Dispatch<React.SetStateAction<any>>
  setConfirmationMessage: React.Dispatch<React.SetStateAction<string>>
  newBeneficiary: any
  setNewBeneficairy: React.Dispatch<React.SetStateAction<any>>
  selectedPrescription: string
  setSelectedPrescription: React.Dispatch<React.SetStateAction<string>>
  orderLine?: orderLineType
  loadingMutation: boolean
  setNoMutationPerformed: React.Dispatch<React.SetStateAction<boolean>>
  setLensInfoVisible: React.Dispatch<React.SetStateAction<boolean>>
  calculators: orderCalculatorsType
}
const BeneficiaryEditForm: React.FC<Props> = ({
  beneficiary,
  selectedBeneficiary,
  setSelectedBeneficiary,
  setConfirmationMessage,
  newBeneficiary,
  setNewBeneficairy,
  selectedPrescription,
  setSelectedPrescription,
  orderLine,
  loadingMutation,
  setNoMutationPerformed,
  setLensInfoVisible,
  calculators
}) => {
  const { id } = useParams<ParamType>()

  const { error, loading, data } = useQuery(GET_ORDER_BENEFICIARIES, {
    variables: {
      id: +id,
    },
  })
  const [
    getBeneficiaryPrescription,
    { loading: getBeneficiaryLoading, data: getBeneficiaryData },
  ] = useLazyQuery(GET_DO_BENEFICIARY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      // cas ou le bénéficiaire n'a pas d'ordo on met par défaut la new-prescription
      if (selectedBeneficiary && !data?.getDOBeneficiary?.prescriptions)
        setSelectedPrescription('new-prescription')
      else if(beneficiary && orderLine?.prescription.id)
        setSelectedPrescription(orderLine.prescription.id)
    },
  })
  const beneficiaryId = orderLine ? calculators.beneficiaryId : ''

  useEffect(() => {
    if (orderLine && selectedBeneficiary && !isNaN(selectedBeneficiary)) {
      getBeneficiaryPrescription({
        variables: {
          id: +selectedBeneficiary,
        },
      })
    }
  }, [getBeneficiaryPrescription, orderLine, selectedBeneficiary])

  if (loading)
    return (
      <h3>
        <Skeleton />
      </h3>
    )
  if (error) return <p>`Error! ${error.message}`</p>

  const prescriptionOptions = getBeneficiaryData?.getDOBeneficiary
    ?.prescriptions ? (
    getBeneficiaryData?.getDOBeneficiary?.prescriptions?.map(
      (prescription: prescriptionType, i: number) => {
        let orderLabel = ''
          if (
            !!orderLine?.order?.[0].orderLines?.find(
              (orderLine: any) =>
                orderLine?.prescription?.id === prescription.id
            )
          ) {
            orderLabel = 'en cours '
            // sinon on affiche l'id de la commande la plus récente c-à-d celle avec le plus grand id pimcore
          } else {
            if (prescription?.orderLines?.length) {
              orderLabel = prescription?.orderLines?.[0]?.order?.[0]?.key
            }
          }
        return (
          <option key={i} value={prescription.id}>
            {orderLine?.prescription?.id === prescription.id ? "Équipement en cours "
            : orderLabel ? `Cmd ${orderLabel}` : ''}{' '}
            {prescription.prescriptionDataLabel}
          </option>
        )
      }
    )
  ) : (
    <></>
  )

  const beneficiaryOptions = (
    <>
      {!beneficiaryId && (
        <option hidden value="">
          Choisir le bénéficiaire
        </option>
      )}
      {data.getDOOrder.customer.beneficiaries
        .filter((benef: beneficiaryType) =>
          beneficiaryId
            ? +benef.id === beneficiaryId
            : benef.id !== beneficiary?.id
        )
        .map((beneficiary: any, i: number) => {
          return (
            <option key={i} value={beneficiary.id} hidden={!!beneficiaryId}>
              {beneficiary.name[0].firstName} {beneficiary.name[0].lastName}{' '}
              {getFormattedDate(beneficiary.birthDate)}{' '}
              {beneficiary.name[0].civility === 'monsieur' ? 'H' : 'F'}
            </option>
          )
        })}
      {beneficiary && !orderLine && (
        <option value="no-beneficiary">Sans bénéficiaire</option>
      )}
      {!beneficiaryId && (
        <option value="new-beneficiary">Nouveau bénéficiaire</option>
      )}
    </>
  )

  return (
    <>
      <Form.Group className="my-4" controlId="beneficiary-form">
        <Row className="align-items-center">
          <Col xs={3}>
            <Form.Label>Bénéficiaire</Form.Label>
          </Col>
          <Col>
            <Form.Select
              className={beneficiaryId ? 'text-lightgray' : ''}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setSelectedBeneficiary(e.target.value)
                setConfirmationMessage('')
                // reset value of prescription and hide lensInfo
                setLensInfoVisible(false)
                setSelectedPrescription('')
                setNoMutationPerformed(false)
              }}
              disabled={loadingMutation}
            >
              {beneficiaryOptions}
            </Form.Select>
          </Col>
        </Row>
      </Form.Group>

      {selectedBeneficiary === 'new-beneficiary' && (
        <NewBeneficiaryForm
          newBeneficiary={newBeneficiary}
          setNewBeneficairy={setNewBeneficairy}
        />
      )}
      {getBeneficiaryLoading && (
        <h3>
          <Skeleton />
        </h3>
      )}
      {orderLine &&
        (getBeneficiaryData || selectedBeneficiary === 'new-beneficiary') && (
          <div>
            <Form.Group className="my-4" controlId="prescription-select-form">
              <Row className="align-items-center">
                <Col xs={3}>
                  <Form.Label>Ordonnance</Form.Label>
                </Col>
                <Col className="position-relative">
                  <div className="position-relative">
                    <Form.Select
                      className={
                        selectedPrescription !== '' &&
                        // si l'order a la prescription
                        !!orderLine?.order?.[0].orderLines.find(
                          (orderLine: any) =>
                            orderLine?.prescription?.id === selectedPrescription
                        )
                          ? 'text-primary'
                          : selectedBeneficiary === 'new-beneficiary' ||
                            !getBeneficiaryData?.getDOBeneficiary?.prescriptions
                          ? 'text-lightgray'
                          : ''
                      }
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        setSelectedPrescription(e.target.value)
                        setConfirmationMessage('')
                        setNoMutationPerformed(false)
                      }}
                      defaultValue={
                        selectedBeneficiary === 'new-beneficiary'
                          ? 'new-prescription'
                          : orderLine?.prescription?.id ? orderLine.prescription.id :''
                      }
                      disabled={loadingMutation}
                    >
                      {selectedBeneficiary !== 'new-beneficiary' &&
                        getBeneficiaryData?.getDOBeneficiary?.prescriptions && (
                          <>
                            <option hidden value="">
                              Choisir l'ordonnance{' '}
                            </option>
                            {prescriptionOptions}
                          </>
                        )}
                      <option
                        hidden={
                          selectedBeneficiary === 'new-beneficiary' ||
                          !getBeneficiaryData?.getDOBeneficiary?.prescriptions
                        }
                        value="new-prescription"
                      >
                        Nouvelle ordonnance
                      </option>
                    </Form.Select>
                  </div>
                </Col>
              </Row>
            </Form.Group>
          </div>
        )}
    </>
  )
}

export default BeneficiaryEditForm
