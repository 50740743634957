import React, {useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import GET_PRODUCTS_DETAILS from 'graphql/queries/getProductDetails'
import { Accordion, Button, Card, Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import { useTranslation } from 'react-i18next'
import { sortAlphabetically } from 'utils/functions'
import { downloadImages } from './ImagesProduitsFunctions'

const ImagesProduits: React.FC = () => {
  const { t } = useTranslation()
  const [products, setProducts] = useState([]) as any
  const [errorList, setErrorList] = useState<string[]>([]) // list of products with no images
  const [defaultValues, setDefaultValues] = useState({
    minPrice: 0,
    maxPrice: 0,
    minLensWidth: 0,
    maxLensWidth: 0,
    minTotalWidth: 0,
    maxTotalWidth: 0,
    frameName: '',
  })
  const [newFilters, setNewFilters] = useState<filterType>(defaultValues)
  const [genders, setGenders] = useState<string[]>([])
  const [shapes, setShapes] = useState<string[]>([])
  const [colors, setColors] = useState<string[]>([])
  const [materials, setMaterials] = useState<string[]>([])
  const [booleanOptions, setBooleanOptions] = useState<string[]>([])

  //QUERY filters and products
  const { data, error, loading } = useQuery(GET_PRODUCTS_DETAILS, {
    onCompleted: (data) => {
    //get min max values for price and measure filters
    const newDefaultValues = {
      minPrice: Math.min(...data.getProductsDetails.edges.map((edge:any) => edge.node.salePrice)),
      maxPrice: Math.max(...data.getProductsDetails.edges.map((edge:any) => edge.node.salePrice)),
      minLensWidth: Math.min(...data.getProductsDetails.edges.map((edge:any) => edge.node.lensWidth)),
      maxLensWidth: Math.max(...data.getProductsDetails.edges.map((edge:any) => edge.node.lensWidth)),
      minTotalWidth:  Math.min(...data.getProductsDetails.edges.map((edge:any) => edge.node.totalWidth)),
      maxTotalWidth: Math.max(...data.getProductsDetails.edges.map((edge:any) => edge.node.totalWidth)),
      frameName: '',
    }
    setDefaultValues(newDefaultValues)
    // set default values for price and measure filters
    setNewFilters(newDefaultValues)
    //set filter options
    setGenders([...new Set(data.getProductsDetails.edges.map((edge:any) => edge.node.gender))] as string[])
    setMaterials([...new Set(data.getProductsDetails.edges.map((edge:any) => edge.node.material))] as string[])
    setColors([...new Set(data.getProductsDetails.edges.map((edge:any) => edge.node.primaryColors))] as string[])
    setShapes([...new Set(data.getProductsDetails.edges.map((edge:any) => edge.node.shape))] as string[])
    const trueKeys =[] as string[]
    data.getProductsDetails.edges.map((edge:any) => {
      return Object.keys(edge.node).map((key:string) => {
        if(edge.node[key]===true && !trueKeys.includes(key))
          trueKeys.push(key)
        return trueKeys
      })
    })
    setBooleanOptions(trueKeys)
    setProducts(data.getProductsDetails.edges)
  }})
  useEffect(() => {
    //filter products
    const filterProducts = () => {
        const regex = new RegExp(newFilters.frameName, 'i')
        let result = data?.getProductsDetails.edges.filter((product: any) => (
        (newFilters.frameName.length<2 || regex.test(product.node.frameName))
        && (!newFilters.gender?.length || newFilters.gender?.includes(product.node.gender))
        && (!newFilters.shape?.length || newFilters.shape?.includes(product.node.shape)) 
        && (!newFilters.primaryColors?.length || newFilters.primaryColors?.includes(product.node.primaryColors)) 
        && (!newFilters.material?.length || newFilters.material?.includes(product.node.material))
        && product.node.salePrice >=(newFilters.minPrice as number)
        && product.node.salePrice <= (newFilters.maxPrice as number)
        && product.node.lensWidth >= (newFilters.minLensWidth as number)
        && product.node.lensWidth <= (newFilters.maxLensWidth as number)
        && product.node.totalWidth >= (newFilters.minTotalWidth as number)
        && product.node.totalWidth <= (newFilters.maxTotalWidth as number)
        && (!newFilters.progressiveCompatible || product.node.progressiveCompatible === newFilters.progressiveCompatible)
        && (!newFilters.availableHomeTrial || product.node.availableHomeTrial === newFilters.availableHomeTrial)
        && (!newFilters.sale || product.node.sale === newFilters.sale)
        && (!newFilters.destocking || product.node.destocking === newFilters.destocking)
        && (!newFilters.withSolarClip || product.node.withSolarClip === newFilters.withSolarClip)
        ))
        setProducts(result)
    }
    filterProducts()
  }, [data, newFilters])

  if(loading) return  <Card><SkeletonBase count={10} /></Card>
  if (error) return <p>Error! {error.message}</p>

  //get filter options
  const getFilterOptions = (values: string[], key: string) => {
    if(key === 'shape'|| key === 'primaryColors' || key === 'material'|| key==='gender')
      return values.map((value, index) => {
        return <Form.Check
          className='me-3'
          key={index}
          type="checkbox"
          id={`${value}`}
          label={value}
          checked={newFilters[key]?.includes(value)??false}
          onChange={() => handleChangeFilter(key, value)}
        />
      })
  }
  //get general characteristics filter options
  const getGeneralCharacteristics = () => {
    return booleanOptions.map((key:string, index:number) =>{
      if(['progressiveCompatible', 'availableHomeTrial', 'sale', 'destocking', 'withSolarClip'].includes(key)){
        const newFiltersArray = newFilters[key as 'progressiveCompatible'|'availableHomeTrial'|'sale'|'destocking'|'withSolarClip'] as boolean
        return <Form.Check
          className='me-3'
          key={index}
          type="checkbox"
          id={key}
          label={t(key)}
          checked={newFiltersArray??false}
          onChange={() => setNewFilters(prevFilters => ({
              ...prevFilters,
              [key]: !newFiltersArray
            }))}
        />
      }
      return <></>
    })
  }

  //handle change filter price and measures or frameName
  const handleChangeFilter = (filterName :string, newValue: string) => {
    if(['shape', 'primaryColors', 'material','gender'].includes(filterName)){
      const newFiltersArray = newFilters[filterName as 'shape'|'primaryColors'|'material'|'gender'] as string[]
      if(!newFiltersArray){
         const newValues = [newValue]
        //add value to array
        setNewFilters(prevFilters => ({
          ...prevFilters,
          [filterName]: newValues
        }))
      }
      else if(newFiltersArray.includes(newValue)){
        const newValues = newFiltersArray.filter((val) => val !== newValue)
        //remove value from array
        setNewFilters(prevFilters => ({
          ...prevFilters,
          [filterName]: newValues
        }))
      }else {
        const newValues = [...newFiltersArray, newValue]
        //add value to array
        setNewFilters(prevFilters => ({
          ...prevFilters,
          [filterName]: newValues
        }))
      }
    }
    else if((filterName.includes('min') || filterName.includes('max'))){
      if(newValue === ''){
        setNewFilters(prevFilters => ({
          ...prevFilters,
          [filterName]: ''
        }))
      }else if(typeof newValue ==="string" && !isNaN(parseFloat(newValue)) && /^(\d+\.?\d{0,2}|\.\d{1,2})$/.test(newValue)){
        setNewFilters(prevFilters => ({
          ...prevFilters,
          [filterName]: +newValue
        }))
      }
    } else if(!(filterName.includes('min') || filterName.includes('max'))){
        setNewFilters(prevFilters => ({
        ...prevFilters,
        [filterName]: newValue
      }))
    }
  }
  //reset filters
  const resetFilters = () => {
    setNewFilters({...defaultValues, frameName: ''})
    setProducts(data.getProductsDetails.edges)
  }

  return (
    <Container>
      <Container className='border p-4'>
        <Row>
            <Form.Group as={Row} controlId={"frameName"} className="mb-3">
              <Form.Label column xs={3} className=''>
                Rechercher par nom de monture :
              </Form.Label>
            <Col xs={4}>
              <Form.Control
                  type="text"
                  value={newFilters.frameName??''}
                  onChange={(e) => handleChangeFilter('frameName', e.target.value)}
                />
            </Col>
            <Col>
              <Button className='me-3' variant='secondary' onClick={resetFilters}
                disabled={loading}>Tout effacer
              </Button>
            </Col>
          </Form.Group>
        </Row>
        <Row className='my-3'>
          <span className='text'>Filtrer par : </span>
        </Row>
        {/* accordions with filter */}
        <Row>
          {/* col 1 */}
          <Col>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="">
                  <Accordion.Header>CARACTÉRISTIQUES GÉNÉRALES</Accordion.Header>
                  <Accordion.Body className='d-flex flex-wrap'>
                    {getGeneralCharacteristics()}
                  </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>GENRE</Accordion.Header>
                  <Accordion.Body className='d-flex flex-wrap'>
                    {getFilterOptions(sortAlphabetically(genders), 'gender')}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            <Accordion defaultActiveKey="2">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>FORME DE LA MONTURE</Accordion.Header>
                  <Accordion.Body className='d-flex flex-wrap'>
                    {getFilterOptions(sortAlphabetically(shapes), 'shape')}
                  </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey="3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>COULEUR</Accordion.Header>
                  <Accordion.Body className='d-flex flex-wrap'>
                    {getFilterOptions(sortAlphabetically(colors), 'primaryColors')}
                  </Accordion.Body>
                </Accordion.Item>
            </Accordion>
          </Col>
          {/* col 2 */}
          <Col>
            <Accordion defaultActiveKey="4">
              <Accordion.Item eventKey="0">
                <Accordion.Header>MATIÈRE</Accordion.Header>
                <Accordion.Body className='d-flex flex-wrap'>
                  {getFilterOptions(sortAlphabetically(materials), 'material')}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey="5">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  PRIX
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col xs={4}>
                      <InputGroup>
                      <InputGroup.Text>De</InputGroup.Text>
                        <Form.Control
                          type="text"
                          value={newFilters.minPrice}
                          onChange={(e) => handleChangeFilter('minPrice', e.target.value)}
                        />
                      <InputGroup.Text>€</InputGroup.Text>
                      </InputGroup>
                    </Col>
                    <Col xs={4}>
                      <InputGroup>
                        <InputGroup.Text>à</InputGroup.Text>
                        <Form.Control
                          type="text"
                          value={newFilters.maxPrice}
                          onChange={(e) => handleChangeFilter('maxPrice', e.target.value)}
                        />
                        <InputGroup.Text>€</InputGroup.Text>
                      </InputGroup>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey="6">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  LARGEUR DU VERRE
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col xs={4}>
                      <InputGroup>
                      <InputGroup.Text>De</InputGroup.Text>
                        <Form.Control
                          type="text"
                          value={newFilters.minLensWidth}
                          onChange={(e) => handleChangeFilter('minLensWidth', e.target.value)}
                        />
                      <InputGroup.Text>mm</InputGroup.Text>
                      </InputGroup>
                    </Col>
                    <Col xs={4}>
                      <InputGroup>
                        <InputGroup.Text>à</InputGroup.Text>
                        <Form.Control
                          type="text"
                          value={newFilters.maxLensWidth}
                          onChange={(e) => handleChangeFilter('maxLensWidth', e.target.value)}
                        />
                        <InputGroup.Text>mm</InputGroup.Text>
                      </InputGroup>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey="7">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  LARGEUR TOTALE
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col xs={4}>
                      <InputGroup>
                      <InputGroup.Text>De</InputGroup.Text>
                        <Form.Control
                          type="text"
                          value={newFilters.minTotalWidth}
                          onChange={(e) => handleChangeFilter('minTotalWidth', e.target.value)}
                        />
                      <InputGroup.Text>mm</InputGroup.Text>
                      </InputGroup>
                    </Col>
                    <Col xs={4}>
                      <InputGroup>
                        <InputGroup.Text>à</InputGroup.Text>
                        <Form.Control
                          type="text"
                          value={newFilters.maxTotalWidth}
                          onChange={(e) => handleChangeFilter('maxTotalWidth', e.target.value)}
                        />
                        <InputGroup.Text>mm</InputGroup.Text>
                      </InputGroup>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      {/* total count */}
      {data && products && <div className='text-muted my-2 text-end'>{products.length?`Résultats : ${products.length}`:'Aucun résultat'}</div>}
      {/* results */}
      {data && products && 
      <Table className='bg-light'>
        <thead>
          <tr>
            <th>Nom de la monture + couleur</th>
            <th>EAN</th>
            <th>Télécharger les images</th>
          </tr>
        </thead>
        <tbody>
          {data && products?.map((product: any, index: number) =>{
            return <tr key={index}>
              <td>{product.node.frameName}</td>
              <td>{product.node.gtin}</td>
              <td>
                <Button variant='primary' 
                  id={product.node.gtin}
                  disabled={errorList.includes(product.node.gtin)}
                  onClick={() => downloadImages(JSON.parse(product.node.imageUrls), product.node.gtin, errorList, setErrorList)}>
                  Télécharger
                </Button>
              </td>
            </tr>
          }
          )}
        </tbody>
      </Table>}
    </Container>
  )
}

export default ImagesProduits
