import { gql } from '@apollo/client'

export const DO_ORDER_EVENT_FIELDS = gql`
  fragment DOOrderEventFields on object_DOOrderEvent {
    id
    comment
    creationDate
    closureDate
    userLoginCreate
    userLoginClosure
    mailInfo
    label
    sentEmail

    eventType {
      ... on object_DOOrderEventType {
        id
        key
        eventFamily
        workflowTransition
        eventStage
        isLastOpen
      }
    }
    order {
      ... on object_DOOrder {
        id
        workflowState
        orderLines {
          ... on object_DOOrderLine {
            id
            status
            state
            stockState
          }
        }
        # pour mettre à jour la date de clôture des events
        events {
          ... on object_DOOrderEvent {
            id
            closureDate
          }
        }
      }
    }
  }
`
