import { gql } from '@apollo/client'

export const GET_DELIVERY_MODE_LISTING = gql`
  query GET_DELIVERY_MODE_LISTING {
    getDODeliveryModeListing {
      edges {
        node {
          id
          key
          label
          active
        }
      }
    }
  }
`
