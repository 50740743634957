import gql from 'graphql-tag'
import { DO_COSIUM_QUOTE_FIELDS } from 'graphql/fragments/DOCosiumQuoteFields'
import { DO_CUSTOMER_FIELDS } from 'graphql/fragments/DOCustomerFields'
import { ORDER_TOTAL_AMOUNT_FIELDS } from 'graphql/fragments/DOOrderTotalAmountFCFields'

/*  cosiumId, totalPaidAmount, workflowState and totalAmountHistory
    added to accommodate the merging of both cosium popups in one */
const GET_ORDER_INFO = gql`
    ${DO_CUSTOMER_FIELDS}
    ${ORDER_TOTAL_AMOUNT_FIELDS}
    ${DO_COSIUM_QUOTE_FIELDS}
    query GET_ORDER_INFO($id: Int!) {
      CQgetOrder(id: $id) {
        edges {
          node {
            order {
              id
              key
              creationDate
              modificationDate
              cosiumQuotes {
                ...DOCosiumQuoteFields
              }
              customer {
                ...DOCustomerFields
              }
              totalAmountHistory {
                ...DOOrderTotalAmountFCFields
              }
              orderType
              dateLimitReturnEssdom
              workflowState
            }
            calculators {
              cosiumId
              totalPaidAmount
              beneficiaryId
            }
          }
        }
      }
    }
  `

export default GET_ORDER_INFO