import { useMutation } from '@apollo/client'
import UPDATE_DO_ORDERLINE from 'graphql/mutations/updateDOOrderLine'
import React, { useContext, useState } from 'react'
import { Button, Form, Container, Row, Col, Spinner } from 'react-bootstrap'
import { ToastContext } from 'store/toast-context'
interface Props {
  orderLine: any
}
const VisionTypeForm: React.FC<Props> = ({ orderLine }) => {
  const { toast } = useContext(ToastContext)

  const [visionType, setVisionType] = useState(orderLine.visionType)

  const [updateDOOrderLine, { loading }] = useMutation(UPDATE_DO_ORDERLINE, {
    onCompleted: (data) => {
      toast({
        title: 'Succès :',
        message: `Type de vision de la ligne ${data.updateDOOrderLine.output.id} mis à jour`,
      })
    },
  })

  const updateDOOrderLineHandler = async (e: any) => {
    try {
      await updateDOOrderLine({
        variables: {
          id: +orderLine.id,
          visionType,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <Form.Check
              inline
              label="VL"
              name={`visionType${orderLine.id}`}
              type="radio"
              defaultChecked={orderLine.visionType === 'vl'}
              id="vl"
              value="vl"
              onChange={(e) => {
                setVisionType(e.target.value)
              }}
              disabled={orderLine.state === 'fac'}
            />
            <Form.Check
              inline
              label="VP"
              name={`visionType${orderLine.id}`}
              type="radio"
              defaultChecked={orderLine.visionType === 'vp'}
              id="vp"
              value="vp"
              onChange={(e) => {
                setVisionType(e.target.value)
              }}
              disabled={orderLine.state === 'fac'}
            />
            <Form.Check
              inline
              label="VL+VP"
              name={`visionType${orderLine.id}`}
              type="radio"
              defaultChecked={orderLine.visionType === 'vl_vp'}
              id="vl_vp"
              value="vl_vp"
              onChange={(e) => {
                setVisionType(e.target.value)
              }}
              disabled={orderLine.state === 'fac'}
            />
            <Form.Check
              inline
              label="Plus tard"
              name={`visionType${orderLine.id}`}
              type="radio"
              defaultChecked={orderLine.visionType === 'plus_tard'}
              id="plus_tard"
              value="plus_tard"
              onChange={(e) => {
                setVisionType(e.target.value)
              }}
              disabled={orderLine.state === 'fac'}
            />
            <Button
              variant="primary btn-sm"
              disabled={visionType === orderLine.visionType || loading}
              onClick={updateDOOrderLineHandler}
            >
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Enregistrer
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default VisionTypeForm
