import {
  Button,
  Spinner
} from 'react-bootstrap'
import { constants } from 'utils/constants'
import { ToastContext } from 'store/toast-context'
import { useContext } from 'react'
import { useMutation } from '@apollo/client'
import CM_CREATE_DO_ORDER_EVENT from 'graphql/mutations/CreateDOOrderEvent'

interface Props {
  orderId: number
  isFromWorkflowPlaceCompleted: boolean
  closeModal?: () => void
}

const OrderNantesToKehlTransferModal: React.FC<Props> = ({
    orderId,
    isFromWorkflowPlaceCompleted,
    closeModal
  }) => {
  const { toast } = useContext(ToastContext)

  // mutation qui crée l'event prêt à monter et passe la commande en attente de préparation
  const [createDOOrderEvent, { loading: createEventLoading }] = useMutation(
    CM_CREATE_DO_ORDER_EVENT,
    {
      onCompleted: () => {
        toast({
          title: 'Succès :',
          message: "Un statut de transfert de monture de Nantes à Kehl a été passé dans la commande.",
        })
        if (closeModal) closeModal()
      },
    }
  )

  const clickConfirmHandler = async () => {
    try {
      await createDOOrderEvent({
        variables: {
          orderId: orderId,
          eventTypeKey:
            isFromWorkflowPlaceCompleted ?
            constants.eventTypeKeys.ORDER_EVENT_TYPE_SAV_FRAME_TRANSFER_TO_KEHL :
            constants.eventTypeKeys.ORDER_EVENT_TYPE_CUSTOMER_FRAME_TRANSFER_TO_KEHL
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  const clickCancelSaveHandler = () => {
    if(closeModal) closeModal()
  }

  return (
  <>
    <div className="mt-4 mb-4 text-center">
      Êtes-vous sûr(e) de vouloir envoyer la monture à Kehl ?
    </div>
    <div className="mt-3 text-center">
      <Button
        className="m-2 btn-primary"
        onClick={clickConfirmHandler}
      >
        {(createEventLoading) && (
          <Spinner
            variant="light"
            className="me-2"
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
        OUI
      </Button>
      <Button
        variant="outline-primary bg-white text-primary"
        className="m-2"
        onClick={clickCancelSaveHandler}
      >
        Annuler
      </Button>
    </div>
  </>)
}

export default OrderNantesToKehlTransferModal