import React, { useEffect, useCallback } from "react"
import { getTokenFromLocalStorage } from '../../utils/functions'
import classes from './FileViewer.module.css'

interface Props {
  type: number // Type of file (ex: 1 for prescription)
  id: number // Pimcore id of referenced object (ex: DOPrescription)
  width?: string
  height?: string
  file: Blob | null
  setFile: React.Dispatch<React.SetStateAction<any>>
  confirmFile: string
  setConfirmFile : React.Dispatch<React.SetStateAction<string>>
}

const FileUploader: React.FC<Props> = ({ 
  type, id, width, height, file, setFile, confirmFile, setConfirmFile
}) => {

  const { REACT_APP_PIMCORE_URL_DOMAIN } = process.env

  const uploadFileHandler = async (e: any) => {
    setFile(e.target.files[0])
    uploadHandler() // Call uploadHandler immediately
  } 

  const uploadHandler = useCallback(() => {
    if (file !== null) {
      const uploadFile = async () => {
          const fd = new FormData();
          fd.append('upload', file);
          fd.append('token', getTokenFromLocalStorage())
          const options = () => {
            return {
              method: 'POST',
              body: fd
            }
          }
    
          const response = await fetch(`${REACT_APP_PIMCORE_URL_DOMAIN}/upload/bo/${type}/${id}`, options());
          if(response.status === 200)
          {
            // Deal with successful response
            setConfirmFile('Fichier enregistré avec succès')
            setTimeout(() => {setConfirmFile('')}, 4000)
          }
          else
            console.error('UPLOAD FILE ERROR', response)

          setFile(null)
      }
      uploadFile()
    }
  }, [REACT_APP_PIMCORE_URL_DOMAIN, file, id, setFile, type, setConfirmFile])

  useEffect(() => {
    uploadHandler()
  }, [uploadHandler, confirmFile])

  return (
    <div className={classes.uploader} style={{width: width, height: height}}>
      { 
        // <input id="file" className={classes.uploadinput} type="file" onChange={uploadFileHandler}/>
        <div className={classes.uploadinput}>
          <label htmlFor={`${id}`} className="d-flex">
              <input
                  className={classes.inputfile}
                  name="file"
                  id={`${id}`}
                  type="file"
                  onChange={uploadFileHandler}
              />
              <div className={`FileViewer ${classes.file}`}>Choisir un fichier</div>
              <div className={classes.filebtn}>Parcourir</div>
          </label>
        </div>
      }
       <div className="text-success">{confirmFile}</div>
    </div>
  )
}

export default FileUploader