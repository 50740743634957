import Card from 'components/UI/Card'
import React from 'react'
import VoucherSearchAndUpdate from './VoucherSearchAndUpdate'

interface Props {}
const DesactivationBon: React.FC<Props> = () => {
  return (
    <Card>
      <VoucherSearchAndUpdate
        btnLabel="Désactiver le bon"
        confirmMessage="Souhaitez-vous désactiver le bon :"
        toastTitle="Désactivation du bon :"
        managesGenericVouchers={false}
      />
    </Card>
  )
}

export default DesactivationBon
