import { gql } from '@apollo/client'

const LOGIN_USER = gql`
  query LOGIN_USER(
    $username: String
    $password: String
    $token: String
    $googleToken: String
  ) {
    getLoginData(
      username: $username
      password: $password
      token: $token
      googleToken: $googleToken
    ) {
      status {
        code
        message
      }
      menuList {
        name
        pageList {
          name
          path
          component
        }
      }
      websiteSettings {
        colissimoExpertThreshold
        essdomReturnAddress
        infiniteScrollTableFetchMoreRowsCount
        infiniteScrollTableInitialRowsCount
        roleOpticienMagasin
        limitVoucher
        delaiNouveaute
      }
      user {
        token
        fullName
        pimcoreAccess
        storeId
        roles
        image
        email
      }
    }
  }
`

export default LOGIN_USER
