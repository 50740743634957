import React, { useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Spinner } from 'react-bootstrap'
import { ToastContext } from 'store/toast-context'
import { useParams } from 'react-router-dom'
import BeneficiaryEditForm from './BeneficiaryEditForm'
import UPDATE_BENEFICIARY_AND_LENSES from 'graphql/mutations/updateBeneficiaryAndLenses'
import { getAge, getEyewearData } from 'utils/functions'
import { GET_ORDER_BENEFICIARIES } from 'graphql/queries/getOrderBeneficiaries'
import EditLensForm from './EditLensForm'
import { CQ_GET_ORDER } from 'graphql/queries/getOrder'

interface Props {
  beneficiary?: beneficiaryType
  closeModal?: () => void
  groupOfOrderLines?: orderLineType[] //cas ou on édite le bénéficiaire sur la carte de gauche
  orderLine?: orderLineType //cas ou on clique sur édition produit on aura l'orderLine
  calculators: orderCalculatorsType
}
const EditBeneficiaryAndLensesModal: React.FC<Props> = ({
  beneficiary,
  closeModal,
  groupOfOrderLines,
  orderLine,
  calculators
}) => {
  const { id } = useParams<ParamType>()
  const { toast } = useContext(ToastContext)

  const [selectedBeneficiary, setSelectedBeneficiary] = useState(
    orderLine ? calculators.beneficiaryId : ''
  )
  const [selectedPrescription, setSelectedPrescription] = useState('')
  const [confirmationMessage, setConfirmationMessage] = useState('')
  const [newBeneficiary, setNewBeneficairy] = useState({
    lastName: '',
    firstName: '',
    birthdate: '',
    gender: '',
  })

  const [lensInfoVisible, setLensInfoVisible] = useState(false)
  const eyewearData = getEyewearData(orderLine?.items)
  const [newLenses, setNewLenses] = useState({right:eyewearData.right,left:eyewearData.left})
  const [noMutationPerformed, setNoMutationPerformed] = useState<boolean>(true)

  const [updateBeneficiaryAndLenses, { loading: loadingMutation }] = useMutation(
    UPDATE_BENEFICIARY_AND_LENSES,
    {
      onCompleted: () => {
        toast({
          title: 'Succès :',
          message:
            selectedBeneficiary === 'no-beneficiary'
              ? 'Suppression du bénéficiaire enregistrée'
              : 'Modification du bénéficiaire enregistrée',
        })
        if (closeModal) {
          closeModal()
        }
      },
      refetchQueries: [
        CQ_GET_ORDER, //qd on change de bénéficiaire, rafraichit la liste d'ordo mais pas la liste de benef quand on supprime
        GET_ORDER_BENEFICIARIES, //qd on ajoute un nouveau bénéficiaire rafraichit la liste des bénéficiaires mais pas la liste d'ordo si on ajoute une nouvelle
      ],
      awaitRefetchQueries: true,
    }
  )

  const getMutationVariables = () => {
    if (selectedBeneficiary === 'new-beneficiary'){
      return {
        orderId: +id,
        jsonNewBeneficiary : JSON.stringify(newBeneficiary),
        orderLineIdToChange: orderLine ? +orderLine.id : null, //cas ou on clique sur édition produit on aura l'orderLine
      }}
    else if (groupOfOrderLines && selectedBeneficiary === 'no-beneficiary')
      return {
        orderId: +id,
        orderLines: JSON.stringify(groupOfOrderLines),
      }
    else {
      // Changement de bénéficiaire et/ou d'ordonnance
      return {
        orderId: +id,
        replacementBeneficiaryId: +selectedBeneficiary,
        orderLineIdToChange: orderLine ? +orderLine.id : null, //cas ou on clique sur édition produit on aura l'orderLine et la prescription
        prescriptionId: orderLine ? +selectedPrescription : null, // et la prescription
        jsonLenses:  JSON.stringify(newLenses)
      }
    }
  }

  const saveHandler = async () => {
    try {
      if (selectedBeneficiary === 'new-beneficiary') {
        await updateBeneficiaryAndLenses({
          variables: getMutationVariables(),
        })
      } else
        setConfirmationMessage(
          selectedBeneficiary === 'no-beneficiary'
            ? "Attention, vous allez retirer le bénéficiaire de l'équipement, ce qui va supprimer les verres correcteurs et l’ordonnance associée"
            : !orderLine //cas ou on a cliqué sur BeneficiaryCard à gauche
            ? 'Êtes-vous sûr(e) de modifier le bénéficiaire de la commande et de lui associer la ou les ordonnances présente(s) sur la commande ?'
            : beneficiary //cas ou on clique sur édition produit avec bénéficiaire
            ? 'Êtes-vous sûr(e) de modifier l’ordonnance et les verres associés à la monture ? Veuillez vérifier la compatibilité des verres sélectionnés et de la monture.'
            : 'Êtes-vous sûr(e) de vouloir ajouter des verres ainsi qu’une ordonnance et un bénéficiaire à la monture ? Veuillez vérifier la compatibilité des verres sélectionnés et de la monture.'
        )
    } catch (e) {
      console.log(e)
    }
  }

  const confirmHandler = async () => {
    try {
      setConfirmationMessage('')
      await updateBeneficiaryAndLenses({
        variables: getMutationVariables(),
      })
    } catch (e) {
      console.log(e)
    }
  }
  const cancelHandler = () => {
    if (confirmationMessage) setConfirmationMessage('')
    else {
      if (closeModal) closeModal()
    }
  }

  const isNewBeneficiaryInValid = () => {
    return (newBeneficiary.lastName === '' ||
      newBeneficiary.firstName === '' ||
      getAge(newBeneficiary.birthdate) <= 16 ||
      newBeneficiary.gender === '');
  }
  const isPrescriptionInvalid = () =>{
    return orderLine && !selectedPrescription && selectedBeneficiary !== 'new-beneficiary';
  }
  const isLensNotSelected = () => {
    return !newLenses.right.lens?.id && !newLenses.left.lens?.id 
  }
  const isLensPriceEmpty = () => {
    return (newLenses.right.lens?.id && !newLenses.right.price)
    || (newLenses.left.lens?.id && !newLenses.left.price);
  }
  const newBeneficiaryInValid = isNewBeneficiaryInValid()
  const isBtnDisabled =
    loadingMutation ||
    !selectedBeneficiary ||
    (selectedBeneficiary === 'new-beneficiary' && newBeneficiaryInValid) ||
    isPrescriptionInvalid() ||
   (orderLine && (isLensNotSelected() || isLensPriceEmpty())) ||
    noMutationPerformed 

  //prevents warning in console after deleting lenses and beneficiary because we want to close unmounted componant
  useEffect(() => {
    const ac = new AbortController();
    return () => ac.abort(); // Abort both fetches on unmount
  }, [])

  useEffect(() => {
    if((selectedBeneficiary && selectedPrescription) || 
      (selectedBeneficiary === 'new-beneficiary'&&!newBeneficiaryInValid))
      setLensInfoVisible(true)
  },[selectedPrescription,selectedBeneficiary, newLenses, newBeneficiaryInValid, lensInfoVisible])

  return (
    <div>
      <h4>
        <strong>{orderLine ?"Sélectionner d’abord le bénéficiaire et l'ordonnance" :"Modification du bénéficiaire"}</strong>
      </h4>

      <BeneficiaryEditForm
        beneficiary={beneficiary}
        calculators={calculators}
        selectedBeneficiary={selectedBeneficiary}
        setSelectedBeneficiary={setSelectedBeneficiary}
        setConfirmationMessage={setConfirmationMessage}
        newBeneficiary={newBeneficiary}
        setNewBeneficairy={setNewBeneficairy}
        orderLine={orderLine}
        selectedPrescription={selectedPrescription}
        setSelectedPrescription={setSelectedPrescription}
        loadingMutation={loadingMutation}
        setNoMutationPerformed={setNoMutationPerformed}
        setLensInfoVisible={setLensInfoVisible}
      />
      
      {orderLine &&
      <EditLensForm 
        newLenses={newLenses} 
        setNewLenses={setNewLenses} 
        lensInfoVisible={lensInfoVisible}
        categoryId={eyewearData.categoryId}
        setNoMutationPerformed={setNoMutationPerformed}
      />}

      <p>{confirmationMessage}</p>

      <div className="mt-3 text-center">
        <Button
          className={`m-2 btn ${confirmationMessage?"btn-success":"btn-primary"}`}
          disabled={isBtnDisabled}
          onClick={() => {
            confirmationMessage ? confirmHandler() : saveHandler()
          }}
        >
          {loadingMutation && (
            <Spinner
              variant="light"
              className="me-2"
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {confirmationMessage ? 'Confirmer' : 'Enregistrer'}
        </Button>

        <Button
          variant="outline-primary bg-white text-primary"
          className="m-2"
          disabled={loadingMutation}
          onClick={() => cancelHandler()}
        >
          Annuler
        </Button>
      </div>
    </div>
  )
}

export default EditBeneficiaryAndLensesModal
