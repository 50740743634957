export default function validateInfo(values: any) {
  let errors = {} as any
  const textRequired = 'Veuillez renseigner ce champ'
  const phoneNumberInvalid = 'Veuillez entrer un numéro de téléphone valide'

  // firstName
  if (!values.firstName?.trim()) {
    errors.firstName = textRequired
  } else if (/\d+/.test(values.firstName)) {
    errors.firstName = 'Le prénom ne doit pas contenir de chiffres'
  }
  // lastName
  if (!values.lastName?.trim()) {
    errors.lastName = textRequired
  } else if (/\d+/.test(values.lastName)) {
    errors.lastName = 'Le nom ne doit pas contenir de chiffres'
  }
  //email
  if (!values.email?.trim()) {
    errors.email = textRequired
  } else if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      values.email
    )
  ) {
    errors.email = 'Veuillez entrer une adresse email valide'
  }

  //landlinePhoneNumber
  if (
    values.landlinePhoneNumber &&
    !/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(
      values.landlinePhoneNumber
    )
  ) {
    errors.landlinePhoneNumber = phoneNumberInvalid
  }
  //mobilePhoneNumber
  if (
    values.mobilePhoneNumber &&
    !/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(
      values.mobilePhoneNumber
    )
  ) {
    errors.mobilePhoneNumber = phoneNumberInvalid
  }
  //postalCode
  if (!values.postalCode?.trim()) {
    errors.postalCode = textRequired
  } else if (values.postalCode && !/^\d{5}$/.test(values.postalCode)) {
    errors.postalCode = 'Veuillez entrer un code postal valide'
  }

  //birthdate
  if (!values.birthdate) {
    errors.birthdate = 'Veuillez renseigner la date de naissance'
  }

  if (!values.prescriptionDate) {
    errors.prescriptionDate = 'Veuillez renseigner la date'
  }
  const prescriptionNameInput = [
    'prescriberFullname',
    'prescriberNumber',
    'prescriberNIN',
  ]
  prescriptionNameInput.map((nameInput: string) => {
    if (!values[nameInput]) {
      return (errors[nameInput] = textRequired)
    }
    return ''
  })

  const pupillaryDistance = ['pupillaryDistanceRight', 'pupillaryDistanceLeft']
  pupillaryDistance.map((nameInput: string) => {
    if (values[nameInput] < 20 || values[nameInput] > 45) {
      return (errors[nameInput] = 'Valeur entre 20 et 45')
    }
    return ''
  })

  const sphere = ['rightSphere', 'leftSphere']
  sphere.map((nameInput: string) => {
    if (values[nameInput] < -12 || values[nameInput] > 12) {
      return (errors[nameInput] = 'Valeur entre -12 et 12')
    }
    return ''
  })
  const cylinder = ['rightCylinder', 'leftCylinder']
  cylinder.map((nameInput: string) => {
    if (values[nameInput] < -4 || values[nameInput] > 4) {
      return (errors[nameInput] = 'Valeur entre -8 et 8')
    }
    return ''
  })
  const axis = ['rightAxis', 'leftAxis']
  axis.map((nameInput: string) => {
    if (values[nameInput] < 0 || values[nameInput] > 180) {
      return (errors[nameInput] = 'Valeur entre 0 et 180')
    }
    return ''
  })
  const addition = ['rightAddition', 'leftAddition']
  addition.map((nameInput: string) => {
    if (values[nameInput] < 0 || values[nameInput] > 4) {
      return (errors[nameInput] = 'Valeur entre 0 et 4')
    }
    return ''
  })

  return errors
}
