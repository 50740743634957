import { useQuery } from '@apollo/client'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import GET_ORDER_PICKING_LISTING from 'graphql/queries/getOrderPickingListing'
import { SetStateAction, useContext, useState } from 'react'
import { Button, Tab, Tabs } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import {
  printOrderIdsPdf,
  stringsToJSXs,
} from 'utils/functions'
import PickingBulkMutationBtn from '../containers/logistique_kehl/PickingBulkMutationBtn'
import { constants } from 'utils/constants'
import ResultsTable from 'components/UI/ResultsTable'
import MenuContext from 'store/menu-context'

interface Props {
  isHomeTrial?: boolean
  pickingEventTypeKeys: string[]
  columns: string[]
}
const PickingTabs: React.FC<Props> = ({
  isHomeTrial,
  pickingEventTypeKeys,
  columns,
}) => {
  const { t } = useTranslation()
  const [key, setKey] = useState(pickingEventTypeKeys[0])
  const [isPrintBtnDisabled, setIsPrintBtnDisabled] = useState<boolean>(false)
  // State for checkbox in picking essDom 1st Tab
  const [checkedIdList, setCheckedIdList] = useState<string[]>([])
  const { totalOrderToPick } = useContext(MenuContext);

  const { error, loading, data, refetch } = useQuery(
    GET_ORDER_PICKING_LISTING,
    {
      variables: {
        orderEventTypeKeys: pickingEventTypeKeys,
      },
    }
  )

  if (error) return <p>`Error! ${error.message}`</p>

  if (loading) return <SkeletonBase count={5} />

  const tabsContent = pickingEventTypeKeys.map((eventTypeKey: string) => {
    // Keeping only orders concerned by event type
    const ordersToDisplay = JSON.parse(
      JSON.stringify(
        data.getOrderPickingListing.edges.filter(
          (edge: any) => edge.node.eventTypeKey === eventTypeKey
        )
      )
    )

    // List of orderIds to be printed
    const orderIds = ordersToDisplay
      .map((item: any) => {
        return item.node.id
      })

    // Customizing display info
    ordersToDisplay.forEach((item: any) => {
      let node = item.node

      node.id = node.id.toString()

      node.cosiumId = (
        <u className="link-primary">
          {node.cosiumId ? node.cosiumId : 'inexistant'}
        </u>
      )

      node.orderKey =
        eventTypeKey ===
        constants.eventTypeKeys.ORDER_EVENT_TYPE_PICKING_KEHL_HOME_TRIAL ? (
          <a
            href={`/bo/relation_client/gestion_commande_client/${node.id}`}
            target="_blank"
            rel="noreferrer"
            onClick={(e: any) => e.stopPropagation()}
          >
            {node.orderKey}
          </a>
        ) : (
          <u className="link-primary"> {node.orderKey}</u>
        )
      node.cosiumId = node.cosiumId ? (
        node.cosiumId
      ) : (
        <p style={{ color: 'red', textDecoration: 'underline' }}>Inexistant</p>
      )
      node.fullName =
        node.customerFirstname !== null && node.customerLastname !== null
          ? node.customerFirstname +
            ' ' +
            node.customerLastname
          : '-'

      node.email =
        node.customerEmail !== null
          ? node.customerEmail
          : '-'

      node.phone = node.customerMobilePhone || node.customerLandlinePhone ? 
        [node.customerMobilePhone,node.customerLandlinePhone].filter( p => p ).join(' / ')
        : '-'

      node.lastEventDateFormatted = node.eventCreationDate
        ? new Intl.DateTimeFormat('fr', {
            dateStyle: 'short',
            timeStyle: 'medium',
          }).format(node.eventCreationDate * 1000)
        : '-'

      node.since = node.eventCreationDate
        ? Math.round(
            (new Date().getTime() / 1000 - node.eventCreationDate) /
              (24 * 60 * 60)
          )
        : '-'

      // Frames and checkbox are used only in picking essDom
      node.frames = node.productsToDestock = node.frameList
      node.checkboxVisible = true
    })

    let checkedIdListNumber = checkedIdList.map((id) => +id)

    const handleClickPrint = (orderIdsList: string[]) => {
      printOrderIdsPdf(orderIdsList.join(','), () => {
        setIsPrintBtnDisabled(false)
      })
      // if eventTypeKey === ORDER_EVENT_TYPE_PICKING_KEHL_HOME_TRIAL (essDom 1st Tab)
      // deselect checkbox
      if (
        eventTypeKey ===
        constants.eventTypeKeys.ORDER_EVENT_TYPE_PICKING_KEHL_HOME_TRIAL
      )
        setCheckedIdList([])
    }
    
  if(eventTypeKey===constants.eventTypeKeys.ORDER_EVENT_TYPE_KEHL_READY_TO_ASSEMBLE && 
    totalOrderToPick !== ordersToDisplay.length)
    refetch()

    return (
      <Tab
        eventKey={eventTypeKey}
        title={
          t(`orderEventType.${eventTypeKey}`) + ` (${ordersToDisplay.length})`
        }
        key={`${eventTypeKey} ${key === eventTypeKey ? 'active' : 'inactive'}`}
      >
        {/* ResultsTable in picking essDom 1st tab */}
        {eventTypeKey ===
        constants.eventTypeKeys.ORDER_EVENT_TYPE_PICKING_KEHL_HOME_TRIAL ? (
          <ResultsTable
            items={ordersToDisplay}
            queryType={t(`orderEventType.${eventTypeKey}`)}
            columns={stringsToJSXs(['orderKey', 'productsToDestock'], t)}
            tableHasCheckboxSelectAll={true}
            checkedIdListState={{ checkedIdList, setCheckedIdList }}
          />
        ) : (
          // ResultsTable in other case
          <ResultsTable
            items={ordersToDisplay}
            queryType={t(`orderEventType.${eventTypeKey}`)}
            columns={stringsToJSXs(columns, t)}
            onClickRows={(item: any) => {
              window.open(
                `/bo/relation_client/gestion_commande_client/${item.id}`
              )
            }}
          />
        )}

        <div className="position-fixed p-2 end-0 bottom-0 me-5 mb-5">
          <Button
            disabled={
              isPrintBtnDisabled ||
              eventTypeKey ===
                constants.eventTypeKeys.ORDER_EVENT_TYPE_PICKING_KEHL_HOME_TRIAL
                ? checkedIdList.length === 0
                : orderIds.length === 0
            }
            onClick={() => {
              setIsPrintBtnDisabled(true) // Désactive tous les boutons d'impression
              handleClickPrint(
                // if eventTypeKey = ORDER_EVENT_TYPE_PICKING_KEHL_HOME_TRIAL print selected orders (essDom 1st Tab)
                eventTypeKey ===
                  constants.eventTypeKeys
                    .ORDER_EVENT_TYPE_PICKING_KEHL_HOME_TRIAL
                  ? checkedIdList
                  : orderIds // else print all
              )
            }}
            variant="primary shadow zindex-2 btn-radius "
          >
            {eventTypeKey ===
            constants.eventTypeKeys.ORDER_EVENT_TYPE_PICKING_KEHL_HOME_TRIAL
              ? 'Impression péniches sélectionnées'
              : 'Impression péniches'}
          </Button>

          {/* 1st tab mutation button  */}
          {eventTypeKey === pickingEventTypeKeys[0] && (
            <PickingBulkMutationBtn
              readyToAssembleOrdersIds={
                isHomeTrial ? checkedIdListNumber : orderIds
              }
              essDom={isHomeTrial}
              setCheckedIdList={setCheckedIdList}
            />
          )}
        </div>
      </Tab>
    )
  })

  const tabs = (
    <Tabs
      id="controlled-tab-picking"
      activeKey={key}
      onSelect={(k) => setKey(k as SetStateAction<string>)}
      className="mb-3"
    >
      {tabsContent}
    </Tabs>
  )

  return <div className="px-3 mt-4">{tabs}</div>
}

export default PickingTabs
