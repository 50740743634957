import { InformationCircleIcon } from '@heroicons/react/outline'
import { getFormattedDateTime } from 'utils/functions'
import HeaderTitle from '../HeaderTitle'

interface Props {
  order: orderType
}

//TODO : revoir VOUCHER / AVOIR par rapport aux states des items
const OrderInfoModal: React.FC<Props> = ({ order }) => {
  const orderCreationDate = order.creationDate
    ? getFormattedDateTime(order.creationDate * 1000, false)
    : ''

  const orderModificationDate = order.modificationDate
    ? getFormattedDateTime(order.modificationDate * 1000, false)
    : ''

  return (
    <div className="modalInfo">
      <div>
        <HeaderTitle
          title="Informations annexes"
          children={<InformationCircleIcon className="header-title-icon" />}
        />
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td>
                <strong>Date de commande :</strong>
              </td>
              <td>{orderCreationDate}</td>
            </tr>
            <tr>
              <td>
                <strong>Date de validation :</strong>
              </td>
              <td>{orderCreationDate}</td>
            </tr>
            <tr>
              <td>
                <strong>Date de dernière modification :</strong>
              </td>
              <td>{orderModificationDate}</td>
            </tr>
            <tr>
              <th>IP commande :</th>
              <td>{order.orderIP}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default OrderInfoModal
