import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import ModalErrorMessage from './ModalErrorMessage'
interface Props {
  onGraphQLErrorFromParent: (
    setGraphQLError: (graphQLError: string) => void
  ) => any
}
const ModalError: React.FC<Props> = ({ onGraphQLErrorFromParent }) => {
  const [graphQLError, setGraphQLError] = useState('')

  useEffect(() => {
    // On envoie au parent la fonction qui met à jour le state de ce composant enfant
    onGraphQLErrorFromParent(setGraphQLError)
  }, [onGraphQLErrorFromParent])

  const handleClose = () => {
    setGraphQLError('')
  }

  return (
    <div className="ModalError">
      <Modal
        show={graphQLError !== ''}
        onHide={handleClose}
        dialogClassName={
          // customSize +
          ' modal-style d-flex justify-content-center align-items-center'
        }
      >
        <Modal.Body>
          <span
            className="btn btn-close position-absolute p-3 top-0 end-0"
            onClick={handleClose}
          ></span>
          {graphQLError !== '' && <ModalErrorMessage error={graphQLError} />}
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ModalError
