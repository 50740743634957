import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import SearchInputState, {
  defaultSearchInputStateValue,
} from 'utils/SearchInputStateInterface'

interface Props {
  label?: string
  placeholder?: string
  searchFunction: (searchInputText: string) => void
  setDisplayResult?: React.Dispatch<React.SetStateAction<boolean>> //state to display or hide the result of research
  setHasNoResultSelected?: React.Dispatch<React.SetStateAction<boolean>> //state to know when click on search
  setSelectedObject: React.Dispatch<React.SetStateAction<any>> // state used to reset selected object in parent
}
const SearchInput: React.FC<Props> = ({
  label,
  placeholder,
  searchFunction,
  setDisplayResult,
  setHasNoResultSelected,
  setSelectedObject,
}) => {
  const [searchInput, setSearchInput] = useState('')
  const [isSearchInputValid, setIsSearchInputValid] =
    useState<SearchInputState>(defaultSearchInputStateValue)

  const searchHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (searchInput.length < 3) {
      setIsSearchInputValid({
        valid: false,
        message: "Entrez un nom d'au moins 3 caractères",
      })
      return
    }

    if (setHasNoResultSelected) setHasNoResultSelected(true)

    if (setDisplayResult) setDisplayResult(true)

    searchFunction(searchInput)
  }

  const cancelHandler = () => {
    setSearchInput('')
    if (setDisplayResult) setDisplayResult(false)
    setIsSearchInputValid({
      valid: true,
      message: '',
    })
    setSelectedObject({})
  }

  return (
    <Form onSubmit={searchHandler}>
      <Row className="mt-3 ms-4">
        <Form.Group controlId="searchInput">
          <Col className="d-flex align-items-center">
            {label && <Form.Label>{label}</Form.Label>}

            <div>
              <Form.Control
                type="text"
                placeholder={placeholder ? placeholder : ''}
                style={{ width: '280px' }}
                className={`ms-2 ${
                  !isSearchInputValid.valid ? 'is-invalid' : ''
                }`}
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value.trimStart())
                  setIsSearchInputValid({ valid: true, message: '' })
                }}
              />

              <Form.Text
                className={`ms-2 ${
                  isSearchInputValid.valid ? '' : 'invalid-feedback'
                }`}
              >
                {isSearchInputValid.message}
              </Form.Text>
            </div>

            <Button className="ms-2 mb-4" type="submit">
              Rechercher
            </Button>
            <Button
              variant="secondary mb-4"
              className="ms-2"
              onClick={cancelHandler}
            >
              Annuler
            </Button>
          </Col>
        </Form.Group>
      </Row>
    </Form>
  )
}

export default SearchInput
