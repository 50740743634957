  import { gql } from '@apollo/client'
  import { DO_FRAME_FIELDS } from 'graphql/fragments/DOFrameFields'

  const UPDATE_DO_FRAME_WITH_VTO_IMG = gql`
  ${DO_FRAME_FIELDS}
  mutation UPDATE_DO_FRAME_WITH_VTO_IMG($gtins: [String]!) {
    updateDOFrameWithVtoImg(gtins: $gtins) {
      success
      output {
        message
        count
        frames{
          ...DOFrameFields
        }
      }
    }
  }
`
export default UPDATE_DO_FRAME_WITH_VTO_IMG