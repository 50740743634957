import React,{ ChangeEvent, useContext, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router'
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import { ToastContext } from 'store/toast-context'
import CM_CREATE_DO_ORDER_EVENT from 'graphql/mutations/CreateDOOrderEvent'
import { constants } from 'utils/constants'
import { CQ_GET_ORDER } from 'graphql/queries/getOrder'

interface Props {
  orderLineList: Array<orderLineType>
  setDisplayCancelOrder: React.Dispatch<React.SetStateAction<boolean>>
}

const CancelOrderForm: React.FC<Props> = ({
  orderLineList,
  setDisplayCancelOrder
}) =>
{
  const { toast } = useContext(ToastContext)
  const { id } = useParams<ParamType>()

  const [comment, setComment] = useState<string>('')
  const [activeOrderLineList, setActiveOrderLineList] = useState<Array<number>>([])

  const [createDOOrderEvent, { loading }] = useMutation(
    CM_CREATE_DO_ORDER_EVENT,
    {
      onCompleted: () => {
        const afterMutationActiveOrderLineList = orderLineList.filter( orderLine => orderLine.status === 'on').map( orderLine => +orderLine.id)
        const mutationError = activeOrderLineList.length && afterMutationActiveOrderLineList.length
        setComment('')
        setDisplayCancelOrder(false)
        toast({
          title: mutationError ? 'Erreur:' : 'Succès :',
          message: mutationError ? "Certaines lignes produit n'ont pas été désactivées" : 'Commande annulée' 
          + (activeOrderLineList.length ? ', désactivation des lignes: ' + activeOrderLineList.join(', ') : '')
        })
      },
    }
  )

  const createOrderEventHandler = async () => {
    try {
      setActiveOrderLineList(orderLineList.filter( orderLine => orderLine.status === 'on').map( orderLine => +orderLine.id))
      await createDOOrderEvent({
        refetchQueries: [CQ_GET_ORDER],
        awaitRefetchQueries: true,
        variables: {
          orderId: +id,
          eventTypeKey: constants.eventTypeKeys.ORDER_EVENT_TYPE_CANCELLED_ORDER,
          comment,
          sentEmail:false,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Container className="mt-3 mb-3">
      <Row>
        <Col>
          <div className="ms-4">
            <Form.Group controlId="cancellingTextArea">
              <Form.Control
                name="cancellingTextArea"
                placeholder={`Commentaire annulation`}
                as="textarea"
                className={`border border-1 rounded shadow`}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setComment(e.target.value)
                }}
                value={comment}
              />
            </Form.Group>
          </div>
        </Col>
        <Col className="d-flex align-items-end">
          <Button className="me-2" size="sm" onClick={() => {
            setComment('')
            setDisplayCancelOrder(false)
          }}>
            Retour
          </Button>
          <Button
            size="sm"
            disabled={comment === ''}
            onClick={createOrderEventHandler}
          >
            {loading ? (
              <Spinner
                variant="light"
                className="mx-4"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <>Confirmer</>
            )}
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default CancelOrderForm