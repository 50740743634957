import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import './Profile.css'
import { CogIcon, LogoutIcon } from '@heroicons/react/outline'
import DOModal from '../Modal/DOModal'

import LOGOUT_USER from '../../graphql/mutations/LogoutUser'
import { useMutation } from '@apollo/client'
import { useContext } from 'react'
import AuthContext from '../../store/auth-context'
import { getTokenFromLocalStorage } from '../../utils/functions'

function Profile() {
  // FIXME à mettre dans un composant particulier pour la déconnexion ?
  const [showConfirmLogoutModal, setShowConfirmLogoutModal] = useState(false)

  const authCtx = useContext(AuthContext)

  // https://www.apollographql.com/docs/react/data/mutations/#calling-the-mutate-function
  // NB : le hook useMutation n'est pas appelé à chaque rendering du composant (cf. lien ci-dessus)
  const [logout] = useMutation(LOGOUT_USER, {
    onCompleted: () => {
      authCtx.logout() // NB : la MAJ des menus dans la fonction logout() va entraîner le re-render de l'application, ce qui va déconnecter car la session de l'utilisateur sera terminée
    },
  })

  const confirmLogout = () => {
    logout({
      variables: {
        token: getTokenFromLocalStorage(),
      },
    })
  }

  return (
    <div className="Profile">
      <Dropdown>
        <Dropdown.Toggle className="dropcustom no-btn no-caret">
          <img
            src={'data:image/png;base64, ' + authCtx.user.image}
            alt={authCtx.user.fullName}
            width="32"
            height="32"
            className="rounded-circle"
          />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href="#/action-1" id="dropdown-username">
            {authCtx.user.fullName}
          </Dropdown.Item>
          <Dropdown.Item className="dropdown-centered" href="#/action-2">
            <CogIcon className="header-title-icon" />
            Paramètres
          </Dropdown.Item>
          <Dropdown.Item
            className="dropdown-centered"
            onClick={() => setShowConfirmLogoutModal(true)}
          >
            <LogoutIcon className="header-title-icon" />
            Déconnexion
          </Dropdown.Item>
          {/* Modale de confirmation de déconnexion */}
          <DOModal
            show={showConfirmLogoutModal}
            confirmModal={confirmLogout}
            closeModal={() => {
              setShowConfirmLogoutModal(false)
            }}
          >
            <>
              <div className="modalConfirmLogout">
                Voulez-vous vraiment vous déconnecter ?
              </div>
            </>
          </DOModal>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default Profile
