import React from 'react'
import classes from './HeaderTitle.module.css'
interface Props {
  title: string
  align?: string //center or right (left by default)
  margin?: string //bootstrap class m-2 mb-3 ...
  size?: string //custom size : "s" for 1rem
}
const HeaderTitle: React.FC<Props> = ({
  title,
  align,
  margin,
  size,
  children,
}) => {
  return (
    <h2
      className={`d-flex align-items-center header-title 
        ${classes.headerTitle}
        ${
          align === 'center'
            ? classes.center
            : align === 'right'
            ? classes.right
            : ''
        }
        ${margin ? margin : ''}
        ${size === 's' ? classes.small : ''}`}
    >
      {children}
      {title}
    </h2>
  )
}
export default HeaderTitle
