import React, { useState } from 'react'
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Table,
  Spinner,
  Alert
} from 'react-bootstrap'
import { useLazyQuery } from '@apollo/client'
import { GET_DO_STOCK_TRACKING_CUSTOM_LISTING } from 'graphql/queries/getDOStockTrackingCustomListing'
import { GET_DO_STOCK_TRACKING_LISTING_BY_ORDER } from 'graphql/queries/getDOStockTrackingListingByOrder'
import { GET_DO_PRODUCT_STOCK_BY_EAN_LISTING } from 'graphql/queries/getDOProductStockByEanListing'

interface DOStockOption {
  value: string
  label: string
}

const mockDOStockOptions: DOStockOption[] = [
  { value: 'wip', label: 'Work-in-progress' },
  { value: 'essdom', label: 'Stock à domicile' },
  { value: 'reserved', label: 'Réservé' },
  { value: 'outside', label: 'Extérieur' },
  { value: 'available', label: 'Disponible' },
]

const TrackingStocks: React.FC = () => {
  const [startDate, setStartDate] = useState<string | null>(null)
  const [endDate, setEndDate] = useState<string | null>(null)
  const [ean, setEan] = useState<string>('')
  const [stockOrigin, setStockOrigin] = useState<string>('')
  const [stockDestination, setStockDestination] = useState<string>('')
  const [orderKey, setOrderKey] = useState<string>('')
  const [isSearched, setIsSearched] = useState<boolean>(false)
  const [warning, setWarning] = useState<string>("")

  // query to get the stock tracking by EAN
  const [ 
    getDOProductStockByEanListing,
    { loading: eanLoading, data: eanData, error: eanError },
  ] = useLazyQuery(GET_DO_PRODUCT_STOCK_BY_EAN_LISTING, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (eanData && eanData.getDOProductStock?.totalCount > 0) {
        setIsSearched(true)
      } else if(eanError) setWarning(eanError.message)
      else setWarning("Aucune donnée disponible")
    },
  })
  // query to get the stock tracking by custom filters (date, stock origin, stock destination)
  const [ 
    getDOStockTrackingCustomListing, 
    { loading, data: trackingData, error }
  ] =
    useLazyQuery(GET_DO_STOCK_TRACKING_CUSTOM_LISTING, {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        if (
          trackingData &&
          trackingData.getDOStockTrackingCustomListing?.totalCount > 0
        ) {
          setIsSearched(true)
        } else if(error) setWarning(error.message)
        else setWarning("Aucune donnée disponible")
      },
    })
  // query to get the stock tracking by order key
  const [ 
    getDOStockTrackingListingByOrder,
    { loading: orderLoading, data: orderData, error: orderError }
  ] = useLazyQuery(GET_DO_STOCK_TRACKING_LISTING_BY_ORDER, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (orderData && orderData.getDOStockTrackingListingByOrder?.totalCount > 0) {
        setIsSearched(true)
      } else if(orderError) setWarning(orderError.message)
      else setWarning("Aucune donnée disponible")
    },
  })

  // Mapping for the stock status
  const statusMappings = {
    available: 'Disponible',
    reserved: 'Réservé',
    wip: 'Work-in-progress',
    essdom: 'Stock à domicile',
    outside: 'Extérieur',
  } as any

  const handleSearch = () => {
    setWarning("")
    let trackingFilters: { [key: string]: any } = {}
    let productGtinFilters: string[] = []

    // Filters related to date creation
    if (startDate) {
      const finalStartDate = new Date(startDate).setHours(0, 0, 0, 0) // Set to 00:00:00 for the start date
      // if endDate not set it to today's date
      if (!endDate) {
        setEndDate(new Date().toISOString().split('T')[0])
      }
      const finalEndDate = new Date(endDate || new Date()).setHours(23, 59, 59, 999) // Set to 23:59:59 for the end date
      // add the date range to the trackingFilters
      trackingFilters.o_creationDate = {
        ...trackingFilters.o_creationDate,
        ...(finalStartDate && {
          $gte: (new Date(finalStartDate).getTime() / 1000).toString(),
        }),
        ...(finalEndDate && {
          $lte: (new Date(finalEndDate).getTime() / 1000).toString(),
        }),
      }
    }
    // Filter by EAN
    if (ean) {
      // trackingFilters.ean = ean
      productGtinFilters.push(ean)
    }

    // Filter by stock origin, if it's not 'Tout'
    if (stockOrigin) {
      if (stockOrigin === 'Tout') {
        trackingFilters.origin = { $not: null }
      } else if (stockOrigin) {
        trackingFilters.origin = stockOrigin
      }
    }

    // Filter by stock destination, if it's not 'Tout'
    if (stockDestination) {
      if (stockDestination === 'Tout') {
        trackingFilters.destination = { $not: null }
      } else if (stockDestination) {
        trackingFilters.destination = stockDestination
      }
    }
    // Stringify the filters object.

    if (stockDestination || stockOrigin || startDate || endDate) {
      getDOStockTrackingCustomListing({
        variables: { filter: JSON.stringify(trackingFilters) },
      })
    }
    if (ean) {
      getDOProductStockByEanListing({
        variables: { eanOrIdmcList: productGtinFilters, stockLocation: '', name: '' },
      })
    }
    // Filter by order key
    if (orderKey) {
      getDOStockTrackingListingByOrder({
        variables: { orderKey: orderKey },
      })
    }
  }

  const isOtherFilterSet = (excludeFilter: keyof trackingFiltersType): boolean => {
    const filters = {
      ean: ean !== '',
      orderKey: orderKey !== '',
      stockOrigin: stockOrigin !== '',
      stockDestination: stockDestination !== '',
    }
    return Object.keys(filters).some((filter) => {
      const key = filter as keyof typeof filters
      return key !== excludeFilter && filters[key]
    })
  }

  const getStockBeforeAfter = (type: any, node: any) => {
    if (!type) {
        return { content: undefined, quantity: undefined }
    }
    const content = type === 'outside'
      ? statusMappings[type]
      : (
          <>
            {statusMappings[type]} {node[`${type}Quantity`]?.[0]?.before} → {node[`${type}Quantity`]?.[0]?.after}
          </>
      )
    const quantity = type === 'outside' 
      ? undefined 
      : node[`${type}Quantity`]?.[0]?.quantity
    return { content, quantity }
  }
  // For EAN data
  const createTrackingByEanRow = () => {
    return eanData?.getDOProductStock?.edges.flatMap(
      (edge: any) => {
        const { node } = edge
        const product = node.frame??node.accessory
        let stockTracking = product.stock[0].tracking
        if (stockTracking) {
          // Clone the array to avoid mutating the original read-only array and order by desc creation date
          stockTracking = [...stockTracking].sort((a: any, b: any) => b.creationDate - a.creationDate)
        }
        return stockTracking?.map((stockItem: any, index:number) => {
          const {origin, destination } = stockItem
          const {
            content: originContent,
            quantity: originQuantity
          } = getStockBeforeAfter(origin, stockItem)
          const {
            content: destinationContent,
            quantity: destinationQuantity
          } = getStockBeforeAfter(destination, stockItem)

          return (
            <tr key={stockItem?.id + index}>
              <td>{index + 1}</td>
              <td>{product?.gtin}</td>
              <td>{product.name + ' '+ product.color.label}</td>
              <td>{stockItem?.orderLineItem?.orderLine?.[0]?.order?.[0]?.key}</td>
              <td>{destinationQuantity ?? originQuantity}</td>
              <td>{stockItem?.comment}</td>
              <td>{originContent}</td>
              <td>{destinationContent}</td>
              <td>{stockItem?.user?.key}</td>
              <td>{new Date(stockItem?.creationDate * 1000).toLocaleString()}</td>
            </tr>
          )
        })})
  }
  // For other data (date, stock origin, stock destination, orderKey)
  const createTrackingByOtherFilterRow = (stockTrackingList:any) => {
    // Clone the array to avoid mutating the original read-only array and order by desc creation date
    const sortedStockTrackingList = [...stockTrackingList].sort(
      (a: any, b: any) => b.node?.stockTracking.creationDate - a.node?.stockTracking.creationDate
    )

    return sortedStockTrackingList.flatMap(
      (edge: any, index: number) => {  
        const { node } = edge 
        const stock = node?.stockTracking
        if (!stock) return <tr key={index}><td colSpan={10}>Aucune donnée disponible</td></tr>
        const { origin, destination } = stock
        const {
          content: originContent,
          quantity: originQuantity
        } = getStockBeforeAfter(origin, stock)
        const {
          content: destinationContent,
          quantity: destinationQuantity
        } = getStockBeforeAfter(destination, stock)
        const product = stock.orderLineItem?.item?.[0]?.frame ?? stock?.orderLineItem?.item?.[0]?.accessory
        const productName = node?.name ?? product?.[0]?.name + " " + product?.[0]?.color.label
        const productGtin = node?.gtin ?? product?.[0]?.gtin
        const orderKey = stock.orderLineItem?.orderLine?.[0]?.order?.[0]?.key
        return (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{productGtin}</td>
            <td>{productName}</td>
            <td>{orderKey}</td>
            <td>{destinationQuantity ?? originQuantity}</td>
            <td>{stock?.comment}</td>
            <td>{originContent}</td>
            <td>{destinationContent}</td>
            <td>{stock?.user?.key}</td>
            <td>{new Date(stock.creationDate * 1000).toLocaleString()}</td>
          </tr>
        )
      }
    )
  }

const isSearchDisabled = 
  !!((!ean && !stockDestination && !stockOrigin && !orderKey && !startDate) || (!startDate && (stockDestination || stockOrigin)))

const isLoading = eanLoading || loading || orderLoading

  return (
    <Card className="p-3">
      <Card.Body>
        <Form onSubmit={handleSearch} className={(endDate||stockOrigin||stockDestination)&&!startDate ? "was-validated" : ""}>
          <Row className="mb-3">
            <Col md={3}>
              <Form.Group controlId='startDate'>
                <Form.Label>Date de début</Form.Label>
                <Form.Control
                  required
                  type="date"
                  value={startDate || ''}
                  disabled={!!orderKey || !!ean}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                {!startDate && (
                  <div className='invalid-feedback'>
                    Veuillez renseigner une date de début.
                  </div>
                )}
            </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Label>Date de fin</Form.Label>
              <Form.Control
                type="date"
                value={endDate || ''}
                disabled={
                  !!orderKey || !!ean 
                }
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Col>
            <Col md={3}>
              <Form.Label>N° de commande :</Form.Label>
              <Form.Control
                type="text"
                value={orderKey}
                disabled={
                  !!startDate || !!endDate || isOtherFilterSet('orderKey')
                }
                onChange={(e) => setOrderKey(e.target.value)}
              />
            </Col>
            <Col md={3}>
              <Form.Label>EAN, IMDC ou nouvel ID :</Form.Label>
              <Form.Control
                type="text"
                value={ean}
                disabled={!!startDate || !!endDate || isOtherFilterSet('ean')}
                onChange={(e) => setEan(e.target.value)}
              />
              {/* ... */}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4}>
              <Form.Label>Stock d'origine</Form.Label>
              <Form.Select
                value={stockOrigin}
                disabled={
                  isOtherFilterSet('stockOrigin')
                }
                onChange={(e) => setStockOrigin(e.target.value)}
              >
                <option value=""></option>
                <option value="Tout">Tout</option>
                {mockDOStockOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
                {/* ... */}
              </Form.Select>
            </Col>
            <Col md={4}>
              <Form.Label>Stock de destination</Form.Label>
              <Form.Select
                value={stockDestination}
                disabled={
                  isOtherFilterSet('stockDestination')
                }
                onChange={(e) => setStockDestination(e.target.value)}
              >
                <option value=""></option>
                <option value="Tout">Tout</option>
                {mockDOStockOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
                {/* ... */}
              </Form.Select>
            </Col>
            <Col md={4} className="d-flex align-items-end">
              <Button
                onClick={handleSearch}
                disabled = {isSearchDisabled || isLoading}
                className="me-2"
              >
                { isLoading? (
                  <Spinner
                    variant="light"
                    className="me-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  ''
                )}
                Rechercher
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  setStartDate(null)
                  setEndDate(null)
                  setEan('')
                  setStockOrigin('')
                  setStockDestination('')
                  setOrderKey('')
                  setIsSearched(false)
                }}
                disabled={
                  !startDate &&
                  !endDate &&
                  !ean &&
                  !stockDestination &&
                  !stockOrigin &&
                  !orderKey
                }
              >
                Effacer tout
              </Button>
            </Col>
          </Row>
        </Form>
        {(trackingData || eanData || orderData) && (
          <Table striped bordered className="mt-3">
            <thead>
              <tr>
                <th>Numéro de la ligne</th>
                <th>EAN</th>
                <th>Nom du produit</th>
                <th>N° commande</th>
                <th>Quantité</th>
                <th>Commentaires</th>
                <th>Stock de départ</th>
                <th>Stock d’arrivée</th>
                <th>User</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
            {warning &&
              <tr>
                <td colSpan={10}>
                  <Alert variant="warning">{warning}</Alert>
                </td>
              </tr>} 
              {isSearched && (
                ean && eanData
                  ? createTrackingByEanRow()
                  : (stockDestination || stockOrigin || startDate || endDate) && trackingData
                  ? createTrackingByOtherFilterRow(trackingData.getDOStockTrackingCustomListing.edges)
                  : orderKey && orderData && createTrackingByOtherFilterRow(orderData.getDOStockTrackingListingByOrder.edges)
              )}
            </tbody>
          </Table>
        )}
      </Card.Body>
    </Card>
  )
}

export default TrackingStocks
