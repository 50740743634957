import React, { useContext, useState } from 'react'
import { useMutation, gql, useQuery } from '@apollo/client'
import { ToastContext } from 'store/toast-context'
import { constants } from 'utils/constants'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import classes from 'components/HeaderTitle.module.css'
import { DO_VOUCHER_FIELDS } from 'graphql/fragments/DOVoucherFields'
import SEARCH_VOUCHERS from 'graphql/queries/searchVouchers'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import { useTranslation } from 'react-i18next'
import { getFormattedDate } from 'utils/functions'
import { GET_DO_VOUCHER_TEMPLATE_LISTING } from 'graphql/queries/getDOVoucherTemplateListing'

interface Props {
  customerId: number
  closeModal?: () => void // sent from parent
}
const ModalAddVoucher: React.FC<Props> = ({ customerId, closeModal }) => {
  const { toast } = useContext(ToastContext)
  const { t } = useTranslation()

  const curDateWithoutTime = new Date().setHours(0, 0, 0, 0) / 1000 // We need to remove hours/minutes/seconds, else the component will call the query in infinite loop when refreshing results

  //need isLoading during the mutation
  const [isLoading, setIsLoading] = useState(false)
  const [voucherAmount, setVoucherAmount] = useState('')
  const [selectedVoucherTemplateId, setSelectedVoucherTemplateId] = useState('')

  const { loading, error, data } = useQuery(GET_DO_VOUCHER_TEMPLATE_LISTING, {
    // fetchPolicy: "network-only", // NB: Theorically, we should request the server all the time to update the newVoucherCode, but we don't need to do it since the newVoucherCode is updated in cache when retrieving a voucher (that also retrieves voucherTemplate and update cache)
    variables: {
      filter: `{ "$and": [
        {"voucherType": "${constants.voucherTypes.VOUCHER_TYPE_PERSONNEL}"},
        { "$or": [{"dateBegin": null}, {"dateBegin": {"$lte":${curDateWithoutTime}}}] },
        { "$or": [{"dateEnd": null}, {"dateEnd": {"$gte":${curDateWithoutTime}}}] },
        {"active": true}
      ] }`,
    },
  })

  const CREATE_PERSONAL_VOUCHER_FROM_TEMPLATE = gql`
    ${DO_VOUCHER_FIELDS}
    mutation CREATE_PERSONAL_VOUCHER_FROM_TEMPLATE(
      $voucherCode: String!
      $amount: Float!
      $customerId: Int!
      $voucherTemplateId: Int!
      $withoutNewVoucherCode: Boolean = false
    ) {
      createPersonalVoucherFromTemplate(
        voucherCode: $voucherCode
        amount: $amount
        customerId: $customerId
        voucherTemplateId: $voucherTemplateId
      ) {
        success
        output {
          voucher {
            ...DOVoucherFields
          }
        }
      }
    }
  `
  const [createVoucherFromTemplate] = useMutation(
    CREATE_PERSONAL_VOUCHER_FROM_TEMPLATE
  )

  const handleSubmitCreateVoucher = async (event: any) => {
    event.preventDefault()

    if (selectedVoucherTemplate) {
      setIsLoading(true)

      try {
        await createVoucherFromTemplate({
          variables: {
            voucherCode: selectedVoucherTemplate.node.newVoucherCode,
            amount: +voucherAmount,
            customerId,
            voucherTemplateId: +selectedVoucherTemplateId,
          },
          refetchQueries: [SEARCH_VOUCHERS], // required to update the table in edit customer view (it is a ROOT_QUERY with filter, we can not update it easily)
        })

        toast({
          title: "Bon d'achat créé :",
          message: selectedVoucherTemplate.node.newVoucherCode,
        })

        closeModal!()
      } catch (e) {
        console.log(e)
        // NB : gestion erreur dans App.tsx mais en fait il y a un bug.
      }
      setIsLoading(false)
    }
  }

  if (loading) return <SkeletonBase variant="px-4" />
  if (error) return <p>`Error! ${error.message}`</p>

  // Récupération de l'élément sélectionné
  const selectedVoucherTemplate = data.getDOVoucherTemplateListing.edges.find(
    (tpl: any) => tpl.node.id === selectedVoucherTemplateId
  )

  return (
    <div className="modal-container">
      <h2 className={`d-flex header-title ${classes.headerTitle}`}>
        Ajouter un bon d'achat
      </h2>
      <Form onSubmit={handleSubmitCreateVoucher}>
        <Container className="mt-4" fluid>
          <Row>
            <Col xs={4}>
              <b>
                Template du bon <sup>*</sup>
              </b>
            </Col>
            <Col>
              <Form.Select
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setSelectedVoucherTemplateId(e.target.value)
                  setVoucherAmount('')
                }}
                required
              >
                <option key="no-template-selected" value=""></option>
                {data.getDOVoucherTemplateListing.edges.map((tpl: any) => {
                  return (
                    <option key={tpl.node.id} value={tpl.node.id}>
                      {tpl.node.clientLabel}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
          {selectedVoucherTemplate && (
            <>
              <Row>
                <Col xs={4}>
                  <b>Code promo</b>
                </Col>
                <Col>{selectedVoucherTemplate?.node.newVoucherCode}</Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <b>
                    Montant préconisé
                    {selectedVoucherTemplate?.node.voucherDiscountType ===
                    'euros' ? (
                      <span> ({constants.currency.EURO})</span>
                    ) : (
                      <span></span>
                    )}
                  </b>
                </Col>
                <Col>
                  {selectedVoucherTemplate?.node.initialAmount?.value.toFixed(
                    2
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <b>
                    Montant
                    {selectedVoucherTemplate?.node.voucherDiscountType ===
                    'euros' ? (
                      <span> ({constants.currency.EURO})</span>
                    ) : (
                      <span></span>
                    )}{' '}
                    <sup>*</sup>
                  </b>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Montant"
                    name="firstname"
                    value={voucherAmount}
                    onChange={(e) => {
                      if (/^(\d*|\d+\.\d{0,2})$/.test(e.target.value))
                        setVoucherAmount(e.target.value)
                      else e.target.value = voucherAmount
                    }}
                    required
                    autoComplete="off"
                  />
                </Col>
              </Row>
            </>
          )}
        </Container>
        <div className="mt-3 text-end">
          <Button
            variant="primary"
            type="submit"
            disabled={voucherAmount === '' || isLoading}
          >
            {isLoading ? (
              <span className="text-white">Enregistrement en cours...</span>
            ) : (
              <span className="text-white">Enregistrer</span>
            )}
          </Button>
          <Button
            variant="outline-primary bg-white text-primary"
            className="m-2"
            onClick={() => closeModal!()}
          >
            Annuler
          </Button>
        </div>
      </Form>
      {/* Displaying voucher infos */}
      {selectedVoucherTemplate && (
        <>
          <hr />
          <div>
            Sites :{' '}
            <span>{selectedVoucherTemplate.node.domainSites?.join(', ')}</span>
          </div>
          <div>
            Montant minimum :{' '}
            <span>
              {selectedVoucherTemplate.node.minAmount.value.toFixed(2)}{' '}
              {constants.currency.EURO}
            </span>
          </div>
          <div>
            {selectedVoucherTemplate.node.productType && (
              <div>
                Produits autorisés :{' '}
                <span>{t(selectedVoucherTemplate.node.productType)}</span>
              </div>
            )}
          </div>
          <div>
            {selectedVoucherTemplate.node.brands &&
              selectedVoucherTemplate.node.brands.length && (
                <div>
                  Eligible aux marques :{' '}
                  {selectedVoucherTemplate.node.brands.map(
                    (brand: any, index: number) => {
                      return (
                        <span key={`brand-${index}`}>
                          {index > 0 && ', '}
                          {brand.name}
                        </span>
                      )
                    }
                  )}
                </div>
              )}
          </div>
          <div>
            Fin de validité :{' '}
            <span>
              {selectedVoucherTemplate.node.duration} jours (
              {getFormattedDate(
                new Date(
                  Date.now() +
                    selectedVoucherTemplate.node.duration * 3600 * 1000 * 24
                )
              )}
              )
            </span>
          </div>
        </>
      )}
    </div>
  )
}

export default ModalAddVoucher
