import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { EyeIcon } from '@heroicons/react/outline'
import { useParams } from 'react-router'
import { useMutation } from '@apollo/client'
import { constants } from 'utils/constants'
import { ToastContext } from 'store/toast-context'
import CM_CREATE_DO_ORDER_EVENT from 'graphql/mutations/CreateDOOrderEvent'
import MenuContext from 'store/menu-context'
import EventEmailPrevisualisationModal from '../Modal/EvenEmailPrevisualisationModal'
import DOModal from 'components/Modal/DOModal'
import GlobalContext from 'store/global-context'

interface Props {
  eventTypes: eventTypeType[]
  eventsInRightSideBar: string[]
}

const EventShortcutOther: React.FC<Props> = ({
  eventTypes,
  eventsInRightSideBar,
}) => {
  const { id } = useParams<ParamType>()
  const global = useContext(GlobalContext) 
  const siteDomainSuffix = "/"+ global.siteDomainSuffix
  const [displaySelect, setDisplaySelect] = useState(true)
  const [selectedEventType, setSelectedEventType] =
    useState<eventTypeType | null>(null)
  const [comment, setComment] = useState('')
  const [mailInfo, setMailInfo] = useState('')
  const [sentEmail, setSentEmail] = useState<boolean>(true)
  const { toast } = useContext(ToastContext)
  const menuContext = useContext(MenuContext)
  const [showPrevisualisation, setShowPrevisualisation] =
    useState<boolean>(false)

  let defaultIndex = 0
  const options = eventTypes.map((eventType: eventTypeType, index: number) => {
    if (
      eventType.key ===
      constants.eventTypeKeys
        .ORDER_EVENT_TYPE_CUSTOMER_SERVICE_AFTERSALES_INFORMATION
    ) {
      defaultIndex = index
    }
    return (
      <option value={eventType.key} key={eventType.key}>
        {eventType.label}
      </option>
    )
  })

  useEffect(() => {
    setSelectedEventType(eventTypes[defaultIndex])
  }, [setSelectedEventType, eventTypes, defaultIndex])

  const [createDOOrderEvent, { loading }] = useMutation(
    CM_CREATE_DO_ORDER_EVENT,
    {
      onCompleted: () => {
        setComment('')
        setMailInfo('')
        setSentEmail(true)
        toast({
          title: 'Succès :',
          message: 'Evènement ' + selectedEventType!.label + ' créé',
        })

        // If the event added is in right side bar list, displays right side bar
        if (eventsInRightSideBar.includes(selectedEventType!.key)) {
          menuContext.setSideBarNewEventCount(
            ++menuContext.sideBarNewEventCount
          )
        }
      },
    }
  )

  const createOrderEventHandler = async () => {
    try {
      if (selectedEventType)
        await createDOOrderEvent({
          variables: {
            mailInfo,
            orderId: +id,
            eventTypeKey: selectedEventType.key,
            comment,
            sentEmail:selectedEventType.emailToSend?sentEmail:false,
          },
        })
    } catch (e) {
      console.log(e)
    }
  }

  const cancel = () => {
    setDisplaySelect(false)
    setComment('')
    setMailInfo('')
    setSentEmail(true)
    setSelectedEventType(eventTypes[defaultIndex])
    setTimeout(() => setDisplaySelect(true), 1)
  }

  return (
    <>
      {displaySelect && (
        <div>
          {!loading && (
            <>
              <div className="p-2">
                <span>Sélectionnez un statut</span>
              </div>
              <div className="d-flex">
                <Form.Group className="me-4">
                  <Form.Select
                    id="otherEvent"
                    aria-label="Other event shortcut select"
                    defaultValue={
                      constants.eventTypeKeys
                        .ORDER_EVENT_TYPE_CUSTOMER_SERVICE_AFTERSALES_INFORMATION
                    }
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setComment('')
                      setMailInfo('')
                      setSentEmail(true)
                      setSelectedEventType(
                        e.target.selectedIndex === null
                          ? null
                          : eventTypes[e.target.selectedIndex]
                      )
                    }}
                  >
                    {options}
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="p-3"></div>

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">
                  Commentaire
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    as="textarea"
                    className="border border-2 rounded shadow"
                    onChange={(e) => setComment(e.target.value)}
                    value={comment}
                  />
                </Col>
              </Form.Group>

              {selectedEventType?.emailToSend !== null &&
                !!selectedEventType?.emailToSend?.length && (
                  <>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="2">
                        Mail
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="textarea"
                          className="border border-2 rounded shadow"
                          onChange={(e) => setMailInfo(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                    <Row>
                      <Col sm="4">
                        <Form.Check
                          type="checkbox"
                          label="Envoyer le mail"
                          className="float-end mt-2"
                          value="yes"
                          defaultChecked={true}
                          onChange={(e) =>
                            setSentEmail(
                              (e.target as HTMLInputElement)?.checked
                                ? true
                                : false
                            )
                          }
                        />
                      </Col>
                      <Col>
                        <div className="ms-2">
                          <EyeIcon className="header-title-icon custom-blue-link ms-2" />
                          <Button
                            variant="link"
                            onClick={() => setShowPrevisualisation(true)}
                          >
                            Prévisualiser
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <DOModal
                      show={showPrevisualisation}
                      customSize="modal-647"
                      closeModal={() => setShowPrevisualisation(false)}
                    >
                      <EventEmailPrevisualisationModal
                        template={siteDomainSuffix + selectedEventType?.emailToSend}
                        mailInfo={mailInfo}
                      />
                    </DOModal>
                  </>
                )}
            </>
          )}
          <div style={{ float: 'right' }}>
            <Button
              className="m-2"
              onClick={cancel}
              disabled={selectedEventType === eventTypes[defaultIndex]}
            >
              Annuler
            </Button>
            <Button
              className="m-2"
              variant="primary"
              onClick={() => {
                if (window.confirm('Êtes-vous sûr(e) ?')) {
                  createOrderEventHandler()
                }
              }}
              disabled={loading}
            >
              {loading && (
                <Spinner
                  variant="light"
                  className="me-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Enregistrer
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default EventShortcutOther
