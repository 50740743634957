import { useState, useCallback } from 'react'

export interface ToastProps {
  id: number
  title: string
  message: string
  onClose: () => void
  manualClose: boolean
}

export function useToasts() {
  const [toasts, setToasts] = useState<ToastProps[] | []>([])

  const toast = useCallback((payload) => {
    //payload est de type {title: "", message:""}
    const id = Date.now()

    function removeToast() {
      setToasts((t) => {
        return t.filter((n) => n.id !== id)
      })
    }

    setToasts((toasts) => [{ id, onClose: removeToast, ...payload }, ...toasts])
    if (!payload.manualClose) setTimeout(removeToast, 4000)
  }, [])

  return { toast, toasts }
}
