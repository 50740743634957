import { UserCircleIcon } from '@heroicons/react/outline'
import HeaderTitle from 'components/HeaderTitle'
import DOModal from 'components/Modal/DOModal'
import React, { useEffect, useState } from 'react'
import { getAge } from 'utils/functions'
import './BeneficiaryCard.css'
import EditBeneficiaryAndLensesModal from './EditBeneficiaryAndLensesModal'

interface Props {
  beneficiary: beneficiaryType
  groupOfOrderLines: orderLineType[]
  isBeneficiaryOff: boolean
  calculators: orderCalculatorsType
}
const BeneficiaryCard: React.FC<Props> = ({
  beneficiary,
  groupOfOrderLines,
  isBeneficiaryOff,
  calculators
}) => {
  const [showEditionBeneficiary, setShowEditionBeneficiary] = useState(false)

  useEffect(() => {
    // prevents warning in console after removing beneficiary because we want to close unmounted componant
    return () => {
      setShowEditionBeneficiary(false)
    }
  }, [])

  return (
    <>
      <div
        className={`custom-mini-card small-content p-1 cursor ${isBeneficiaryOff?'bg-off':''}`}
        onClick={() => {
          setShowEditionBeneficiary(true)
        }}
      >
        <HeaderTitle
          title={`Bénéficiaire`}
          children={<UserCircleIcon className="header-title-icon" />}
        />

        <span>
          {beneficiary.name[0].firstName + ' ' + beneficiary.name[0].lastName}
        </span>
        <br />
        {beneficiary?.birthDate && (<>
          <span>
          {new Intl.DateTimeFormat('fr', {
            dateStyle: 'short',
          }).format(
            new Date(
              beneficiary.birthDate.replace(/ - /g, '/').replace(/ /g, 'T')
            )
          )}
        </span>
        <span> ({getAge(beneficiary.birthDate)} ans)</span>
        </>)}
      </div>
      <DOModal
        show={showEditionBeneficiary}
        closeModal={() => setShowEditionBeneficiary(false)}
        customSize="modal-647"
      >
        <EditBeneficiaryAndLensesModal
          beneficiary={beneficiary}
          groupOfOrderLines={groupOfOrderLines}
          calculators={calculators}
        />
      </DOModal>
    </>
  )
}

export default BeneficiaryCard
