import { gql } from '@apollo/client'
import { DO_ACCESSORY_FIELDS } from 'graphql/fragments/DOAccessoryFields'
import { DO_FRAME_FIELDS } from 'graphql/fragments/DOFrameFields'

const UPDATE_DO_PRODUCT_STOCK_THRESHOLD = gql`
  ${DO_FRAME_FIELDS}
  ${DO_ACCESSORY_FIELDS}
  mutation updateDOProductStockThreshold(
    $gtin: String!
    $salesThreshold: String
    $hometrialThreshold: String
  ){
    updateDOProductStockThreshold(
      gtin: $gtin
      salesThreshold: $salesThreshold
      hometrialThreshold: $hometrialThreshold
    ){
      success
      output {
        frame {
          ...DOFrameFields
        }
        accessory{
          ...DOAccessoryFields
        }
      }
    }
  }
`

export default UPDATE_DO_PRODUCT_STOCK_THRESHOLD