import React, { Component } from "react";

export class TachesLogistiquesKehl extends Component {
    render() {
        return (
            <div>
                <h1>TachesLogistiquesKehl</h1>
            </div>
        );
    }
}

export default TachesLogistiquesKehl;
