import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-regular-svg-icons'

interface Props {
  children: any
}

const CopyableCell: React.FC<Props> = ({ children }) => {
  const [fadeStyle, setFadeStyle] = useState({})

  const copyToClipboard = (text: any) => {
    navigator.clipboard.writeText(text.toString()).then(() => {
      // Start fade effect
      setFadeStyle({ opacity: 1, transition: 'opacity 1s' })
      // Remove fade effect after 1 second
      setTimeout(
        () => setFadeStyle({ opacity: 0, transition: 'opacity 1s' }),
        100
      )
      // Reset style after the transition ends
      setTimeout(() => setFadeStyle({}), 500)
    })
  }

  return (
    <td onClick={() => copyToClipboard(children)}>
      {children}
      <FontAwesomeIcon
        icon={faCopy}
        style={{ cursor: 'pointer', marginLeft: '10px', ...fadeStyle }}
      />
    </td>
  )
}

export default CopyableCell
