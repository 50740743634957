import React, { Component } from "react";

export class ExportExpeditionKehl extends Component {
    render() {
        return (
            <div>
                <h1>ExportExpeditionKehl</h1>
            </div>
        );
    }
}

export default ExportExpeditionKehl;
