export const commaToPoint = (a: any, keys: any[]) => {
  for (let i = 0; i < keys.length; i++) {
    if (keys[i] in a) {
      a[keys[i]] = Number(a[keys[i]].replace(/,/g, '.'))
    }
  }
  return a
}

export const commaToArray = (a: any, keys: any[]) => {
  for (let i = 0; i < keys.length; i++) {
    if (keys[i] in a) {
      a[keys[i]] = a[keys[i]].split(',').map((num: any) => Number(num))
    }
  }
  return a
}

export const addImageObject = (a: any) => {
  if ('imageUrls' in a) {
    a['imageUrls'] = a['imageUrls']
      .split('|')
      .map((element: any, index: number) => {
        return { url: element, order: index + 1 }
      })
  }

  return a
}
