import { gql } from '@apollo/client'

export const GET_FORMER_ORDER_PRESCRIPTIONS = gql`
  query GET_FORMER_ORDER_PRESCRIPTIONS ($beneficiaryId:Int!) {
    getFormerOrderPrescriptionListing(beneficiaryId:$beneficiaryId) {
      totalCount
      edges {
        node {
          orderId
          prescriptionId
          label
          validity
        }
      }
    }
  }
`