import React from 'react'
import Skeleton from 'react-loading-skeleton'
interface Props {
  rowCountLeft?: number
  rowCountRight?: number
  variantLeft?: string
  variantRight?: string
}
const SkeletonMultiColumn: React.FC<Props> = ({
  rowCountLeft,
  variantLeft,
  rowCountRight,
  variantRight,
}) => {
  return (
    <div>
      <div className="d-flex">
        <div className={`w-50 ${variantLeft}`}>
          <p>
            <Skeleton count={rowCountLeft} />
          </p>
        </div>
        <div className={`ms-3 w-50 ${variantRight}`}>
          <p>
            <Skeleton count={rowCountRight} />
          </p>
        </div>
      </div>
    </div>
  )
}

export default SkeletonMultiColumn
