import gql from 'graphql-tag'

const GET_DO_ORDER_EVENT_TYPE_GRP_LISTING = gql`
  query GET_DO_ORDER_EVENT_TYPE_GRP_LISTING {
    getDOOrderEventTypeGrpListing {
      edges {
        node {
          key
          label
          eventTypes {
            ... on object_DOOrderEventType {
              id
              label
              key
              workflowTransition
              orderEventsToClose {
                ... on object_DOOrderEventType {
                  id
                  key
                  label
                  eventFamily
                }
              }
              eventFamily
              emailToSend
            }
          }
        }
      }
    }
  }
`

export default GET_DO_ORDER_EVENT_TYPE_GRP_LISTING
