import { /*Button,*/ Container, Row, Col } from 'react-bootstrap'
import { CalculatorIcon } from '@heroicons/react/outline'
import HeaderTitle from './HeaderTitle'
import { useQuery } from '@apollo/client'
import SkeletonBase from './Skeleton/SkeletonBase'
import Card from './UI/Card'
import GET_ORDER_ACCOUNTING_EVENTS_DATA from 'graphql/queries/getDOOrderAccountingEventsData'
import { useParams } from 'react-router-dom'
import ResultsTable from './UI/ResultsTable'
import { useTranslation } from 'react-i18next'
import { stringsToJSXs } from 'utils/functions'
import ColorEnclosedLabel from './UI/ColorEnclosedLabel'
import React from 'react'

interface Props {}
const TableAccounting: React.FC<Props> = () => {
  const { id } = useParams<ParamType>()
  const { t } = useTranslation()

  // const [checkedIdList, setCheckedIdList] = useState<string[]>([])

  const { loading, error, data } = useQuery(GET_ORDER_ACCOUNTING_EVENTS_DATA, {
    variables: {
      id: +id,
    },
  })

  if (loading)
    return (
      <Card>
        <SkeletonBase count={5} />
      </Card>
    )
  if (error) return <p>`Error! ${error.message}`</p>

  const events = data.CQgetDOOrderAccountingEventsData.edges.map(
    (event: any) => event.node
  )

  const jsonData = JSON.parse(JSON.stringify(events))
  jsonData.forEach((event: any) => {
    event.accountingStatus = event.label
    event.amount = event.mailInfo
    event.sentEmail = event.sentEmail ? 'oui' : 'NON'
    event.creation =
      new Intl.DateTimeFormat('fr', {
        dateStyle: 'short',
        timeStyle: 'short',
      }).format(new Date(event.creationDate * 1000)) +
      (event.userLoginCreate ? ' par ' + event.userLoginCreate : '')

    event.closure = event.closureDate
      ? event.closureDate +
        (event.userLoginClosure ? ' par ' + event.userLoginClosure : '')
      : ''

    event.checkboxVisible = !event.closureDate
  })

  return (
    <div
      data-cy="accountingHistory"
      className="table-accounting custom-card py-3 mx-auto"
    >
      <div className="px-3">
        <HeaderTitle
          title=" Statuts comptables"
          children={<CalculatorIcon className="header-title-icon" />}
        />
      </div>
      {events.length === 0 && <div className="px-3">Pas d'enregistrement</div>}
      {events.length > 0 && (
        <>
          <ResultsTable
            queryType=""
            items={jsonData.slice(
              jsonData.length > 10 ? jsonData.length - 10 : 0,
              jsonData.length
            )}
            columns={stringsToJSXs(
              [
                'creation',
                'closure',
                'accountingStatus',
                'amount',
                'comment',
                'sentEmail',
              ],
              t
            )}
            // TODO : décommenter pour pouvoir activer la sélection d'une ligne (v2)
            // checkedIdListState={{ checkedIdList, setCheckedIdList }}
          />

          {/* TODO : décommenter + mettre en place la fonction qui va clôturer les events sélectionnés (v2) */}
          {/* <div className="mt-2 d-flex justify-content-end px-3">
            <Button disabled={checkedIdList.length === 0}>
              Clore les statuts sélectionnés
            </Button>
          </div> */}
        </>
      )}

      <Container id="tableAccounting">
        <Row>
          <Col>
            <div className="float-end">
              {/* To allow a float right without the need of including it into the child component */}
              <ColorEnclosedLabel
                label={`Total paiements : ${data.CQgetDOOrderAccountingEventsData.totalPaymentAmount.toFixed(
                  2
                )} €`}
                color={'firebrick'}
              />
            </div>
          </Col>
          <Col>
            <ColorEnclosedLabel
              label={`Total remboursements : ${data.CQgetDOOrderAccountingEventsData.totalRefundAmount.toFixed(
                2
              )} €`}
              color={'darkgreen'}
            />
          </Col>
          <Col>
            <ColorEnclosedLabel
              label={`Balance : ${data.CQgetDOOrderAccountingEventsData.totalPaidAmount.toFixed(
                2
              )} €`}
              color={'darkmagenta'}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default TableAccounting
