import gql from 'graphql-tag'

const GET_ORDER_PICKING_LISTING = gql`
  query GET_ORDER_PICKING_LISTING(
    $orderEventTypeKeys: [String]
  ) {
    getOrderPickingListing(
      orderEventTypeKeys: $orderEventTypeKeys
    ) {
      edges {
        node {
          id #orderId
          orderKey
          orderType
          customerFirstname
          customerLastname
          customerEmail
          customerLandlinePhone
          customerMobilePhone
          eventCreationDate
          frameList
          eventTypeKey
          cosiumId
        }
      }
      totalCount
    }
  }
`
    
export default GET_ORDER_PICKING_LISTING