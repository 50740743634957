import { gql } from '@apollo/client'

export const GET_DO_FRAME_CUSTOM_LISTING = gql`
  query GET_DO_FRAME_CUSTOM_LISTING($frameNameFilter: String!, $language: String) {
    getDOFrameCustomListing(frameName: $frameNameFilter, language: $language) {
      totalCount
      edges {
        node {
          id
          key
          sku
          variantLevel
          bridgeWidth {
            value
          }
          lensWidth {
            value
          }
          salePrice18n
          imageUrls
          name
          visible
          color {
            ... on object_DOColor {
              label
            }
          }
          images {
            image {
              fullpath
            }
          }
          stock {
            ... on fieldcollection_DOProductStockFC {
              availableQuantity
              essdomQuantity
              bbgrQuantity
            }
          }
        }
      }
    }
  }
`
