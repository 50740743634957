import './UniqueVoucherTemplateListToggle.css'
import { gql, useMutation } from '@apollo/client'
import React, { useContext, useState } from 'react'
import { Button, Container, Row, Col, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ToastContext } from 'store/toast-context'
import { getFormattedDate } from 'utils/functions'
import AuthContext from 'store/auth-context'

interface Props {
  item: any
  onCancel: () => void
}
const UniqueVoucherTemplateListToggle: React.FC<Props> = ({
  item,
  onCancel,
}) => {
  const { toast } = useContext(ToastContext)
  const { t } = useTranslation()
  const authCtx = useContext(AuthContext)

  const BULK_CREATE_VOUCHERS = gql`
    mutation BULK_CREATE_VOUCHERS(
      $voucherTemplateId: Int!
      $amount: Int!
      $prefixedCode: String!
      $quantity: Int!
      $email: String!
      $saveEmail: Boolean
    ) {
      bulkCreateVouchers(
        voucherTemplateId: $voucherTemplateId
        amount: $amount
        prefixedCode: $prefixedCode
        quantity: $quantity
        email: $email
        saveEmail: $saveEmail
      ) {
        success
        output {
          totalVouchersSuccessfullyCreated
          totalFailedVouchers
        }
      }
    }
  `
  const [amount, setAmount] = useState(
    item?.initialAmount?.value ? item.initialAmount.value : ''
  )
  const [prefixedCode, setPrefixedCode] = useState(
    item?.voucherPrefixedCode ? item.voucherPrefixedCode : ''
  )
  const [quantity, setQuantity] = useState('')
  const [email, setEmail] = useState(authCtx.user.email ?? '')

  const [generateBulkVouchers, { loading }] = useMutation(
    BULK_CREATE_VOUCHERS,
    {
      onCompleted: (data) => {
        const { totalVouchersSuccessfullyCreated, totalFailedVouchers } =
          data.bulkCreateVouchers.output
        if (totalVouchersSuccessfullyCreated === 0 && totalFailedVouchers === 0)
          toast({
            title: 'Traitement ultérieur (nbr trop élevé) :',
            message: `Planification de la génération des ${quantity} bons la nuit prochaine ; un e-mail sera envoyé lors de la création des bons (à ${email}).`,
            manualClose: true,
          })
        else
          toast({
            title: 'Succès :',
            message: `Génération de ${t('voucher.voucher_count', {
              count: totalVouchersSuccessfullyCreated,
            })}. Consulter votre boîte mail (${email}) pour télécharger votre fichier CSV.`,
            manualClose: true,
          })
        setQuantity('')
        if (!authCtx.user.email) authCtx.user.email = email
        setEmail(authCtx.user.email)
      },
    }
  )

  const generateBulkVouchersHandler = async () => {
    if (
      window.confirm(
        +quantity > authCtx.websiteSettings.limitVoucher
          ? `Attention vous souhaitez créer ${quantity} bons de réduction, du fait de la demande, la création se réalisera la nuit. Un mail de confirmation de création sera envoyé à l’adresse e-mail : ${email}`
          : `Attention, vous allez générer ${quantity} BDR uniques`
      )
    ) {
      try {
        await generateBulkVouchers({
          awaitRefetchQueries: true,
          variables: {
            voucherTemplateId: +item.id,
            amount: +amount,
            prefixedCode,
            quantity: +quantity,
            email,
            saveEmail: !authCtx.user.email,
          },
        })
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <>
      <Container fluid className="uniqueVoucher">
        <Row>
          <Col>
            <strong>Montant :</strong>{' '}
            <input
              name="amount"
              type="number"
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value)
              }}
            />{' '}
            <strong>Préfixe :</strong>{' '}
            <input
              name="prefixedCode"
              type="text"
              value={prefixedCode}
              onChange={(e) => {
                setPrefixedCode(e.target.value)
              }}
            />{' '}
            <strong>Quantité :</strong>{' '}
            <input
              name="quantity"
              type="number"
              value={quantity}
              min="1"
              onChange={(e) => {
                setQuantity(e.target.value)
              }}
            />{' '}
            <strong>Email :</strong>{' '}
            <input
              name="email"
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
          </Col>
        </Row>
        <Row className="py-3">
          <Col>
            <strong>Date de validité :</strong>{' '}
            {getFormattedDate(
              new Date(Date.now() + item.duration * 3600 * 1000 * 24)
            )}
          </Col>
        </Row>
        <Row className="float-end">
          <Col>
            <Button
              variant="primary"
              disabled={
                !amount ||
                !prefixedCode ||
                !quantity ||
                !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                  email
                ) ||
                loading
                  ? true
                  : false
              }
              onClick={() => {
                generateBulkVouchersHandler()
              }}
            >
              {loading ? (
                <>
                  <Spinner
                    variant="light"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{' '}
                  Génération en cours
                </>
              ) : (
                <>Génération de masse</>
              )}
            </Button>
            <Button
              variant="secondary"
              className="m-3"
              onClick={() => {
                onCancel()
              }}
            >
              Annuler
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default UniqueVoucherTemplateListToggle
