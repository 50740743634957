import gql from 'graphql-tag'

const SEARCH_BENEFICIARIES = gql`
  query SEARCH_BENEFICIARIES($firstName: String, $lastName: String) {
    CQgetDOBeneficiaryCustomListing(
      firstName: $firstName
      lastName: $lastName
    ) {
      edges {
        node {
          id
          firstName
          lastName
          birthDate
          customerId
        }
      }
      totalCount
    }
  }
`

export default SEARCH_BENEFICIARIES
