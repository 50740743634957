import React, { Component } from "react";

export default class GrandLivre extends Component {
    render() {
        return (
            <div>
                <h1>GrandLivre</h1>
            </div>
        );
    }
}
