import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import { GET_FORMER_ORDER_PRESCRIPTIONS } from 'graphql/queries/getFormerOrderPrescriptions'

interface Props {
  beneficiary: beneficiaryType
  disabled: boolean
  setSelectedFormerPrescription: React.Dispatch<React.SetStateAction<any>>
}
const SelectPrescription: React.FC<Props> = ({
  beneficiary,
  disabled,
  setSelectedFormerPrescription
}) => {
  const [selectedPrescription, setSelectedPrescription] = useState<any|null>(null)

  const { loading, error, data } = useQuery(GET_FORMER_ORDER_PRESCRIPTIONS, {
    variables : {
      beneficiaryId: +beneficiary.id
    }
  })

  if (loading)
    return (
      <Spinner
        variant="primary mb-3 ms-3"
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    )
  if (error) return <p>`Error! ${error.message}`</p>

  const handleOpenOrder = () => {
    if(selectedPrescription)
      window.open(`${selectedPrescription.orderId}`)
  }

  const handleSelectedFormerPrescription = (wtc: string) => {
    setSelectedFormerPrescription({id: selectedPrescription.prescriptionId, wtc: wtc})
  }

  const jsonData = JSON.parse(JSON.stringify(data.getFormerOrderPrescriptionListing.edges))
  jsonData.options = []
  if (jsonData.length === 0)
    jsonData.options = [<option key="none">Pas d’ancienne commande</option>]
  else 
    jsonData.map((edge: any, i: number) => {
      if(jsonData.options.length === 0)  
        jsonData.options.push(
          <option value="" key={i + edge.node.prescriptionId} disabled hidden>
            { edge.node.label }
          </option>
        )

      jsonData.options.push(
        <option value={edge.node.prescriptionId} key={i}>
          { edge.node.label }
        </option>
      )
      return true
    })

  return (
    <>
      <div className="d-flex justify-content-start align-items-center m-2">
        <Button
          className="btn-sm me-2"
          onClick={handleOpenOrder}
          disabled={!selectedPrescription} // désactivé si aucune cde est sélectionner
        >
          Voir
        </Button>
        <Form.Select
          style={{ fontSize: '12px' }}
          onChange={(e: React.FocusEvent<HTMLSelectElement>) => {
            const prescription = jsonData.find( (edge: any) => edge.node.prescriptionId === parseInt(e.target.value) )
            setSelectedPrescription(prescription.node)
          }}
          defaultValue={''}
          className={
            selectedPrescription ? '' : 'text-lightgray'
          }
        >
          {jsonData.options.map((option: any) => {
            return option
          })}
        </Form.Select>
      </div>

      <div className="d-flex justify-content-center align-items-center mb-1">
        <Button className="btn-sm p-1" disabled={disabled || !selectedPrescription?.validity} onClick={() => handleSelectedFormerPrescription('cor')}>
          Copie correction
        </Button>
        <Button className="btn-sm p-1 mx-1" disabled={disabled || !selectedPrescription?.validity} onClick={() => handleSelectedFormerPrescription('eps')}>
          Copie EP
        </Button>
        <Button className="btn-sm p-1" disabled={disabled || !selectedPrescription?.validity} onClick={() => handleSelectedFormerPrescription('all')}>
          Copier les deux
        </Button>
      </div>
    </>
  )
}

export default SelectPrescription
