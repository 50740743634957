import {
  Button,
  Spinner
} from 'react-bootstrap'
import { constants } from 'utils/constants'
import { ToastContext } from 'store/toast-context'
import { useContext } from 'react'
import { useMutation } from '@apollo/client'
import BULK_CREATE_ORDER_EVENTS from 'graphql/mutations/bulkCreateOrderEvents'
import { useTranslation } from 'react-i18next'
import GET_ORDER_EVENTS from 'graphql/queries/getDOOrderEvents'

interface Props {
  orderId: number
  worflowSate: string
  closeModal?: () => void
}

const OrderReadyToShipModal: React.FC<Props> = ({
    orderId,
    worflowSate,
    closeModal
  }) => {
  const { toast } = useContext(ToastContext)
  const { t } = useTranslation()

  // mutation create event(s)
  const [bulkCreateOrderEvents, { loading }] = useMutation(
    BULK_CREATE_ORDER_EVENTS,
    {
      variables: {
        orderId,
        events: JSON.stringify(worflowSate ==='in_verification'? 
          [{key:constants.eventTypeKeys.ORDER_EVENT_TYPE_KEHL_READY_TO_ASSEMBLE}, {key:constants.eventTypeKeys.ORDER_EVENT_TYPE_IN_PREPARATION_AT_KEHL}] : 
          [{key:constants.eventTypeKeys.ORDER_EVENT_TYPE_IN_PREPARATION_AT_KEHL}]),
      },
      //update events history
      refetchQueries: [
        // works only on success
        GET_ORDER_EVENTS,
      ],
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        if (
          data.bulkCreateOrderEvents.output.creationFailEventTypeKeys.length > 0
        ) {
          toast({
            title: 'Echec :',
            message: `${t('status_count_fail', {
              count:
                data.bulkCreateOrderEvents.output.creationFailEventTypeKeys
                  .length,
            })}`,
            manualClose: true,
          })
        }
        if (
          data.bulkCreateOrderEvents.output.creationFailEventTypeKeys.length === 0
        ) {
          toast({
            title: 'Succès :',
            message: `La commande est prête à être expédiée`,
          })
        }
        else if (
          data.bulkCreateOrderEvents.output.creationSuccessEventTypeKeys
            .length > 0 && data.bulkCreateOrderEvents.output.creationFailEventTypeKeys.length !== 0
        ) {
          toast({
            title: 'Succès :',
            message: `${t('status_count_success', {
              count:
                data.bulkCreateOrderEvents.output.creationSuccessEventTypeKeys
                  .length,
            })}`,
          })
        }
        if (closeModal) closeModal()
      },
    }
  )
  const clickConfirmHandler = async () => {
    try {
      bulkCreateOrderEvents()
    } catch (e) {
      console.log(e)
    }
  }

  const clickCancelSaveHandler = () => {
    if(closeModal) closeModal()
  }

  return (
  <>
    <div className="mt-4 mb-4 text-center">
      Êtes-vous sûr(e) que la commande est prête à être expédiée ?
    </div>
    <div className="mt-3 text-center">
      <Button
        className="m-2 btn-primary"
        disabled={loading}
        onClick={clickConfirmHandler}
      >
        {(loading) && (
          <Spinner
            variant="light"
            className="me-2"
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
        Confirmer
      </Button>
      <Button
        variant="outline-primary bg-white text-primary"
        className="m-2"
        onClick={clickCancelSaveHandler}
      >
        Annuler
      </Button>
    </div>
  </>)
}

export default OrderReadyToShipModal