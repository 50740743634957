import gql from 'graphql-tag'
import { DO_CONTACT_INFO_FC_FIELDS } from 'graphql/fragments/DOContactInfoFCFields'
import { DO_CONTACT_NAME_FC_FIELDS } from 'graphql/fragments/DOContactNameFCFields'
import { ORDER_TOTAL_AMOUNT_FIELDS } from 'graphql/fragments/DOOrderTotalAmountFCFields'

const GET_ORDERS_BY_ORDER_EVENT_TYPE_LISTING = gql`
  ${DO_CONTACT_INFO_FC_FIELDS}
  ${DO_CONTACT_NAME_FC_FIELDS}
  ${ORDER_TOTAL_AMOUNT_FIELDS}
  query GET_ORDERS_BY_ORDER_EVENT_TYPE_LISTING(
    $orderEventTypeId: Int
    $orderEventTypeKeys: [String]
    $limit: Int
    $offset: Int
    $siteFilter: String
    $fromDate: String
    $toDate: String
  ) {
    getDOOrderByDOOrderEventTypeListing(
      orderEventTypeId: $orderEventTypeId
      orderEventTypeKeys: $orderEventTypeKeys
      limit: $limit
      offset: $offset
      siteFilter: $siteFilter
      fromDate: $fromDate
      toDate: $toDate
    ) {
      edges {
        node {
          order {
            id
            key
            creationDate
            orderType
            workflowState
            siteDomain {
              ... on object_DOSite {
                id
                iso2Code
              }
            }
            comment
            customer {
              ... on object_DOCustomer {
                id
                contactName {
                  ...DOContactNameFCFields
                }
                contactInfo {
                  ...DOContactInfoFCFields
                }
              }
            }
            orderLines {
              ... on object_DOOrderLine {
                id
                items {
                  ... on object_DOOrderLineItem {
                    label
                  }
                }
              }
            }
            totalAmountHistory {
              ...DOOrderTotalAmountFCFields
            }
          }
          lastOrderEvent {
            id
            creationDate
            comment
            mailInfo
            eventType {
              ... on object_DOOrderEventType {
                id
                key
                eventFamily
              }
            }
          }
          lensFocal
          firstPaymentMethodLabel
          frames
        }
      }
      orderEventType {
        ... on object_DOOrderEventType {
          id
          key
          label
          eventFamily
        }
      }
      totalOrders
    }
  }
`

export default GET_ORDERS_BY_ORDER_EVENT_TYPE_LISTING
