//Create ZIP file and download images
import JSZip from 'jszip'
export const downloadImages = async (imageUrls: { url: string; order: number }[], ean: string, errorList: string[], setErrorList: React.Dispatch<React.SetStateAction<string[]>>) => {
    //create a zip file
    const zip = new JSZip()
    let hasError = false
    await Promise.all(
        imageUrls.map(async ({ url }, i) => {
        if (!url.includes('removebg')) {
            url = url.replaceAll("/imgprd/image", "/imgprd/image/removebg")
        }
        //fetch images
        const response = await fetch(url)
        if (!response.ok) { 
            // get the error message from the HTTP response,
            hasError = true
            setErrorList([...errorList, ean])
            return
        }else{
            //add image to zip file
            zip.file(`image${i+1}.png`, response.blob(), { binary: true })
        }
        })
    )
    if(hasError){
        alert('Erreur: il n\'y a pas d\'images détourées pour ce produit.')
    }else{
        //generate the zip contents as a blob
        zip.generateAsync({ type: 'blob' }).then((content) => {
        //trigger the download of the zip file
        const FileSaver = require('file-saver')
        FileSaver.saveAs(content, `${ean}.zip`)
        })
    }
}