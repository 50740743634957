import { gql } from '@apollo/client'

export const GET_INDICATORS = gql`
    query GET_INDICATORS {
        getIndicators {
            totalOnlineProducts
            tryAtHome
            outOfStock
            outOfStockTryAtHome
            tryOnline
        }
    }`