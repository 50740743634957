import { useMutation } from '@apollo/client'
import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ToastContext } from 'store/toast-context'
import { capitalizeFirstLetter } from 'utils/functions'
import EditBeneficiaryAndLensesModal from './EditBeneficiaryAndLensesModal'
import EditLensReplacementForm from './EditLensReplacementForm'
import { CM_DELETE_LENSES } from 'graphql/mutations/deleteLenses'
import { ADD_GUARANTEE_TO_ORDERLINE } from 'graphql/mutations/addGuaranteeToOrderLine'
import { CQ_GET_ORDER } from 'graphql/queries/getOrder'
import EditProductSearchForm from './EditProductSearchForm'

interface Props {
  item: any
  closeModal?: () => void
  orderLine: orderLineType
  isHometrial: boolean
  calculators: orderCalculatorsType
}

const EditFrameModal: React.FC<Props> = ({ item, closeModal, orderLine, isHometrial, calculators }) => {
  const { t } = useTranslation()
  const { REACT_APP_PIMCORE_URL_DOMAIN } = process.env
  const { toast } = useContext(ToastContext)

  const [selectedRadio, setSelectedRadio] = useState('')
  const [awaitingConfirmation, setAwaitingConfirmation] = useState(false)
  let product: any = {}
  let frame: any
  let lensReplacement: any
  let imagesUrlsT: any = ''
  if (item.item[0].__typename === 'fieldcollection_DOOLItemFrameFC') {
    frame = item.item[0].frame[0]
    if (frame) {
      product.brand = frame?.brand?.name
      imagesUrlsT = JSON.parse(frame?.imageUrls)
      if (frame?.images || imagesUrlsT?.length) {
        product.image = frame.images
          ? `${REACT_APP_PIMCORE_URL_DOMAIN}` + frame.images[0]?.image?.fullpath
          : imagesUrlsT.find((obj: any) => obj.order === 1).url
      }
      
      product.color = frame?.color?.label
      product.category = frame?.category?.parentCategory?.label + ' '+(t(frame.category?.rim))
      product.name = frame?.name
      product.ean = frame?.gtin
      product.sku = frame?.sku
      product.idmc = frame?.idmc
      product.id = frame.id
    }
  } else if (
    item.item[0].__typename === 'fieldcollection_DOOLItemLensReplacementFC'
  ) {
    lensReplacement = item.item[0].lensReplacement[0]

    if (lensReplacement) {
      product.brand = 'Monture client'
      imagesUrlsT = JSON.parse(lensReplacement?.imageUrls)
      if (lensReplacement?.images || imagesUrlsT?.length) {
        product.image = lensReplacement?.images
          ? `${REACT_APP_PIMCORE_URL_DOMAIN}` +
            lensReplacement.images[0]?.image?.fullpath
          : imagesUrlsT?.find((obj: any) => obj.order === 1).url
      }
      if (lensReplacement.category.rim)
        product.category = capitalizeFirstLetter(
          t(lensReplacement.category.rim)
        )
      product.otherInfo = ''
    }
  }

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadio(e.target.value)
    setAwaitingConfirmation(false)
  }
  const saveHandler = async () => {
    try {
      if (awaitingConfirmation) {
        if (lensReplacement && orderLine.order[0].orderLines.length < 2) {
          // si c'est un remplacement de verre et s'il reste moins de deux orderLines : on peut pas supprimer
          if (closeModal) closeModal()
          toast({
            title: 'Remplacement de verres non supprimés :',
            message:
              ' car il doit rester au moins une ligne produit dans le détail de la commande',
            manualClose: true,
          })
        } else
          await deleteLenses({
            refetchQueries: [CQ_GET_ORDER],
            awaitRefetchQueries: true,
            variables: { orderLineId: +orderLine.id },
          })
      }
      setAwaitingConfirmation(!awaitingConfirmation)
    } catch (error) {}
  }
  
  const [deleteLenses, { loading }] = useMutation(CM_DELETE_LENSES, {
    onCompleted: (data) => {
      if (closeModal) {
        closeModal()
      }
      if (!data?.deleteLenses?.output?.errorMessage) {
        toast({
          title: 'Succès :',
          message: 'Suppression des verres enregistrée',
        })
      } else if (data?.deleteLenses?.output?.errorMessage) {
        toast({
          title: 'Erreur :',
          message: data?.deleteLenses?.output?.errorMessage,
          manualClose: true,
        })
      }
    },
  })

  const [addGuarantee, { loading:loadingGuarantee }] = useMutation(ADD_GUARANTEE_TO_ORDERLINE, {
    onCompleted: (data) => {
      if (closeModal) {
        closeModal()
      }
      if (!data?.addGuaranteeToOrderLine?.output?.errorMessage) {
        toast({
          title: 'Succès :',
          message: 'Ajout de la Garantie Sérénité Plus',
        })
      } else if (data?.addGuaranteeToOrderLine?.output?.errorMessage) {
        toast({
          title: 'Erreur :',
          message: data?.addGuaranteeToOrderLine?.output?.errorMessage,
          manualClose: true,
        })
      }
    },
  })
  const addGuaranteeHandler = async () =>{
    await addGuarantee({
      refetchQueries: [CQ_GET_ORDER],
      awaitRefetchQueries: true,
      variables: { orderLineId: +orderLine.id },
    })
  }
  //prevents warning in console after adding lenses if prescription null because we want to close unmounted componant
    useEffect(() => {
      return () => {
        if (awaitingConfirmation) setAwaitingConfirmation(false)
      }
    }, [awaitingConfirmation, setAwaitingConfirmation])

  return (
    <div>
      <div className="d-flex">
        {product.image && (
          <img
            src={product.image}
            alt={product.brand}
            className="brand-model-image"
            style={{
              height: '100%',
              width: '140px',
              objectFit: 'contain',
              objectPosition: 'center',
            }}
          />
        )}
        <Container>
          <Row>
            <Col xs={frame?3:4}>
              <b>{frame ? 'Marque' : 'Type de monture'}</b>
            </Col>
            <Col>
              {frame
                ? product.brand 
                : `Monture ${product?.category?.toLowerCase()}`}
            </Col>
          </Row>      
          {frame && (
            <>
              <Row>
                <Col xs={3}>
                  <b>Modèle Couleur</b>
                </Col>
                <Col>
                  {product.name} {product.color}
                </Col>
              </Row>
               <Row>
                <Col xs={3}>
                  <b>Catégorie</b>
                </Col>
                <Col>
                  {product.category}
                </Col>
              </Row>
               <Row>
                <Col xs={3}>
                  <b>EAN</b>
                </Col>
                <Col>
                  {product.ean}
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <b>SKU</b>
                </Col>
                <Col>
                  {product.sku}
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <b>IDMC</b>
                </Col>
                <Col>
                  {product.idmc}
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <b>ID</b>
                </Col>
                <Col>
                  {product.id}
                </Col>
              </Row>
            </>
          )}
          <Row className="mt-2 mb-3">
            <Col>
              <b>Prix unitaire</b> {(isHometrial && item.hometrialProductAmount ? item.hometrialProductAmount.value : item.unitAmount.value).toFixed(2)} €
            </Col>
            <Col>
              <b>Quantité </b>x{item.quantity}
            </Col>
            <Col>
              <b>Total </b>
              {(item.unitAmount.value * item.quantity).toFixed(2)} €
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="mt-3">
        <Row>
          <Col xs={2}>Voulez-vous : </Col>
          <Col className="mb-3">
            <fieldset>
              <Form.Group controlId={'input' + item.id}>
                <Row>
                  <Form.Check
                    inline
                    label="Ajouter/Modifier des verres correcteurs"
                    name="editFrame"
                    type="radio"
                    value="addLenses"
                    id="addLenses"
                    disabled={orderLine.order[0].orderType === 'essdom'}
                    onChange={(e) => handleSelect(e)}
                  />

                  <Form.Check
                    inline
                    label="Supprimer les verres"
                    name="editFrame"
                    type="radio"
                    value="deleteLenses"
                    id="deleteLenses"
                    disabled={!orderLine.prescription}
                    onChange={(e) => handleSelect(e)}
                  />

                  <Form.Check
                    inline
                    label="Modifier par une monture"
                    name="editFrame"
                    type="radio"
                    value="frame"
                    id="editFrame"
                    onChange={(e) => handleSelect(e)}
                  />
                  <Form.Check
                    inline
                    label="Modifier par un remplacement de verres"
                    name="editFrame"
                    type="radio"
                    value="lensReplacement"
                    id="editLenseReplacement"
                    disabled={orderLine.prescription === null}
                    onChange={(e) => handleSelect(e)}
                  />
                  <Form.Check
                    inline
                    label="Ajouter la Garantie Sérénité Plus"
                    name="editFrame"
                    type="radio"
                    value="addGuarantee"
                    id="addGuarantee"
                    disabled={orderLine.prescription === null ||
                    orderLine.items.some((item) => !item.item || (item.item[0].hasOwnProperty('genericItem') && item.item[0].genericItem[0].itemType === 'warranty'))} //disabled guarantee if no prescription or if already have guarantee
                    onChange={(e) => handleSelect(e)}
                  />
                </Row>
              </Form.Group>
            </fieldset>
          </Col>
          {(selectedRadio === 'frame' || selectedRadio === 'editLenses') && (
            <EditProductSearchForm
              orderLineItem={item}
              selectedRadio={selectedRadio}
              closeModal={closeModal}
            />
          )}
          {selectedRadio === 'deleteLenses' && (
            <div>
              <div className="d-flex justify-content-center">
                {awaitingConfirmation && (
                  <h4>
                    Êtes-vous sûr de supprimer les verres de l'équipement ?
                    <br />
                    Cela supprimera également l'ordonnance associée.
                  </h4>
                )}
              </div>

              <div className="mt-3 text-center">
                <Button
                  className={`m-2 btn ${
                    awaitingConfirmation ? 'btn-success' : 'btn-primary'
                  }`}
                  disabled={loading}
                  onClick={saveHandler}
                >
                  {loading && (
                    <Spinner
                      variant="light"
                      className="me-2"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  {awaitingConfirmation ? 'Confirmer' : 'Enregistrer'}
                </Button>

                <Button
                  variant="outline-primary bg-white text-primary"
                  className="m-2"
                  disabled={loading}
                  onClick={() => {
                    if (awaitingConfirmation) setAwaitingConfirmation(false)
                    else if (closeModal) closeModal()
                  }}
                >
                  Annuler
                </Button>
              </div>
            </div>
          )}

          {selectedRadio === 'lensReplacement' && (
            <EditLensReplacementForm
              orderLineItem={item}
              closeModal={closeModal}
            />
          )}
          {selectedRadio === 'addLenses' && (
            <EditBeneficiaryAndLensesModal
              beneficiary={
                orderLine.prescription
                  ? orderLine.prescription.beneficiary[0]
                  : undefined
              }
              calculators={calculators}
              orderLine={orderLine}
              closeModal={closeModal}
            />
          )}
          {selectedRadio === 'addGuarantee' && (
           <div className="mt-3 text-center">
              <Button
                className={`m-2 btn ${
                  awaitingConfirmation ? 'btn-success' : 'btn-primary'
                }`}
                disabled={loadingGuarantee}
                onClick={(addGuaranteeHandler)}
              >
                {loadingGuarantee && (
                  <Spinner
                    variant="light"
                    className="me-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Enregistrer
              </Button>
              <Button
                variant="outline-primary bg-white text-primary"
                className="m-2"
                disabled={loadingGuarantee}
                onClick={closeModal}
              >
                Annuler
              </Button>
            </div>
          )}
        </Row>
      </Container>
    </div>
  )
}

export default EditFrameModal
