import { useLazyQuery } from '@apollo/client'
import React, { useState, useEffect, useCallback } from 'react'
import GET_UNPAID_ORDER_LISTING from 'graphql/queries/getUnpaidOrderListing'
import { Container, Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import SortableResultsTable from 'components/UI/SortableResultsTable'
import SkeletonBase from 'components/Skeleton/SkeletonBase'

interface Props {}

const CommandesNonPayees: React.FC<Props> = () => {
  const { t } = useTranslation()

  const ordersColumns = [
    {
      headerName: t('orders_monitoring.headers.country'),
      accessor: 'country',
      disableSortBy: true,
    },
    {
      headerName: t('orders_monitoring.headers.copy'),
      accessor: 'copy',
      disableSortBy: false,
    },
    {
      headerName: t('orders_monitoring.headers.order_number'),
      accessor: 'orderKey',
      disableSortBy: false,
    },
    {
      headerName: t('orders_monitoring.headers.customer_name'),
      accessor: 'customerName',
      disableSortBy: false,
    },
    {
      headerName: t('orders_monitoring.headers.email'),
      accessor: 'customerLogin',
      disableSortBy: false,
    },
    {
      headerName: t('orders_monitoring.headers.phone'),
      accessor: 'deliveryPhoneNumber',
      disableSortBy: false,
    },
    {
      headerName: t('orders_monitoring.headers.days_since_last_event'),
      accessor: 'eventCreationDateDisplay',
      disableSortBy: false,
    },
    {
      headerName: t('orders_monitoring.headers.state'),
      accessor: 'orderStateDisplay',
      disableSortBy: false,
    },
    {
      headerName: t('orders_monitoring.headers.total_amount_variant'),
      accessor: 'totalAmount',
      disableSortBy: false,
    },
  ]

  const [unpaidOrderFilter, setUnpaidOrderFilter] = useState<boolean>(true)
  const [awaitingPaymentOrderFilter, setAwaitingPaymentOrderFilter] = useState<boolean>(true)
  const [fromDateFilter, setFromDateFilter] = useState<string>('')
  const [queryDate, setQueryDate] = useState<string>('')
  const [rawData, setRawData] = useState<any>(null)
  const [filteredData, setFilteredData] = useState<any>(null)

  const [
    getUnpaidOrderListing,
    { loading },
  ] = useLazyQuery(GET_UNPAID_ORDER_LISTING, {
    onCompleted: (data) => {
      setRawData(data?.getUnpaidOrderListing.edges.map((element: any) => {
        let newNode = Object.assign({}, element?.node)
        newNode.oo_id = newNode.id
        const newDate = new Date(newNode.eventCreationDate * 1000)
        const diffInDays = Math.round((new Date().getTime() / 
          1000 - newNode.eventCreationDate) / (24 * 60 * 60))
        newNode.eventCreationDateDisplay = `${newDate.toLocaleDateString('fr-fr')}` +
          ` ${newDate.toLocaleTimeString('fr-fr')} (${diffInDays})`
        return newNode
      }))
    }
  })

  const fetchUnpaidOrders = useCallback(async (fromDate: string) => {
    try {
      if(!fromDate.length)
      {
        let d = new Date()
        d.setDate(d.getDate() - 10)
        fromDate = d.toISOString().substring(0,10)
      }
      setQueryDate(fromDate)
      await getUnpaidOrderListing({
        variables: {
          fromDate: fromDate
        }
      })
    } catch (e) {
      console.log(e)
    }
  }, [setQueryDate, getUnpaidOrderListing])

  const filterListing = useCallback(() => {
    if(rawData)
    {
      setFilteredData(rawData.filter((node: any) => 
        (node.orderState === (unpaidOrderFilter ? 1 : 0) || 
        node.orderState === (awaitingPaymentOrderFilter ? 2 : 0)) && 
        (!fromDateFilter || node.eventCreationDate * 1000 > new Date(fromDateFilter).getTime())))
    }
  }, [unpaidOrderFilter, awaitingPaymentOrderFilter, fromDateFilter, rawData])

  useEffect(() => {
    fetchUnpaidOrders('')
  }, [fetchUnpaidOrders])

  useEffect(() => {
    filterListing()
  }, [filterListing])

  const onRowClick = (rowId: string) => {
    window.open(`/bo/relation_client/gestion_commande_client/${rowId}`)
  }

  return (
    <>
    <Container>
      <Row className="d-flex align-items-center">
        <Col xs={3}>
          <Form.Check
            key={'1'}
            inline
            label={'Commande non payée'}
            name="country-group"
            type="checkbox"
            defaultChecked={unpaidOrderFilter}
            onChange={() => setUnpaidOrderFilter(!unpaidOrderFilter)}
          />
        </Col>
        <Col xs={6}>
          <Form.Check
            key={'2'}
            inline
            label={'Commande en attente de paiement par chèque ou virement'}
            name="country-group"
            type="checkbox"
            defaultChecked={unpaidOrderFilter}
            onChange={() => setAwaitingPaymentOrderFilter(!awaitingPaymentOrderFilter)}
          />
        </Col>
        <Col xs={3}>
          <Form.Group as={Row} className="mb-3 justify-content-end me-2" controlId="toDate">
            <Form.Label column sm={2} className="text-nowrap">
              Du :
            </Form.Label>
            <Col md="auto">
              <Form.Control
                type="date"
                placeholder="Au"
                defaultValue={queryDate}
                onChange={(e: any) => e.target.value < queryDate ? 
                  fetchUnpaidOrders(e.target.value) :
                  setFromDateFilter(e.target.value)}
                className="w-100"
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
    </Container>
    {loading && (
      <SkeletonBase count={10} />
    )}
    {!loading && filteredData && (
      <>
        {!filteredData.length && (
          <div className="d-flex justify-content-center mt-2"><strong>Aucun résultat</strong></div>
        )}
        {!!filteredData.length && (
          <div className="results">
            <SortableResultsTable
              tableColumns={ordersColumns}
              tableData={filteredData}
              onClickRows={onRowClick}
              dataTotalSize={filteredData.length}
              fetchMoreData={() => {}}
              displayLineNumbers={false}
              tableType="monitoring"
              nbOfNextColumnsToCopy={4}
            />
          </div>
        )}
      </>
    )}
    </>
  )
}

export default CommandesNonPayees