import { gql } from '@apollo/client'

export const GET_DO_SITE_LISTING = gql`
  query GET_DO_SITE_LISTING {
    getDOSiteListing {
      edges {
        node {
          id
          domainSuffix
        }
      }
    }
  }
`
