import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline'
import { ExclamationIcon } from '@heroicons/react/solid'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getAge, getCorrection, isPrescriptionValid } from 'utils/functions'
interface Props {
  prescription: prescriptionType
  openPrescription: number | null
  setOpenPrescription: React.Dispatch<React.SetStateAction<null | number>>
  numberOfPrescription: number
  beneficiaryBirthdate: string
  isGroupOff: boolean
}
const PrescriptionCard: React.FC<Props> = ({
  beneficiaryBirthdate,
  prescription,
  setOpenPrescription,
  openPrescription,
  numberOfPrescription,
  isGroupOff
}) => {
  const { t } = useTranslation()

  let prescriptionDate = prescription?.prescriptionDate
  let prescriptionInfo = prescriptionDate
    ? new Intl.DateTimeFormat('fr', {
        dateStyle: 'short',
      }).format(
        new Date(
          prescriptionDate.replace(/ - /g, '/').replace(/ /g, 'T')
        )
      ) +
      ' - ' +
      t('get_age', {
        count: getAge(prescriptionDate),
      })
    : ''
    
    let validPrescription = prescriptionDate && beneficiaryBirthdate && isPrescriptionValid(beneficiaryBirthdate, prescriptionDate)

  return (
    <div
      className="cursor custom-mini-card"
      // TODO : clique sur ordo pour voir le détail de la prescription
      onClick={() => {
        if (openPrescription === +prescription.id) {
          setOpenPrescription(null)
        } else {
          setOpenPrescription(+prescription.id)
        }
      }}
    >
      <div
        className={`small-content rounded p-2 ${
          isGroupOff?' bg-off':
          (!validPrescription && prescriptionDate!==null? 'bg-orange': 
          openPrescription === +prescription.id ?'bg-turquoise':'')}`}
      >
        <div className="d-flex justify-content-between">
          <h4>Ordonnance n°{numberOfPrescription}</h4>
          {openPrescription === +prescription.id ? (
            <EyeOffIcon className={`header-title-icon ${(validPrescription||!prescriptionDate)?'text-white':'text-dark'}`} />
          ) : (
            <EyeIcon className={`header-title-icon ${(validPrescription||!prescriptionDate)?'custom-blue-link':'text-dark'}`} />
          )}
        </div>

        {validPrescription ?
          <h6>{prescriptionInfo}</h6> :
          <h6>
            <b>
              <ExclamationIcon className={`header-title-icon ${!prescriptionDate ? (openPrescription === +prescription.id ? 'text-white':'')
                :'text-dark'}`}/>
              {!prescriptionDate ?'Absence de date':'Ordonnance expirée'}
            </b>
            <br/>{prescriptionInfo}
          </h6> 
        }

        <br />
        <span>
          {' '}
          source EP :{' '}
          {prescription.pupillaryDistanceSource
            ? t(prescription.pupillaryDistanceSource)
            : 'Non renseigné'}{' '}
        </span>
        <br />
        {prescription.rightEyeCorrections?.[0].pupillaryDistance ||
        prescription.leftEyeCorrections?.[0].pupillaryDistance ? (
          <>
            {/* si EP non renseigné */}
            <span>
              EPO {prescription.rightEyeCorrections?.[0].pupillaryDistance}|
              {prescription.leftEyeCorrections?.[0].pupillaryDistance} EP:{' '}
              {prescription?.pupillaryDistanceVisionType
                ? t(prescription.pupillaryDistanceVisionType)
                : 'Non renseigné'}
            </span>
          </>
        ) : (
          <>
            {/* si EP non renseigné */}
            <span>EPO[!]</span>
          </>
        )}
        <br />
        <span>
          source : {t(prescription.source)} | RBT SECU{' '}
          {prescription.refund ? 'DEMANDÉ' : 'NON'}
        </span>
        <br />
        <span>-OD: {getCorrection(prescription.rightEyeCorrections)}</span>
        <br />
        <span>-OG: {getCorrection(prescription.leftEyeCorrections)}</span>
      </div>
    </div>
  )
}

export default PrescriptionCard
