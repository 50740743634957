import React, { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

interface Props {
  item: any
  orderLine: any
  setItemAndOrderLine: React.Dispatch<React.SetStateAction<any>>
  setEditionModal: React.Dispatch<React.SetStateAction<string>>
}

const TypeBadge: React.FC<Props> = ({
  item,
  orderLine,
  setEditionModal,
  setItemAndOrderLine,
}) => {
  const [tooltipText, setTooltipText] = useState('Copier');
  let isActive = orderLine?.status === 'on'
  let type = 'default'
  let bgColor = isActive ? 'bg-turquoise' : 'bg-badge-secondary'

  const getCssClass = (item: any, orderLine: any) => {
    //type verres
    if(item.item)
    {
      if (item.item[0].__typename === 'fieldcollection_DOOLItemLensFC') {
        if (item.item[0].eyeSide === 'oeil_droit') {
          let cosiumCode = item.item[0]?.lens[0]?.cosiumCode
          bgColor = isActive
            ? 'border border-info text-info'
            : 'border border-secondary text-secondary'

          type = `OD ${cosiumCode?cosiumCode:'Inexistant'}`
        } else if (item.item[0].eyeSide === 'oeil_gauche') {
          let cosiumCode = item.item[0]?.lens[0]?.cosiumCode
          bgColor = isActive
            ? 'border border-info text-info'
            : 'border border-secondary text-secondary'
          type = `OG ${cosiumCode?cosiumCode:'Inexistant'}`
        }
      } else if (
        //type monture ou remplacement de verre
        item.item[0].__typename === 'fieldcollection_DOOLItemFrameFC' ||
        item.item[0].__typename === 'fieldcollection_DOOLItemLensReplacementFC'
      ) {
        type = `${orderLine?.prescription ? 'Équipement' : 'Monture'} n°${
          orderLine.id
        }`
      } else if (
        item.item[0].__typename === 'fieldcollection_DOOLItemAccessoryFC'
      ) {
        bgColor = isActive ? 'bg-brown' : 'bg-badge-secondary'
        type = `Accessoire n°${orderLine.id}`
      } else if (
        item.item[0].__typename === 'fieldcollection_DOOLItemShippingFC'
      ) {
        type = `Frais de port n°${orderLine.id}`
      } else if (
        item.item[0].__typename === 'fieldcollection_DOOLItemVoucherFC'
      ) {
        type = `Remise n°${orderLine.id}`
      }
      else if (
        item.item[0].__typename === 'fieldcollection_DOOLItemGenericFC'
      ) {
        const genericItem = item.item[0].genericItem[0]
        // bgColor = isActive
        //       ? 'border border-info text-info'
        //       : 'border border-secondary text-secondary'
        // #0B6DBD
        bgColor = isActive ? 'bg-dark-blue' : 'bg-badge-secondary'
        if(genericItem.itemType === 'warranty')
          type = 'Garantie'
        else if(genericItem.itemType === 'discount')
          type = 'Remise'
        else
          type = genericItem.isNegative ? 'Remise générique' : 'Produit générique'
      }

    } else if (orderLine.order[0].orderType==='panier'){
      // GUARANTEE
      bgColor = isActive
            ? 'border border-info text-info'
            : 'border border-secondary text-secondary'
      type = 'Garantie'
    } else
      type = 'Frais de service'
  }

  if (item && orderLine) getCssClass(item, orderLine)

  const handleClickOrderLine = () => {
    if (!orderLine.state) {
      setItemAndOrderLine({ item: item, orderLine: orderLine })
      if(!item.item){
        setEditionModal('orderLineItem')
      }else
        switch (item.item[0].__typename) {
          case 'fieldcollection_DOOLItemFrameFC':
          case 'fieldcollection_DOOLItemLensReplacementFC':
            setEditionModal('frame')
            break
          case 'fieldcollection_DOOLItemShippingFC':
            // no more modal price changes
            // setEditionModal('shipping')
            break
          case 'fieldcollection_DOOLItemAccessoryFC':
              setEditionModal('accessory')
            break
          case 'fieldcollection_DOOLItemGenericFC':
            setEditionModal('generic')
            break;
          case 'fieldcollection_DOOLItemLensFC':
            if (item.item[0].lens[0]?.cosiumCode) {
              //copy cosiumCode
              navigator.clipboard.writeText(item.item[0].lens[0]?.cosiumCode).then(() => {
                setTooltipText('Copié !')
                setTimeout(() => {setTooltipText('Copier')}, 2000)
              })
              .catch((error) => {
                console.error('Erreur lors de la copie dans le presse-papiers :', error)
              })
            }
            break
        }
    }
  }

  const isLensWithoutCosiumCode = item?.item?.[0]?.__typename === 'fieldcollection_DOOLItemLensFC' && !item?.item[0]?.lens[0]?.cosiumCode
  const renderBadge = (
    <span
      data-cy="badgeType"
      className={`badge rounded-pill ${
       item?.item?.[0]?.__typename === 'fieldcollection_DOOLItemVoucherFC' || isLensWithoutCosiumCode ||  item?.item?.[0]?.__typename === 'fieldcollection_DOOLItemShippingFC'  ? '' : 'cursor'
      } ${bgColor}`}
      onClick={handleClickOrderLine}
    >
      {type}
    </span>
  )
  const renderOverlayTrigger = (
    <OverlayTrigger
      overlay={<Tooltip>{tooltipText}</Tooltip>}
      placement="top"
    >
      {renderBadge}
    </OverlayTrigger>
  )

  return !isLensWithoutCosiumCode && item?.item?.[0]?.__typename === "fieldcollection_DOOLItemLensFC" ?  renderOverlayTrigger : renderBadge
  }

export default TypeBadge