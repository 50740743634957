import React, { useContext } from 'react'
import './App.css'
import Pages from './containers/Pages'
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
  ApolloLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { ApolloProvider } from '@apollo/client/react'
import './i18n'
import { onError } from '@apollo/client/link/error'
import AuthContext from './store/auth-context'
import ToastContextProvider from './store/toast-context'
import { MenuContextProvider } from 'store/menu-context'
import { GlobalContextProvider } from 'store/global-context'
import ModalError from './components/Modal/ModalError'
import { GoogleOAuthProvider } from '@react-oauth/google'
const App: React.FC = () => {
  const authCtx = useContext(AuthContext)

  const {
    REACT_APP_PIMCORE_URL_DOMAIN,
    REACT_APP_PIMCORE_GRAPHQL_ENDPOINT_NAME,
    REACT_APP_PIMCORE_GRAPHQL_API_KEY,
    REACT_APP_GOOGLE_CLIENT_ID,
  } = process.env

  const httpLink = createHttpLink({
    uri: `${REACT_APP_PIMCORE_URL_DOMAIN}/pimcore-graphql-webservices/${REACT_APP_PIMCORE_GRAPHQL_ENDPOINT_NAME}?apikey=${REACT_APP_PIMCORE_GRAPHQL_API_KEY}`,
  })

  let setGraphQLError = (graphQLError: string) => {} // Méthode qui met à jour le state dans la modale qui est un enfant de ce composant

  const onGraphQLError = (
    setGraphQLErrorFromChild: (graphQLError: string) => void
  ) => {
    // setGraphQLError récupère une fonction du composant enfant --> ça va permettre de mettre à jour le state de l'enfant et de l'actualiser sans actualiser le composant actuel
    setGraphQLError = setGraphQLErrorFromChild
  }

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    // Bug dans Apollo : https://github.com/apollographql/apollo-client/issues/5708 (qui nous oblige à faire des try/catch pour toutes les mutations) - tjs d'actualité au 07/01/2022
    if (graphQLErrors) {
      const objectNotFoundRegex = /object with(.*)not found/
      graphQLErrors.forEach(({ message }) => {
        if (message === 'EMPTY_TOKEN' || message === 'WRONG_TOKEN') {
          setGraphQLError('Session expirée. Veuillez vous reconnecter.') // Envoi de l'erreur au composant enfant
          authCtx.logout()
        } else if (!message.match(objectNotFoundRegex)) {
          setGraphQLError(message) // Envoi de l'erreur au composant enfant
        }
      })
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
      setGraphQLError(
        `[Erreur réseau]: Veuillez réessayer, si le problème persiste contactez l’administrateur.`
      ) // Envoi de l'erreur au composant enfant
    }
  })

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const storedUser = localStorage.getItem('user')
    let token = ''
    if (storedUser !== null) {
      const jsonStoredUser = JSON.parse(storedUser)
      token = jsonStoredUser.token
    }
    if (token !== '') {
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          'x-auth-token': `${token}`,
        },
      }
    }
    // login case (no token set yet)
    return {
      headers,
    }
  })

  const onCompletedLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((data) => {
      const jsonData = JSON.parse(JSON.stringify(data))
      if (jsonData.data?.success && jsonData.data?.user)
        authCtx.onMutationCallback(jsonData.data.success, jsonData.data.user)

      return data
    })
  })

  const apolloClient = new ApolloClient({
    link: from([onCompletedLink, errorLink, authLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        fieldcollection_DOContactInfoFC: {
          keyFields: ['email', 'mobilePhoneNumber', 'landlinePhoneNumber'],
        },
        object_DOOrder: {
          fields: {
            orderLines: {
              // Prevents warning in console after removing order lines
              merge(existing, incoming) {
                return incoming
              },
            },
          },
        },
        object_DOPrescription: {
          fields: {
            orderLines: {
              // Prevents warning in console after adding beneficiary to orderLine
              merge(existing, incoming) {
                return incoming
              },
            },
          },
        },
        object__DOBeneficiary: {
          fields: {
            prescriptions: {
              // Prevents warning in console after removing order lenses
              merge(existing, incoming) {
                return incoming
              },
            },
          },
        },
      },
    }),
    credentials: 'same-origin',
    connectToDevTools: process.env.NODE_ENV === 'development',
  })

  return (
    <ApolloProvider client={apolloClient}>
      <GlobalContextProvider>
        <MenuContextProvider>
          <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID ?? ''}>
            <ToastContextProvider>
              <div className="App">
                <Pages />
                {/* Modale de message d'erreur */}
                <ModalError onGraphQLErrorFromParent={onGraphQLError} />
              </div>
            </ToastContextProvider>
          </GoogleOAuthProvider>
        </MenuContextProvider>
      </GlobalContextProvider>
    </ApolloProvider>
  )
}

export default App
