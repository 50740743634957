import { useMutation } from '@apollo/client'
import ModalErrorMessage from 'components/Modal/ModalErrorMessage'
import CREATE_DO_ORDER_EVENT_FOR_ORDERS from 'graphql/mutations/createDOOrderEventForOrders'
import GET_ORDER_PICKING_LISTING from 'graphql/queries/getOrderPickingListing'
import { useContext, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ToastContext } from 'store/toast-context'
import { constants } from 'utils/constants'

interface Props {
  readyToAssembleOrdersIds: number[]
  essDom?: boolean
  setCheckedIdList: (value: string[]) => void
}
const PickingBulkMutationBtn: React.FC<Props> = ({
  readyToAssembleOrdersIds,
  essDom,
  setCheckedIdList,
}) => {
  //state to disable buttons during mutation
  const [isDisabled, setIsDisabled] = useState(false)

  const [showErrorModal, setShowErrorModal] = useState(false)

  const { toast } = useContext(ToastContext)

  const { t } = useTranslation()

  // Bulk mutation with combination of all mutations
  // NB : ne met pas à jour le token côté serveur car success === null au niveau du parent fictif bulkCreateOrderEvents
  const [createDOOrderEventForOrders, { data }] = useMutation(
    CREATE_DO_ORDER_EVENT_FOR_ORDERS,
    {
      variables: {
        ordersAndComments: readyToAssembleOrdersIds.map((orderId: number) => {
          return { orderId: +orderId }
        }),
        eventTypeKey: essDom
          ? constants.eventTypeKeys
              .ORDER_EVENT_TYPE_HOME_TRIAL_PICKING_IN_PREPARATION
          : constants.eventTypeKeys.ORDER_EVENT_TYPE_IN_PREPARATION_AT_KEHL,
      },
      refetchQueries: [
        // works only on success
        GET_ORDER_PICKING_LISTING,
      ],
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        // Displaying toast for successful orders
        if (
          data.createDOOrderEventForOrders.output.creationSuccessOrderKeys
            .length > 0
        )
          setCheckedIdList([])
        toast({
          title: 'Mise en préparation :',
          message:
            t('message.enumerate_order_key', {
              count:
                data.createDOOrderEventForOrders.output.creationSuccessOrderKeys
                  .length,
            }) +
            data.createDOOrderEventForOrders.output.creationSuccessOrderKeys.join(
              ', '
            ),
        })

        // Displaying error modal for failed orders
        if (
          data.createDOOrderEventForOrders.output.creationFailOrderKeys.length >
          0
        )
          setShowErrorModal(true)

        setIsDisabled(false)
      },
      onError: () => {
        setIsDisabled(false)
      },
    }
  )

  const callCreateDOOrderEventForOrders = async () => {
    setIsDisabled(true)
    try {
      await createDOOrderEventForOrders()
    } catch (e) {
      // NB : gestion erreur dans App.tsx
    }
  }

  return (
    <>
      <Button
        data-cy="preparation"
        className="ms-2"
        variant={'primary shadow zindex-2 btn-radius'}
        onClick={callCreateDOOrderEventForOrders}
        disabled={isDisabled || readyToAssembleOrdersIds.length === 0}
      >
        Mise en préparation
      </Button>

      {/* Creating a error modal in case some orders could not be updated */}
      {data && (
        <div className="ModalError">
          <Modal
            show={showErrorModal}
            onHide={() => setShowErrorModal(false)}
            dialogClassName={
              ' modal-style d-flex justify-content-center align-items-center'
            }
          >
            <Modal.Body>
              <span
                className="btn btn-close position-absolute p-3 top-0 end-0"
                onClick={() => setShowErrorModal(false)}
              ></span>
              <ModalErrorMessage
                error={
                  t('message.fail_to_change_transition_to_considered', {
                    count:
                      data.createDOOrderEventForOrders.output
                        .creationFailOrderKeys.length,
                  }) +
                  data.createDOOrderEventForOrders.output.creationFailOrderKeys.join(
                    ', '
                  )
                }
              />
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  )
}

export default PickingBulkMutationBtn
