import { ExclamationIcon } from '@heroicons/react/outline'
import React from 'react'
interface Props {
  error?: string
}
const ModalErrorMessage: React.FC<Props> = ({ error }) => {
  return (
    <div className="ModalErrorMessage">
      <ExclamationIcon className="header-title-icon" /> Une erreur s'est
      produite :
      <hr />
      <p>{error ? error : 'Erreur graphQL'}</p>
    </div>
  )
}

export default ModalErrorMessage
