import { ChangeEvent, useEffect, useRef, useState } from "react";
import classes from "./TypeaheadInput.module.css"

export interface Props {
    options: productNameColorType[]
    onSearch: (query: string) => void
    minLength: number
    onClickOption: (option: productNameColorType) => void
    placeholder?: string
}

const TypeaheadInput: React.FC<Props> = ({options, onSearch, minLength, onClickOption, placeholder }) => {

    const [inputValue, setInputValue] = useState<string>("")
    const [lenghtInput, setLenghtInput] = useState<number>(0);
    const [dropdownWidth, setDropdownWidth] = useState<number | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const toggleIsOpen = () =>{
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        if (inputRef.current) {
            setDropdownWidth(inputRef.current.offsetWidth);
        }
    }, [inputRef]);


    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    
    return (
        <div ref={wrapperRef}>
            <div className={classes.inputContainer}>  
                <input
                    value={inputValue}
                    className="form-control"
                    placeholder={placeholder} 
                    onChange={(e : ChangeEvent<HTMLInputElement>) => {
                        setInputValue(e.target.value);
                        setLenghtInput(e.target.value.length);
                        setIsOpen(true);
                        if(e.target.value.length >= minLength) {
                            onSearch(e.target.value)
                        }
                    }}
                    onClick={toggleIsOpen}
                    ref={inputRef}
                />
            </div> 
            {(lenghtInput >= 3 && options.length !==0 && isOpen) && 
                <ul className={classes.dropdown} 
                    style={{ width: dropdownWidth ? `${dropdownWidth}px` : 'auto'}}>
                    {options.map((option, index) => (
                        <li 
                            key={index}
                            onClick={() => {
                                onClickOption(option)
                                setIsOpen(false)
                                setInputValue("")
                            }}>
                                {option.productName + ' - ' + option.color?.label}
                        </li>
                    ))}
                </ul>
            }     
        </div>
    );
};

export default TypeaheadInput;