import HeaderTitle from '../HeaderTitle'
import { ArrowDownIcon, CurrencyEuroIcon } from '@heroicons/react/outline'
import React from 'react'
import { useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import SkeletonBase from 'components/Skeleton/SkeletonBase'

const OrderPayments: React.FC = () => {
  const { id } = useParams<ParamType>()

  const GET_ORDER_PAYMENTS_BALANCE = gql`
    query GET_ORDER_PAYMENTS_BALANCE($id: Int!) {
      CQgetOrder(id: $id) {
        edges {
          node {
            calculators {
              totalPaidAmount
            }
          }
        }
      }
    }
  `
  const { loading, error, data } = useQuery(GET_ORDER_PAYMENTS_BALANCE, {
    variables: {
      id: +id,
    },
  })

  if (loading) return <SkeletonBase variant="px-4" />
  if (error) return <p>`Error! ${error.message}`</p>

  return (
    <div className="bg-white mt-3 d-flex flex-column justify-content-between">
      <div className="d-flex flex-column">
        <HeaderTitle
          title="Suivi des paiements"
          children={<CurrencyEuroIcon className="header-title-icon" />}
        />
        <span className="text-secondary">
          Balance des paiements :{' '}
          {(+data.CQgetOrder.edges[0].node.calculators.totalPaidAmount).toFixed(2)} €
        </span>
      </div>
      <div>
        <div className="mt-3">
          <a href="#tableAccounting">Historique des paiements</a>
          <ArrowDownIcon className="header-title-icon text-secondary" />
        </div>
      </div>
    </div>
  )
}

export default OrderPayments
