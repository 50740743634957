import React, { useRef } from 'react'
import { Button, Form } from 'react-bootstrap'

interface Props {
  createOrderEvent: (comment: string) => void
  hideForm: () => void
  mutationLoading: boolean
}
const EventForm: React.FC<Props> = ({
  createOrderEvent,
  hideForm,
  mutationLoading,
}) => {
  const commentRef = useRef<HTMLTextAreaElement>(null)

  return (
    <div className="AlertForm">
      <Form
        onSubmit={(e) => {
          e.preventDefault()
          if (commentRef.current === null || !commentRef.current.value) {
            alert('Veuillez saisir un commentaire')
            return
          }
          createOrderEvent(commentRef.current.value)
        }}
      >
        <div className="d-flex flex-column justify-content-center aligns-items-center m-2">
          <textarea
            className="form-control mb-2"
            ref={commentRef}
            placeholder="Saisissez votre commentaire ici"
          ></textarea>

          {/* TODO : gérer l'appui sur la touche entrée pour envoyer automatiquement le commentaire ?? */}

          <div className="d-flex justify-content-end align-items-center">
            <Button
              variant="outline-secondary p-1 s-text btn-sm"
              onClick={hideForm}
              disabled={mutationLoading}
            >
              Annuler
            </Button>
            <Button
              variant="outline-primary p-1 s-text ms-2 btn-sm"
              type="submit"
              disabled={mutationLoading}
            >
              Ajouter
            </Button>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default EventForm
