import React from 'react'
interface Props {
  className: string
  text?: string
  handleClick: () => void
}
const DOButton: React.FC<Props> = ({ className, text, handleClick }) => {
  return (
    <span className={'btn ' + className} onClick={handleClick}>
      {text}
    </span>
  )
}

export default DOButton
