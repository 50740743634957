import React from 'react'
import { gql, useQuery } from '@apollo/client'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import { Container, Form, Row } from 'react-bootstrap'

interface Props {
  selectedLensReplacement: any
  setSelectedLensReplacement: React.Dispatch<React.SetStateAction<any>>
  label?: boolean
}
const LensReplacementForm: React.FC<Props> = ({
  selectedLensReplacement,
  setSelectedLensReplacement,
  label,
}) => {
  const { REACT_APP_PIMCORE_URL_DOMAIN } = process.env

  const GET_DO_LENS_REPLACEMENT_LISTING = gql`
    query GET_DO_LENS_REPLACEMENT_LISTING {
      getDOLensReplacementListing {
        edges {
          node {
            id
            name
            imageUrls
            images {
              image {
                fullpath
              }
            }
          }
        }
      }
    }
  `

  const {
    loading,
    error,
    data: lensReplacementData,
  } = useQuery(GET_DO_LENS_REPLACEMENT_LISTING, {
    onCompleted: (data) => {
      setSelectedLensReplacement(data.getDOLensReplacementListing.edges[0])
    },
  })

  if (loading) return <SkeletonBase count={4} variant="px-4 pt-1 mt-4" />
  if (error) return <p>`Error! ${error.message}`</p>

  const options = lensReplacementData.getDOLensReplacementListing.edges.map(
    (elem: any) => {
      const lensReplacement = elem.node

      return (
        <option value={lensReplacement.id} key={lensReplacement.id}>
          {lensReplacement.name}
        </option>
      )
    }
  )

  return (
    <Container className="mt-3">
      <Row style={{ fontSize: '14px' }}>
        <Form.Group
          className="d-flex justify-content-center"
          controlId="editLensReplacementForm"
        >
          {label && (
            <Form.Label style={{ width: '150px' }} className="me-2">
              <b>Type de monture</b>
            </Form.Label>
          )}

          <Form.Select
            style={{ width: '390px' }}
            id="editLensReplacementForm"
            aria-label="Lens replacement select"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setSelectedLensReplacement(
                lensReplacementData.getDOLensReplacementListing.edges[
                  e.target.selectedIndex
                ]
              )
            }}
          >
            {options}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row className="d-flex mt-4 justify-content-center align-items-center">
        <img
          src={
            selectedLensReplacement
              ? selectedLensReplacement.node.images
                ? REACT_APP_PIMCORE_URL_DOMAIN +
                  selectedLensReplacement.node.images[0].image.fullpath
                : JSON.parse(selectedLensReplacement?.node.imageUrls).find(
                    (obj: any) => obj.order === 1
                  ).url
              : lensReplacementData.getDOLensReplacementListing.edges[0].node
                  .images
              ? REACT_APP_PIMCORE_URL_DOMAIN +
                lensReplacementData.getDOLensReplacementListing.edges[0].node
                  .images[0].image.fullpath
              : JSON.parse(
                  lensReplacementData.getDOLensReplacementListing.edges[0].node
                    .imageUrls
                ).find((obj: any) => obj.order === 1).url
          }
          alt="type de monture"
          className="h-100"
          style={{
            width: '140px',
            objectFit: 'contain',
            objectPosition: 'center',
          }}
        />
      </Row>
    </Container>
  )
}

export default LensReplacementForm
