import { gql } from '@apollo/client'
import { DO_ORDER_EVENT_FIELDS } from 'graphql/fragments/DOOrderEventFields'

const BULK_UPDATE_DO_ORDERLINES = gql`
  ${DO_ORDER_EVENT_FIELDS}

  mutation UPDATE_DO_ORDERLINES(
    $orderLineIdList: String!
    $stateType: String!
    $comment: String!
  ) {
    bulkUpdateDOOrderLines(
      orderLineIdList: $orderLineIdList
      stateType: $stateType
      comment: $comment
    ) {
      success
      output {
        updateSuccessOrderLinesIds
        updateFailOrderLinesIds
        order {
          id
          events {
            ...DOOrderEventFields
          }
          orderLines {
            ... on object_DOOrderLine {
              id
              status
              state
              stockState
              items {
                ... on object_DOOrderLineItem {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`

export default BULK_UPDATE_DO_ORDERLINES
