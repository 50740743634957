import { gql } from '@apollo/client'
import { DO_VOUCHER_TEMPLATE_FIELDS } from 'graphql/fragments/DOVoucherTemplateFields'

export const GET_DO_VOUCHER_TEMPLATE_LISTING = gql`
  ${DO_VOUCHER_TEMPLATE_FIELDS}
  query GET_DO_VOUCHER_TEMPLATE_LISTING($filter: String, $withoutNewVoucherCode: Boolean = false) {
    getDOVoucherTemplateListing(filter: $filter) {
      edges {
        node {
          ...DOVoucherTemplateFields
        }
      }
      totalCount
    }
  }
`
