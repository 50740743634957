import React, { ChangeEvent, useContext, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { EyeIcon } from '@heroicons/react/outline'
import DOModal from 'components/Modal/DOModal'
import EventEmailPrevisualisationModal from '../Modal/EvenEmailPrevisualisationModal'
import GlobalContext from 'store/global-context'

interface Props {
  eventType: eventTypeType
  onUpdate: (e: {
    key: string
    checked: boolean
    data: {
      sentEmail?: boolean | null
      comment?: string | null
      emailVariable?: string | null
      orderEventsToClose?: eventTypeType[]
    }
  }) => any
}

const EventShortcutCommentAndMail: React.FC<Props> = ({
  eventType,
  onUpdate,
}) => {
  const global = useContext(GlobalContext) 
  const siteDomainSuffix = "/"+ global.siteDomainSuffix
  const [mailInfo, setMailInfo] = useState<string>('')
  const [showPrevisualisation, setShowPrevisualisation] =
    useState<boolean>(false)

  const mailInfoChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    onUpdate({
      key: eventType.key,
      checked: true,
      data: { emailVariable: e.target.value },
    })
    setMailInfo(e.target.value)
  }

  return (
    <>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2">
          Commentaire
        </Form.Label>
        <Col sm="8">
          <Form.Control
            as="textarea"
            className="border border-2 rounded shadow"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onUpdate({
                key: eventType.key,
                checked: true,
                data: { comment: e.target.value },
              })
            }}
          />
        </Col>
      </Form.Group>

      {eventType.emailToSend !== null && !!eventType.emailToSend?.length && (
        <>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Mail
            </Form.Label>
            <Col sm="8">
              <Form.Control
                as="textarea"
                className="border border-2 rounded shadow"
                onChange={mailInfoChangeHandler}
              />
            </Col>
          </Form.Group>
          <Row>
            <Col sm="4">
              <Form.Check
                type="checkbox"
                label="Envoyer le mail"
                className="float-end mt-2"
                value="yes"
                defaultChecked={true}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  onUpdate({
                    key: eventType.key,
                    checked: true,
                    data: {
                      sentEmail: (e.target as HTMLInputElement)?.checked
                        ? true
                        : false,
                    },
                  })
                }}
              />
            </Col>
            <Col>
              <div className="ms-2">
                <EyeIcon className="header-title-icon custom-blue-link ms-2" />
                <Button
                  variant="link"
                  onClick={() => setShowPrevisualisation(true)}
                >
                  Prévisualiser
                </Button>
              </div>
            </Col>
          </Row>
          <DOModal
            show={showPrevisualisation}
            customSize="modal-647"
            closeModal={() => setShowPrevisualisation(false)}
          >
            <EventEmailPrevisualisationModal
              template={siteDomainSuffix + eventType.emailToSend}
              mailInfo={mailInfo}
            />
          </DOModal>
        </>
      )}
    </>
  )
}

export default EventShortcutCommentAndMail
