import { gql } from '@apollo/client'
import { DO_ACCESSORY_FIELDS } from 'graphql/fragments/DOAccessoryFields'
import { DO_FRAME_FIELDS } from 'graphql/fragments/DOFrameFields'

export const GET_DO_PRODUCT_STOCK = gql`
  ${DO_FRAME_FIELDS}
  ${DO_ACCESSORY_FIELDS}
  query GET_DO_PRODUCT_STOCK($eanOrIdmcList: [String]!, $stockLocation: String, $name: String) {
    getDOProductStock(eanOrIdmcList: $eanOrIdmcList, stockLocation: $stockLocation, name: $name) {
        edges {
            node {
                idmcList
                idProductV4
                frame {
                    ...DOFrameFields
                }
                accessory{
                  ...DOAccessoryFields
                }
            }
        }
        totalCount
    }
  }
`
