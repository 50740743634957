import { useQuery } from '@apollo/client'
import OrderListToggle from 'components/OrderListToggle'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import Card from 'components/UI/Card'
import ResultsTable from 'components/UI/ResultsTable'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  stringsToJSXs,
  getFormattedDate,
} from 'utils/functions'
import { constants } from 'utils/constants'
import GET_ORDER_BY_PAYMENT_EVENT_TYPE_LISTING from 'graphql/queries/getDOOrderByPaymentEventTypeListing'

interface Props {}

const CommandesAttenteRemboursement: React.FC<Props> = () => {
  const { t } = useTranslation()

  const { data, error, loading } = useQuery(
    GET_ORDER_BY_PAYMENT_EVENT_TYPE_LISTING,
    {
      variables: {
        orderEventTypeKeys: [
          constants.eventTypeKeys.ORDER_EVENT_TYPE_TO_REFUND,
        ],
      },
    }
  )

  if (loading)
    return (
      <Card>
        <SkeletonBase count={5} />
      </Card>
    )
  if (error) return <p>`Error! ${error.message}`</p>

  const formattedData: any = []

  data.getDOOrderByPaymentEventTypeListing.edges.forEach(
      ({ node: originalNode }: { node: any }, index: number) => {
      const item = { ...originalNode }
      item.node = {}
      item.node.id = item.orderId
      item.node.orderKey = item.orderKey
      item.node.orderKey = (
        <a
          href={`/bo/relation_client/gestion_commande_client/${item.orderId}`}
          target="_blank"
          rel="noreferrer"
          onClick={(e: any) => e.stopPropagation()}
        >
          {item.orderKey}
        </a>
      )
      item.node.fullName = item.customerFullname
      item.node.comment = item.orderComment

      item.node.creationDate = item.orderCreationDate
        ? getFormattedDate(item.orderCreationDate * 1000)
        : ''

      item.node.requestDate = item.eventCreationDate
        ? getFormattedDate(item.eventCreationDate * 1000)
        : ''

      item.node.firstPaymentMethodLabel = item.paymentMethodLabel

      item.node.orderTotalAmount = (+item.totalAmount).toFixed(2)
      
      item.node.amountToRefund = !isNaN(item.eventMailInfo)
        ? (+item.eventMailInfo).toFixed(2)
        : ''

      item.node.amountToRefundDisplay = item.node.amountToRefund

      // Pass the toggle component itself
      item.node.toggleComponent = OrderListToggle

      item.node.eventTypeKey = item.eventTypeKey

      formattedData.push(item)
    }
  )

  return (
    <div data-cy="refundResult">
      <Card>
        <ResultsTable
          items={formattedData}
          queryType="Commandes en attente de remboursement"
          columns={stringsToJSXs(
            [
              'orderKey',
              'fullName',
              'comment',
              'creationDate',
              'requestDate',
              'firstPaymentMethodLabel',
              'orderTotalAmount',
              'amountToRefundDisplay',
            ],
            t
          )}
        ></ResultsTable>
      </Card>
    </div>
  )
}

export default CommandesAttenteRemboursement
