import gql from 'graphql-tag'

const GET_TOTAL_ORDERS_BY_ORDER_EVENT_TYPE_LISTING = gql`
  query GET_TOTAL_ORDERS_BY_ORDER_EVENT_TYPE_LISTING($fetchTotals: Boolean) {
    getTotalOrdersByOrderEventTypeListing(fetchTotals: $fetchTotals) {
      edges {
        node {
          id
          orderEventTypeCategoryId
          orderEventTypeCategoryLabel
          orderEventTypeId
          orderEventTypeLabel
          totalOrders
        }
      }
    }
  }
`

export default GET_TOTAL_ORDERS_BY_ORDER_EVENT_TYPE_LISTING
