import { gql } from '@apollo/client'

import { DO_PRESCRIPTION_FIELDS } from 'graphql/fragments/DOPrescriptionFields'

export const GET_DO_BENEFICIARY = gql`
  ${DO_PRESCRIPTION_FIELDS}
  query GET_DO_BENEFICIARY($id: Int) {
    getDOBeneficiary(id: $id) {
      id
      prescriptions {
        ...DOPrescriptionFields
      }
    }
  }
`
