import { gql } from '@apollo/client'
import { DO_CONTACT_NAME_FC_FIELDS } from 'graphql/fragments/DOContactNameFCFields'
import { DO_VISION_CORRECTION_FC_FIELDS } from './DOVisionCorrectionFCFields'

export const DO_BENEFICIARY_FIELDS = gql`
  ${DO_CONTACT_NAME_FC_FIELDS}
  ${DO_VISION_CORRECTION_FC_FIELDS}
  fragment DOBeneficiaryFields on object_DOBeneficiary {
    id
    birthDate
    name {
      ...DOContactNameFCFields
    }
    prescriptions {
      ... on object_DOPrescription {
        id
        prescriptionDate
        prescriberFullname
        rightEyeCorrections {
          ...DOVisionCorrectionFCFields
        }
        leftEyeCorrections {
          ...DOVisionCorrectionFCFields
        }
      }
    }
  }
`
