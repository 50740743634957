import { gql } from '@apollo/client'

export const GET_DO_STOCK_TRACKING_CUSTOM_LISTING = gql`
  query getDOStockTrackingCustomListing($filter: String!) {
    getDOStockTrackingCustomListing(filter: $filter) {
      edges {
        node {
          id 
          gtin
          name
          stockTracking {
            ...on object_DOStockTracking {
              id
              creationDate
              comment
              origin
              destination
              availableQuantity {
                ... on fieldcollection_DOStockTrackingFC {
                  before
                  after
                  quantity
                }
              }
              reservedQuantity {
                ... on fieldcollection_DOStockTrackingFC {
                  before
                  after
                  quantity
                }
              }
              wipQuantity {
                ... on fieldcollection_DOStockTrackingFC {
                  before
                  after
                  quantity
                }
              }
              essdomQuantity {
                ... on fieldcollection_DOStockTrackingFC {
                  before
                  after
                  quantity
                }
              }
              user {
                ... on object_DOUser {
                  key
                }
              }
              orderLineItem {
                ... on object_DOOrderLineItem {
                  item {
                    ... on fieldcollection_DOOLItemFrameFC {
                      frame {
                        ... on object_DOFrame {
                          name
                          gtin
                          color {
                            ... on object_DOColor {
                              label
                            }
                          }
                        }
                      }
                    }
                    ... on fieldcollection_DOOLItemAccessoryFC {
                      accessory {
                        ... on object_DOAccessory {
                          name
                            gtin
                            color {
                              ... on object_DOColor {
                                label
                              }
                            }
                        }
                      }
                    }
                  }
                  orderLine {
                    ... on object_DOOrderLine {
                      order {
                        ... on object_DOOrder {
                          key
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      totalCount
    }
  }
`
