import { gql } from '@apollo/client'
import { DO_ORDER_EVENT_FIELDS } from 'graphql/fragments/DOOrderEventFields'

const UPDATE_BENEFICIARY_AND_LENSES = gql`
  ${DO_ORDER_EVENT_FIELDS}
  mutation UPDATE_BENEFICIARY_AND_LENSES(
    $orderId: Int!
    $replacementBeneficiaryId: Int
    $orderLines: String
    $jsonNewBeneficiary: String #json with newBeneficiary lastName, firstName, birthdate and gender
    $orderLineIdToChange: Int
    $prescriptionId: Int
    $jsonLenses:String #json with righ and/or left lens and price
  ) {
    updateBeneficiaryAndLenses(
      orderId: $orderId
      replacementBeneficiaryId: $replacementBeneficiaryId
      orderLines: $orderLines
      jsonNewBeneficiary:$jsonNewBeneficiary
      orderLineIdToChange: $orderLineIdToChange
      prescriptionId: $prescriptionId
      jsonLenses: $jsonLenses
    ) {
      output {
        order {
          id
          customer {
            ... on object_DOCustomer {
              id
              beneficiaries {
                ... on object_DOBeneficiary {
                  id
                  name {
                    ... on fieldcollection_DOContactNameFC {
                      firstName
                      lastName
                      civility
                    }
                  }
                  birthDate
                }
              }
            }
          }
          events {
            ...DOOrderEventFields
          }
          orderLines {
            ... on object_DOOrderLine {
              id
              items {
                ... on object_DOOrderLineItem {
                  id
                  item {
                    ... on fieldcollection_DOOLItemLensFC {
                      lens {
                        ... on object_DOLens {
                          id
                        }
                      }
                    }
                  }
                }
              }
              prescription {
                ... on object_DOPrescription {
                  id
                  beneficiary {
                    id
                    name {
                      ... on fieldcollection_DOContactNameFC {
                        firstName
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export default UPDATE_BENEFICIARY_AND_LENSES
