import React from 'react'
interface Props {}
const PageNotFound: React.FC<Props> = () => {
  return (
    <div className="text-center">
      <h1>404</h1>
      <h3>Page non trouvée</h3>
      <p>La page que vous avez demandée n'existe pas</p>
    </div>
  )
}

export default PageNotFound
