import { useQuery } from '@apollo/client'
import { SparklesIcon, ExclamationIcon } from '@heroicons/react/outline'
import React, { useContext, useState } from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { constants } from 'utils/constants'
import HeaderTitle from './HeaderTitle'
import DOModal from 'components/Modal/DOModal'
import OrderCosiumAllInModal from 'components/Modal/OrderCosiumAllInModal'
import OrderNantesToKehlTransferModal from 'components/Modal/OrderNantesToKehlTransferModal'
import SkeletonBase from './Skeleton/SkeletonBase'
import Card from './UI/Card'
import OrderReadyToShipModal from './Modal/OrderReadyToShipModal'
import AuthContext from 'store/auth-context'
import OrderReturnToVerificationModal from './Modal/OrderReturnToVerificationModal'
import { CQ_GET_ORDER } from 'graphql/queries/getOrder'

interface Props {
  closeModal?: () => void
}

const SpecialEventShortcuts: React.FC<Props> = ({ closeModal }) => {
  const { id } = useParams<ParamType>()
  const { t } = useTranslation()
  const authCtx = useContext(AuthContext)

  const {
    ORDER_EVENT_TYPE_KEHL_READY_TO_ASSEMBLE,
    ORDER_EVENT_TYPE_RETURN_TO_VERIFICATION,
    ORDER_EVENT_TYPE_ORDER_TO_CREATE_IN_COSIUM,
  } = constants.eventTypeKeys

  const [showCosiumAmountCheckModal, setShowCosiumAmountCheckModal] =
    useState(false)

  const [showNantesToKehlTransferModal, setShowNantesToKehlTransferModal] =
    useState(false)
  
  const [showReadyToShipModal, setShowReadyToShipModal] =
    useState(false)
  const [showReturnToVerificationModal, setShowReturnToVerificationModal] =
    useState(false)

  const [isMutationLoading, setIsMutationLoading] = useState<boolean>(false)
  
  const initAllStates = () => {
    setShowCosiumAmountCheckModal(true)
  }

  const { loading: orderLoading, data: orderData, error } = useQuery(
    CQ_GET_ORDER,
    {
      variables: {
        id: +id,
      },
    }
  )

  const displayReadToAssemleBtn = ['created', 'awaiting_payment', 'in_verification' ].includes(orderData?.CQgetOrder.edges[0].node.order.workflowState)

  if (orderLoading)
    return (
      <Card>
        <SkeletonBase count={2} variant="px-4 pt-1 mt-4" />
      </Card>
    )
  if (error) return <p>`Error! ${error.message}`</p>

  const order = orderData.CQgetOrder.edges[0].node.order
  const calculators = orderData.CQgetOrder.edges[0].node.calculators
  const orderToCreateInCosiumOpenEventList = order?.events.filter(
    (e: orderEvent) =>
      e.closureDate === null &&
      ORDER_EVENT_TYPE_ORDER_TO_CREATE_IN_COSIUM === e.eventType.key
  )
  
  // KEHL READY TO ASSEMBLE
  const isReadyToAssembleButtonDisabled =
    !calculators.cosiumId ||
    order.totalAmountHistory === null ||
    order.workflowState !== 'in_verification' ||
    orderToCreateInCosiumOpenEventList.length > 0
  // RETURN TO VERIFICATION
  const isReturnToVerifcationButtonDisabled =
    order.workflowState !== 'awaiting_preparation'

  // NANTES TO KEHL TRANSFER
  const isNantesToKehlTransferButtonDisabled =
    order.workflowState !== 'completed' &&
    !calculators.hasLensReplacement

  // READY TO SHIP 
  const isReadyToShipButtonDisabled =
    (authCtx.user.roles[0] !== 'logistique_nantes' &&
    authCtx.user.fullName !== 'admin')|| 
    (order.workflowState !== 'in_verification' &&
    order.workflowState !== 'awaiting_preparation')

  const getTooltip = () => {
    if (displayReadToAssemleBtn)
    //KEHL READY TO ASSEMBLE
    return !calculators.cosiumId
    ? "Cette commande n'a pas de devis Cosium associé"
    : order.totalAmountHistory === null
    ? "Cette commande n'a pas de montant Cosium"
    : order.workflowState !== 'in_verification'
    ? "La commande n'est pas en vérification"
    : orderToCreateInCosiumOpenEventList.length > 0
    ? "La commande n'a pas été reportée sur Cosium"
    : ''
    // RETURN TO VERIFICATION
    else if (order.workflowState !== 'awaiting_preparation')
      return "La commande n'est pas en attente de préparation"
    return ''
  }
  return (
    <div className="custom-card py-3 mb-4">
      <div className="px-3">
        <HeaderTitle
          title="Statuts particuliers"
          children={<SparklesIcon className="header-title-icon" />}
        />
      </div>
      <div className="px-3">
        {order.orderType === 'panier' &&
          <OverlayTrigger
            placement="bottom"
            overlay={(displayReadToAssemleBtn? isReadyToAssembleButtonDisabled : isReturnToVerifcationButtonDisabled) ?
              <Tooltip id="tooltip-disabled">{getTooltip()}</Tooltip>: <></>
            }
          >
            <span className="d-inline-block">
              {displayReadToAssemleBtn ?
                <Button
                  data-cy="atelierKehl"
                  disabled={isReadyToAssembleButtonDisabled || showCosiumAmountCheckModal || isMutationLoading}
                  onClick={() => {
                    initAllStates()
                    setShowCosiumAmountCheckModal(true)
                  }}
                >
                  {t('orderEventType.' + ORDER_EVENT_TYPE_KEHL_READY_TO_ASSEMBLE)}
                </Button> :
                 <Button
                  data-cy="atelierKehl"
                  disabled={isReturnToVerifcationButtonDisabled || showReturnToVerificationModal || isMutationLoading}
                  onClick={() => {setShowReturnToVerificationModal(true)}}
                >
                  {t('orderEventType.' + ORDER_EVENT_TYPE_RETURN_TO_VERIFICATION)}
                </Button>
                }

            </span>
          </OverlayTrigger>
        }
        {/* Tooltip NANTES TO KEHL TRANSFER */}
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="tooltip-disabled">
              <ExclamationIcon className="header-title-icon" />
              Action par une personne de Nantes uniquement !
            </Tooltip>
          }
        >
          <span className="d-inline-block">
            <Button
              className="ms-2"
              disabled={isNantesToKehlTransferButtonDisabled}
              onClick={() => setShowNantesToKehlTransferModal(true)}
            >
              {t('nantesToKehlTransfer')}
            </Button>
          </span>
        </OverlayTrigger>
        {/* Tooltip READY TO SHIP */}
        <OverlayTrigger
          placement="bottom"
          overlay={
            isReadyToShipButtonDisabled ? (
              <Tooltip id="tooltip-disabled">
                <ExclamationIcon className="header-title-icon" />
                Action par une personne de Nantes uniquement !
              </Tooltip>
            ) : (
              <></>
            )
          }
        >
          <span className="d-inline-block">
            <Button
              className="ms-2"
              disabled={isReadyToShipButtonDisabled}
              onClick={() => setShowReadyToShipModal(true)}
            >
              {t('readyToShip')}
            </Button>
          </span>
        </OverlayTrigger>
      </div>

      <DOModal
        show={showCosiumAmountCheckModal}
        customSize="modal-647"
        closeModal={() => setShowCosiumAmountCheckModal(false)}
      >
        <OrderCosiumAllInModal
          order={order}
          calculators={calculators}
          isReportedInCosium={true}
          isOnReadyToAssembleModal={true}
          closeModal={() => setShowCosiumAmountCheckModal(false)}
          setIsMutationLoading={setIsMutationLoading}
        />
      </DOModal>

      <DOModal
        show={showNantesToKehlTransferModal}
        customSize="modal-580"
        closeModal={() => setShowNantesToKehlTransferModal(false)}
      >
        <OrderNantesToKehlTransferModal
          orderId={+id}
          isFromWorkflowPlaceCompleted={
            order.workflowState === 'completed'
          }
          closeModal={() => setShowNantesToKehlTransferModal(false)}
        />
      </DOModal>
      <DOModal
        show={showReadyToShipModal}
        customSize="modal-580"
        closeModal={() => setShowReadyToShipModal(false)}
      >
        <OrderReadyToShipModal
          orderId={+id}
          worflowSate={order.workflowState}
          closeModal={() => setShowReadyToShipModal(false)}
        />
      </DOModal>
       <DOModal
        show={showReturnToVerificationModal}
        customSize="modal-580"
        closeModal={() => setShowReturnToVerificationModal(false)}
      >
        <OrderReturnToVerificationModal
          orderId={+id}
          eventTypeKey={ORDER_EVENT_TYPE_RETURN_TO_VERIFICATION}
          closeModal={() => setShowReturnToVerificationModal(false)}
          setIsMutationLoading={setIsMutationLoading}
        />
      </DOModal>
      
    </div>
  )
}

export default SpecialEventShortcuts
