import { InformationCircleIcon } from '@heroicons/react/outline'
import HeaderTitle from '../HeaderTitle'
import CopyableCell from '../../utils/CopyableCell'
interface Props {
  orderBeneficiaryBirthdate: any
  orderBeneficiaryContactName: any
  orderBeneficiaryContactInfo: any
  isTrueBeneficiary: boolean
}

//TODO : revoir VOUCHER / AVOIR par rapport aux states des items
const OrderBeneficiaryInfo: React.FC<Props> = ({
  orderBeneficiaryBirthdate,
  orderBeneficiaryContactName,
  orderBeneficiaryContactInfo,
  isTrueBeneficiary
}) => {
  return (
    <div className="modalInfo">
      <div>
        <HeaderTitle
          title={`Informations ${isTrueBeneficiary ? 'Bénéficiaire' : 'Client'}`}
          children={<InformationCircleIcon className="header-title-icon" />}
        />
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td>
                <strong>Nom :</strong>
              </td>
              <CopyableCell>
                {orderBeneficiaryContactName?.lastName}
              </CopyableCell>
            </tr>
            <tr>
              <td>
                <strong>Prénom :</strong>
              </td>
              <CopyableCell>
                {orderBeneficiaryContactName?.firstName}
              </CopyableCell>
            </tr>
            <tr>
              <td>
                <strong>Né(e) le :</strong>
              </td>
              <CopyableCell>
                {new Intl.DateTimeFormat('fr', {
                  dateStyle: 'short',
                }).format(new Date(orderBeneficiaryBirthdate))}
              </CopyableCell>
            </tr>
            <tr>
              <th>Tél Port. :</th>
              <CopyableCell>
                {orderBeneficiaryContactInfo?.mobilePhoneNumber}
              </CopyableCell>
            </tr>
            <tr>
              <th>Tél Dom. :</th>
              {orderBeneficiaryContactInfo?.landlinePhoneNumber && (
                <CopyableCell>
                  {orderBeneficiaryContactInfo?.landlinePhoneNumber}
                </CopyableCell>
              )}
            </tr>
            <tr>
              <th>E-mail :</th>
              <CopyableCell>{orderBeneficiaryContactInfo?.email}</CopyableCell>
            </tr>
            <tr>
              <th>Adresse :</th>
              <CopyableCell>
                {orderBeneficiaryContactInfo?.address +
                  ' ' +
                  orderBeneficiaryContactInfo?.postalCode +
                  ' ' +
                  orderBeneficiaryContactInfo?.city}
              </CopyableCell>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default OrderBeneficiaryInfo
