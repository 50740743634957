import { gql } from '@apollo/client'

const CREATE_DO_ORDER_EVENT_FOR_ORDERS = gql`
  mutation CREATE_DO_ORDER_EVENT_FOR_ORDERS(
    $eventTypeKey: String!
    $ordersAndComments: [OrderIdAndCommentObject]!
  ) {
    createDOOrderEventForOrders(
      eventTypeKey: $eventTypeKey
      ordersAndComments: $ordersAndComments
    ) {
      success
      output {
        creationSuccessOrderKeys
        creationFailOrderKeys
        creationFailOrderCosiumIds
        creationSuccessOrderCosiumIds
      }
    }
  }
`

export default CREATE_DO_ORDER_EVENT_FOR_ORDERS
