import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
const resources = {
  fr: {
    translation: {
      firstName: 'Prénom',
      lastName: 'Nom',
      birthDate: 'Date de naissance',
      password:'Mot de passe',
      montures: 'Montures',
      // workflowState
      created:'Création de la commande',
      awaiting_payment:'En attente de paiement',
      in_verification: 'En vérification',
      awaiting_preparation: 'En attente de préparation',
      in_preparation: 'En préparation',
      delivery_in_progress: 'En cours de livraison',
      trial_in_progress:'Essdom en cours',
      completed:"Terminée",
      aftersales_check: 'Prise en charge SAV',
      cancelled: 'Annulée',
     
      translatedWorkflowState: 'Statut',
      fullName: 'Prénom Nom',
      orderPaidDate: 'Date de paiement',
      phoneNumber: 'Téléphone',
      orderSentToStoreDate: "Date d'envoi au magasin",
      arrivedAtStore: 'Arrivée au magasin ?',
      deliveryToCustomer: 'Retrait par le client',
      orderArrivedAtStoreDate: 'Date Livraison Magasin',
      arrivedSince: 'Depuis',
      mailsSentCount: 'Mails envoyés',
      orderId: 'ID Cde',
      orderKey: 'N° de commande',
      cosiumId: 'N° de devis Cosium',
      products: 'produits',
      'tout-produit': 'Tout produit',
      'toute-monture': 'Toute monture',
      'avec-verres-correcteurs': 'Avec verres correcteurs',
      'avec-verres-correcteurs-non-teintes':
        'Avec verres correcteurs non teintés',
      'avec-verres-correcteurs-teintes': 'Avec verres correcteurs teintés',
      platinum: 'Platinum',
      gold: 'Gold',
      silver: 'Silver',
      blacklist: 'Blacklist',
      others: 'Autres',
      birthdate: 'Date de naissance',
      email: 'Email',
      phone: 'Téléphone',
      landlinePhoneNumber: 'Téléphone',
      mobilePhoneNumber: 'Mobile',
      postalCode: 'Code Postal',
      fr: 'Français',
      es: 'Espagnol',
      it: 'Italien',
      country: 'Pays',
      language: 'Langue',
      civility: 'Civilité',
      madame: 'Madame',
      monsieur: 'Monsieur',

      line: 'Ligne',
      creation: 'Création',
      closure: 'Clôture',
      status: 'Statut',
      comment: 'Commentaire',
      mailInfo: 'Info mail',
      sentEmail: 'Mail envoyé',
      creationDate: 'Date de création',
      requestDate: 'Demandé le',
      orderTotalAmount: 'Total Cde (€)',
      amountToRefundDisplay: 'Mt à rembourser (€)',
      amountToPay: 'Mt restant dû (€)',
      firstPaymentMethodLabel: '1er mode de paiement',

      with_count_lens: '(Avec {{count}} verre)',
      with_count_lens_plural: '(Avec {{count}} verres)',

      get_age: '{{count}} an',
      get_age_plural: '{{count}} ans',

      eyeSide: {
        oeil_gauche: 'OG',
        oeil_droit: 'OD',
      },
      product: '{{count}} produit',
      product_plural: '{{count}} produits',

      orderEventType: {
        // Utilisé dans EventList
        Alert: 'une alerte',
        AssemblyInformation: 'une information de montage',
        // Utilisé dans PickingClients
        KehlWorkshop_ReadyToAssemble: 'Atelier Kehl - Prêt à monter',
        ReturnToVerification:'Retour en vérification',
        InPreparationAtKehl: 'En cours de préparation',
        PickingKehl_HomeTrial: 'Picking Kehl - Essai à domicile',
        HomeTrialPicking_InPreparation: 'En cours de préparation',
      },
      // Cas particulier d'un bouton pouvant passer deux events différents
      nantesToKehlTransfer: 'Transfert monture Nantes > Kehl',
      readyToShip:'Prêt à être expédié',
      voucher: {
        state: {
          disponible: 'Disponible',
          utilise: 'Utilisé',
          annule: 'Annulé',
          desactive: 'Désactivé',
          expire: 'Expiré',
        },
        generique: 'Générique',
        unique: 'Unique',
        personnel: 'Personnel',
        voucher_count: '{{count}} bon',
        voucher_count_plural: '{{count}} bons',
      },
      voucherPrefixedCode: 'Préfixe',
      genericCode: 'Code avantage',
      clientLabel: 'Libellé',
      prettyInitialAmount: 'Montant',
      prettyProductType: 'Produits autorisés',
      endDate: 'Fin de validité',
      voucherType: 'Type',
      active: 'Actif',
      label: 'Libellé',
      prettyDateEnd: 'Date expiration',
      voucherState: 'Etat',
      voucherCode: 'Code',
      amount: 'Montant',
      duration: 'Durée (jours)',

      orders_monitoring: {
        headers: {
          country: 'Pays',
          order_number: 'N° commande',
          creation_date: 'Date',
          customer_name: 'Nom',
          days_since_last_event: 'Depuis (j)',
          lens_focal: 'Type de verre',
          order_type: 'Type de commande',
          total_amount: 'Montant',
          comment: 'Commentaire',
          email: 'E-mail',
          phone: 'Téléphone',
          state: 'Etat',
          total_amount_variant: 'Total cde (€)',
          copy: 'Copie'
        },
      },

      // Picking clients
      lastEventDateFormatted: 'Date Statut',
      since: 'Depuis (j)',
      lastEventComment: 'Commentaire',
      message: {
        enumerate_order_key: 'Commande n°',
        enumerate_order_key_plural: 'Commandes n°',
        enumerate_order_key_exp_plural: 'Commandes :',
        fail_to_change_transition_to_considered:
          "La commande suivante n'a pas pu être mise en préparation : ",
        fail_to_change_transition_to_considered_plural:
          "Les commandes suivantes n'ont pas pu être mises en préparation : ",
      },
      // Picking essDom
      productsToDestock: 'Produits à déstocker',
      done: 'FAIT',
      frames: 'Montures',

      //pupillary distance source
      via_logiciel: 'via logiciel',
      ordo_scannee: 'ordonnance scannée',
      ancienne_paire: 'ancienne paire de lunettes',
      magasin_partenaire: 'magasin partenaire',
      deja_commande: 'déjà commandé',
      // prescription source
      telecharge: 'téléchargé',
      ordo_precedente: 'ordo précédente',
      // vision type
      vl: 'VL',
      vp: 'VP',
      vl_vp: 'VL + VP',
      non_renseigne: 'Non renseigné',
      plus_tard: 'Plus tard',
      // prescriptionForm
      prescriptionDate: 'Date de prescription',
      prescriberNumber: 'Numéro prescripteur',
      prescriberNIN: 'Numéro identification national',
      prescriberFullname: 'Nom Dr prescripteur',
      pupillaryDistanceSource: 'Mode de saisie écart pupillaire',
      pupillaryDistanceRight: 'Écart droit',
      pupillaryDistanceLeft: 'Écart gauche',
      rightSphere: 'Sphère',
      rightCylinder: 'Cylindre',
      rightAxis: 'Axe',
      rightAddition: 'Addition',
      leftSphere: 'Sphère',
      leftCylinder: 'Cylindre',
      leftAxis: 'Axe',
      leftAddition: 'Addition',
      prescriptionSource: 'Mode de saisie ordonnance',
      customerComment: 'Commentaire client',
      internalComment: 'Commentaire interne',
      prescriptionVisionType: 'Correction',
      pupillaryDistanceVisionType: 'Écart pupillaire',
      radioFrame: 'Monture',

      accountingStatus: 'Statut comptable',

      // DOFrameCategory rim
      cerclee: 'cerclée',
      demi_cerclee: 'demi-cerclée',

      status_count_fail: 'Vous ne pouvez pas passer ce statut',
      status_count_fail_plural: '{{count}} statuts non créés (vous ne pouvez pas les passer)',
      status_count_success: '{{count}} statut créé',
      status_count_success_plural: '{{count}} statuts créés',
      status_close_count_fail: '{{count}} statut non clôturé',
      status_close_count_fail_plural: '{{count}} statuts non clôturés',
      status_close_count_success: '{{count}} statut clôturé',
      status_close_count_success_plural: '{{count}} statuts clôturés',
      // monitoring nouveaux produits
      designation: "Désignation",
      importDate: "Date d'import",
      stockBac: "Stock en bac",
      location:"Emplacement",
      withPhotos: "Avec photos",
      isNew: "Nouveauté",
      // images produits
      progressiveCompatible: 'Compatible progressif',
      availableHomeTrial: 'Essai à domicile disponible',
      sale: 'En solde',
      destocking: 'Destockage',
      withSolarClip: 'Avec clip solaire'
    },
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'fr', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
