import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import AccordionMenu from '../components/AccordionMenu'
import Navbar from '../components/Navbar/Navbar'
import Home from './Home'
import { useState, useContext, useEffect } from 'react'
import buildUserRoutes from '../utils/buildUserRoutes'
import AuthContext from '../store/auth-context'
import LoginRoute from '../utils/LoginRoute'
import PageNotFound from './PageNotFound'

const Pages: React.FC = () => {
  const [openMenu, setOpenMenu] = useState(true)

  const authCtx = useContext(AuthContext)
  const isLoggedIn = authCtx.isLoggedIn

  let userRoutesContent = [<p>toto</p>]

  useEffect(() => {
    function handleResize() {
        window.innerWidth < 1200 ? setOpenMenu(false) : setOpenMenu(true)
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isLoggedIn) {
    userRoutesContent = buildUserRoutes(authCtx.menus)
  }

  return (
    <div className="pages">
      <Router>
        <Navbar />
        <div className="page">
          {isLoggedIn && (
            <>
              <div className="toggleAccordionMenu bg-white position-relative">
                {openMenu && <AccordionMenu />}
              </div>
              <div
                className={`AccordionMenuBtn bg-light position-fixed d-flex justify-content-center align-items-center rounded-circle shadow ${
                  !openMenu && 'AccordionMenu_close'
                }`}
              >
                <span
                  className="material-icons-outlined"
                  onClick={() => setOpenMenu(!openMenu)}
                >
                  {openMenu ? 'menu_open' : 'chevron_right'}
                </span>
              </div>
            </>
          )}

          <div className="page-container p-3">
            <Switch>
              {/* mettre des routes dans une condition provoque un warning => enlever le warning en mettant les routes dans un composant custom  */}
              {!isLoggedIn && <LoginRoute />}
              <Route path="/" exact>
                <div className="breadcrumb">
                  <div className="breadcrumb-item active">Home</div>
                </div>
                <Home />
              </Route>
              {isLoggedIn && userRoutesContent}
              <Route path="*">
                <PageNotFound />
              </Route>
            </Switch>
          </div>
        </div>
      </Router>
    </div>
  )
}
export default Pages
