import React, { useEffect, useState } from 'react';
import Indicator from './Indicator';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { useQuery } from '@apollo/client';
import { GET_INDICATORS } from 'graphql/queries/getIndicators';

const idc = [
    {
        id : 0,
        key: 'totalOnlineProducts',
        label: 'Total produits en ligne',
        value: -1,
    },
    {
        id : 1,
        key: 'tryAtHome',
        label: 'Essayables à domicile',
        value: -1,
    },
    {
        id: 2,
        key: 'outOfStock',
        label: 'Rupture de stock',
        value: -1,
    },
    {
        id: 3,
        key: 'outOfStockTryAtHome',
        label: 'Rupture de stock essdom',
        value: -1,
    },
    {
        id: 4,
        key: 'tryOnline',
        label: 'Essayables en ligne',
        value: -1,
    }
];

const Indicators: React.FC = () => {

    const [indicators, setIndicators] = useState(idc);

    const { loading: getIndicatorsLoading, data: getIndicatorsData } = useQuery(GET_INDICATORS)

    useEffect(() => {
        setIndicators(prevIndicators => {
            return prevIndicators.map((item) => {
                const key = item.key;
                const value = getIndicatorsData?.getIndicators[key];
                return { ...item, value: value };
            });
        });
    }, [getIndicatorsData])

    return (
        <>
            <Container className="gap-3" style={{ maxWidth: '960px' }}>
                <h2 className='fs-3 pb-3'>Indicateurs produits</h2>
                <Row>
                    {indicators.map((indicator) => (
                        <Col key={indicator.id}>
                            <Indicator  label={indicator.label} value={indicator.value} loading={getIndicatorsLoading}/>
                        </Col>
                    ))}
                </Row>            
            </Container>
        </>
    );
};

export default Indicators;