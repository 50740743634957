import { gql } from '@apollo/client'

export const DO_VOUCHER_TEMPLATE_FIELDS = gql`
  fragment DOVoucherTemplateFields on object_DOVoucherTemplate {
    id
    genericCode
    voucherPrefixedCode
    clientLabel
    initialAmount {
      value
      unit {
        abbreviation
      }
    }
    productType
    dateBegin
    dateEnd
    voucherType
    active
    comment
    onDiscount
    onlyFirstOrder
    domainSites
    brands {
      ... on object_DOBrand {
        id
        name
      }
    }
    duration
    minAmount {
      value
      unit {
        abbreviation
      }
    }
    voucherDiscountType
    newVoucherCode @skip(if: $withoutNewVoucherCode)
  }
`
