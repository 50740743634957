import React, { ChangeEvent, useState } from 'react'
import { Form } from 'react-bootstrap'
import EventShortcutCommentAndMail from './EventShortcutCommentAndMail'

interface Props {
  eventType: eventTypeType
  onUpdate: (e: {
    key: string
    checked: boolean
    data: {
      sentEmail?: boolean | null
      comment?: string | null
      emailVariable?: string | null
      orderEventsToClose?: eventTypeType[]
    }
  }) => any
}
const EventShortcutCheckbox: React.FC<Props> = ({ eventType, onUpdate }) => {
  const [checked, setChecked] = useState(false)

  const check = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = (e.target as HTMLInputElement)?.checked
    onUpdate({
      key: eventType.key,
      checked: isChecked,
      data: {
        sentEmail: eventType.emailToSend?isChecked:false,
        comment: null,
        emailVariable: null,
        orderEventsToClose: eventType.orderEventsToClose,
      },
    })
    setChecked((e.target as HTMLInputElement)?.checked)
  }

  return (
    <>
      <div
        className={`p-2 ${
          checked ? 'border border-light border-2 rounded mb-3' : ''
        }  `}
      >
        <Form.Check
          type="checkbox"
          label={eventType.label}
          value={eventType.key}
          onChange={check}
          className="position-relative bg-white"
          style={{ top: '-1.2em', left: '2em', width: 'fit-content' }}
        />
        {checked && (
          <EventShortcutCommentAndMail
            eventType={eventType}
            onUpdate={onUpdate}
          />
        )}
      </div>
    </>
  )
}

export default EventShortcutCheckbox
