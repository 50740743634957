import { gql } from '@apollo/client'

export const ADD_PRODUCT_TO_ORDER = gql`
  mutation ($orderId: Int!, $newProductId: Int!, $salePrice: Float) {
    addProductToOrder(orderId: $orderId, newProductId: $newProductId, salePrice: $salePrice) {
      success
      output {
        order {
          id
          events {
            ... on object_DOOrderEvent {
              id
              eventType {
                ... on object_DOOrderEventType {
                  id
                  key
                  eventFamily
                }
              }
            }
          }
        }
        orderLineItem {
          id
          label
          unitAmount {
            value
          }
          item {
            ... on fieldcollection_DOOLItemAccessoryFC {
              accessory {
                ... on object_DOAccessory {
                  id
                  name
                  salePrice18n
                }
              }
            }
            ... on fieldcollection_DOOLItemFrameFC {
              frame {
                ... on object_DOFrame {
                  id
                  name
                  salePrice18n
                }
              }
            }
          }
          orderLine {
            ... on object_DOOrderLine {
              id
              stockState
              status
            }
          }
        }
      }
    }
  }
`
