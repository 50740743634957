import { CheckIcon } from '@heroicons/react/solid'
import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

interface Props {
  values: any
  errors: any
  isFocusOut: any
  inputName: string
  inputType: string
  required?: boolean
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void //pour les Form.control
  handleFocusOut?: (e: React.FocusEvent<HTMLInputElement>) => void //pour les Form.control
  handleChangeSelect?: (e: React.ChangeEvent<HTMLSelectElement>) => void //pour les Form.Select
  handleFocusOutSelect?: (e: React.FocusEvent<HTMLSelectElement>) => void //pour les Form.Select
  radioOptions?: string[] //pour les Form.Check de type radio
  isFocusOutAndSetErrors?: (inputName: string) => void //pour valider les radio et check
  disabled?: boolean
  handleGeneratePassword?:(inputName: string)=>void
  handleCopyPassword?:(text:string)=>void
}
const InputCustom: React.FC<Props> = ({
  disabled,
  children, //pour passer les options du Form.Select
  values,
  inputName,
  inputType,
  errors,
  handleChange,
  handleFocusOut,
  handleChangeSelect,
  handleFocusOutSelect,
  handleGeneratePassword,
  handleCopyPassword,
  isFocusOut,
  required,
  radioOptions,
  isFocusOutAndSetErrors,
}) => {
  const { t } = useTranslation()
  const getBorderColor = () => {
    //   s'il y a une erreur => bordure rouge
    if (errors[inputName]) {
      return 'is-invalid'
    } else {
      //   s'il n'y a pas d'erreur et le form est submit ou l'input est focusOut => bordure verte
      if (isFocusOut[inputName]) {
        return 'is-valid'
      }
    }
  }

  return (
    <Form.Group
      controlId={inputName}
      className='d-flex m-2'
    >
      <Form.Label className='w-350 mb-0'>
        <pre className="label_pre mb-2">
          {t(inputName)} {required && <sup>*</sup>}
        </pre>
      </Form.Label>

      <div className="d-flex flex-column">
        {/* radioOptions en props => input de type radio */}
        {radioOptions ? (
          <div className="d-flex">
            {radioOptions.map((option, index: number) => {
              return (
                <Form.Check
                  disabled={disabled}
                  key={option}
                  inline
                  label={t(option)}
                  name={inputName}
                  type="radio"
                  id={option}
                  value={option}
                  checked={values[inputName] === option.toString()}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange && handleChange(e)
                    //vérifie s'il y a une erreur ou si on a touché à ce groupe
                    isFocusOutAndSetErrors && isFocusOutAndSetErrors(inputName)
                  }}
                />
              )
            })}
            {isFocusOut[inputName] && (
              <CheckIcon className="header-title-icon text-success" />
            )}
          </div>
        ) : inputType === 'textarea' ? (
          <Form.Control
            disabled={disabled}
            className={`${getBorderColor()}`}
            type={inputType}
            name={inputName}
            as="textarea"
            rows={2}
            placeholder={t(inputName)}
            value={values[inputName]?values[inputName]:''}
            onChange={handleChange}
            onBlur={handleFocusOut}
          />
        ) :  inputType === 'password'?(
            <div className='d-flex'>
              <Form.Group
                controlId={inputName}
                className='d-flex'
              >
                <div className='position-relative'>
                  <Form.Control className={`w-350 my-2${values[inputName]?(values[inputName].length<4?' is-invalid':' is-valid'):''}`} 
                  type="text"  
                  name={inputName}
                  value={values[inputName]?values[inputName]:''}
                  onChange={handleChange}
                  onBlur={handleFocusOut}
                  />
                  {values[inputName] && values[inputName].length<4 && <div className="position-absolute w-350 password-error text-danger small-content">Mot de passe invalide (minimum 4 caractères)</div>}
                </div>  
                <Button variant="outline-secondary m-2 me-0" size="sm" onClick={()=>handleGeneratePassword&&handleGeneratePassword(inputName)}>Générer</Button>
                <Button variant="outline-primary m-2" size="sm" onClick={()=>handleCopyPassword&&handleCopyPassword(inputName)}>Copier</Button>
              </Form.Group>
            </div>
        ):
        // handleChange
        inputType !== 'select' ? (
          // Si ce n'est PAS un select (input="text" par exemple ...)
          // handleChange en props => input Form.Control
          <Form.Control
            disabled={disabled}
            type={inputType}
            name={inputName}
            className={`${getBorderColor()} w-600`}
            placeholder={t(inputName)}
            value={values[inputName]?values[inputName]:''}
            onChange={handleChange}
            onBlur={handleFocusOut}
          /> // sinon on a un Form.Select
        ) : (
          <Form.Select
            disabled={disabled}
            className={`${getBorderColor()} w-600`}
            name={inputName}
            value={values[inputName]}
            onChange={handleChangeSelect}
            onBlur={handleFocusOutSelect}
          >
            {/* la liste d'option est passé en props dans children */}
            {children}
          </Form.Select>
        )}

        {errors[inputName] ? (
          <div className="text-danger small-content">{errors[inputName]}</div>
        ) : (
          <span
            className="text-danger small-content"
            style={{ height: '1em', width: '1px' }}
          ></span>
        )}
      </div>
    </Form.Group>
  )
}

export default InputCustom
