import './ModalAddress.css'
import React, { useContext, useReducer, useState } from 'react'
import HeaderTitle from '../HeaderTitle'
import { Button, Form, Spinner } from 'react-bootstrap'
import { HomeIcon } from '@heroicons/react/outline'
import { useMutation, useLazyQuery } from '@apollo/client'
import { GET_DELIVERY_MODE_LISTING } from 'graphql/queries/getDeliveryModeListing'
import Card from '../UI/Card'
import { GET_COUNTRIES_LISTING } from 'graphql/queries/getCountriesListing'
import { ToastContext } from '../../store/toast-context'
import { useEffect } from 'react'
import SkeletonMultiColumn from 'components/Skeleton/SkeletonMultiColumn'
import { GET_DO_STORE_LISTING } from 'graphql/queries/getDOStoreListing'
import { constants } from 'utils/constants'
import PickupPointDelivery from './../PickupPointDelivery'
import { UPDATE_DO_ORDER_DELIVERY_MODE } from '../../graphql/mutations/updateDOOrderDeliveryMode'
import { GET_ORDER_DELIVERY_DETAILS } from 'graphql/queries/getOrderDeliveryDetails'
import { CQ_GET_ORDER } from 'graphql/queries/getOrder'

interface formState {
  firstname: string
  lastname: string
  address: string
  additionalAddressDetails: string
  postalCode: string
  city: string
  mobilePhoneNumber: string
  landlinePhoneNumber: string
  deliveryMode: number
  deliveryStore: number
  country: string
}

interface Props {
  order: orderType
  closeModal?: () => void
}
const ModalAddress: React.FC<Props> = ({ order, closeModal }) => {
  const { toast } = useContext(ToastContext)

  const [selectedDeliveryModeKey, setSelectedDeliveryModeKey] = useState(order.deliveryMode.key)

  const [orderDeliveryModeMutation] = useMutation(UPDATE_DO_ORDER_DELIVERY_MODE)

  //need isLoading during the mutation
  const [isLoading, setIsLoading] = useState(false)

  let deliveryName
  if (order.deliveryName) {
    deliveryName = order.deliveryName[0]
  }
  let deliveryAddress
  if (order.deliveryAddress) {
    deliveryAddress = order.deliveryAddress[0]
  }
  const addressInitialState = {
    firstname: deliveryName ? deliveryName.firstName : '',
    lastname: deliveryName ? deliveryName.lastName : '',
    address: deliveryAddress ? deliveryAddress.address : '',
    additionalAddressDetails: deliveryAddress
      ? deliveryAddress.additionalAddressDetails || ''
      : '',
    postalCode: deliveryAddress ? deliveryAddress.postalCode : '',
    city: deliveryAddress ? deliveryAddress.city : '',
    mobilePhoneNumber: deliveryAddress ? deliveryAddress.mobilePhoneNumber : '',
    landlinePhoneNumber: deliveryAddress
      ? deliveryAddress.landlinePhoneNumber || ''
      : '',
    deliveryMode: order.deliveryMode ? order.deliveryMode.id : 0,
    deliveryStore: order.deliveryStore ? order.deliveryStore.id : 0,
    country: deliveryAddress ? deliveryAddress.country : 'FR'
  }

  const [pickupPoint, setPickupPoint] = useState<PickupPoint>({
    id: order.pickupPointCode ?? '0',
    name: '',
    address: [],
    zipcode: addressInitialState.postalCode,
    city: '',
    distanceInMeters: 0,
    holidays: [],
    workingHours: {},
    latitude: 0,
    longitude: 0
  })

  const formReducer = (state: formState, action: any) => {
    switch (action.type) {
      case 'HANDLE INPUT TEXT':
        return { ...state, [action.field]: action.payload }
      default:
        return state
    }
  }

  const [addressState, dispatch] = useReducer(formReducer, addressInitialState)
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'HANDLE INPUT TEXT',
      field: e.target.name,
      payload: e.target.value,
    })
  }

  const [
    getDeliveryModeListing,
    { loading: deliveryModeListingLoading, data: deliveryModeListingData },
  ] = useLazyQuery(GET_DELIVERY_MODE_LISTING)
  const [
    getCountries,
    { loading: countriesListingLoading, data: countriesListingData },
  ] = useLazyQuery(GET_COUNTRIES_LISTING)

  const [
    getStores,
    { loading: storeListingLoading, data: storeListingData },
  ] = useLazyQuery(GET_DO_STORE_LISTING)

  useEffect(() => {
    getDeliveryModeListing()
    getCountries()
    getStores()
  }, [getDeliveryModeListing, getCountries, getStores])

  if (deliveryModeListingLoading || countriesListingLoading || storeListingLoading) {
    return (
      <SkeletonMultiColumn
        rowCountLeft={8}
        rowCountRight={8}
        variantRight="pe-5"
      />
    )
  }
  if (!deliveryModeListingData || !countriesListingData || !storeListingData) {
    return (
      <Card>
        <p>Aucun mode de livraison trouvé ou pays non trouvé</p>
      </Card>
    )
  }

  
  const getSelectedDeliveryMode = () => deliveryModeListingData.getDODeliveryModeListing.edges.find((mode: any) => parseInt(mode.node.id) === addressState.deliveryMode)

  /* attention il y a un bug dans apollo : https://github.com/apollographql/apollo-client/issues/5708
  C'est pour ça qu'on est obligé d'utiliser un try catch. Le onError global au niveau de Link ne fonctionne pas avec les mutations */
  const handleSubmitNewOrderAddress = async (event: any) => {
    event.preventDefault()

    setIsLoading(true)
    //convert the type of the deliveryMode id to integer to use it in mutation
    let newID : number = parseInt(addressState.deliveryMode.toString())
    let newStoreID : number = parseInt(addressState.deliveryStore.toString())
    let newPickupPointCode : string | null = pickupPoint.id.toString() ?? null
    try {
      if(selectedDeliveryModeKey === constants.deliveryModeKeys.STORE && !newStoreID)
          throw new Error('Veuillez sélectionner un magasin')

      if(selectedDeliveryModeKey === constants.deliveryModeKeys.PICKUP_POINT 
        && !+newPickupPointCode)
        throw new Error('Veuillez sélectionner un point retrait')

      if(selectedDeliveryModeKey !== constants.deliveryModeKeys.PICKUP_POINT)
        newPickupPointCode = null

      await orderDeliveryModeMutation({
        refetchQueries: [CQ_GET_ORDER, GET_ORDER_DELIVERY_DETAILS], // Needs to refresh orderlines
        variables: {
          id: parseInt(order.id),
          firstName: addressState.firstname,
          lastName: addressState.lastname,
          address: addressState.address,
          additionalAddressDetails: addressState.additionalAddressDetails,
          postalCode: addressState.postalCode,
          city: addressState.city,
          country: addressState.country,
          mobilePhoneNumber: addressState.mobilePhoneNumber,
          landlinePhoneNumber: addressState.landlinePhoneNumber,
          newDeliveryId: newID,
          newStoreId: newStoreID,
          newPickupPointCode: newPickupPointCode
        }
      })

      toast({
        title: 'Modifications enregistrées :',
        message: 'Adresse de livraison',
      })

      if (closeModal)
        // Si on connait la fonction qui permet de fermer la modale, on la ferme
        closeModal()
    } catch (e) {
      alert(e)
      // NB : gestion erreur dans App.tsx mais en fait il y a un bug.
    }
    setIsLoading(false)
  }

  return (
    <div className="modalAddress">
      <div className="modal-container">
        <HeaderTitle
          title="Adresse de livraison"
          children={<HomeIcon className="header-title-icon" />}
        />
        <Form onSubmit={handleSubmitNewOrderAddress}>
          <div className="d-flex">
            <Form.Group className="me-4" controlId="firstname">
              <Form.Label>
                Prénom <sup>*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                name="firstname"
                value={addressState.firstname || ''}
                onChange={handleTextChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="lastname">
              <Form.Label>
                Nom <sup>*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                name="lastname"
                value={addressState.lastname || ''}
                onChange={handleTextChange}
                required
              />
            </Form.Group>
          </div>
          <div className="d-flex">
            <Form.Group className="me-4" controlId="deliveryMode">
              <Form.Label>
                Mode de livraison <sup>*</sup>
              </Form.Label>
              <Form.Select
                aria-label="Delivery mode select"
                defaultValue={addressState.deliveryMode}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  addressState.deliveryMode = parseInt(e.target.value)
                  setSelectedDeliveryModeKey(getSelectedDeliveryMode()?.node.key)
                }}
                required
              >
                {deliveryModeListingData.getDODeliveryModeListing.edges.map(
                  (mode: any) => {
                    if (mode.node.active) {
                      return (
                        <option key={mode.node.label} value={mode.node.id}>
                          {mode.node.label}
                        </option>
                      )
                    } else {
                      return ''
                    }
                  }
                )}
              </Form.Select>
            </Form.Group>
            {selectedDeliveryModeKey === constants.deliveryModeKeys.STORE &&
              <Form.Group className="me-4" controlId="deliveryStore">
                <Form.Label>
                  Nom du magasin <sup>*</sup>
                </Form.Label>
                <Form.Select
                  aria-label="Delivery store select"
                  defaultValue={addressState.deliveryStore}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    addressState.deliveryStore = parseInt(e.target.value)
                  }}
                  required
                >
                  <option key="no_store_selected" value="0">
                    Sélectionner le magasin
                  </option>
                  {storeListingData.getDOStoreListing.edges.map(
                    (mode: any) => {
                      if (mode.node.shippingDestination) {
                        return (
                          <option key={mode.node.id} value={mode.node.id}>
                            {mode.node.label}
                          </option>
                        )
                      } else {
                        return ''
                      }
                    }
                  )}
                </Form.Select>
              </Form.Group>
            }
          </div>
          {selectedDeliveryModeKey === constants.deliveryModeKeys.PICKUP_POINT && 
            <div className="d-flex pickupPoint">
              {!!+pickupPoint.id && !+pickupPoint.name.length &&
              <div>
                <Spinner
                  variant="primary ms-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </div>
              }
              <PickupPointDelivery pickupPoint={pickupPoint} setPickupPoint={setPickupPoint}></PickupPointDelivery>
            </div>
          }
          <div className="d-flex">
            <Form.Group className="me-4" controlId="address">
              <Form.Label>
                Adresse <sup>*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={addressState.address || ''}
                onChange={handleTextChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="additionalAddressDetails">
              <Form.Label>Complément d'adresse</Form.Label>
              <Form.Control
                type="text"
                name="additionalAddressDetails"
                value={addressState.additionalAddressDetails || ''}
                onChange={handleTextChange}
              />
            </Form.Group>
          </div>
          <div className="d-flex">
            <Form.Group className=" me-4" controlId="postalCode">
              <Form.Label>
                Code Postal <sup>*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                name="postalCode"
                value={addressState.postalCode || ''}
                onChange={handleTextChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="city">
              <Form.Label>
                Ville <sup>*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={addressState.city || ''}
                onChange={handleTextChange}
                required
              />
            </Form.Group>
          </div>
          <div className="d-flex">
            <Form.Group className=" me-4" controlId="mobilePhoneNumber">
              <Form.Label>
                Téléphone mobile <sup>*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                name="mobilePhoneNumber"
                value={addressState.mobilePhoneNumber || ''}
                onChange={handleTextChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="landlinePhoneNumber">
              <Form.Label>Téléphone fixe</Form.Label>
              <Form.Control
                type="text"
                name="landlinePhoneNumber"
                value={addressState.landlinePhoneNumber || ''}
                onChange={handleTextChange}
              />
            </Form.Group>
          </div>
          <div className="d-flex">
            <Form.Group className="me-4" controlId="deliveryMode">
              <Form.Label>
                Pays <sup>*</sup>
              </Form.Label>
              <Form.Select
                aria-label="Delivery address country select"
                defaultValue={addressState.country}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  addressState.country = e.target.value
                }}
                required
              >
                {countriesListingData.getCountriesListing.edges.map(
                  (country: any) => {
                    return (
                      <option key={country.node.key} value={country.node.value}>
                        {country.node.key}
                      </option>
                    )
                  }
                )}
              </Form.Select>
            </Form.Group>
          </div>
          <div className="mt-4 d-flex justify-content-end">
            <Button variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? (
                <span className="text-white">Enregistrement en cours...</span>
              ) : (
                <span className="text-white">
                  Modifier l'adresse de livraison
                </span>
              )}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default ModalAddress
