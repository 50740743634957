import { gql } from '@apollo/client'

const BULK_CLOSE_ORDER_EVENTS = gql`
  mutation BULK_CLOSE_ORDER_EVENTS($orderId: Int!, $eventsId:[Int]) {
    bulkCloseOrderEvents(orderId: $orderId, eventsId: $eventsId) {
      success
      output {
        closeSuccessEventTypeKeys
        closeFailEventTypeKeys
      }
    }
  }
`

export default BULK_CLOSE_ORDER_EVENTS
