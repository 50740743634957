import { gql } from '@apollo/client'
//Deleting guarantee
export const CM_DELETE_GENERIC_ITEM = gql`
    mutation CM_DELETE_GENERIC_ITEM($orderLineItemId: Int!) {
      deleteGenericItem(orderLineItemId: $orderLineItemId) {
        output {
          errorMessage
          order {
            ... on object_DOOrder {
              id
              events {
                ... on object_DOOrderEvent {
                  id
                  eventType {
                    ... on object_DOOrderEventType {
                      id
                      key
                      eventFamily
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
`