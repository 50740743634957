import { useMutation } from '@apollo/client'
import HeaderTitle from 'components/HeaderTitle'
import BULK_CLOSE_ORDER_EVENTS from 'graphql/mutations/bulkCloseOrderEvents'
import GET_ORDER_EVENTS from 'graphql/queries/getDOOrderEvents'
import React, { useContext } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ToastContext } from 'store/toast-context'

interface Props {
    id:number
    checkedIdList: string []
    setCheckedIdList : React.Dispatch<React.SetStateAction<string[]>>
    closeModal : () => void
    authorisedToClose : boolean
}
const ModalCloseStatus: React.FC<Props> = ({id, checkedIdList, setCheckedIdList, closeModal, authorisedToClose }) => {
    const { toast } = useContext(ToastContext)
    const { t } = useTranslation()
    const [bulkCloseOrderEvents, { loading }] = useMutation(
        BULK_CLOSE_ORDER_EVENTS,
        {
        variables: {
            orderId: +id,
            eventsId: checkedIdList.map((id: string) => { return +id }),
        },
        refetchQueries: [
            // works only on success
            GET_ORDER_EVENTS,
        ],
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            if (
            data.bulkCloseOrderEvents.output.closeFailEventTypeKeys.length > 0
            ) {
            toast({
                title: 'Echec :',
                message: `${t('status_close_count_fail', {
                count:
                    data.bulkCloseOrderEvents.output.closeFailEventTypeKeys
                    .length,
                })}`,
                manualClose: true,
            })
            }
            if (
            data.bulkCloseOrderEvents.output.closeSuccessEventTypeKeys
                .length > 0
            ) {
            toast({
                title: 'Succès :',
                message: `${t('status_close_count_success', {
                count:
                    data.bulkCloseOrderEvents.output.closeSuccessEventTypeKeys
                    .length,
                })}`,
            })
                if (closeModal)
                // Si on connait la fonction qui permet de fermer la modale, on la ferme
                closeModal()
            }
            setCheckedIdList([])
        },
        }
    )
   const callBulkCloseOrderEvents = async () => {
    try {
      await bulkCloseOrderEvents()
    } catch (e) {
      setCheckedIdList([])
      // NB : gestion erreur dans App.tsx
    }
  }
  return (
    <div>
        <HeaderTitle title={authorisedToClose ? 
        "Êtes-vous sûr(e) de vouloir clôturer ce(s) statut(s) ?"
        :"Vous devez laisser au moins un statut logistique d’ouvert."}/>
        <Modal.Footer>          
        {authorisedToClose && 
            <Button variant="primary me-2" onClick={callBulkCloseOrderEvents} disabled={loading}>
            Valider
            </Button>}
            <Button variant="secondary" onClick={closeModal} disabled={loading}>
            {authorisedToClose?"Annuler":"OK"}
            </Button>
        </Modal.Footer>
    </div>
  )
}

export default ModalCloseStatus
