import { gql } from '@apollo/client'

export const GET_DO_PRODUCT_STOCK_BY_EAN_LISTING = gql`
  query GET_DO_PRODUCT_STOCK_BY_EAN_LISTING($eanOrIdmcList: [String]!, $stockLocation: String, $name: String) {
    getDOProductStock(eanOrIdmcList: $eanOrIdmcList, stockLocation: $stockLocation, name: $name) {
        totalCount
        edges {
          node {
            frame {
              ... on object_DOFrame {
                name
                gtin
                color {
                  ... on object_DOColor {
                    label
                  }
                }
                stock {
                ... on fieldcollection_DOProductStockFC {
                  availableQuantity
                  essdomQuantity
                  wipQuantity
                  reservedQuantity
                  tracking {
                    ... on object_DOStockTracking {
                      comment
                      origin
                      destination
                      creationDate
                      id
                      availableQuantity {
                        ... on fieldcollection_DOStockTrackingFC {
                          before
                          after
                          quantity
                        }
                      }
                      reservedQuantity {
                        ... on fieldcollection_DOStockTrackingFC {
                          before
                          after
                          quantity
                        }
                      }
                      wipQuantity {
                        ... on fieldcollection_DOStockTrackingFC {
                          before
                          after
                          quantity
                        }
                      }
                      essdomQuantity {
                        ... on fieldcollection_DOStockTrackingFC {
                          before
                          after
                          quantity
                        }
                      }
                      orderLineItem {
                        ... on object_DOOrderLineItem {
                          orderLine {
                            ... on object_DOOrderLine {
                              order {
                                ... on object_DOOrder {
                                  key
                                }
                              }
                            }
                          }
                        }
                      }
                      user {
                        ... on object_DOUser {
                          key
                        }
                      }
                    }
                  }
                }
              }
              }
            }
            accessory {
              ... on object_DOAccessory {
                name
                gtin
                color {
                  ... on object_DOColor {
                    label
                  }
                }
                stock {
                ... on fieldcollection_DOProductStockFC {
                  availableQuantity
                  essdomQuantity
                  wipQuantity
                  reservedQuantity
                  tracking {
                    ... on object_DOStockTracking {
                      comment
                      origin
                      destination
                      creationDate
                      id
                      availableQuantity {
                        ... on fieldcollection_DOStockTrackingFC {
                          before
                          after
                          quantity
                        }
                      }
                      reservedQuantity {
                        ... on fieldcollection_DOStockTrackingFC {
                          before
                          after
                          quantity
                        }
                      }
                      wipQuantity {
                        ... on fieldcollection_DOStockTrackingFC {
                          before
                          after
                          quantity
                        }
                      }
                      essdomQuantity {
                        ... on fieldcollection_DOStockTrackingFC {
                          before
                          after
                          quantity
                        }
                      }
                      orderLineItem {
                        ... on object_DOOrderLineItem {
                          orderLine {
                            ... on object_DOOrderLine {
                              order {
                                ... on object_DOOrder {
                                  key
                                }
                              }
                            }
                          }
                        }
                      }
                      user {
                        ... on object_DOUser {
                          key
                        }
                      }
                    }
                  }
                }
              
                }

              }
            }
          }
        }
    }
  }
`
