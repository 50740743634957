import React, { useState, useContext, useEffect } from 'react'
import { Button, Form, Spinner, Row, Col, Container, Table} from 'react-bootstrap'
import { ToastContext } from 'store/toast-context'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_DO_PRODUCT_STOCK } from 'graphql/queries/getDOProductStock'
import { RefreshIcon, XIcon } from '@heroicons/react/solid'
import BULK_UPDATE_DO_PRODUCT_STOCK from 'graphql/mutations/bulkUpdateDOProductStock'
import { validateNumberInput } from 'utils/functions'

interface UpdateData {
  [key: string]: {
    salesThreshold : number | undefined | null
    hometrialThreshold : number | undefined | null
    quantity :  number | undefined | null
    stockLocation : string 
  }
}

const GestionBatch: React.FC = () => {
    const { toast } = useContext(ToastContext)
    //stock entry = 1 or stock removal = 2 or null if not defined
    const [stockMovementType, setStockMovmentType] = useState<1|2|null>(null)  
    //codes of gtin/ean or idmc
    const [codes, setCodes] = useState<string[]>([])
    //list of codes not found
    const [errorCodes, setErrorCodes] = useState<string[]>([])

    const [comment, setComment] = useState<string>('')
    const [data, setData] = useState<productIdmcsNodeType[]>([])

    const [updateData, setUpdateData] = useState<UpdateData>({})
    const [globalQuantity, setGlobalQuantity] = useState<number |null>(null)
    const [globalSales, setGlobalSales] = useState<number |null>(null)
    const [globalHometrial, setGlobalHometrial] = useState<number |null>(null)
    const [fromMutation, setFromMutation] = useState<boolean>(false)

    //query
    const [getDOProductStock, { loading, data: stockData }] = useLazyQuery(GET_DO_PRODUCT_STOCK, {
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            if (stockData) {
                //si les nouvelles données de stockData > data 
                if(stockData.getDOProductStock.edges.length>data.length) {
                    toast({
                        title: 'Succès :',
                        message: 'Le produit a été ajouté dans le tableau'
                    })
                }
                else {
                    // check if last code exist in stockData frame.gtin
                    handleErrorCodes()
                    if(!fromMutation)
                    toast({
                        title: 'Echec :',
                        message: 'Aucun produit supplémentaire n’a été ajouté dans le tableau',
                        manualClose: true
                    })
                    setFromMutation(false)
                }
                setData(stockData.getDOProductStock.edges)
            }
        },
    })
    const handleSearch = () => {
        getDOProductStock({
            variables: { eanOrIdmcList: Array.from(new Set(codes)), stockLocation: '', name: '' },
        })
    }
    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter' && codes.length) {
            handleSearch()
        }
    }
    //reset value in table
    const resetTable = () => {
        setUpdateData({})
        setGlobalQuantity(null)
        setGlobalSales(null)
        setGlobalHometrial(null)
    }
    //erase all table and reset all 
    const onClickEraseAll = () => {
        if (window.confirm('Êtes-vous sûr(e) de tout effacer ?')) {
        eraseAll()
        }
    } 
    const eraseAll = () => {
        changeStockMovment(null)
        setCodes([])
        setErrorCodes([])
        setData([])
        resetTable()
    }
    //mutation
    const [bulkUpdateStock, { loading: bulkUpdateLoading}] = useMutation(BULK_UPDATE_DO_PRODUCT_STOCK, {
        variables: {
            productDataJson: JSON.stringify(updateData),
            stockMovementType,
            comment
        },
        onCompleted: (mutationData) => {
        if(mutationData)
        {
            //refresh component 
            setFromMutation(true)
            handleSearch()
            //reset
            changeStockMovment(null)
            resetTable()
            if(mutationData.bulkUpdateDOProductStock.output.frames.length || mutationData.bulkUpdateDOProductStock.output.accessories.length)
            toast({
              title: 'Succès :',
              message: 'Modifications apportées'
            })
            if(mutationData.bulkUpdateDOProductStock.output.failGtin.length)
             toast({
                title: 'Echec :',
                message: 'Pour ces codes ean : '+ mutationData.bulkUpdateDOProductStock.output.failGtin.join(', '),
                manualClose: true
            })
        }
        },
    })
    const handleBulkUpdateStock = () => {
        bulkUpdateStock()
    }
    // disabled mutation button
   const isMutationBtnDisabled = () => {
        for (const key in updateData) {
            const dataKey = updateData[key]
            if (
                (dataKey.quantity && dataKey.quantity > 0) ||
                (typeof dataKey.salesThreshold === "number" && dataKey.salesThreshold >= 0) ||
                (typeof dataKey.hometrialThreshold  === "number" && dataKey.hometrialThreshold >= 0) ||
                (dataKey.stockLocation !== undefined)
            ) {
                return false // if one variable is updated, button is enable
            }
        }
        return true //no updated variable , button is disabled
    }

    //handle change EAN or IDMC in textarea
    const handleCodeChange = (e: any) => {
        setCodes(
        e.target.value
            .split('\n')
            .filter(Boolean)
            .map((str: any) => str.trim())
        )
    }
    // handle change comment in textarea
    const handleCommentChange = (e: any) => {
        setComment(e.target.value)
    }
    //remove product (x icon)
    const removeProduct = (idmcs: string, gtin: string) => {
        const idmcsArray = idmcs.split(',')
        const newCodes = codes.filter(item => !idmcsArray.includes(item) && item !== gtin)
        setCodes(newCodes)
        const updatedData = data.filter(item => item.node.frame ?
        item.node.frame.gtin !== gtin : item.node.accessory?.gtin !== gtin)
        setData(updatedData)
    }
    //error message
    const handleErrorCodes = () => {
        const newErrorCodes = [] as string[]
        codes.forEach((searchCode: string) => {
            //si le code recherché n'existe ni dans les idmc listes ni dans les frame.gtin ou accessory.gtin
            if (!stockData.getDOProductStock.edges.some((edge: productIdmcsNodeType) =>
            (edge.node.frame??edge.node.accessory)?.gtin === searchCode)
            && !stockData.getDOProductStock.edges.some((edge: productIdmcsNodeType) => edge.node.idmcList.split(',').includes(searchCode))) {
                newErrorCodes.push(searchCode)
            }
        })
        // alors le code n'existe pas l'ajouté à la liste d'erreurs
        setErrorCodes(newErrorCodes)
    }
    //change stock movment type (radio button)
    const changeStockMovment = (type:1|2|null) => {
        setStockMovmentType(type)
        setComment('')
    }
    //handle change stock location
    const handleChangeStockLocation = (id:string, value:string) =>{
           setUpdateData((prev) => ({
            ...prev,
            [id]: {
                ...prev[id],
                stockLocation: value,
            }
        }))

    }
    //handle change quantity or sales threshold or hometrial threshold
    const handleChangeQtyOrThreshold = (id: string, value: string, key: string) => {
        setUpdateData((prev) => ({
            ...prev,
            [id]: {
                ...prev[id],
                [key]: value === '' ? null : +validateNumberInput(value),
            },
        }))
    }
    const handleGlobalInputChange = (value:string, inputName: 'quantity'|'salesThreshold'|'hometrialThreshold') => {
        const newValue = getUpdateValue(value, inputName)
        data.forEach((elem)=>{
            const nodeId = (elem.node.frame??elem.node.accessory)?.id
            if(nodeId){
                const isChanged = !updateData[nodeId]?.[inputName] || updateData[nodeId][inputName] === getGlobalValue(inputName)
                if(isChanged) {
                    setUpdateData((prev) => ({
                        ...prev,
                        [nodeId]: {
                            ...prev[nodeId],
                            [inputName]: newValue
                        }
                    }))
                }
            }
        })
        setGlobalState(inputName, newValue)
    }
    
    useEffect(() => {}, [data, updateData])

    //get global value with input name
    const getGlobalValue = (inputName: string) => {
        switch (inputName) {
            case 'quantity':
                return globalQuantity
            case 'salesThreshold':
                return globalSales
            case 'hometrialThreshold':
                return globalHometrial
            default:
                return null
        }
    }
    //get update value
    const getUpdateValue = (value:string, inputName:string) => {
         switch (inputName) {
            case 'quantity':
                return (+value)?+validateNumberInput(value) :null
            case 'salesThreshold':
            case 'hometrialThreshold':
                return  value==='' ? null: +validateNumberInput(value)
            default:
                return null
        }
    }
    //set global state
    const setGlobalState = (inputName: string, value:null|number) => {
        switch (inputName) {
            case 'quantity':
            setGlobalQuantity(value);
            break
            case 'salesThreshold':
            setGlobalSales(value)
            break
            case 'hometrialThreshold':
            setGlobalHometrial(value)
            break
            default:
            break
        }
    }
    //get border color for inputs
    const getInputClass = (PRODUCT: frameType|accessoryType, inputName: 'quantity'|'salesThreshold'|'hometrialThreshold') => {
        const inputValue = updateData[PRODUCT.id]?.[inputName]
        const globalValue = getGlobalValue(inputName)
        if (globalValue === inputValue && inputValue!==null) {
            return "border-orange"
        } else if ((typeof inputValue === "number" && inputName!=="quantity") || inputValue) {
            return "is-valid"
        }
        return ''
    }

    //table rows
    const renderTableRows = ()  =>{ 
        return (data?.length ? (data.map((productIdmcsNode: productIdmcsNodeType, key: number) => {
            const PRODUCT = productIdmcsNode.node.frame ?? productIdmcsNode.node.accessory
            const IDMCS = productIdmcsNode.node.idmcList
            const stockInfo = PRODUCT?.stock[0]
            let stockEnBac =
            stockInfo.totalQuantity - stockInfo.wipQuantity - stockInfo.essdomQuantity
            let stockDispo = stockEnBac - stockInfo.reservedQuantity
            if(PRODUCT)
                return (
                    <tr key={key}>
                        <td>
                            {PRODUCT.gtin}
                        </td>
                        <td>{IDMCS}</td>
                        <td>
                            {PRODUCT.name +" "+PRODUCT.color.label}
                        </td>
                        <td>{stockDispo}</td>
                        {stockMovementType && <td>
                        <Form.Floating  style={{height:'0', padding:"0"}}>
                            <Form.Control 
                                className={getInputClass(PRODUCT, 'quantity')}
                                style={{width:'90px', height:'2.35rem', padding:"0.375rem 0.75rem", paddingLeft:"1.2rem"}}
                                type="text"
                                value={updateData[PRODUCT.id]?.quantity===0?'': updateData[PRODUCT.id]?.quantity??''}
                                onChange={(e) => handleChangeQtyOrThreshold(PRODUCT.id, e.target.value, 'quantity')}
                            />
                            <label
                                htmlFor="floatingQuantity"
                                style={{ position: "absolute", left: "-0.25rem", color:"black" }}
                            >
                            {stockMovementType === 1?"+":"-"}
                            </label>
                        </Form.Floating>
                        </td>}
                        <td>{stockInfo.reservedQuantity}</td>
                        <td>
                        <Form.Floating  style={{height:'0', padding:"0"}}>
                            <Form.Control
                                className={getInputClass(PRODUCT, 'salesThreshold')}
                                style={{width:'130px', height:'2.35rem', padding:"0.375rem 0.75rem", paddingLeft:"2.85rem"}}
                                type="text"
                                value={updateData[PRODUCT.id]?.salesThreshold??''}
                                onChange={(e) => handleChangeQtyOrThreshold(PRODUCT.id, e.target.value, 'salesThreshold')}
                            />
                            <label
                                htmlFor="floatingSalesThresh"
                                style={{ position: "absolute", left: "-0.25rem" }}
                            >
                                {stockInfo.salesThreshold?stockInfo.salesThreshold + '→':'0→'}
                            </label>
                        </Form.Floating>
                        </td>
                        <td>
                            <Form.Floating  style={{height:'0', padding:"0"}}>
                                <Form.Control
                                    className={getInputClass(PRODUCT, 'hometrialThreshold')}
                                    style={{width:'130px', height:'2.35rem', padding:"0.375rem 0.75rem", paddingLeft:"2.85rem"}}
                                    type="text"
                                    value={updateData[PRODUCT.id]?.hometrialThreshold??''}
                                    onChange={(e) => handleChangeQtyOrThreshold(PRODUCT.id, e.target.value, 'hometrialThreshold')}
                                    disabled={productIdmcsNode.node.accessory !== null}
                                />
                                <label
                                    htmlFor="floatingHometrialThresh"
                                    style={{ position: "absolute", left: "-0.25rem" }}
                                >
                                    {stockInfo.homeTrialThreshold?stockInfo.homeTrialThreshold + '→':'0→'}
                                </label>
                            </Form.Floating>
                        </td>
                        <td>
                            <Form.Control
                                className={updateData[PRODUCT.id]?.stockLocation!==undefined?'is-valid':''}
                                type="text"
                                value={updateData[PRODUCT.id]?.stockLocation??(PRODUCT.stockLocation||'')}
                                onChange={(e) => handleChangeStockLocation(PRODUCT.id,e.target.value)}
                            />
                        </td>
                        <td className="align-middle">
                            <span
                            className="btn btn-close"
                            onClick={() => removeProduct(IDMCS, PRODUCT.gtin)}
                            ></span>
                        </td>
                    </tr>
                )
            return <tr></tr>
        })): <tr></tr>)
    }

    return (
        <Container fluid>
            <Row>
                <Form.Group as={Col} sm={6} className="mb-3">
                <Form.Label>
                EAN ou IDMC : 
                </Form.Label>
                <Form.Control
                    as="textarea"
                    placeholder='Ajouter les codes EAN ou IDMC séparés par des sauts de lignes'
                    rows={5}
                    onKeyDown={handleKeyDown}
                    onChange={handleCodeChange}
                    onKeyPress={(e) => {
                        e.stopPropagation()
                    }}
                    />
            {errorCodes.length ? <span className='text-danger'>Le(s) code(s) saisi(s) n'existe(nt) pas : {errorCodes.join(', ')}</span>:<></>}
            </Form.Group>
            <Col sm={2} style={{ alignSelf: 'center'}}>
                <Button
                    onClick={handleSearch}
                    disabled={loading||!codes.length||bulkUpdateLoading}
                    variant="primary shadow"
                    >
                        {loading ? (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                variant="light"
                            />
                        ) : "Ajouter"}
                </Button>
            </Col>
            </Row>
            <Row>
                <Form.Group as={Col} className="mb-3">
                    <Form.Label as="legend" column sm={5}>
                        Type de mouvement :
                    </Form.Label>
                    <Form.Check
                        inline
                        type="radio"
                        label="entrée"
                        name="stockMovementType"
                        onChange={() => changeStockMovment(1)}
                        value="in"
                        id="in"
                        checked={stockMovementType===1}
                    />
                    <Form.Check
                        inline
                        type="radio"
                        label="sortie"
                        name="stockMovementType"
                        onChange={() => changeStockMovment(2)}
                        value="out"
                        id="out"
                        checked={stockMovementType===2}
                    />
                </Form.Group>
                 <Form.Group as={Col} sm={6} className="mb-3">
                    {stockMovementType && <Form.Control
                        as="textarea"
                        placeholder='Commentaires'
                        rows={3}
                        value={comment}
                        onChange={handleCommentChange}
                        onKeyPress={(e) => {
                            e.stopPropagation()
                        }}
                    />}
               </Form.Group>
            </Row>
            <Row>
                <Table striped>
                    <thead>
                        <tr>
                            <th>EAN</th>
                            <th>IDMC</th>
                            <th>Produit</th>
                            <th>Stock dispo</th>
                            {stockMovementType && <th>
                                <Form.Control 
                                    className={globalQuantity?'border-orange':''}
                                    style={{width:'90px'}}
                                    type="text"
                                    placeholder="Quantité"
                                    value={globalQuantity?globalQuantity :''}
                                    onChange={(e) => handleGlobalInputChange(e.target.value, 'quantity')}
                                    />
                            </th>}
                            <th>Stock réservé</th>
                            <th>
                                <Form.Control 
                                    className={globalSales!==null?'border-orange':''}
                                    style={{width:'130px'}}
                                    type="text"
                                    placeholder="Seuil de vente"
                                    value={globalSales!==null?globalSales:''}
                                    onChange={(e) => handleGlobalInputChange(e.target.value, 'salesThreshold')}
                                    />
                            </th>
                            <th>
                                <Form.Control 
                                    className={globalHometrial!==null?'border-orange':''}
                                    style={{width:'130px'}}
                                    type="text" 
                                    placeholder="Seuil essdom"
                                    value={globalHometrial!==null?globalHometrial:''}
                                    onChange={(e) => handleGlobalInputChange(e.target.value, 'hometrialThreshold')}
                                    />
                            </th>
                            <th>Emplacement de stock</th>
                            <th>
                                {data.length?
                                <XIcon className="cursor link-danger"  onClick={onClickEraseAll} />
                                :<></>}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderTableRows()}
                    </tbody>
                </Table>
            {data.length ?
            <div className='mb-3'>
                <small className='text-success'><b>⎯</b> modification individuelle</small>
                <small className='ms-3 text-orange'><b>⎯</b> modification global</small>
            </div>:<></>
            }
            </Row>
            <Row style={{justifyContent:"flex-end"}} className="position-fixed p-2 end-0 bottom-0 me-5 mb-5">
                <Col xs="auto" className="me-5">
                    <Button
                    onClick={handleBulkUpdateStock}
                    disabled={loading||!codes.length||bulkUpdateLoading || isMutationBtnDisabled()}
                    variant="primary shadow"
                    >
                    {bulkUpdateLoading ? (
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="light"
                        />
                    ) : "Modifier"}
                    </Button>
                </Col>
                <Col xs="auto" className="me-5">
                    <Button
                    className="p-0 ps-1 mt-1"
                    variant="outline-primary shadow"
                    onClick={resetTable}
                    >
                        <RefreshIcon className="header-title-icon" />
                    </Button>
                </Col>
            </Row>
        </Container>
  )
}

export default GestionBatch
