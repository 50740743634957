import React, { Component } from 'react'
import Dashboard from '../components/Dashboard/Dashboard'
import Card from 'components/UI/Card'
export class Home extends Component {
  render() {
    return (
      <Card>
        <Dashboard />
      </Card>
    )
  }
}

export default Home
