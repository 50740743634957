import React, { Component } from "react";

export default class EcrituresComptables extends Component {
    render() {
        return (
            <div>
                <h1>EcrituresComptables</h1>
            </div>
        );
    }
}
