import React, { Component } from "react";

export default class EntreeSortieDeStock extends Component {
    render() {
        return (
            <div>
                <h1>EntreeSortieDeStock</h1>
            </div>
        );
    }
}
