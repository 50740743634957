import { gql } from '@apollo/client'
import { DO_ACCESSORY_FIELDS } from 'graphql/fragments/DOAccessoryFields'
import { DO_FRAME_FIELDS } from 'graphql/fragments/DOFrameFields'

const MODIFY_DO_PRODUCT_STOCK = gql`
  ${DO_FRAME_FIELDS}
  ${DO_ACCESSORY_FIELDS}
  mutation modifyDOProductStock(
    $productGtin: String!
    $quantity: Int!
    $comment: String!
  ) {
    modifyDOProductStock(
      productGtin: $productGtin
      quantity: $quantity
      comment: $comment
    ) {
      success
      output {
        frame {
          ...DOFrameFields
        }
        accessory {
          ...DOAccessoryFields
        }
      }
    }
  }
`

export default MODIFY_DO_PRODUCT_STOCK
