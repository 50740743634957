import { gql } from '@apollo/client'
import { DO_ORDER_EVENT_FIELDS } from 'graphql/fragments/DOOrderEventFields'

const DUPLICATE_ORDER_LINE = gql`
  ${DO_ORDER_EVENT_FIELDS}
  mutation DUPLICATE_ORDER_LINE(
    $orderId: Int!
    $orderLineId: Int!
    $newPrescription: Boolean!
    $state: String
    $frameId: Int
    $frameQuantity: Int
    $frameAmount: Float
    $lensReplacementId: Int
    $prescriptionId: Int
    $beneficiaryId: Int
    $jsonLenses: String
    $key: String
    $label: String
    $quantity: Int
    $amount: Float
    $shippingFeeId: Int
    $voucherId: Int
    $accessoryId: Int
    $accessoryQuantity: Int
    $accessoryAmount: Float
  ) {
    duplicateOrderLine(
      orderId: $orderId
      orderLineId: $orderLineId
      newPrescription: $newPrescription
      state: $state
      frameId: $frameId
      frameQuantity: $frameQuantity
      frameAmount: $frameAmount
      lensReplacementId: $lensReplacementId
      prescriptionId: $prescriptionId
      beneficiaryId: $beneficiaryId
      jsonLenses: $jsonLenses
      key: $key
      label: $label
      quantity: $quantity
      amount: $amount
      shippingFeeId: $shippingFeeId
      voucherId: $voucherId
      accessoryId: $accessoryId
      accessoryQuantity: $accessoryQuantity
      accessoryAmount: $accessoryAmount
    ) {
      success
      message
      output {
        event{
          ...DOOrderEventFields
        }
      }
    }
  }
`

export default DUPLICATE_ORDER_LINE
