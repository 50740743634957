import { gql } from '@apollo/client'

export const GET_DO_STORE_LISTING = gql`
  query GET_DO_STORE_LISTING {
    getDOStoreListing(sortBy: "label", sortOrder: "ASC") {
      edges {
        node {
          id
          label
          shippingDestination
        }
      }
      totalCount
    }
  }
`