import React, { useState } from 'react'
import Card from 'components/UI/Card'
import { Alert, Button, Col, Form, Row, Spinner, Table } from 'react-bootstrap'
import { useLazyQuery, useQuery } from '@apollo/client'
import { RefreshIcon } from '@heroicons/react/solid'
import GET_DO_VOUCHER_LISTING from 'graphql/queries/getDOVoucherWithDateFilterListing'
// import GET_DO_VOUCHER_MARKETING_CAMPAIGNS_LISTING from 'graphql/queries/getDOVoucherMarketingCampaignListing'
import { GET_DO_STORE_LISTING } from 'graphql/queries/getDOStoreListing'
import { CSVLink } from 'react-csv'

interface Props {}

const invertDateFormat = (dateString: string) => {
  const [date, time] = dateString.split(' ')
  const [year, month, day] = date.split('-')
  const invertedDate = `${day}-${month}-${year} ${time}`
  return invertedDate
}

const ExportBDRUtilisesEnMagasin: React.FC<Props> = () => {
  // const { data, loading } = useQuery(GET_DO_VOUCHER_MARKETING_CAMPAIGNS_LISTING)
  const { data: storeData, loading: loadingStore } =
    useQuery(GET_DO_STORE_LISTING)
  const [getVouchers, { data: voucherData, loading: loadingNew }] =
    useLazyQuery(GET_DO_VOUCHER_LISTING)
  const [dateBegin, setDateBegin] = useState('')
  const [dateEnd, setDateEnd] = useState('')
  // const [selectedMarketingCampaign, setSelectedMarketingCampaign] = useState('')
  const [selectedStore, setSelectedStore] = useState<number | undefined>()
  const [showDanger, setShowDanger] = useState(true)
  const [onlineStore, setOnlineStore] = useState(false)

  const onRechercherClick = () => {
    getVouchers({
      variables: {
        dateBegin,
        dateEnd,
        // marketingCampaign: selectedMarketingCampaign || undefined,
        storeId: onlineStore ? 0 : selectedStore || undefined,
      },
    })
  }

  const flattenVoucherData = (
    voucherData: any,
    dateBegin: string,
    dateEnd: string
  ) => {
    const flattenedData: any[] = []

    voucherData?.getDOVoucherWithDateFilterListing?.edges.forEach(
      (voucher: any) => {
        const { totalAmount, voucherCode, voucherUsage } = voucher.node

        voucherUsage.forEach((usage: any) => {
          const usageDate = new Date(usage.usageDate)
          const beginDate = new Date(dateBegin)
          const endDate = new Date(dateEnd)

          // Filter usageDate between dateBegin and dateEnd
          if (usageDate >= beginDate && usageDate <= endDate) {
            flattenedData.push({
              voucherCode,
              usageDate: invertDateFormat(usage.usageDate),
              store: usage.store?.label ?? usage.order?.orderIP,
              totalAmount: totalAmount.value,
            })
          }
        })
      }
    )

    return flattenedData
  }
  const csvData = flattenVoucherData(voucherData, dateBegin, dateEnd)
  const headers = [
    { label: 'Code avantage', key: 'voucherCode' },
    { label: "Date d'utilisation", key: 'usageDate' },
    { label: 'Magasin', key: 'store' },
    { label: 'Montant', key: 'totalAmount' },
  ]

  return (
    <div>
      <Card>
        <Row className="g-2 p-3">
          <Col xs="auto">
            <Form.Group
              as={Row}
              className="mb-3 justify-content-start"
              controlId="fromDate"
            >
              <Form.Label column sm={2} className="text-nowrap">
                Du :
              </Form.Label>
              <Col md="auto">
                <Form.Control
                  type="date"
                  placeholder="Du"
                  value={dateBegin}
                  onChange={(e) => setDateBegin(e.target.value)}
                  className="w-100"
                />
              </Col>
            </Form.Group>
          </Col>
          <Col xs="auto">
            <Form.Group
              as={Row}
              className="mb-3 justify-content-start me-2"
              controlId="toDate"
            >
              <Form.Label column sm={2} className="text-nowrap">
                Au :
              </Form.Label>
              <Col md="auto">
                <Form.Control
                  type="date"
                  placeholder="Au"
                  value={dateEnd}
                  onChange={(e) => setDateEnd(e.target.value)}
                  className="w-100"
                />
              </Col>
            </Form.Group>
          </Col>
          <Col xs="auto" className="me-5">
            <Button
              className="p-0 ps-1 mt-1"
              variant="outline-primary"
              onClick={() => {
                setDateBegin('')
                setDateEnd('')
              }}
            >
              <RefreshIcon className="header-title-icon" />
            </Button>
          </Col>
          {/* <Col xs="auto" className="ms-auto"> */}
          {/* <label>Opération commerciale :</label> */}
          {/* <Row className="align-items-center">
              <Col xs="auto">
                <Form.Label className="text-nowrap mb-0">
                  Opération commerciale :
                </Form.Label>
              </Col>
              <Col xs="auto">
                <Form.Select
                  aria-label="Default select example"
                  style={{ width: '150px' }}
                  onChange={(e) => setSelectedMarketingCampaign(e.target.value)}
                >
                  <option value={''}>Toutes</option>
                  {loading ? (
                    <option>Loading...</option>
                  ) : (
                    data?.getDOVoucherMarketingCampaignListing.edges
                      .slice()
                      .map((elem: any) => (
                        <option
                          key={elem?.node.id}
                          value={elem?.node.campaignName}
                        >
                          {elem?.node.campaignName}
                        </option>
                      ))
                  )}
                </Form.Select>
              </Col>
            </Row> */}
          {/* </Col> */}

          <Col xs="auto" className="ms-auto">
            {/* <label>Opération commerciale :</label> */}
            <Row className="align-items-center">
              <Col xs="auto">
                <Form.Label className="text-nowrap mb-0">Magasins :</Form.Label>
              </Col>
              <Col xs="auto">
                <Form.Select
                  aria-label="Default select example"
                  disabled={onlineStore}
                  style={{ width: '200px' }}
                  onChange={(e) => setSelectedStore(parseInt(e.target.value))}
                >
                  <option value={onlineStore ? 0 : ''}>
                    {onlineStore ? '-' : 'Tous'}
                  </option>
                  {loadingStore ? (
                    <option>Loading...</option>
                  ) : (
                    storeData?.getDOStoreListing.edges
                      .slice(1)
                      .map((elem: any) => (
                        <option key={elem?.node.id} value={elem?.node.id}>
                          {onlineStore ? '-' : elem?.node.label}
                        </option>
                      ))
                  )}
                </Form.Select>
              </Col>
            </Row>
          </Col>
          <Col sm="auto">
            <Form.Check
              type="checkbox"
              label="Utilisation site"
              className="float-end mt-2"
              value="yes"
              defaultChecked={false}
              onChange={() => {
                setOnlineStore(!onlineStore)
              }}
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-center mt-4 mb-4">
          <Button
            data-cy="saveShipping"
            className="me-3"
            type="submit"
            disabled={!dateBegin || !dateEnd}
            onClick={() => {
              onRechercherClick()
              setShowDanger(true)
            }}
          >
            {(loadingNew || loadingStore) && (
              <Spinner
                variant="light me-1"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            Rechercher
          </Button>{' '}
        </div>
        {voucherData && csvData.length > 0 && (
          <Table striped bordered hover responsive className="text-center">
            <thead>
              <tr>
                <th>Code avantage</th>
                <th>Date d'utilisation</th>
                <th>Magasin</th>
                <th>Montant</th>
              </tr>
            </thead>
            <tbody>
              {csvData.slice(0, 20).map((elem, index) => (
                <tr key={index}>
                  <td>{elem.voucherCode}</td>
                  <td>{elem.usageDate}</td>
                  <td>{elem.store}</td>
                  <td>{elem.totalAmount}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {voucherData?.getDOVoucherWithDateFilterListing?.edges.length === 0 &&
          showDanger && (
            <Alert
              variant="danger"
              dismissible
              className="mt-4"
              onClose={() => setShowDanger(false)}
            >
              <Alert.Heading>
                {onlineStore
                  ? 'Aucun BDR utilisé sur le site durant cette période'
                  : 'Aucun BDR utilisé par les magasins durant cette période'}
              </Alert.Heading>
            </Alert>
          )}
      </Card>
      {voucherData?.getDOVoucherWithDateFilterListing?.edges.length > 0 && (
        <div className="position-fixed p-2 end-0 bottom-0 me-5 mb-5">
          <CSVLink
            data={csvData}
            headers={headers}
            filename={`BDR_Du_${dateBegin}_Au_${dateEnd}.csv`}
          >
            <Button
              disabled={false}
              variant="primary shadow zindex-2 btn-radius "
              type="button"
            >
              Export CSV
            </Button>
          </CSVLink>
        </div>
      )}
    </div>
  )
}

export default ExportBDRUtilisesEnMagasin
