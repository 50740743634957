import React, {useState} from 'react'
import { getTokenFromLocalStorage } from '../../utils/functions'
import Card from 'components/UI/Card'
import { Button, Row } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'

interface Props {}
const ExportProduitsEssayes: React.FC<Props> = () => {

  const {REACT_APP_PIMCORE_URL_DOMAIN} = process.env

  const [loading, setLoading] = useState<boolean>(false)

  const downloadHandler = async () => {
    try {
      const options = () => {
        return {
          method: 'POST',
          headers: {
            'Accept': 'text/csv',
          },
          body: new URLSearchParams({
            'token': getTokenFromLocalStorage() // Security check
          })
        }
      }

      setLoading(true)
      await fetch(`${REACT_APP_PIMCORE_URL_DOMAIN}/export-produits-essdom`,options())
      .then(response => {
        if(response.status!==200) {
          throw new Error('Error fetching the csv file.')
        }
        else
        {
          const { format } = require('date-fns')
          const today = format(new Date(), 'yyyyMMdd')
          const fileName = `${today}-export-produits-essayes.csv`

          response.text().then( csv => {
            const bytes = [];
            for (let i = 0; i < csv.length; i++) {
              bytes.push(csv.charCodeAt(i) & 0xff);
            }
            // Créer un tableau d'octets avec les codes ASCII
            const byteArray = new Uint8Array(bytes);
            // Convertir le tableau au format ANSI/Windows-1252
            const csvData = new Blob([byteArray], { type:'text/csv;charset=ANSI/Windows-1252;' })
            const a = document.createElement("a");
            a.href = URL.createObjectURL(csvData);
            a.download = fileName;
            a.click();
            a.remove()
          })
        }
      })
      .catch( error => {
        throw new Error(error)
      })
      setLoading(false)

    } catch(err)
    {
      setLoading(false)
      console.log(err)
      alert('Une erreur est survenue')
    }
  }

  return (
    <Card>
      <Row>
        <h2>Télécharger les produits essayés</h2>
      </Row>
      <Row>
        <div className="mt-3">
        <Button
          disabled={loading}
          variant="primary shadow zindex-2 btn-radius "
          type="button"
          onClick={downloadHandler}
        >
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              variant="light"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            'Télécharger'
          )}
        </Button>
        </div>
      </Row>
    </Card>
  )

}

export default ExportProduitsEssayes