import { useQuery } from '@apollo/client'
import ResultsTable from 'components/UI/ResultsTable'
import Card from 'components/UI/Card'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import { useTranslation } from 'react-i18next'
import {
  stringsToJSXs,
  getFormattedDate,
} from 'utils/functions'
import OrderListToggle from 'components/OrderListToggle'
import { constants } from 'utils/constants'
import GET_ORDER_BY_PAYMENT_EVENT_TYPE_LISTING from 'graphql/queries/getDOOrderByPaymentEventTypeListing'

interface Props {}

const CommandesRestantDu: React.FC<Props> = () => {
  const { t } = useTranslation()

  const { data, error, loading } = useQuery(
    GET_ORDER_BY_PAYMENT_EVENT_TYPE_LISTING,
    {
      variables: {
        orderEventTypeKeys: CUSTOMER_MUST_PAY_ORDER_EVENT_KEYS,
      },
    }
  )

  if (loading)
    return (
      <Card>
        <SkeletonBase count={5} />
      </Card>
    )

  if (error) return <p>`Error! ${error.message}`</p>



  const formattedData: any = []

  data.getDOOrderByPaymentEventTypeListing.edges.forEach(
      ({ node: originalNode }: { node: any }, index: number) => {
      const item = { ...originalNode }
      item.node = {}
      item.node.id = item.orderId
      item.node.orderKey = item.orderKey
      item.node.orderKey = (
        <a
          href={`/bo/relation_client/gestion_commande_client/${item.orderId}`}
          target="_blank"
          rel="noreferrer"
          onClick={(e: any) => e.stopPropagation()}
        >
          {item.orderKey}
        </a>
      )
     item.node.fullName = item.customerFullname

      item.node.email = item.customerEmail

       item.node.creationDate = item.orderCreationDate
        ? getFormattedDate(item.orderCreationDate * 1000)
        : ''

      item.node.requestDate = item.eventCreationDate
        ? getFormattedDate(item.eventCreationDate * 1000)
        : ''

      item.node.since = item.eventCreationDate
        ? Math.round(
            (new Date().getTime() / 1000 - item.eventCreationDate) /
              (24 * 60 * 60)
          )
        : '-'

      item.node.orderTotalAmount = (+item.totalAmount).toFixed(2)

      item.node.amountToPay = !isNaN(item.eventMailInfo)
        ? (+item.eventMailInfo).toFixed(2)
        : ''

      // Pass the toggle component itself
      item.node.toggleComponent = OrderListToggle

      item.node.eventTypeKey = item.eventTypeKey

      formattedData.push(item)
    }
  )

  return (
    <div data-cy="amountToPayResult">
      <Card>
        <ResultsTable
          items={formattedData}
          queryType="Commandes restant dû"
          columns={stringsToJSXs(
            [
              'orderKey',
              'fullName',
              'email',
              'creationDate',
              'requestDate',
              'since',
              'orderTotalAmount',
              'amountToPay',
            ],
            t
          )}
        ></ResultsTable>
      </Card>
    </div>
  )
}

export default CommandesRestantDu

export const CUSTOMER_MUST_PAY_ORDER_EVENT_KEYS = [
  constants.eventTypeKeys.ORDER_EVENT_TYPE_CUSTOMER_MUST_PAY_EXTRA,
  constants.eventTypeKeys.ORDER_EVENT_TYPE_AWAITING_PAYMENT_BY_CB,
  constants.eventTypeKeys.ORDER_EVENT_TYPE_AWAITING_PAYMENT_BY_PAYPAL,
  constants.eventTypeKeys.ORDER_EVENT_TYPE_AWAITING_PAYMENT_BY_CHEQUE,
  constants.eventTypeKeys.ORDER_EVENT_TYPE_AWAITING_PAYMENT_BY_BANK_TRANSFER,
  constants.eventTypeKeys.ORDER_EVENT_TYPE_ORDER_AWAITING_PAYMENT_REMINDER
]
