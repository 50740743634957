import { Button, Spinner } from 'react-bootstrap'
import { useState, useContext, useRef, useEffect } from 'react'
import { useMutation, gql } from '@apollo/client'
import { ToastContext } from 'store/toast-context'

interface Props {
  orderId: number
  trayLocation: string
  closeModal?: () => void
}

const OrderChangeTrayLocation: React.FC<Props> = ({
  orderId,
  trayLocation,
  closeModal,
}) => {
  const { toast } = useContext(ToastContext)

  const UPDATE_ORDER_TRAY_LOCATION = gql`
    mutation UPDATE_ORDER_TRAY_LOCATION($id: Int!, $trayLocation: String!) {
      updateDOOrder(id: $id, input: { trayLocation: $trayLocation }) {
        success
        output {
          id
          trayLocation
        }
      }
    }
  `

  const [updateDOOrderTrayLocation, { loading }] = useMutation(
    UPDATE_ORDER_TRAY_LOCATION,
    {
      onCompleted: (data) => {
        toast({
          title: 'Nouvel emplacement pénichier :',
          message: `${data.updateDOOrder.output.trayLocation}`,
        })
      },
    }
  )

  const [newTrayLocation, setNewTrayLocation] = useState('')
  const trayLocationInput = useRef<HTMLInputElement>(null)

  const updateDOOrderTrayLocationHandler = async () => {
    try {
      await updateDOOrderTrayLocation({
        variables: {
          id: +orderId,
          trayLocation: newTrayLocation,
        },
      })

      if (closeModal)
        // Si on connait la fonction qui permet de fermer la modale, on la ferme
        closeModal()
    } catch (ex) {
      console.log(ex)
    }
  }

  useEffect(() => {
    trayLocationInput.current?.focus()
  }, []) 

  return (
    <>
      <h4>
        <strong>Emplacement pénichier</strong>
      </h4>
      <div className="m-2">
        Ajouter ou modifier l'emplacement de cette commande
        <br />
        (Emplacement actuel:{' '}
        {trayLocation === null || trayLocation?.trim() === ''
          ? 'aucun'
          : trayLocation}
        )
      </div>
      <div className="mt-3 m-2">
        <input
          ref={trayLocationInput}
          className="w-50"
          type="text"
          onChange={(e: any) => setNewTrayLocation(e.target.value)}
        />
      </div>
      <div className="mt-3 text-center">
        <Button
          className="m-2 btn btn-primary"
          disabled={newTrayLocation === '' || loading}
          onClick={() => updateDOOrderTrayLocationHandler()}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Enregistrer
        </Button>
        <Button
          variant="outline-primary bg-white text-primary"
          className="m-2"
          onClick={() => (closeModal ? closeModal() : null)}
        >
          Annuler
        </Button>
      </div>
    </>
  )
}

export default OrderChangeTrayLocation
