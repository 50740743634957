import { gql } from '@apollo/client'
import { DO_ORDER_EVENT_FIELDS } from 'graphql/fragments/DOOrderEventFields'

const BULK_DELETE_DO_ORDERLINES = gql`
  ${DO_ORDER_EVENT_FIELDS}

  mutation DELETE_DO_ORDERLINES($orderLineIdList: String!, $comment: String!) {
    bulkDeleteDOOrderLines(
      orderLineIdList: $orderLineIdList
      comment: $comment
    ) {
      success
      output {
        deleteSuccessOrderLinesIds
        deleteFailOrderLinesIds
        order {
          id
          events {
            ...DOOrderEventFields
          }
          orderLines {
            ... on object_DOOrderLine {
              id
              status
              state
              stockState
              items {
                ... on object_DOOrderLineItem {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`

export default BULK_DELETE_DO_ORDERLINES
