interface Props {
  onChangeSearchFilter: (event: any) => any;
  chosenFilter: string;
}

const Radio: React.FC<Props> = (props: any) => {
  return (
    <div onChange={props.onChangeSearchFilter}>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="radio"
          name="searchFilter"
          id="commande"
          value="commande"
          checked={props.chosenFilter === "commande"}
          readOnly
        />
        <label className="form-check-label" htmlFor="commande">
          Commande
        </label>
      </div>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="radio"
          name="searchFilter"
          id="client"
          value="client"
          checked={props.chosenFilter === "client"}
          readOnly
        />
        <label className="form-check-label" htmlFor="client">
          Prénom / Nom du Bénéficiaire / Client
        </label>
      </div>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="radio"
          name="searchFilter"
          id="email"
          value="email"
          checked={props.chosenFilter === "email"}
          readOnly
        />
        <label className="form-check-label" htmlFor="email">
          Email du client
        </label>
      </div>
    </div>
  );
};

export default Radio;
