import { useMutation } from '@apollo/client'
import InputField from 'components/InputValidations/InputField'
import React, { useEffect, useState, useContext } from 'react'
import { Form, Button, Spinner } from 'react-bootstrap'
import { getDateInputFormat, getValidityYears, formatPrescriptionValue } from '../../../utils/functions'
import { constants } from 'utils/constants'
import SelectPrescription from '../SelectPrescription'
import UPDATE_DO_PRESCRIPTION from 'graphql/mutations/updateDOPrescription'
import { ToastContext } from 'store/toast-context'
import { CQ_GET_ORDER } from 'graphql/queries/getOrder'

interface Props {
  prescription: prescriptionType
  orderLines: orderLineType[]
  order: orderType
  beneficiary: beneficiaryType
}
const PrescriptionForm: React.FC<Props> = ({
  prescription,
  orderLines,
  order,
  beneficiary,
}) => {
  const { toast } = useContext(ToastContext)

  // Input field values
  const [defaultValues, setDefaultValues] = useState<any>({})
  const [values, setValues] = useState<any>({})
  const [editedValues, setEditedValues] = useState<any>({})
  const [noMutationPerformed, setNoMutationPerformed] = useState<boolean>(false)
  const validityYears = beneficiary?.birthDate? getValidityYears(beneficiary.birthDate) : null
  // wtc (what to copy) : all/cor/eps (everything/corrections only/pupillary distances only)
  const [selectedFormerPrescription, setSelectedFormerPrescription] = useState<any>({id: null, wtc: ''})

  // Validator functions
  const correctionSphereValidator = (value: any) => value < -12 || value > 12 ? 'Valeur entre -12 et 12' : ''
  const correctionCylinderValidator = (value: any) => value < -8 || value > 8 ? 'Valeur entre -8 et 8' : ''
  const correctionAxisValidator = (value: any) => value < 0 || value > 180 ? 'Valeur entre 0 et 180' : ''
  const correctionAdditionValidator = (value: any) => value < 0 || value > 4 ? 'Valeur entre 0 et 4' : ''
  const pupillaryDistanceValidator = (value: any) => value < 20 || value > 45 ? 'Valeur entre 20 et 45' : ''

  const elemOfPrescriptionForm =
  [
    {
      title: 'Information',
      inputsGroup: [
        [
          {
            inputType: 'date',
            inputName: 'prescriptionDate',
            isRequired: true,
            copyable: true
          },
          {
            inputType: 'text',
            inputName: 'prescriberNumber',
            copyable: true
          },
        ],
        [
          { 
            inputType: 'text', 
            inputName: 'prescriberNIN', 
          },
          {
            inputType: 'text',
            inputName: 'prescriberFullname',
            copyable: true
            // isRequired: true
          },
        ],
        [{ 
          inputType: 'toggle', 
          inputName: 'refund'
        }],
      ],
    },
    {
      title: 'Écart pupillaire',
      inputsGroup: [
        [
          {
            inputType: 'select',
            inputName: 'pupillaryDistanceSource',
            optionList: constants.pupillaryDistanceSourceList
          },
        ],
        [
          {
            inputType: 'number',
            inputName: 'pupillaryDistanceRight',
            additionalAttributes: { step: 0.5, min: 20.0, max: 45.0 },
            inputClassName: 'w-150',
            validator: pupillaryDistanceValidator
          },
          {
            inputType: 'number',
            inputName: 'pupillaryDistanceLeft',
            additionalAttributes: { step: 0.5, min: 20.0, max: 45.0 },
            validator: pupillaryDistanceValidator,
            inputClassName: 'w-150',
          },
        ],
      ],
    },

    {
      title: 'Correction',
      inputsGroup: [
        [
          {
            inputType: 'select',
            inputName: 'prescriptionSource',
            optionList: constants.prescriptionSourceList
          },
        ],
        [
          {
            inputType: 'text',
            inputName: 'rightSphere',
            validator: correctionSphereValidator,
            inputClassName: 'w-90px pe-2',
          },
          {
            inputType: 'text',
            inputName: 'rightCylinder',
            validator: correctionCylinderValidator,
            inputClassName: 'w-90px pe-2',
          },
          {
            inputType: 'text',
            inputName: 'rightAxis',
            validator: correctionAxisValidator,
            inputClassName: 'w-90px pe-2',
          },
          {
            inputType: 'text',
            inputName: 'rightAddition',
            validator: correctionAdditionValidator,
            inputClassName: 'w-90px pe-2',
          },
        ],
        [
          {
            inputType: 'text',
            inputName: 'leftSphere',
            validator: correctionSphereValidator,
            inputClassName: 'w-90px pe-2',
          },
          {
            inputType: 'text',
            inputName: 'leftCylinder',
            validator: correctionCylinderValidator,
            inputClassName: 'w-90px pe-2',
          },
          {
            inputType: 'text',
            inputName: 'leftAxis',
            validator: correctionAxisValidator,
            inputClassName: 'w-90px pe-2',
          },
          {
            inputType: 'text',
            inputName: 'leftAddition',
            validator: correctionAdditionValidator,
            inputClassName: 'w-90px pe-2',
          },
        ],
        [
          {
            inputType: 'textarea',
            inputName: 'customerComment',
            inputClassName: 'w-400 disable'
          },
        ],
        [
          {
            inputType: 'textarea',
            inputName: 'internalComment',
            inputClassName: 'w-400'
          },
        ],
      ],
    },

    {
      title: 'Type de vision',
      inputsGroup: [
        [
          {
            inputType: 'radio',
            inputName: 'pupillaryDistanceVisionType',
            optionList: ['vl', 'vp', 'plus_tard']
          },
        ],
        [
          {
            inputType: 'radio',
            inputName: 'prescriptionVisionType',
            optionList: ['vl', 'vp', 'vl_vp', 'plus_tard']
          },
        ],
      ],
    },
  ]

  const [isPrescriptionFormDisabled, setIsPrescriptionFormDisabled] = useState<boolean>(false)

  const disablePrescriptionForm = (orderLines: orderLineType[]) => orderLines.map((orderLine: orderLineType) => orderLine.state).includes('fac')
  const hasDataToSave = () => !!Object.keys(editedValues).length

  useEffect(() => {
    const { refund, prescriberNumber, prescriberNIN, prescriberFullname } = prescription
    const formatPrescriptionDate = prescription.prescriptionDate
      ? getDateInputFormat(prescription.prescriptionDate)
      : ''
    const defaultValues = {
      refund: refund === null ? false : refund,
      prescriptionDate: formatPrescriptionDate === undefined ? '' : formatPrescriptionDate,
      prescriberNumber: prescriberNumber,
      prescriberNIN: prescriberNIN,
      prescriberFullname: prescriberFullname,
      rightSphere:prescription.rightEyeCorrections? formatPrescriptionValue(prescription.rightEyeCorrections?.[0].sphere):'+0.00',
      rightCylinder: prescription.rightEyeCorrections? formatPrescriptionValue(prescription.rightEyeCorrections?.[0].cylinder):'+0.00',
      rightAxis: prescription.rightEyeCorrections? Number(prescription.rightEyeCorrections?.[0].axis)?.toString():'0',
      rightAddition: prescription.rightEyeCorrections? formatPrescriptionValue(prescription.rightEyeCorrections?.[0].addition):'+0.00',
      leftSphere: prescription.leftEyeCorrections? formatPrescriptionValue(prescription.leftEyeCorrections?.[0].sphere):'+0.00',
      leftCylinder: prescription.leftEyeCorrections? formatPrescriptionValue(prescription.leftEyeCorrections?.[0].cylinder):'+0.00',
      leftAxis: prescription.leftEyeCorrections? Number(prescription.leftEyeCorrections?.[0].axis)?.toString():'0',
      leftAddition: prescription.leftEyeCorrections?formatPrescriptionValue(prescription.leftEyeCorrections?.[0].addition):'+0.00',
      prescriptionSource: prescription.source,
      prescriptionVisionType: prescription.prescriptionVisionType,
      customerComment: prescription.customerComment,
      internalComment: prescription.internalComment,
      pupillaryDistanceVisionType: prescription.pupillaryDistanceVisionType,
      pupillaryDistanceSource: prescription.pupillaryDistanceSource,
      pupillaryDistanceRight: prescription.rightEyeCorrections?.[0].pupillaryDistance === null ?
        '' : prescription.rightEyeCorrections?.[0].pupillaryDistance,
      pupillaryDistanceLeft: prescription.leftEyeCorrections?.[0].pupillaryDistance === null ?
        '' : prescription.leftEyeCorrections?.[0].pupillaryDistance
    }
    setDefaultValues(defaultValues)
    setValues(defaultValues)
    setIsPrescriptionFormDisabled(disablePrescriptionForm(orderLines))
  }, [prescription, orderLines])  

  useEffect(() => {
    let invalidValues = Object.keys(values)?.filter((key) => {
        //if values are out of range
          return ((key.includes('Sphere') && (Number(values[key])>12 || Number(values[key]<-12)))
          ||(key.includes('Cylinder') && Number(values[key]>8||Number(values[key]<-8)))
          ||(key.includes('Axis') &&  Number(values[key]>180||Number(values[key]<0))) 
          ||(key.includes('Add') && Number(values[key]>4||Number(values[key]<0))))
    })
    setEditedValues(() => {
      if(!invalidValues.length && Object.keys(values).length)
      {
        let reducedValues = Object.keys(values).map(key => {
          const valuesTypeNumber = ['rightSphere','rightCylinder','rightAxis','rightAddition',
            'leftSphere','leftCylinder','leftAxis','leftAddition']
            if(values[key]&& ['pupillaryDistanceRight','pupillaryDistanceLeft'].includes(key))
              valuesTypeNumber.push(key)
          let isNumber = valuesTypeNumber.includes(key)
          return (isNumber ?
            Number(values[key]) !== Number(defaultValues[key]) : 
            values[key] !== defaultValues[key]) ? {[key]: (isNumber ? Number(values[key]) : values[key])} : {}
        }).reduce((prev: any, current: any) => Object.assign(prev, current))
  
        if(Object.keys(reducedValues).length)
          return Object.assign({id: +prescription.id}, reducedValues)
      }
      return {}
    })
    // Reset former prescription link if any value is changed 
    setSelectedFormerPrescription({id:null,wtc:''})
  }, [values, defaultValues, prescription.id])

  useEffect(() => {
    if(noMutationPerformed)
    {
      toast({
        title: 'Modification du fichier enregistrée pour :',
        message: `Ordonnance id ${prescription.id}`,
      })
      setNoMutationPerformed(false)
    }

  }, [noMutationPerformed, toast, prescription.id])

  useEffect(() => {
    const formerOrderPrescription = beneficiary.prescriptions.find( (prescription: any) => parseInt(prescription.id) === selectedFormerPrescription.id)
    if(formerOrderPrescription)
    {
      switch(selectedFormerPrescription.wtc)
      {
        case 'eps':
          setValues({
            ...values,
            pupillaryDistanceRight: formerOrderPrescription?.rightEyeCorrections?.[0].pupillaryDistance,
            pupillaryDistanceLeft: formerOrderPrescription?.leftEyeCorrections?.[0].pupillaryDistance,
            pupillaryDistanceSource: 'deja_commande'
          })
          break;
        case 'cor':
          setValues({
            ...values,
            rightSphere: formerOrderPrescription?.rightEyeCorrections? formatPrescriptionValue(formerOrderPrescription.rightEyeCorrections[0].sphere):'+0.00',
            rightCylinder: formerOrderPrescription?.rightEyeCorrections? formatPrescriptionValue(formerOrderPrescription.rightEyeCorrections[0].cylinder):'+0.00',
            rightAxis: formerOrderPrescription?.rightEyeCorrections? Number(formerOrderPrescription.rightEyeCorrections[0].axis)?.toString():'0',
            rightAddition: formerOrderPrescription?.rightEyeCorrections? formatPrescriptionValue(formerOrderPrescription.rightEyeCorrections[0].addition):'+0.00',
            leftSphere: formerOrderPrescription?.leftEyeCorrections? formatPrescriptionValue(formerOrderPrescription.leftEyeCorrections[0].sphere):'+0.00',
            leftCylinder: formerOrderPrescription?.leftEyeCorrections? formatPrescriptionValue(formerOrderPrescription.leftEyeCorrections[0].cylinder):'+0.00',
            leftAxis: formerOrderPrescription?.leftEyeCorrections? Number(formerOrderPrescription.leftEyeCorrections[0].axis)?.toString():'0',
            leftAddition: formerOrderPrescription?.leftEyeCorrections?formatPrescriptionValue(formerOrderPrescription?.leftEyeCorrections[0].addition):'+0.00',
            prescriptionSource: 'ordo_precedente'
          })
          break;
        default:
          setValues({
            ...values,
            rightSphere:formerOrderPrescription?.rightEyeCorrections? formatPrescriptionValue(formerOrderPrescription.rightEyeCorrections[0].sphere):'+0.00',
            rightCylinder: formerOrderPrescription?.rightEyeCorrections? formatPrescriptionValue(formerOrderPrescription.rightEyeCorrections[0].cylinder):'+0.00',
            rightAxis: formerOrderPrescription?.rightEyeCorrections? Number(formerOrderPrescription.rightEyeCorrections[0].axis)?.toString():'0',
            rightAddition: formerOrderPrescription?.rightEyeCorrections? formatPrescriptionValue(formerOrderPrescription.rightEyeCorrections[0].addition):'+0.00',
            leftSphere: formerOrderPrescription?.leftEyeCorrections? formatPrescriptionValue(formerOrderPrescription.leftEyeCorrections[0].sphere):'+0.00',
            leftCylinder: formerOrderPrescription?.leftEyeCorrections? formatPrescriptionValue(formerOrderPrescription.leftEyeCorrections[0].cylinder):'+0.00',
            leftAxis: formerOrderPrescription?.leftEyeCorrections? Number(formerOrderPrescription.leftEyeCorrections?.[0].axis)?.toString():'0',
            leftAddition: formerOrderPrescription?.leftEyeCorrections?formatPrescriptionValue(formerOrderPrescription?.leftEyeCorrections[0].addition):'+0.00',
            prescriptionSource: 'ordo_precedente',
            pupillaryDistanceRight: formerOrderPrescription?.rightEyeCorrections?.[0].pupillaryDistance,
            pupillaryDistanceLeft: formerOrderPrescription?.leftEyeCorrections?.[0].pupillaryDistance,
            pupillaryDistanceSource: 'deja_commande'
          })
      }
    }
  }, [selectedFormerPrescription, beneficiary.prescriptions, values])

  //fonction qui fera la mutation d'updatePrescription si tous les champs sont valides
  const updatePrescriptionPromise = async () => {
    if(hasDataToSave())
    {
      try {
        updatePrescription({
          variables: editedValues,
          refetchQueries: [CQ_GET_ORDER],
          awaitRefetchQueries: true
        })
      } catch (e) {
        // NB : gestion erreur dans App.tsx
      }
    }
    else
    {
      // In case there is only the file to upload
      // flag the toast with the right message
      setNoMutationPerformed(true)
    }
  }

  const [
    updatePrescription,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(UPDATE_DO_PRESCRIPTION, {
    onCompleted: (data) => {
      if (data && !mutationError) {
        toast({
          title: 'Modifications enregistrées pour :',
          message: `Ordonnance id ${prescription.id}`,
        })
      }
      // setPerformAction(false)
    },
    update(cache) {
      // by default js force a date null value to be 1970-1-1
      // use cache modify to fix that
      if (
        !values.prescriptionDate ||
        values.prescriptionDate === 'yyyy-MM-dd'
      ) {
        cache.modify({
          id: `object_DOPrescription:${prescription.id}`,
          fields: {
            prescriptionDate() {
              return null //value of reset date
            },
          },
        })
      }
    },
  })

  // reset prescription form without saving
  const resetHandler = () => {
    if (
      window.confirm(
        'Êtes-vous sûr de vouloir supprimer toutes les informations de l’ordonnance (date, médecin et corrections) ?\nIl faudra cliquer ensuite sur le bouton « Enregistrer cette ordonnance » pour prendre en compte cette action.'
      )
    ) {
      setValues({
        ...values,
        refund: false,
        prescriptionDate: '',
        prescriberNumber: '',
        prescriberNIN: '',
        prescriberFullname: '',
        rightSphere: '+0.00',
        rightCylinder: '+0.00',
        rightAxis: '0',
        rightAddition: '+0.00',
        leftSphere: '+0.00',
        leftCylinder: '+0.00',
        leftAxis: '0',
        leftAddition: '+0.00',
        prescriptionSource: 'saisie',
        internalComment: '',
        prescriptionVisionType: 'plus_tard',
        pupillaryDistanceVisionType: 'plus_tard',
      })
    }
  }

  return (
    <Form
      className="p-2"
    >
      {!!Object.keys(values).length && elemOfPrescriptionForm.map((elem: any, i: number) => {
        return (
          <React.Fragment key={i}>
            <h5>{elem.title}</h5>
            {elem.inputsGroup.map((inputGroup: any, j: number) => {
              if (inputGroup[0].inputName === 'refund') {
                return (
                  <React.Fragment key={j}>
                    <InputField 
                      key={inputGroup[0].inputName}
                      inputType={inputGroup[0].inputType}
                      inputName={inputGroup[0].inputName}
                      disabled={isPrescriptionFormDisabled}
                      defaultValue={defaultValues[inputGroup[0].inputName] ? 'true' : ''}
                      values={values}
                      setValues={setValues}
                    />
                    <SelectPrescription
                      beneficiary={beneficiary}
                      disabled={isPrescriptionFormDisabled}
                      setSelectedFormerPrescription={setSelectedFormerPrescription}
                    />
                  </React.Fragment>
                )
              } else if (inputGroup[0].inputType === 'select' || inputGroup[0].inputType === 'radio') {
                return (
                  <InputField 
                    key={inputGroup[0].inputName}
                    inputType={inputGroup[0].inputType}
                    inputName={inputGroup[0].inputName}
                    labelTop={true}
                    inputClassName="w-320"
                    disabled={isPrescriptionFormDisabled}
                    defaultValue={defaultValues[inputGroup[0].inputName]}
                    values={values}
                    setValues={setValues}
                    options={inputGroup[0].optionList}
                  />
                )
              } else {
                return (
                  <React.Fragment key={j}>
                  {(inputGroup[0].inputName !== 'customerComment' || !!defaultValues[inputGroup[0].inputName]?.length) && <div className="d-flex align-items-center" key={i + j}>
                    {elem.title === 'Correction' && j === 1 && <div className="eye-side-label">Droit</div>}
                    {elem.title === 'Correction' && j === 2 && (
                      <div className="eye-side-label">Gauche</div>
                    )}
                    {inputGroup.map((input: any) => {
                      return (
                        <InputField 
                          key={input.inputName}
                          inputType={input.inputType}
                          inputName={input.inputName}
                          labelTop={true}
                          inputClassName={input.inputClassName}
                          disabled={isPrescriptionFormDisabled}
                          defaultValue={defaultValues[input.inputName]}
                          values={values}
                          setValues={setValues}
                          additionalAttributes={input?.additionalAttributes}
                          validator={input?.validator}
                          validityYears = {validityYears}
                          isReadonly={inputGroup[0].inputName === 'customerComment'}
                          copyable={input.copyable}
                        />
                      )
                    })}
                  </div>}
                  </React.Fragment>
                )
              }
            })}
          </React.Fragment>
        )
      })}
      <div className="d-flex justify-content-center ">
        <Button
          onClick={updatePrescriptionPromise}
          disabled={!hasDataToSave() || mutationLoading}
        >
          {mutationLoading ? (
            <>
              {' '}
              Enregistrement en cours{' '}
              <Spinner
                variant="light ms-2"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </>
          ) : (
            <> Enregistrer cette ordonnance</>
          )}
        </Button>
        <Button
          variant="outline-secondary ms-2"
          onClick={resetHandler}
        >
          Réinitialiser
        </Button>
      </div>
    </Form>
  )
}

export default PrescriptionForm