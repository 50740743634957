import gql from 'graphql-tag'
import { DO_ORDER_EVENT_FIELDS } from 'graphql/fragments/DOOrderEventFields'
import { ORDER_TOTAL_AMOUNT_FIELDS } from 'graphql/fragments/DOOrderTotalAmountFCFields'

/*  cosiumId, totalPaidAmount, workflowState and totalAmountHistory
    added to accommodate the merging of both cosium popups in one */
const GET_ORDER_EVENTS = gql`
  ${DO_ORDER_EVENT_FIELDS}
  ${ORDER_TOTAL_AMOUNT_FIELDS}
  query GET_ORDER_EVENTS($id: Int!) {
    CQgetOrder(id: $id) {
      edges {
        node{        
          order{
            id
            workflowState
            totalAmountHistory {
              ...DOOrderTotalAmountFCFields
            }
            orderType
            events {
              ...DOOrderEventFields
            }
          }
          # needed for the modal OrderCosiumAllInModal
          calculators {
            cosiumId
            totalPaidAmount
          }
        }
      }
    }
  }
`

export default GET_ORDER_EVENTS
