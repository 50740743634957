import { gql } from '@apollo/client'
import { DO_BENEFICIARY_FIELDS } from 'graphql/fragments/DOBeneficiaryFields'
import { DO_CONTACT_INFO_FC_FIELDS } from 'graphql/fragments/DOContactInfoFCFields'
import { DO_CONTACT_NAME_FC_FIELDS } from 'graphql/fragments/DOContactNameFCFields'
import { DO_ORDER_EVENT_FIELDS } from 'graphql/fragments/DOOrderEventFields'
import { DO_VISION_CORRECTION_FC_FIELDS } from 'graphql/fragments/DOVisionCorrectionFCFields'

export const GET_ORDER_LISTING = gql`
  ${DO_CONTACT_NAME_FC_FIELDS}
  ${DO_CONTACT_INFO_FC_FIELDS}
  ${DO_VISION_CORRECTION_FC_FIELDS}
  ${DO_BENEFICIARY_FIELDS}
  ${DO_ORDER_EVENT_FIELDS}
  query GET_ORDER_LISTING($filter: String) {
    getOrderListing(filter: $filter) {
      totalCount
      edges {
        node {
            order {
                ... on object_DOOrder {
                    id
                    key
                    workflowState
                    deliveryMode {
                        ... on object_DODeliveryMode {
                        id
                        label
                        key
                        }
                    }
                    deliveryName {
                        ...DOContactNameFCFields
                    }
                    deliveryAddress {
                        ...DOContactInfoFCFields
                    }
                    events {
                        ...DOOrderEventFields
                    }
                    deliveryStore {
                        ... on object_DOStore {
                        id
                        label
                        }
                    }
                    orderLines {
                        ... on object_DOOrderLine {
                        prescription {
                            ... on object_DOPrescription {
                            id
                            prescriptionDate
                            prescriberFullname
                            rightEyeCorrections {
                                ...DOVisionCorrectionFCFields
                            }
                            leftEyeCorrections {
                                ...DOVisionCorrectionFCFields
                            }
                            beneficiary {
                                ...DOBeneficiaryFields
                            }
                            }
                        }
                        }
                    }
                    orderType
                    creationDate
                    customer {
                        ... on object_DOCustomer {
                        id
                        contactName {
                            ...DOContactNameFCFields
                        }

                        contactInfo {
                            ...DOContactInfoFCFields
                        }
                        }
                    }
                }
            }
            calculators{
                cosiumId
                orderPaidDate
                orderSentToStoreDate
                mailsSentCount
                productCount
            }
        }
      }
    }
  }
`
