import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { CheckIcon } from '@heroicons/react/solid'
import { formatPrescriptionValue, isPrescriptionValid, getPrescriptionWarning } from 'utils/functions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-regular-svg-icons'

interface Props {
  inputType: string
  inputName: string
  inputClassName?: string
  labelTop?: boolean
  required?: boolean
  disabled: boolean
  defaultValue: string
  values: any[any]
  setValues: React.Dispatch<React.SetStateAction<any>>
  additionalAttributes?: any
  options?: any
  format?: RegExp
  validator?: (value: any) => string,
  validityYears?:1|3|5|null
  isReadonly?: boolean
  copyable?: boolean
}

const InputText: React.FC<Props> = ({
  inputType,
  inputName,
  labelTop,
  inputClassName,
  required,
  disabled,
  defaultValue,
  values,
  setValues,
  additionalAttributes,
  options,
  format,
  validator,
  validityYears,
  isReadonly,
  copyable
}) => {
  const { t } = useTranslation()

  const [borderColor, setBorderColor] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [fadeStyle, setFadeStyle] = useState({})

  const handleChange = (e: any) => {
    //if inputName is an input of correction and e.target.value is non digit 
    if(['Sphere','Cylinder','Axis','Add'].some(el => inputName.includes(el)) && !/^-?\d*[.,]?\d*$/.test(e.target.value)){
      //remove non digit character
      setValues({
        ...values,
        [inputName]: e.target.value.replace(/[^\d.-]+/g, '')
      })
    }else {
      if(format !== undefined && format !== null && !format.test(e.target.value))
        e.target.value = values[inputName]

      setValues({
        ...values,
        [inputName]: inputType === 'toggle' ? !values[inputName] : e.target.value
      })

      if(!errorMessage.length)
        setBorderColor('')
    }
  }

  const handleFocusOut = () => {
    if(['Sphere','Cylinder','Add'].some(el => inputName.includes(el)) ){
       //format correction with 2 decimals 
      values[inputName] = formatPrescriptionValue(values[inputName])
    }
    if(inputName.includes('Axis') && !values[inputName]){
      //default axis 0
      values[inputName] = '0'
    }
    if(inputName.includes('pupillaryDistance') && (!values[inputName]||values[inputName]>45||values[inputName]<20)){
      //default PD null
      values[inputName] = null
    }
    setValues({
      ...values,
      [inputName]: values[inputName]
      })
      
    //handle error 
    // empty input
    if(required && !values[inputName]?.length)
      setErrorMessage('Veuillez renseigner ce champ')
    //validator error
    else if(validator !== undefined && validator(Number(values[inputName]))){
      setErrorMessage(validator(Number(values[inputName])))
      setBorderColor('is-invalid')
    }
    // no error
    else{
      setErrorMessage('')
      setBorderColor(getBorderColor())
    }
  }
  const handleSelectAll = (event: any) => {
    event.target.select()
  }

  const getBorderColor = () => {
    //if value change add green border otherwise no color
    return (inputType === 'number' ? 
      Number(values[inputName]) !== Number(defaultValue) : 
      values[inputName] !== defaultValue) ? 'is-valid' : '' 
  }
  // Copy text
  const copyToClipboard = (inputName: string) => {
    let text = values[inputName] as string
    if (inputName.match(/date/i))
      text = new Intl.DateTimeFormat('fr', {
              dateStyle: 'short',
            }).format(new Date(values[inputName])).toString()
    navigator.clipboard.writeText(text).then(() => {
      // Start fade effect
      setFadeStyle({ opacity: 1, transition: 'opacity 1s' })
      // Remove fade effect after 1 second
      setTimeout(
        () => setFadeStyle({ opacity: 0, transition: 'opacity 1s' }),
        100
      )
      // Reset style after the transition ends
      setTimeout(() => setFadeStyle({}), 500)
    })
  } 
  
  useEffect(() => {
    if(errorMessage.length)
      setBorderColor('is-invalid')
  }, [errorMessage])

  useEffect(() => {
    setBorderColor('')
  }, [defaultValue])

  useEffect(()=>{
    if(inputName==='prescriptionDate' && values[inputName] && validityYears 
    && !isPrescriptionValid('',values[inputName], validityYears)){
      setErrorMessage(getPrescriptionWarning(validityYears))
      setBorderColor('is-invalid')
    }
  },[setErrorMessage,validityYears,inputName, values ])
  return (
    <>
    {inputType !== 'toggle' && 
      <Form.Group
      controlId={inputName}
      className={`d-flex m-2 ${labelTop && 'flex-column'}`}
      >
        <Form.Label className='mb-0'>
          <pre className="label_pre mb-2">
            {t(inputName)} {required && <sup>*</sup>}
          </pre>
        </Form.Label>
        <div className="d-flex flex-column">
          {(['text','number','date'].includes(inputType)) && 

            <div className='d-flex align-items-center'>
              <Form.Control
                  disabled={disabled}
                  type={inputType}
                  name={inputName}
                  className={`${borderColor} ${inputClassName && inputClassName}`}
                  placeholder={['Sphere','Cylinder','Add'].some(el => inputName.includes(el)) ? '+0.00' : inputName.includes('Axis') ? '0' : t(inputName)}
                  onChange={handleChange}
                  onBlur={handleFocusOut}
                  onClick={handleSelectAll}
                  step={additionalAttributes?.step}
                  min={additionalAttributes?.min}
                  max={additionalAttributes?.max}
                  value={values[inputName]?values[inputName]:''}
                  readOnly={isReadonly??false}
                />
                {/* If copyable copy text */}
                {copyable && <FontAwesomeIcon
                  onClick={() => copyToClipboard(inputName)}
                  size='lg'
                  icon={faCopy}
                  style={{ cursor: 'pointer', marginLeft: '10px', ...fadeStyle }}
                />}
            </div>        
          }
          {inputType === 'textarea' && 
            <Form.Control
              disabled={disabled}
              className={`${borderColor} ${inputClassName && inputClassName}`}
              type={inputType}
              name={inputName}
              as="textarea"
              rows={2}
              placeholder={t(inputName)}
              onChange={handleChange}
              onBlur={handleFocusOut}
              value={values[inputName]?values[inputName]:''}
              readOnly={isReadonly??false}
            />
          }
          {inputType === 'select' &&
            <Form.Select
              disabled={disabled}
              className={`${borderColor} ${inputClassName && inputClassName}`}
              name={inputName}
              value={values[inputName]??''}
              onChange={handleChange}
              onBlur={handleFocusOut}
            >
              <option key='' value=''></option>
              {options.map(
                (option: any, k: number) => {
                  return (
                    <option key={option.key + k} value={option.value}>
                      {option.key}
                    </option>
                  )
                }
              )}
            </Form.Select>
          }
          {inputType === 'radio' && 
            <div className="d-flex">
            {options.map((option: any, index: number) => {
              return (
                <Form.Check
                  disabled={disabled}
                  key={option}
                  inline
                  label={t(option)}
                  name={inputName}
                  type="radio"
                  id={inputName + option + index}
                  value={option}
                  checked={values[inputName] === option}
                  onChange={handleChange}
                />
              )
            })}
            {/* Radio buttons will never have error messages */}
            {values[inputName] !== defaultValue && (
              <CheckIcon className="header-title-icon text-success" />
            )}
            </div>
          }
        </div>
        <div className="text-danger small-content input-error-msg">{errorMessage}</div>
      </Form.Group>}
    {inputType === 'toggle' &&
      <Form.Group
      className="d-flex m-2"
      key={inputName}
      >
        <Form.Label className="me-1">Remboursement ?</Form.Label>
        <div
          className={`d-flex mb-2 ${values[inputName]} ? 'text-danger' : 'text-secondary'`}
        >
          <Form.Check
            type="switch"
            id="refund"
            checked={values[inputName]}
            onChange={handleChange}
            disabled={disabled}
          />
          {values[inputName] !== (defaultValue === 'true') && (
            <CheckIcon className="header-title-icon text-success ms-3" />
          )}
        </div>
      </Form.Group>
    }
    </>
  )
}

export default InputText