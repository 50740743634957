import { useParams } from 'react-router-dom'
import Card from '../../components/UI/Card'
import VoucherDetails from './VoucherDetails'
import { gql, useMutation, useQuery } from '@apollo/client'
import GET_DO_VOUCHER from 'graphql/queries/getDOVoucher'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import { useContext } from 'react'
import AuthContext from 'store/auth-context'
import { DO_VOUCHER_FIELDS } from 'graphql/fragments/DOVoucherFields'

const GestionBDR = () => {
  const { id } = useParams<ParamType>()
  const authCtx = useContext(AuthContext)

  /** Retrieving voucher */
  const {
    loading: voucherLoading,
    data: voucherData,
    error: voucherError,
  } = useQuery(GET_DO_VOUCHER, {
    variables: {
      id: +id,
    },
  })

  /** Apply voucher in store */
  const APPLY_VOUCHER_IN_STORE = gql`
    ${DO_VOUCHER_FIELDS}
    mutation APPLY_VOUCHER_IN_STORE(
      $id: Int!
      $usageDate: String!
      $storeId: Int!
      $withoutNewVoucherCode: Boolean = true
    ) {
      updateDOVoucher(
        id: $id
        input: {
          voucherState: "utilise"
          isStoreUsed: true
          voucherUsage: {
            replace: false
            items: {
              DOVoucherUsageFC: [
                {
                  usageDate: $usageDate
                  store: { id: $storeId, type: "object" }
                }
              ]
            }
          }
        }
      ) {
        success
        output {
          ...DOVoucherFields
        }
      }
    }
  `
  const [applyVoucherInStore] = useMutation(APPLY_VOUCHER_IN_STORE, {
    variables: {
      id: +id,
      usageDate: `${new Date().toISOString()}`,
      storeId: parseInt(authCtx.user.storeId),
    },
  })

  // Returns nothing if id null
  if (id == null) {
    return (
      <Card>
        <h1>Bon de réduction</h1>
        <p>Aucun bon de réduction recherché</p>
      </Card>
    )
  }

  if (voucherLoading) {
    return (
      <Card>
        <h1>Bon de réduction</h1>
        <SkeletonBase count={6} />
      </Card>
    )
  }
  if (voucherError && !voucherError.message.match(/object with(.*)not found/))
    return <p>`Error! ${voucherError.message}`</p>

  if (!voucherData)
    return (
      <Card>
        <h1>Bon de réduction</h1>
        <p>Aucun bon de réduction trouvé</p>
      </Card>
    )

  return (
    <Card>
      <h1>Bon de réduction {voucherData.getDOVoucher.voucherCode}</h1>
      <VoucherDetails
        voucher={voucherData.getDOVoucher}
        mutationFunction={applyVoucherInStore}
        btnLabel="Utiliser le bon"
        confirmMessage="Souhaitez-vous utiliser le bon :"
        toastTitle="Utilisation du bon :"
      />
    </Card>
  )
}

export default GestionBDR
