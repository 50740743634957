import './CodeBarreEAN.css'
import React, { useState, useRef } from 'react'
import Card from 'components/UI/Card'
import Barcode from 'react-barcode'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { Button, Form, Spinner } from 'react-bootstrap'
import { useLazyQuery } from '@apollo/client'
import GET_DO_PRODUCT_CUSTOM_LISTING from 'graphql/queries/getDOProductCustomListing'

const CodeBarreEAN: React.FC = () => {
  const [inputValue, setInputValue] = useState<string>('')
  const [AllValues, setAllValues] = useState<any[]>([])
  const [errorMessage, setErrorMessage] = useState<string>('')
  const barcodeRefs = useRef<any[]>([])

  // Query is executed by SearchInput
  const [
    getDOProductCustomListing,
    { loading, data: productData },
  ] = useLazyQuery(GET_DO_PRODUCT_CUSTOM_LISTING, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      handleDownloadPDF()
    },
  })

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value)
  }

  const handleFetchData = () => {
    const values: any = inputValue
      .split('\n')
      .filter((value: any) => value.trim() !== '')
      .map((value: any) => value.trim())
    setAllValues(values)

    const valuesJson = JSON.stringify(values)
    setErrorMessage('')
    getDOProductCustomListing({
      variables: {
        productGtin: valuesJson,
      },
    })
  }

  const handleDownloadPDF = () => {
    const jsonData = JSON.parse(
      JSON.stringify(productData.getDOProductCustomListing.edges)
    )

    // Create a new PDF document
    const doc: jsPDF = new jsPDF('l', 'mm')
    doc.setFontSize(10)
    let i: number = 0
    let isPage: boolean = false
    let noGtinExisted: any[] = []
    const promises: Promise<any>[] = [] // Create an array to hold promises

    // Loop through the barcode refs and add each one to a new page in the PDF
    barcodeRefs?.current.forEach((barcodeRef: any, index: number) => {
      // Create a canvas from the barcode component
      if (!barcodeRef.current) {
        i++
        return
      }
      // Get the name corresponding to the current barcode value
      let product = jsonData?.find((product: any) => 
        product?.frame
          ? product.frame.gtin === AllValues[index - i] 
          : product.accessory.gtin === AllValues[index - i ]
      )
      product = product?.frame ?? product?.accessory
      let name = product?.name
      let color = product?.color.label ?? ''
      if (!name) {
        noGtinExisted.push(AllValues[index - i])
      } else {
        const promise = html2canvas(barcodeRef.current).then((canvas: any) => {
          // Set the size of the PDF page to fit the barcode
          doc.addPage([78, 23])
          const imgData: string = canvas.toDataURL('image/png')
          let marginX = 4
          const svgWidth = barcodeRef?.current?.querySelector('svg')?.getBBox().width // Get svg with in pixels
          if(svgWidth)
          {
            const canvasSvgWidth = 70 * svgWidth / 266 // Optimal ratio is 266 for 70. 
            marginX = Math.floor((78 - canvasSvgWidth ) / 2) // Needs to adjust in case it's different.
          }

          // Barcode
          doc.addImage(imgData, 'PNG', marginX, 4, 150, 15)
          // Name + Color
          doc.text(name + ' ' + color, 1, 4)

          // Ean code
          const eanString = AllValues[index - i]
          const context = canvas.getContext("2d")
          const metrics = context.measureText(eanString)
          const xOffset = ((78 - (metrics.width / 2)) / 2) + metrics.width / 20 // Crude approximation with relative size of text inside canvas
          doc.text(eanString, xOffset, 21)
          
          isPage = true
        })
        promises.push(promise) // Add the promise to the array
      }
    })

    // Wait for all promises to resolve before saving the PDF
    Promise.all(promises).then(() => {
      if (isPage) {
        doc.deletePage(1)
        doc.save('barcodes.pdf')
      }
    })

    if (noGtinExisted.length > 0) {
      setErrorMessage(
        `Ces EAN saisis (${noGtinExisted.join(
          ', '
        )}) n'existent pas dans la base de données.`
      )
    }
  }

  const generateBarcodes = () => {
    const values: any[] = inputValue
      .split('\n')
      .filter((value: any) => value.trim() !== '')
      .map((value: any) => value.trim())
      barcodeRefs.current = [] // barcodeRefs needs to be flushed before repopulating it (in case one or more lines are erased by user before retrying)
    return values.map((value: any, index: number) => {
      const barcodeRef: any = React.createRef<HTMLDivElement>()
      barcodeRefs.current[index] = barcodeRef
      return (
        <div
          key={index}
          ref={barcodeRef}
          style={{ position: 'absolute', left: '-9999px', width: '150mm' }}
        >
          <Barcode
            value={value}
            format="CODE128"
            height={50}
            width={2}
            displayValue={false}
          />
        </div>
      )
    })
  }

  return (
    <Card>
      <Form>
        <fieldset>
          <Form.Group className="mb-3">
            <Form.Label>
              Liste des numéros EAN des montures séparées par des sauts de ligne
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              onChange={handleInputChange}
              onKeyPress={(e) => {
                e.stopPropagation()
              }}
            />
          </Form.Group>
        </fieldset>
        {errorMessage && (
          <p style={{ color: 'red', fontSize: '12px', marginBottom: '15px' }}>
            {' '}
            {errorMessage}{' '}
          </p>
        )}

        <Button
          disabled={inputValue.length === 0 || loading}
          type="button"
          onClick={handleFetchData}
        >
          {loading && (
            <Spinner
              as="span"
              className="me-2"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="light"
            />
          )}
          Imprimer les codes barres EAN
        </Button>
      </Form>
      {generateBarcodes()}
    </Card>
  )
}

export default CodeBarreEAN
