import { useQuery } from '@apollo/client'
import DOButton from 'components/Modal/DOButton'
import DOModal from 'components/Modal/DOModal'
import ModalAddVoucher from 'components/Modal/ModalAddVoucher'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import ResultsTable from 'components/UI/ResultsTable'
import SEARCH_VOUCHERS from 'graphql/queries/searchVouchers'
import { useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { constants } from 'utils/constants'
import {
  getFormattedDate,
  isVoucherOnTime,
  stringsToJSXs,
} from 'utils/functions'

interface Props {
  customerId: string
}
const ToggleCustomerVouchers: React.FC<Props> = ({ customerId }) => {
  const { t } = useTranslation()
  const [showAddVoucherModal, setShowAddVoucherModal] = useState(false)

  const { loading, error, data } = useQuery(SEARCH_VOUCHERS, {
    variables: {
      filter: '{"customer__id": {"$like" :"' + customerId + '"}}',
    },
  })

  let jsonData = null
  if (data) {
    jsonData = JSON.parse(JSON.stringify(data))

    // Sorting by dateEnd asc
    jsonData.getDOVoucherListing.edges.sort((a: any, b: any) => {
      return (
        new Date(a.node.dateEnd).getTime() - new Date(b.node.dateEnd).getTime()
      )
    })

    jsonData.getDOVoucherListing.edges.forEach((item: any) => {
      let voucher = item.node

      voucher.label = voucher.voucherTemplate.clientLabel

      /**
       * "Expiré" is not a voucherState option but we want to display it
       * so we check here if the voucher is still available but the date is in the past
       */
      if (
        voucher.voucherState === 'disponible' &&
        !isVoucherOnTime(voucher.dateBegin, voucher.dateEnd)
      )
        voucher.voucherState = t('voucher.state.expire')
      else voucher.voucherState = t(`voucher.state.${voucher.voucherState}`)

      voucher.prettyDateEnd = voucher.dateEnd
        ? getFormattedDate(voucher.dateEnd)
        : '-'

      voucher.amount =
        voucher.totalAmount?.value.toFixed(2) + ' ' + constants.currency.EURO
    })
  }

  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Bons d'achat</Accordion.Header>
        <Accordion.Body>
          <DOButton
            className="btn btn-primary float-end"
            text="Ajouter un bon d'achat"
            handleClick={() => setShowAddVoucherModal(true)}
          />
          <DOModal
            show={showAddVoucherModal}
            customSize="modal-647"
            closeModal={() => setShowAddVoucherModal(false)}
          >
            <ModalAddVoucher customerId={+customerId} />
          </DOModal>

          {error && <div>Erreur lors de la récupération des bons</div>}

          {loading && <SkeletonBase count={3} />}

          {jsonData && jsonData.getDOVoucherListing.totalCount === 0 && (
            <div>Pas d'enregistrement</div>
          )}

          {jsonData && jsonData.getDOVoucherListing.totalCount > 0 && (
            <ResultsTable
              items={jsonData.getDOVoucherListing}
              queryType="Bons de réduction"
              columns={stringsToJSXs(
                [
                  'label',
                  'prettyDateEnd',
                  'voucherState',
                  'voucherCode',
                  'amount',
                ],
                t
              )}
            />
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default ToggleCustomerVouchers
