import React, { useState } from "react"
import FileViewer from 'components/UI/FileViewer'
import FileUploader from 'components/UI/FileUploader'

interface Props {
  id: number // Prescription pimcore ID
  width?: string
  height?: string
}

const PrescriptionFileViewer: React.FC<Props> = ({
  id, width, height
}) => {

  const [file, setFile] = useState<Blob | null>(null)
  const [confirmFile, setConfirmFile] = useState('')

  return (
    <div style={{ width: width, height: height}}>
      <FileViewer 
        type={1} 
        id={id} 
        width="100%" 
        height="90%" 
        file={file} 
        setConfirmFile={setConfirmFile}/>
      <FileUploader 
        type={1}
        id={id}
        width="100%"
        height="10%"
        file={file}
        setFile={setFile}
        confirmFile={confirmFile}
        setConfirmFile={setConfirmFile}/>
    </div>
  )
}

export default PrescriptionFileViewer