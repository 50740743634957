import React from 'react';
import Indicators from './Indicators';

const Dashboard: React.FC = () => {

    return (
        <div>
            <h1 className='text-center pb-2'>Tableau de bord</h1>
            <Indicators/>
        </div>
    );
};

export default Dashboard;