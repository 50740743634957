import React from 'react'
import BeneficiaryCard from './BeneficiaryCard'
import PrescriptionCard from './PrescriptionCard'
interface Props {
  beneficiary: beneficiaryType
  prescription: prescriptionType
  openPrescription: number | null
  setOpenPrescription: React.Dispatch<React.SetStateAction<null | number>>
  numberOfPrescription: number
  groupOfOrderLines: orderLineType[]
  isGroupOff: boolean
  isBeneficiaryOff: boolean
  calculators: orderCalculatorsType
}
const OrderDetailLeftSide: React.FC<Props> = ({
  beneficiary,
  prescription,
  setOpenPrescription,
  openPrescription,
  numberOfPrescription,
  groupOfOrderLines,
  isGroupOff,
  isBeneficiaryOff,
  calculators
}) => {
  return (
    <div className="order-detail-leftside">
      <BeneficiaryCard
        beneficiary={beneficiary}
        groupOfOrderLines={groupOfOrderLines}
        isBeneficiaryOff={isBeneficiaryOff}
        calculators={calculators}
      />
      <PrescriptionCard
        beneficiaryBirthdate={beneficiary?.birthDate}
        prescription={prescription}
        openPrescription={openPrescription}
        setOpenPrescription={setOpenPrescription}
        numberOfPrescription={numberOfPrescription}
        isGroupOff={isGroupOff}
      />
    </div>
  )
}

export default OrderDetailLeftSide
