import React, {useState,useEffect} from "react";
import Card from 'components/UI/Card'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { RefreshIcon } from '@heroicons/react/solid'
import { getTokenFromLocalStorage } from '../../utils/functions'


interface Props {}
const ExportDesVentes: React.FC<Props> = () => {
  
  const {REACT_APP_PIMCORE_URL_DOMAIN} = process.env
  const DATE_TYPE_PAID_ORDER = 1
  const DATE_TYPE_INVOICED_ORDER = 2

  const [dateBegin, setDateBegin] = useState<string>('')
  const [dateEnd, setDateEnd] = useState<string>('')
  const [dateType, setDateType] = useState<number>(1)
  const [typeValue, setTypeValue] = useState<string>('1')
  const [enableDownload, setEnableDownload] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setEnableDownload(dateType * dateBegin.length * typeValue.length > 0)
  }, [dateType, dateBegin, dateEnd, typeValue])

  const downloadHandler = async () => {
      try {
        const options = () => {
          return {
            method: 'POST',
            headers: {
              'Accept': 'text/csv',
            },
            body: new URLSearchParams({
              'token': getTokenFromLocalStorage(), // Security check
              dateBegin,
              dateEnd,
              dateType: dateType.toString()
            })
          }
        }

        setLoading(true)
        await fetch(`${REACT_APP_PIMCORE_URL_DOMAIN}/export-ventes`,options())
        .then(response => {
          if(response.status!==200) {
             throw new Error('Error fetching the csv file.')
          }
          else
          {
            const { format, parseISO } = require('date-fns');
            const today = format(new Date(), 'yyyyMMdd');
            const fileName = `${today}-export_des_ventes_du_${format(parseISO(dateBegin), 'ddMMyyyy')}${dateEnd?'_au_'+format(parseISO(dateEnd), 'ddMMyyyy'):''}.csv`;

            response.text().then( csv => {
              // Convertir le texte en un tableau de codes ASCII
              const bytes = [];
              for (let i = 0; i < csv.length; i++) {
                bytes.push(csv.charCodeAt(i) & 0xff);
              }
              // Créer un tableau d'octets avec les codes ASCII
              const byteArray = new Uint8Array(bytes);
              // Convertir le tableau au format ANSI/Windows-1252
              const csvData = new Blob([byteArray], { type:'text/csv;charset=ANSI/Windows-1252;' })
              const a = document.createElement("a");
              a.href = URL.createObjectURL(csvData);
              a.download = fileName;
              a.click();
              a.remove()
            })
            .catch( error => {
              throw new Error(error)
            })
            setLoading(false)
          }
        })
        .catch( error => {
          throw new Error(error)
        })

      } catch (err) {
        setLoading(false)
        console.log(err)
        alert('Une erreur est survenue')
      }
  }

  const exportTypeOptions = (
    <>
      <option value="">Choisissez</option>
      <option value={1}>Export des ventes</option>
    </>
  )

  return (
    <div>
      <Card>
        <Row className="g-2 mb-2">
          <Col>
            <Form.Label className="text-nowrap">
              Par date de :
            </Form.Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Check
              type="radio"
              label="Premier paiement"
              name="dateType"
              id="isActiveRadio"
              onChange={() => setDateType(DATE_TYPE_PAID_ORDER)}
              className="mt-2 text-nowrap"
              checked={dateType === DATE_TYPE_PAID_ORDER}
            />
          </Col>
          <Col>
            <Form.Group
              as={Row}
              className="mb-3 justify-content-start"
              controlId="fromDate"
            >
            <Form.Label column sm={2} className="text-nowrap">
              Du :
            </Form.Label>
            <Col md="auto">
              <Form.Control
                type="date"
                placeholder="Du"
                value={dateBegin}
                onChange={(e) => setDateBegin(e.target.value)}
                className="w-100"
              />
            </Col>
            </Form.Group>
          </Col>
          <Col xs={1}>
            <Button
              className="p-0 ps-1 mt-1"
              variant="outline-primary"
              onClick={() => {
                setDateType(1)
                setDateBegin('')
                setDateEnd('')
              }}
            >
              <RefreshIcon className="header-title-icon" />
            </Button>
          </Col>
          <Col xs={4}>
            
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Form.Check
              type="radio"
              label="Facturation"
              name="dateType"
              id="isActiveRadio"
              onChange={() => setDateType(DATE_TYPE_INVOICED_ORDER)}
              className="mt-2"
              checked={dateType === DATE_TYPE_INVOICED_ORDER}
            />
          </Col>
          <Col>
            <Form.Group
              as={Row}
              className="mb-3 justify-content-start me-1"
              controlId="toDate"
            >
            <Form.Label column sm={2} className="text-nowrap">
              Au :
            </Form.Label>
            <Col md="auto">
              <Form.Control
                type="date"
                placeholder="Au"
                value={dateEnd}
                onChange={(e) => setDateEnd(e.target.value)}
                className="w-100"
              />
            </Col>
            </Form.Group>
          </Col>
          <Col xs={1}>
          </Col>
          <Col xs={4}>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={6}>
            <Form.Group
              as={Row}
              className="mb-3 justify-content-start"
              controlId="exportType"
            >
            <Form.Label column sm={2} className="text-nowrap">
              Type : 
            </Form.Label>
            <Col md="auto">
            <Form.Select
              aria-label="Default select example"
              disabled={false}
              style={{ width: '180px' }}
              onChange={(e) => {setTypeValue(e.target.value)}}
              value={typeValue}
            >
              {exportTypeOptions}
            </Form.Select>
            </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              disabled={!enableDownload || loading}
              variant="primary shadow zindex-2 btn-radius "
              type="button"
              onClick={downloadHandler}
            >
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                'Télécharger'
              )}
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  )
}

export default ExportDesVentes
