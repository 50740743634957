import {Col, Row, Form} from "react-bootstrap"
import './EditLensForm.css'
import { useEffect, useState } from "react"
import { useQuery } from '@apollo/client'
import { GET_LENS_LISTING } from 'graphql/queries/getLensListing'
import Skeleton from "react-loading-skeleton"
import { PencilAltIcon, XIcon } from "@heroicons/react/outline"
import { validateDecimalInput } from "utils/functions"

interface Props {
  newLenses : {right: {lens: any,price: string}, left: {lens: any,price: string}}
  setNewLenses : React.Dispatch<React.SetStateAction< {right: {lens: any,price: string}, left: {lens: any,price: string}} >>
  lensInfoVisible : boolean
  categoryId: string
  setNoMutationPerformed: React.Dispatch<React.SetStateAction<boolean>>
}
type priceTTC = {
    rightPrice: number;
    leftPrice: number;
    totalPrice: number;
}

const EditLensForm: React.FC<Props> = ({
  newLenses,
  setNewLenses,
  lensInfoVisible, 
  setNoMutationPerformed,
  categoryId}) => {
  // filters
  const [focalFilters, setFocalFilters] = useState<string[]>([])
  const [typeFilters, setTypeFilters]= useState<string[]>([])
  const [lensDetailsFilter, setLensDetailFilter] = useState('')
  const [lensFormVisible, setLensFormVisible] = useState(false)
  const [totalPrice, setTotalPrice] = useState('')

  const toggleLensFormVisibility = () => {
    return setLensFormVisible(!lensFormVisible)
  }
  //lens list
  const [lensList, setLensList] = useState([]as any)

  const resetLensDetail = (isRightLens=false as boolean) => {
    let radioButton = undefined;
    setNoMutationPerformed(false)
    //remove radio
    if(isRightLens){
      radioButton = document.querySelector('#od'+newLenses.right.lens?.id) as HTMLInputElement
      //remove lens details
      setNewLenses({...newLenses, right:{lens:{}, price:''} })
    }
    else {
      radioButton = document.querySelector('#og'+newLenses.left.lens?.id) as HTMLInputElement
      //remove lens details
      setNewLenses({...newLenses, left:{lens:{}, price:''}})
    }
    if(radioButton) radioButton.checked = false
  }
  const { error, data: lensListingData, loading: lensListingLoading, } = useQuery(GET_LENS_LISTING, {variables: {categoryId:+categoryId}})

  const handleChangeCheckbox = (e:React.ChangeEvent<HTMLInputElement>, isTypeFilter:boolean) => {
    let newFilter = e.target.value
    if(e.target.checked){
      if(isTypeFilter && !typeFilters.includes(newFilter))
        setTypeFilters([...typeFilters, newFilter])
      else if (!focalFilters.includes(newFilter))
        setFocalFilters([...focalFilters, newFilter])
    }else if (isTypeFilter) 
      setTypeFilters(typeFilters.filter((item) => item !== newFilter))
    else 
      setFocalFilters(focalFilters.filter((item) => item !== newFilter))
  }

  const lensClickHandler = (lens: any) => {
    const rightEyeRadio = document.querySelector('#od'+lens.id) as HTMLInputElement
    if(rightEyeRadio)
      rightEyeRadio.checked = true
    const leftEyeRadio = document.querySelector('#og'+lens.id) as HTMLInputElement
    if(leftEyeRadio)
      leftEyeRadio.checked = true
    setNewLenses({...newLenses, right: {lens:lens, price:''}, left: {lens:lens, price:''}})
  }

  const filterLensList = () =>{
    if(typeFilters.length || focalFilters.length || lensDetailsFilter.length){
      const newLensList = lensList.filter((elem:any) => {
        const isTypeMatch = typeFilters.length ? typeFilters.includes(elem?.lensType) : true
        const isFocalMatch = focalFilters.length ? focalFilters.includes(elem?.focal) : true
        const isLensDetailsMatch =  lensDetailsFilter? elem?.lensDetails?.toLowerCase().includes(lensDetailsFilter.toLocaleLowerCase()) : true
        const isCosiumCodeMatch = lensDetailsFilter? elem?.cosiumCode?.toLowerCase().includes(lensDetailsFilter.toLowerCase()) : true
        return isTypeMatch && isFocalMatch && (isLensDetailsMatch || isCosiumCodeMatch)
      });

      return newLensList
    } else {
      return [...lensList]
    }
  }
  const getPriceTTC = ():priceTTC =>  {
    let rightPrice = newLenses.right.lens?.basePrice?.value ? newLenses.right.lens.basePrice.value / 2 : 0
    let leftPrice = newLenses.left.lens?.basePrice?.value ? newLenses.left.lens.basePrice.value / 2 : 0
    return  {rightPrice, leftPrice, totalPrice:rightPrice+leftPrice }
  }
  useEffect(() => {
    if(!lensListingLoading && lensListingData?.getLensCustomListing?.edges)
    setLensList(JSON.parse(lensListingData?.getLensCustomListing?.edges))
    if(!newLenses.right.lens?.id && !newLenses.left.lens?.id && lensInfoVisible)
      setLensFormVisible(true)
    else if(!lensInfoVisible)
      setLensFormVisible(false)

    if(newLenses.right.price||newLenses.left.price)
     setTotalPrice((Number(newLenses.right.price)+Number(newLenses.left.price)).toString())
    else  
      setTotalPrice('')

  }, [lensListingLoading, lensListingData, newLenses, lensInfoVisible])

  if (lensListingLoading)
    return (
      <h3>
        <Skeleton />
      </h3>
    )
  if (error) return <p>`Error! ${error.message}`</p>

  const getLenslist =()=>{
    let list = filterLensList()
    if(list.length)
      return list.map((lens:any, i:number) =>{
          return <li key={lens.id}>
            <div className="d-flex justify-content-between">
              <Col xs={8} id={lens.id} className="me-2 lens-label" onClick={() => {lensClickHandler(lens)}}>{lens.lensDetails} [{lens.cosiumCode}]</Col>
              <Col xs={1} className="d-flex justify-content-end">{(lens.salePrice.value / 2).toFixed(2)} €</Col>
              <Col xs={1} className="d-flex justify-content-center">
                <Form.Check
                  type="radio"
                  name="rightLens"
                  id={"od"+lens.id}
                  value={"od"+lens.id}
                  defaultChecked={+newLenses.right.lens?.id===+lens.id}
                  onClick={()=>{
                    if(+newLenses.right.lens.id === +lens.id)
                      resetLensDetail(true)
                    else
                      setNewLenses({...newLenses, right: {lens:lens, price:''}})
                  }}
                />
              </Col>
              <Col xs={1} className="d-flex justify-content-center">
                <Form.Check
                  type="radio"
                  name="leftLens"
                  id={"og"+lens.id}
                  value={"og"+lens.id}
                  defaultChecked={+newLenses.left.lens?.id===+lens.id}
                  onClick={()=>{
                    if(+newLenses.left.lens.id === +lens.id)
                      resetLensDetail(false)
                    else
                      setNewLenses({...newLenses, left: {lens:lens, price:''}})
                  }}
                />
              </Col>
            </div>
          </li>
      })
    else return <li>Aucun Résultat</li>
  }
  return (
    <>
    {/* Filter and lens list */}
    {lensFormVisible && 
    <div>
      <div className="text form-check-inline">Sélectionner le verre<sup>*</sup></div>
      <Form.Check
        className="text-15px form-check-inline me-2"
        type="checkbox"
        id="transparent"
        label="Transparent"
        value="transparent"
        defaultChecked={false}
        onChange={(e) => {handleChangeCheckbox(e,true)}}
      />
      <Form.Check
        className="text-15px form-check-inline me-2"
        type="checkbox"
        id="solaire"
        label="Teinté"
        value="solaire"
        defaultChecked={false}
        onChange={(e) => {handleChangeCheckbox(e,true)}}
      />
      <Form.Check
        className="text-15px form-check-inline me-2"
        type="checkbox"
        id="photochromique"
        label="Photochromique"
        value="photochromique"
        defaultChecked={false}
        onChange={(e) => {handleChangeCheckbox(e,true)}}
      />
      <Form.Check
        className="text-15px form-check-inline me-2"
        type="checkbox"
        id="unifocal"
        label="Unifocal"
        value="unifocal"
        defaultChecked={false}
        onChange={(e) => {handleChangeCheckbox(e,false)}}
      />
      <Form.Check
        className="text-15px form-check-inline me-2"
        type="checkbox"
        id="progressif"
        label="Progressif"
        value="progressif"
        defaultChecked={false}
        onChange={(e) => {handleChangeCheckbox(e,false)}}
      />
      {/* lens searchbar */}
      <div  className="position-relative">
        <Form.Control 
          type="text"
          className="my-2 pe-5"
          placeholder="Rechercher un verre" 
          value={lensDetailsFilter}
          onChange={(e) => setLensDetailFilter(e.target.value)}
        />
        {lensDetailsFilter && <XIcon className="header-title-icon position-absolute xicon-lens-details link-secondary cursor" onClick={()=>setLensDetailFilter('')}/>}
      </div>
      {/* LENS LIST */}
      <ul className="header-lens-list my-0 p-0" >
        <li>
          <div className="d-flex justify-content-between border border-bottom-0 bg-light">
            <Col xs={8}  className="font-weight-bold ps-1">Libellé</Col>
            <Col xs={1} className="font-weight-bold">Prix</Col>
            <Col xs={1} className="font-weight-bold text-center">
                OD
            </Col>
            <Col xs={1} className="font-weight-bold text-center">
                OG
            </Col>
          </div>
          </li>
      </ul>
      <ul className="lens-list">
        {getLenslist()}
      </ul>
    </div>}

       {lensInfoVisible && <>
     {/* ODG */}
      <Row className="d-flex justify-content-between align-items-center">
        <Col>
          {/* Badge OD */}
          <div className="badge shadow-sm bg-light border text badge-lens-details">
            <div className="text-13px"> <b>OD : </b>{newLenses.right.lens?.lensDetails ? newLenses.right.lens?.lensDetails : <span className="no-lens-warning">(sans verre)</span>}</div>
            {newLenses.right.lens?.basePrice?.value && <div className="mt-2">Prix U. de vente site {newLenses.right.lens?.basePrice?.value / 2} €</div>}
            <Form.Group controlId="rightSalePrice" className="d-flex align-items-center mt-2">
              <Form.Label>Prix de vente TTC<sup>*</sup></Form.Label>
              <Form.Control
                type="text"
                className="ms-2 me-2 w-70px light-placeholder"
                placeholder={getPriceTTC().rightPrice?.toString()} 
                value={newLenses.right.price} 
                onChange={(e) => {
                  setNoMutationPerformed(false)
                  setNewLenses({...newLenses, right:{lens:newLenses.right.lens, price: validateDecimalInput(e.target.value)}})
                }}
                disabled={!newLenses.right.lens?.lensDetails}
              /><span>€</span>
            </Form.Group>

            <div className="d-flex justify-content-end mt-2">
              <PencilAltIcon className="xicon-lens-details cursor link-primary" onClick={()=>toggleLensFormVisibility()}/>
              {newLenses.right.lens?.lensDetails && <XIcon className="xicon-lens-details link-secondary cursor ms-2"  
                onClick={()=>resetLensDetail(true)}/>}
            </div>
          </div>
        </Col>

        <Col>
          {/* Badge OG */}
          <div className="badge shadow-sm bg-light border text badge-lens-details">
            <div className="text-13px"> <b>OG : </b>{newLenses.left.lens?.lensDetails ? newLenses.left.lens?.lensDetails : <span className="no-lens-warning">(sans verre)</span>}</div>
            {newLenses.left.lens?.basePrice?.value && <div className="mt-2">Prix U. de vente site {newLenses.left.lens?.basePrice?.value / 2} €</div>}
            <Form.Group controlId="leftSalePrice" className="d-flex align-items-center mt-2">
              <Form.Label>Prix de vente TTC<sup>*</sup></Form.Label>
              <Form.Control
                type="text"
                className="ms-2 me-2 w-70px light-placeholder"
                placeholder={getPriceTTC().leftPrice?.toString()} 
                value={newLenses.left.price} 
                onChange={(e) => {
                  setNoMutationPerformed(false)
                  setNewLenses({...newLenses, left:{lens:newLenses.left.lens, price:validateDecimalInput(e.target.value)}})
                }}
                disabled={!newLenses.left.lens?.lensDetails}
              /><span>€</span>
            </Form.Group>

            <div className="d-flex justify-content-end mt-2">
               <PencilAltIcon className="xicon-lens-details cursor link-primary" onClick={()=>toggleLensFormVisibility()}/>
              {newLenses.left.lens?.lensDetails &&  <XIcon className="xicon-lens-details link-secondary cursor ms-2"  
              onClick={() => {resetLensDetail()}}/>}
            </div>
          </div>
        </Col>
      </Row>
      {/* TOTAL */}
      <Row className="my-3">
        <Form.Group controlId="totalSalePrice" className="d-flex align-items-center mt-2">
          <Form.Label><b>Prix de vente Total TTC</b></Form.Label>
          <Form.Control
            type="text"
            className="ms-2 me-1 w-70px"
            placeholder={getPriceTTC().totalPrice?.toString()} 
            value={totalPrice}
            readOnly
          /><span>€</span>
        </Form.Group>
      </Row>

    </>}
    </>
  )
}

export default EditLensForm
