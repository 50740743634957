import { gql } from '@apollo/client'

const CREATE_VOUCHER_TEMPLATE = gql`
  mutation CREATE_VOUCHER_TEMPLATE(
    $label: String!
    $voucherType: String!
    $genericCode: String
    $voucherPrefixedCode: String
    $comment: String
    $initialAmount: Float
    $onDiscount: Boolean!
    $onlyFirstOrder: Boolean!
    $active: Boolean!
    $duration: Int
    $dateBegin: String
    $dateEnd: String
    $minAmount: Float
    $brands: String
    $domainSites: String
    $productType: String
  ) {
    createVoucherTemplate(
      label: $label
      voucherType: $voucherType
      genericCode: $genericCode
      voucherPrefixedCode: $voucherPrefixedCode
      comment: $comment
      initialAmount: $initialAmount
      onDiscount: $onDiscount
      onlyFirstOrder: $onlyFirstOrder
      active: $active
      duration: $duration
      dateBegin: $dateBegin
      dateEnd: $dateEnd
      minAmount: $minAmount
      brands: $brands
      domainSites: $domainSites
      productType: $productType
    ) {
      success
      output {
        voucherTemplate {
          id
        }
      }
    }
  }
`

export default CREATE_VOUCHER_TEMPLATE
