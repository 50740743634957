import { gql } from '@apollo/client'

export const GET_DO_BRAND_LISTING = gql`
  query GET_DO_BRAND_LISTING {
    getDOBrandListing {
      edges {
        node {
          id
          key
          name
        }
      }
    }
  }
`
