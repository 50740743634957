import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
interface Props {
  show?: boolean
  size?: 'sm' | 'lg' | 'xl' | undefined
  customSize?: string // FIXME revoir gestion de la largeur max (on a une classe css modal-647 qui a été créée, il faudrait dynamiser ce point)
  confirmModal?: (...args: any) => any
  isDisabled?: boolean
  closeModal: () => void,
  closeOnExternalClick?: boolean
}
const DOModal: React.FC<Props> = ({
  children,
  size,
  customSize,
  confirmModal,
  isDisabled,
  show,
  closeModal,
  closeOnExternalClick
}) => {
  const [displayModal, setDisplayModal] = useState(show)

  React.useEffect(() => {
    setDisplayModal(show)
  }, [show])

  return (
    <>
      <div className="ModalV2" onClick={closeOnExternalClick ? closeModal : () => {}}>
        <Modal
          show={displayModal}
          dialogClassName={
            customSize +
            ' modal-style d-flex justify-content-center align-items-center'
          }
          size={size}
          onClick={(e: MouseEvent) => e.stopPropagation()}
        >
          <Modal.Body>
            <span
              data-cy="closeBtn"
              className="btn btn-close position-absolute p-3 top-0 end-0"
              onClick={closeModal}
            ></span>
            {React.cloneElement(children as React.ReactElement<any>, {
              closeModal: closeModal,
            })}
          </Modal.Body>
          {confirmModal && (
            <Modal.Footer>
              <Button onClick={confirmModal} disabled={isDisabled}>
                Valider
              </Button>
              <Button
                className="btn-secondary"
                onClick={closeModal}
                disabled={isDisabled}
              >
                Annuler
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    </>
  )
}

export default DOModal
