import { gql } from '@apollo/client'

const GET_DO_PRODUCT_CUSTOM_LISTING = gql`
  query GET_DO_PRODUCT_CUSTOM_LISTING($productGtin: String!) {
    getDOProductCustomListing(productGtin: $productGtin) {
      totalCount
      edges {
        frame {
          name
          gtin
          color {
            ... on object_DOColor {
              label
            }
          }
        }
        accessory {
          name
          gtin
          color {
            ... on object_DOColor {
              label
            }
          }
        }
      }
    }
  }
`
export default GET_DO_PRODUCT_CUSTOM_LISTING