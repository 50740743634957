import { gql } from '@apollo/client'
import { DO_CONTACT_NAME_FC_FIELDS } from 'graphql/fragments/DOContactNameFCFields'
import { DO_CONTACT_INFO_FC_FIELDS } from 'graphql/fragments/DOContactInfoFCFields'
const UPDATE_DO_CUSTOMER = gql`
  ${DO_CONTACT_NAME_FC_FIELDS}
  ${DO_CONTACT_INFO_FC_FIELDS}
  mutation UPDATE_DO_CUSTOMER(
    $id: Int!
    $firstName: String
    $lastName: String
    $civility: String
    $birthdate: String
    $country: String
    $email: String
    $siteDomainId: Int
    $landlinePhoneNumber: String
    $mobilePhoneNumber: String
    $postalCode: String
    $blacklisted: Boolean
    $password: String
  ) {
    updateCustomer(
      id: $id
      blacklisted: $blacklisted
      password:$password
      email: $email
      birthdate: $birthdate
      firstName: $firstName
      lastName: $lastName
      civility: $civility
      siteDomainId: $siteDomainId
      postalCode: $postalCode
      country: $country
      mobilePhoneNumber: $mobilePhoneNumber
      landlinePhoneNumber: $landlinePhoneNumber
    ) {
      success
      output {
        customer{
          login
          contactName {
            ...DOContactNameFCFields
          }
          contactInfo {
            ...DOContactInfoFCFields
          }
          birthdate
          blacklisted
          siteDomain {
            ... on object_DOSite {
              domainSuffix
            }
          }
        }
      }
    }
  }
`

export default UPDATE_DO_CUSTOMER
