import React, { useCallback, useEffect, useState } from "react"
import { getTokenFromLocalStorage } from '../../utils/functions'
import classes from './FileViewer.module.css'
import { TrashIcon } from '@heroicons/react/outline'

interface Props {
  type: number // Type of file (ex: 1 for prescription)
  id: number // Pimcore id of referenced object (ex: DOPrescription)
  width?: string
  height?: string
  file?: Blob | null
  setConfirmFile : React.Dispatch<React.SetStateAction<string>>
}
const FileViewer: React.FC<Props> = ({
  type, id, width, height, file, setConfirmFile
}) => {

  const { REACT_APP_PIMCORE_URL_DOMAIN } = process.env

  const [fileBlob, setFileBlob] = useState<Blob | null>(null) // Raw data for pdf
  const [fileUrl, setFileUrl] = useState<any>(null) // Url for png and jpeg
  const [loading, setLoading] = useState<boolean>(false)

  const resetStates = () => {
    setFileBlob(null)
    setFileUrl(null)
  }

  const clickHandler = (e: any) => {
    e.preventDefault(); // Prevents anchor triggering
    let w = window.open();
    if(w !== null)
    {
      if(fileBlob?.type === 'application/pdf')
        w.document.write(`<iframe frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" src="${URL.createObjectURL(fileBlob)}"/>`);
      else
      {
        let image = new Image();
        image.src = fileUrl;
        w.document.write(image.outerHTML);
      }
    }
  }

  const clickDeleteHandler = (e: any) => {
    e.stopPropagation()
    deleteHandler()
  }

  useEffect(() => {
    const getFile = async () => {
      setLoading(true)
      if(file === undefined || file === null)
      {          
        const options = () => {
          return {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
              'token': getTokenFromLocalStorage() // Security check
            })
          }
        }
    
        const response = await fetch(`${REACT_APP_PIMCORE_URL_DOMAIN}/download/bo/${type}/${id}`, options());
        if(response.status === 200)
        {
          const blob = await response.blob()
          if(blob.size)
          {
            setFileBlob(blob)
            const reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
              const base64data = reader.result
              setFileUrl(base64data)
            }
          }
          else
          {
            resetStates()
          }
        }
        else
        {
          resetStates()
          const errorMessage = await response.text()
          console.error(errorMessage)
        }
      }
      else
      {
        setFileBlob(file)
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = () => {
          const base64data = reader.result
          setFileUrl(base64data)
        }
      }
      setLoading(false)
    }
    getFile()

  }, [file, REACT_APP_PIMCORE_URL_DOMAIN, type, id])

  const deleteHandler = useCallback(() => {
      const performDelete = async () => {
        const options = () => {
          return {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
              'token': getTokenFromLocalStorage() // Security check
            })
          }
        }
    
        const response = await fetch(`${REACT_APP_PIMCORE_URL_DOMAIN}/remove/bo/1/${id}`, options());
        if(response.status === 200)
        {
            // Deal with successful response
            setConfirmFile('Fichier supprimé avec succès')
            setTimeout(() => {setConfirmFile('')}, 4000)
          
          resetStates()
        }
        else
          console.error('FILE DELETE ERROR', response)
      }

      performDelete()
  }, [REACT_APP_PIMCORE_URL_DOMAIN, id, setConfirmFile])


  return (
    <div className={classes.wrapper} style={{width: width, height: height}}>
      {!loading && <>
        {fileBlob !== null && fileUrl !== null && 
          <object
            className={fileBlob?.type === 'application/pdf' ? classes.pdfcontainer : classes.container}
            data={fileBlob?.type === 'application/pdf' ? URL.createObjectURL(fileBlob) + '#toolbar=0&navpanes=0&scrollbar=0' : fileUrl}
            type={fileBlob?.type}
            aria-label="ordonnance"
            style={{
                objectPosition: "center"
            }}
          ></object>
        }
        {(fileUrl === null) && 
          <div className={classes.message}>Aucun fichier</div>
        }
      </>}
      {loading && <div className={classes.message}>Chargement en cours</div>}
      {fileUrl !== null && <div className={classes.linktofile} onClick={clickHandler}>
        <div className={classes.deletefile} onClick={clickDeleteHandler}><TrashIcon className="header-title-icon text-white" /></div>
      </div>}
    </div>
  )
}

export default FileViewer