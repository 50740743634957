import React, { useState, useEffect }from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap'

interface Props {
  setSelectedGenericItem: React.Dispatch<React.SetStateAction<any>>
}

const GenericItemForm: React.FC<Props> = ({setSelectedGenericItem}) => {

  const [GIKey, setGIKey] = useState<string>('')
  const [OLILabel, setOLILabel] = useState<string>('')
  const [OLIQuantity, setOLIQuantity] = useState<string>('1')
  const [OLIAmount, setOLIAmount] = useState<string>('')

  const GIColClass = "d-flex text-nowrap align-items-center pe-2"
  const GILabelClass = "mt-2 me-2 fw-bold"

  // setSelectedGenericItem(null)

  useEffect(() => {
    if(GIKey.length && OLILabel.length && OLIQuantity.length && OLIAmount.length)
      setSelectedGenericItem({
        key: GIKey,
        label: OLILabel,
        quantity: +OLIQuantity,
        amount: +OLIAmount
      })
    else
      setSelectedGenericItem(null)

  }, [GIKey, OLILabel, OLIQuantity, OLIAmount, setSelectedGenericItem])

  return (
    <Container className="mt-3">
      <Row>
        <Col className={GIColClass}>
          <Form.Label className={GILabelClass}>Libellé</Form.Label>
          <Form.Control
            defaultValue={OLILabel}
            onChange={(e) => { setOLILabel(e.target.value) }}
          />
        </Col>
        <Col className={GIColClass} xs={3}>
          <Form.Label className={GILabelClass}>Type</Form.Label>
          <Form.Select
            defaultValue={GIKey}
            onChange={(e) => { setGIKey(e.target.value) }}
            >
            <option value="">Choisir</option>
            <option value="generic_positive">Montant positif</option>
            <option value="generic_negative">Montant négatif</option>
          </Form.Select>
        </Col>
        <Col className={GIColClass} xs={2}>
          <Form.Label className={GILabelClass}>Quantité</Form.Label>
          <Form.Control
            defaultValue={OLIQuantity}
            onChange={(e) => { 
              if (/^\d*$/.test(e.target.value))
                setOLIQuantity(e.target.value) 
              else
                e.target.value = OLIQuantity
            }}
          />
        </Col>
        <Col className={GIColClass} xs={3}>
          <Form.Label className={GILabelClass}>Prix unitaire</Form.Label>
          <Form.Control 
            className="w-90px"
            defaultValue={OLIAmount}
            onChange={(e) => {
              if(e.target.value.includes(','))
                e.target.value = e.target.value.replace(',', '.')

              if (/^(\d*|\d+\.\d{0,2})$/.test(e.target.value))
                setOLIAmount(e.target.value) 
              else
                e.target.value = OLIAmount
              }}
          />
          <Form.Label className="mt-2 ms-2 fw-bold">€</Form.Label>
        </Col>
      </Row>
    </Container>
  )
}

export default GenericItemForm