import React, { createContext, useContext, useState } from 'react'

export type GlobalContent = {
  openMenu: boolean
  setOpenMenu: (o: boolean) => void
  totalOrderToPick: number
  setTotalOrderToPick: (i: number) => void
  sideBarNewEventCount: number
  setSideBarNewEventCount: (o: number) => void
}

export const MenuContext = createContext<GlobalContent>({
  openMenu: true,
  setOpenMenu: () => {},
  totalOrderToPick: 0,
  setTotalOrderToPick: () => {},
  sideBarNewEventCount: 0,
  setSideBarNewEventCount: () => {}
})

export const MenuContextProvider: React.FC<React.ReactNode> = ({
  children,
}) => {
  const [openMenu, setOpenMenu] = useState(true)
  const [totalOrderToPick, setTotalOrderToPick] = useState<number>(0)
  const [sideBarNewEventCount, setSideBarNewEventCount] = useState<number>(0)

  return (
    <MenuContext.Provider
      value={{ openMenu, setOpenMenu, totalOrderToPick, setTotalOrderToPick, sideBarNewEventCount, setSideBarNewEventCount}}
    >
      {children}
    </MenuContext.Provider>
  )
}

export const useGlobalContext = () => useContext(MenuContext)

export default MenuContext