import gql from 'graphql-tag'

const GET_UNPAID_ORDER_LISTING = gql`
  query GET_UNPAID_ORDER_LISTING($fromDate: String) {
    getUnpaidOrderListing(fromDate: $fromDate) {
      totalCount
      edges {
        node {
          id
          orderKey
          eventCreationDate
          customerLogin
          customerName
          deliveryPhoneNumber
          orderState
          orderStateDisplay
          totalAmount
          country
        }
      }
    }
  }
`

export default GET_UNPAID_ORDER_LISTING