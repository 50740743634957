import { useMutation } from '@apollo/client'
import { ADD_GENERIC_ITEM_TO_ORDER } from 'graphql/mutations/addGenericItemToOrder'
import React from 'react'
import { useContext, useState } from 'react'
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import { ToastContext } from 'store/toast-context'
import LensReplacementForm from '../LensReplacementForm'
import ProductSearchForm from './ProductSearchForm'
import GenericItemForm from '../GenericItemForm'
import { ADD_PRODUCT_TO_ORDER } from 'graphql/mutations/addProductToOrder'

interface Props {
  order: orderType
  resetStateType: (newStateType: string) => void
  selectedRadioAddLineName: string
  setSelectedRadioAddLineName: (value: React.SetStateAction<string>) => void
}
const AddNewLineForm: React.FC<Props> = ({
  order,
  resetStateType,
  selectedRadioAddLineName,
  setSelectedRadioAddLineName,
}) => {
  const { toast } = useContext(ToastContext)

  const [addProduct, { loading: addProductLoading }] = useMutation(
    ADD_PRODUCT_TO_ORDER,
    {
      onCompleted: (data) => {
        toast({
          title: 'Ligne ajoutée :',
          message: `Équipement ${data.addProductToOrder.output.orderLineItem.orderLine?.[0]?.id} : ${selectedProduct.productName}`,
        })
        resetStateType('')
      },
    }
  )

  const [addLensReplacement, { loading: addLensReplacementLoading }] =
    useMutation(ADD_PRODUCT_TO_ORDER, {
      onCompleted: (data) => {
        toast({
          title: 'Ligne ajoutée :',
          message: `Équipement ${data.addProductToOrder.output.orderLineItem.orderLine?.[0]?.id} : ${selectedLensReplacement.node.name}`,
        })
        resetStateType('')
      },
    })
  

  const [addGenericItem, { loading: addGenericItemLoading }] =
    useMutation(ADD_GENERIC_ITEM_TO_ORDER, {
      onCompleted: (data) => {
        toast({
          title: 'Ligne ajoutée :',
          message: `Équipement ${data.addGenericItemToOrder.output.orderLineItem.orderLine?.[0]?.id} : ${selectedGenericItem.label}`,
        })
        resetStateType('')
      }
    })

  const addProductHandler = async () => {
    try {
      await addProduct({
        variables: {
          orderId: +order.id,
          newProductId: +selectedProduct.id,
          salePrice: parseFloat(salePrice),
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  const changeLensHandler = async () => {
    try {
      await addLensReplacement({
        variables: {
          orderId: +order.id,
          newProductId: +selectedLensReplacement.node.id,
          salePrice: null,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  const addGenericItemHandler = async () => {
    try {
      await addGenericItem({
        variables: {...{orderId: +order.id}, ...selectedGenericItem}
      })
    } catch(e) {
      console.log(e)
    }
  }

  // selectedFrame id, frameName
  const [selectedProduct, setSelectedProduct] = useState<any>()
  const [selectedLensReplacement, setSelectedLensReplacement] = useState<any>()
  const [selectedGenericItem, setSelectedGenericItem] = useState<any>()
  const [salePrice, setSalePrice] = useState<string>('')

  return (
    <Container className="mb-3">
      <Row>
        <Col className="text-center">
          <span className="fw-bold me-3">Type de produit* :</span>
          <Form.Check
            inline
            label="Monture"
            name="add-order-line"
            type="radio"
            id="frame-add"
            value="frame-add"
            onChange={(e) => {
              setSelectedProduct({})
              setSelectedRadioAddLineName(e.target.value)
            }}
          />
          <Form.Check
            inline
            label="Accessoire"
            name="add-order-line"
            type="radio"
            id="accessory-add"
            value="accessory-add"
            onChange={(e) => {
              setSelectedProduct({})
              setSelectedRadioAddLineName(e.target.value)
            }}
            disabled={order.orderType === 'essdom'}
          />
          <Form.Check
            inline
            label="Remplacement de verres"
            name="add-order-line"
            type="radio"
            id="change-lens"
            value="change-lens"
            onChange={(e) => {
              setSelectedRadioAddLineName(e.target.value)
            }}
            disabled={order.orderType === 'essdom'}
          />
          <Form.Check
            inline
            label="Produit générique"
            name="add-order-line"
            type="radio"
            id="generic-item-add"
            value="generic-item-add"
            onChange={(e) => {
              setSelectedRadioAddLineName(e.target.value)
            }}
            disabled={order.orderType === 'essdom'}
          />
        </Col>
      </Row>

      {(selectedRadioAddLineName === 'frame-add' || 
        selectedRadioAddLineName === 'accessory-add') &&
      <ProductSearchForm
        isFrame = {selectedRadioAddLineName === 'frame-add'}
        placeholder={selectedRadioAddLineName === 'frame-add'? "Nom Monture":"Nom Accessoire"}
        setSelectedProduct={setSelectedProduct}
        salePrice={salePrice}
        setSalePrice={setSalePrice}
      />
      }
      {selectedRadioAddLineName === 'change-lens' && (
        <LensReplacementForm
          selectedLensReplacement={selectedLensReplacement}
          setSelectedLensReplacement={setSelectedLensReplacement}
        />
      )}
      {selectedRadioAddLineName === 'generic-item-add' && (
        <GenericItemForm
          setSelectedGenericItem={setSelectedGenericItem}
        />
      )}

      <Row className="float-end mt-3">
        <Col>
          <Button className="me-2" size="sm" onClick={() => resetStateType('')}>
            Retour
          </Button>
          <Button
            size="sm"
            disabled={
              selectedRadioAddLineName === '' ||
              ((selectedRadioAddLineName === 'frame-add' || 
                selectedRadioAddLineName === 'accessory-add') &&
                Object.keys(selectedProduct).length === 0) ||
              (selectedRadioAddLineName === 'generic-item-add' &&
                selectedGenericItem === null) ||
              addProductLoading ||
              addLensReplacementLoading || 
              addProductLoading || /*voir les valeurs à sélectionner selon le radio button choisi (cf. Object.keys(selectedFrame).length === 0 dans le cas où on cherche à ajouter une frame) */
              addGenericItemLoading
            }
            onClick={() => {
              if (selectedRadioAddLineName === 'frame-add' || selectedRadioAddLineName === 'accessory-add') addProductHandler()
              else if (selectedRadioAddLineName === 'change-lens') changeLensHandler()
              else if (selectedRadioAddLineName === 'generic-item-add') addGenericItemHandler()
              // TODO gérer les autres cas
            }}
          >
            {addProductLoading ||
            addLensReplacementLoading || 
            addProductLoading ||
            addGenericItemLoading? (
              <Spinner
                variant="light"
                className="mx-4"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <>Ajouter la ligne</>
            )}
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default AddNewLineForm
