import { gql } from '@apollo/client'

export const DO_ACCESSORY_FIELDS = gql`
  fragment DOAccessoryFields on object_DOAccessory {
    id
    key
    creationDate
    name
    gtin
    stockLocation
    visible
    idmc
    color {
        ... on object_DOColor {
          label
        }
      }
    imageUrls
    stock {
      ... on fieldcollection_DOProductStockFC {
        totalQuantity
        availableQuantity
        essdomQuantity
        wipQuantity
        reservedQuantity
        salesThreshold
        homeTrialThreshold
        bbgrQuantity
      }
    }
  }
`