import React from 'react'
import { useState } from 'react'

const AuthContext = React.createContext({
  isLoggedIn: false,
  user: {
    token: '', // NB : token à ne pas utiliser pour envoi vers serveur car on ne le met pas à jour après chaque mutation (ça nécessite rafraichissement de la page) - on va se baser sur le local storage
    fullName: '',
    pimcoreAccess: true,
    storeId: '',
    roles: [],
    image: '',
    email: '',
  },
  menus: [
    {
      name: '',
      pageList: [],
    },
  ],
  websiteSettings: {
    colissimoExpertThreshold: 0,
    essdomReturnAddress: '',
    infiniteScrollTableFetchMoreRowsCount: 0,
    infiniteScrollTableInitialRowsCount: 0,
    roleOpticienMagasin: '',
    limitVoucher: 0,
    delaiNouveaute: 0,
  },
  login: (user: any) => {},
  logout: () => {},
  buildMenus: (menus: any) => {},
  setWebsiteSettings: (websiteSettings: any) => {},
  onMutationCallback: (success: boolean, user: any) => {},
})

export const AuthContextProvider = (props: any) => {
  let initMenus = []
  const storedMenus = localStorage.getItem('menus')
  if (storedMenus !== null) {
    initMenus = JSON.parse(storedMenus)
  }

  let initWebsiteSettings = {
    colissimoExpertThreshold: 0,
    essdomReturnAddress: '',
    infiniteScrollTableFetchMoreRowsCount: 0,
    infiniteScrollTableInitialRowsCount: 0,
    roleOpticienMagasin: '',
    limitVoucher: 0,
    delaiNouveaute: 0,
  }
  const storedWebsiteSettings = localStorage.getItem('websiteSettings')
  if (storedWebsiteSettings !== null) {
    initWebsiteSettings = JSON.parse(storedWebsiteSettings)
  }

  let initUser = {
    token: '',
    fullName: '',
    pimcoreAccess: true,
    storeId: '',
    roles: [],
    image: '',
    email: '',
  }
  const storedUser = localStorage.getItem('user')
  if (storedUser !== null) {
    initUser = JSON.parse(storedUser)
  }

  const [menus, setMenus] = useState<any>(initMenus)
  const [websiteSettings, setWebsiteSettings] =
    useState<any>(initWebsiteSettings)
  let user = initUser // pas un state, sinon à chaque MAJ du token, il y a MAJ du composant sur lequel on se trouve après mutation (rappel : chaque mutation met à jour le token)

  const isLoggedIn = !!user.token

  const loginHandler = (loggedUser: any) => {
    localStorage.setItem('user', JSON.stringify(loggedUser)) // On met d'abord à jour le local storage

    user = loggedUser
  }

  const logoutHandler = () => {
    localStorage.clear() // On met d'abord à jour le local storage

    user = {
      token: '',
      fullName: '',
      pimcoreAccess: true,
      storeId: '',
      roles: [],
      image: '',
      email: '',
    }
    // state menus qui permet de rafraîchir l'app et de rediriger vers le login
    setMenus([
      {
        name: '',
        pageList: [],
      },
    ])
  }

  const menusHandler = (menus: any) => {
    setMenus(menus)
    localStorage.setItem('menus', JSON.stringify(menus))
  }

  const websiteSettingsHandler = (websiteSettings: any) => {
    setWebsiteSettings(websiteSettings)
    localStorage.setItem('websiteSettings', JSON.stringify(websiteSettings))
  }

  const mutationCallbackHandler = (success: boolean, user: any) => {
    if (success) loginHandler(user)
    else {
      logoutHandler()
    }
  }

  const contextValue = {
    isLoggedIn,
    menus,
    websiteSettings,
    user,
    login: loginHandler,
    logout: logoutHandler,
    buildMenus: menusHandler,
    setWebsiteSettings: websiteSettingsHandler,
    onMutationCallback: mutationCallbackHandler,
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthContext
