import { useMutation } from '@apollo/client'
import { CUSTOMER_MUST_PAY_ORDER_EVENT_KEYS } from 'containers/comptabilite/CommandesRestantDu'
import CM_CREATE_DO_ORDER_EVENT from 'graphql/mutations/CreateDOOrderEvent'
import GET_ORDER_BY_PAYMENT_EVENT_TYPE_LISTING from 'graphql/queries/getDOOrderByPaymentEventTypeListing'
import React, { useContext, useState } from 'react'
import { Button, Form, Container, Row, Col, Spinner } from 'react-bootstrap'
import { ToastContext } from 'store/toast-context'
import { constants } from 'utils/constants'
import { validateDecimalInput } from 'utils/functions'
interface Props {
  item: any
  type: any
  onCancel: () => void
}
const OrderListToggle: React.FC<Props> = ({ item, onCancel }) => {
  const { toast } = useContext(ToastContext)

  const [amount, setAmount] = useState(item.amountToRefund || '')
  const [amountRefund, setAmountRefund] = useState(item.amountRefund || '')
  const [eventToCreateTypeKey, setEventToCreateTypeKey] = useState('')
  
  const isCustomerMustPay = CUSTOMER_MUST_PAY_ORDER_EVENT_KEYS.includes(
    item.eventTypeKey
  )
  const isCustomerMustPayExtra =
    item.eventTypeKey ===
    constants.eventTypeKeys.ORDER_EVENT_TYPE_CUSTOMER_MUST_PAY_EXTRA
  const isToRefund =
    item.eventTypeKey ===
    constants.eventTypeKeys.ORDER_EVENT_TYPE_TO_REFUND

  const [createDOOrderEvent, { loading }] = useMutation(
    CM_CREATE_DO_ORDER_EVENT,
    {
      onCompleted: (data) => {
        toast({
          title: 'Succès :',
          message: isCustomerMustPay
            ? 'Paiement restant dû enregistré'
            : 'Remboursement enregistré',
        })
      },
    }
  )

  const createOrderEventHandler = async (e: any) => {
    try {
      await createDOOrderEvent({
        refetchQueries: [GET_ORDER_BY_PAYMENT_EVENT_TYPE_LISTING],
        awaitRefetchQueries: true,
        variables: {
          mailInfo: amount,
          closureDate: `${new Date().toISOString()}`,
          orderId: +item.id,
          eventTypeKey: eventToCreateTypeKey,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  const createOrderPartialRefundEventHandler = async (e: any) => {
    try {
      await createDOOrderEvent({
        refetchQueries: [GET_ORDER_BY_PAYMENT_EVENT_TYPE_LISTING],
        awaitRefetchQueries: true,
        variables: {
          mailInfo: parseFloat(amountRefund).toFixed(2),
          closureDate: `${new Date().toISOString()}`,
          orderId: +item.id,
          eventTypeKey: eventToCreateTypeKey,
        },
      })
      await createDOOrderEvent({
        refetchQueries: [GET_ORDER_BY_PAYMENT_EVENT_TYPE_LISTING],
        awaitRefetchQueries: true,
        variables: {
          mailInfo: parseFloat((amount - amountRefund).toString()).toFixed(2).toString(),
          orderId: +item.id,
          sentEmail: false,
          eventTypeKey: constants.eventTypeKeys.ORDER_EVENT_TYPE_TO_REFUND,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  const handleAmountRefundChange  = (value: string) => {
    const validateAmountRefund = validateDecimalInput(value)
    setAmountRefund(validateAmountRefund)
    const refundFloat = parseFloat(validateAmountRefund) || 0;
    const amountFloat = parseFloat(amount) || 0;
    if(refundFloat > amountFloat){
      setAmountRefund(amountFloat);
    } else {
      setAmountRefund(validateAmountRefund);
    }
  }

  return (
    <>
      <Container fluid>
        {isCustomerMustPay ? (
          <>
            <Row className="align-items-center">
              <Col xs={2}>
                <b>Montant restant dû:</b>
              </Col>
              <Col>{item.amountToPay}</Col>
            </Row>
            <Row className="align-items-center">
              <Col xs={2}>
                <b>Montant encaissé:</b>
              </Col>
              <Col>
                <input
                  name="receivedAmount"
                  type="text"
                  onChange={(e) => {
                    setAmount(validateDecimalInput(e.target.value))
                  }}
                  value={amount}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className="align-items-center">
              <Col xs={2}>
                <b>Montant total à rembourser:</b>
              </Col>
              <Col>{item.amountToRefund}</Col>
            </Row>
            <Row className="align-items-center">
              <Col xs={2}>
                <strong><b>Montant à rembourser:</b></strong>
              </Col>
              <Col>
                <input
                  name="amountRefund"
                  type="text"
                  onChange={(e) => {
                    handleAmountRefundChange(e.target.value)
                  }}
                  value={amountRefund}
                />
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col xs={12}>
            <Form.Check
              inline
              label="Chèque"
              name={`group${item.id}`}
              type="radio"
              id={`inline-${item.id}-cheque`}
              value={
                isCustomerMustPay
                  ? constants.eventTypeKeys.ORDER_EVENT_TYPE_PAID_BY_CHEQUE
                  : isCustomerMustPayExtra
                  ? constants.eventTypeKeys
                      .ORDER_EVENT_TYPE_EXTRA_AMOUNT_PAID_BY_CHEQUE
                  : isToRefund
                  ? constants.eventTypeKeys.ORDER_EVENT_TYPE_REFUNDED_BY_CHEQUE
                  : constants.eventTypeKeys
                      .ORDER_EVENT_TYPE_PARTIALLY_REFUNDED_BY_CHEQUE
              }
              onChange={(e) => {
                setEventToCreateTypeKey(e.target.value)
              }}
            />
            <Form.Check
              inline
              label="Paypal"
              name={`group${item.id}`}
              type="radio"
              id={`inline-${item.id}-paypal`}
              value={
                isCustomerMustPay
                  ? constants.eventTypeKeys.ORDER_EVENT_TYPE_PAID_BY_PAYPAL
                  : isCustomerMustPayExtra
                  ? constants.eventTypeKeys
                      .ORDER_EVENT_TYPE_EXTRA_AMOUNT_PAID_BY_PAYPAL
                  : isToRefund
                  ? constants.eventTypeKeys.ORDER_EVENT_TYPE_REFUNDED_BY_PAYPAL
                  : constants.eventTypeKeys
                      .ORDER_EVENT_TYPE_PARTIALLY_REFUNDED_BY_PAYPAL
              }
              onChange={(e) => {
                setEventToCreateTypeKey(e.target.value)
              }}
            />
            <Form.Check
              inline
              label="Virement"
              name={`group${item.id}`}
              type="radio"
              id={`inline-${item.id}-virement`}
              value={
                isCustomerMustPay
                  ? constants.eventTypeKeys
                      .ORDER_EVENT_TYPE_PAID_BY_BANK_TRANSFER
                  : isCustomerMustPayExtra
                  ? constants.eventTypeKeys
                      .ORDER_EVENT_TYPE_EXTRA_AMOUNT_PAID_BY_BANK_TRANSFER
                  : isToRefund
                  ? constants.eventTypeKeys
                      .ORDER_EVENT_TYPE_REFUNDED_BY_BANK_TRANSFER
                  : constants.eventTypeKeys
                      .ORDER_EVENT_TYPE_PARTIALLY_REFUNDED_BY_BANK_TRANSFER
              }
              onChange={(e) => {
                setEventToCreateTypeKey(e.target.value)
              }}
            />
            <Form.Check
              inline
              label="CB"
              name={`group${item.id}`}
              type="radio"
              id={`inline-${item.id}-cb`}
              value={
                isCustomerMustPay
                  ? constants.eventTypeKeys.ORDER_EVENT_TYPE_PAID_BY_CB
                  : isCustomerMustPayExtra
                  ? constants.eventTypeKeys
                      .ORDER_EVENT_TYPE_EXTRA_AMOUNT_PAID_BY_CB
                  : isToRefund
                  ? constants.eventTypeKeys.ORDER_EVENT_TYPE_REFUNDED_BY_CB
                  : constants.eventTypeKeys
                      .ORDER_EVENT_TYPE_PARTIALLY_REFUNDED_BY_CB
              }
              onChange={(e) => {
                setEventToCreateTypeKey(e.target.value)
              }}
            />
            <Button
              variant="primary"
              disabled={!eventToCreateTypeKey || loading || !amount || (!isCustomerMustPay ? !(amountRefund && amountRefund > 0) : false)}
              onClick={isCustomerMustPay || (+amountRefund === +amount) ? createOrderEventHandler : createOrderPartialRefundEventHandler}
            >
              {loading && (
                <Spinner
                  variant="light"
                  className="me-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Enregistrer
            </Button>
            <Button
              variant="secondary"
              className="m-3"
              onClick={() => {
                onCancel()
              }}
            >
              Annuler
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default OrderListToggle