import React from 'react'
import { Form, Col, Row } from 'react-bootstrap'
import { getAge } from 'utils/functions'
interface Props {
  newBeneficiary: any
  setNewBeneficairy: React.Dispatch<React.SetStateAction<any>>
}
const NewBeneficiaryForm: React.FC<Props> = ({
  newBeneficiary,
  setNewBeneficairy,
}) => {
  const ageValidation = getAge(newBeneficiary.birthdate) <= 16
  return (
    <Form>
      <Form.Group as={Row} className="mb-2" controlId="lastname">
        <Form.Label column sm="3">
          Nom <sup>*</sup>
        </Form.Label>
        <Col sm="5">
          <Form.Control
            type="text"
            placeholder="Nom"
            defaultValue=""
            onChange={(e) => {
              setNewBeneficairy({
                ...newBeneficiary,
                lastName: e.target.value,
              })
            }}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-2" controlId="firstname">
        <Form.Label column sm="3">
          Prénom <sup>*</sup>
        </Form.Label>
        <Col sm="5">
          <Form.Control
            type="text"
            placeholder="Prénom"
            defaultValue=""
            onChange={(e) => {
              setNewBeneficairy({
                ...newBeneficiary,
                firstName: e.target.value,
              })
            }}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-0" controlId="dateOfBirth">
        <Form.Label column sm="3">
          Date de naissance <sup>*</sup>
        </Form.Label>
        <Col sm="5">
          <Form.Control
            type="date"
            className={ageValidation ? 'is-invalid' : ''}
            defaultValue=""
            onChange={(e) => {
              setNewBeneficairy({
                ...newBeneficiary,
                birthdate: e.target.value,
              })
            }}
          />
          <div className="text-danger small-content">
            {ageValidation ? 'Le patient a moins de 16 ans' : ''}
          </div>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-2" controlId="gender">
        <Form.Label column sm="3">
          Genre <sup>*</sup>
        </Form.Label>
        <Col sm="5">
          <Form.Check
            inline
            label="Homme"
            name="gender"
            type="radio"
            id="monsieur"
            value="monsieur" // civility value of contactNameFC
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setNewBeneficairy({
                ...newBeneficiary,
                gender: e.target.value,
              })
            }}
          />
          <Form.Check
            inline
            label="Femme"
            name="gender"
            type="radio"
            id="madame"
            value="madame" // civility value of contactNameFC
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setNewBeneficairy({
                ...newBeneficiary,
                gender: e.target.value,
              })
            }}
          />
        </Col>
      </Form.Group>
    </Form>
  )
}

export default NewBeneficiaryForm
