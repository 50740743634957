import { gql } from '@apollo/client'

export const GET_COUNTRIES_LISTING = gql`
  query GET_COUNTRIES_LISTING {
    getCountriesListing {
      totalCount
      edges {
        node {
          key
          value
        }
      }
    }
  }
`
