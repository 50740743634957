import { gql } from '@apollo/client'
import { DO_ORDER_EVENT_FIELDS } from 'graphql/fragments/DOOrderEventFields'

const GET_ORDER_ACCOUNTING_EVENTS_DATA = gql`
${DO_ORDER_EVENT_FIELDS}
query GET_ORDER_ACCOUNTING_EVENTS_DATA(
  $id: Int!
) {
	CQgetDOOrderAccountingEventsData(id: $id) {
    edges {
      node {
        ...DOOrderEventFields
      }
    }
    totalPaymentAmount
    totalRefundAmount
    totalPaidAmount
  }
}
`

export default GET_ORDER_ACCOUNTING_EVENTS_DATA