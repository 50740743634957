import { gql } from '@apollo/client'

const UPDATE_DO_ORDERLINE = gql`
  mutation UPDATE_DO_ORDERLINE(
    $id: Int!
    $treatmentFormInUse: Boolean
    $insuranceInvoiceInUse: Boolean
    $visionType: String
  ) {
    updateDOOrderLine(
      id: $id
      input: {
        treatmentFormInUse: $treatmentFormInUse
        insuranceInvoiceInUse: $insuranceInvoiceInUse
        visionType: $visionType
      }
    ) {
      success
      output {
        id
        insuranceInvoiceInUse
        treatmentFormInUse
        visionType
      }
    }
  }
`
export default UPDATE_DO_ORDERLINE
