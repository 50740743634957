import { gql } from '@apollo/client'

export const ADD_GUARANTEE_TO_ORDERLINE = gql`
  mutation ($orderLineId: Int!) {
    addGuaranteeToOrderLine(orderLineId: $orderLineId) {
      success
      output {
        order {
          id
          events {
            ... on object_DOOrderEvent {
              id
              eventType {
                ... on object_DOOrderEventType {
                  id
                  key
                  eventFamily
                }
              }
            }
          }
        }
        orderLineItem {
          id
          label
          unitAmount {
            value
          }
          item {
            ... on fieldcollection_DOOLItemGenericFC {
              genericItem {
                ... on object_DOGenericItem {
                  id
                  label
                  itemType
                  amount {
                    value
                  }
                  isNegative
                }
              }
            }
          }
          orderLine {
            ... on object_DOOrderLine {
              id
              stockState
              status
            }
          }
        }
      }
    }
  }
`