import { gql } from "@apollo/client";
import { DO_CONTACT_INFO_FC_FIELDS } from "graphql/fragments/DOContactInfoFCFields";
import { DO_CONTACT_NAME_FC_FIELDS } from "graphql/fragments/DOContactNameFCFields";

export const GET_DO_ORDER_BY_ORDER_TYPE = gql`
${DO_CONTACT_INFO_FC_FIELDS}
${DO_CONTACT_NAME_FC_FIELDS}
query GET_DO_ORDER_BY_ORDER_TYPE($orderIds: String!, $orderType: String!) {
  getDOOrderByOrderType(orderIds: $orderIds, orderType: $orderType) {
    edges {
      node {
        order {
          ... on object_DOOrder {
            id
            key
            customer {
              ... on object_DOCustomer {
                login
              }
            }
            deliveryName {
             ...DOContactNameFCFields
            }
            deliveryAddress {
             ...DOContactInfoFCFields
            }
            deliveryMode {
              ... on object_DODeliveryMode {
                id
                key
              }
            }
            pickupPointCode
          }
        }
        calculators {
          cosiumId
          productCount
        }
      }
    }
  }
}
`