import { gql } from '@apollo/client'
import { DO_FRAME_FIELDS } from 'graphql/fragments/DOFrameFields'

//custom query : new frame listing (new frame or frame without img)
const GET_NEW_DO_FRAME_LISTING = gql`
${DO_FRAME_FIELDS}
query GET_NEW_DO_FRAME_LISTING ($offset: Int, $limit: Int){
  getNewDOFrameCustomListing (offset: $offset, limit: $limit) {
    totalCount 
    edges {
      node {
        ...DOFrameFields
      }
    }
    totalFrames
  }
}`

  export default GET_NEW_DO_FRAME_LISTING