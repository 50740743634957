import React, { useContext, useState, useRef, useEffect } from 'react'
import { Button, Col, Form, Row, Spinner, Table } from 'react-bootstrap'
import Card from 'components/UI/Card'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import {
  getFormattedDate,
  extractPhoneNumberFromContactInfo,
  getTrackingNumber,
} from 'utils/functions'
import CREATE_DO_ORDER_EVENT_FOR_ORDERS from 'graphql/mutations/createDOOrderEventForOrders'
import { constants } from 'utils/constants'
import { ToastContext } from 'store/toast-context'
import { GET_ORDER_LISTING } from 'graphql/queries/getOrderListing'

interface orderAndComment {
  orderId: number
  mailInfo: string
  comment: string
}
interface parcelType {
  [orderId: string]: string
}
// /bo/logistique_kehl/expedition_commande in documents Pimcore
const ExpeditionColisEssdom: React.FC = () => {
  const { t } = useTranslation()
  const { toast } = useContext(ToastContext)

  const [searchInputText, setSearchInputText] = useState('')
  const [orderType, setOrderType] = useState('panier')
  //list of parcel number
  const [parcelNumbers, setParcelNumbers] = useState([] as any)
  //list of return parcel number for essdom
  const [returnParcelNumbers, setReturnParcelNumbers] = useState([] as any)
  //list of order to ship
  const [orderToDisplayList, setOrderToDisplayList] = useState(
    [] as orderType[]
  )
  const [info, setInfo] = useState('')
  const inputSearchRef = useRef<HTMLInputElement | null>(null)
  const inputPackageNumberOutRef = useRef<HTMLInputElement | null>(null)

  //Lazy query
  const [getOrderListing, { loading: orderLoading, data: orderData }] =
    useLazyQuery(GET_ORDER_LISTING, {
      fetchPolicy: 'cache-and-network',
      onCompleted: (orderData) => {
        // if we have orderData construct the table with the list of orders
        if (orderData?.getOrderListing?.totalCount === 0) {
          setInfo("La commande saisie n'existe pas")
        } else if (
          orderData?.getOrderListing?.edges[0].node.order.orderType !== orderType
        ) {
          setInfo(
            `La commande saisie n'est pas une commande ${
              orderType === 'panier' ? 'classique' : "d'essai à domicile"
            }`
          )
        } else if (
          // (orderData?.getDOOrderListing?.edges[0].node.events.find(
          //   (event: any) =>
          //     event.eventType.key ===
          //     constants.eventTypeKeys.ORDER_EVENT_TYPE_AFTERSALESTASK
          //   //pas sûr de l'event ? 'NantesSav_ReadyToAssemble' ou 'KehlWorkshop_ReadyToAssemble' ou 'AftersalesTask'
          // ) &&
          //   orderData?.getDOOrderListing?.edges[0].node.workflowState ===
          //     'awaiting_preparation') ||
          orderData?.getOrderListing?.edges[0].node.order.workflowState ===
          'in_preparation'
        ) {
          // if(orderToDisplayList.length===5){
          //   setInfo("Enregistrer les 5 commandes ci-dessous avant de continuer")
          // }
          // //check if order is not already in the list
          // else
          if (
            !orderToDisplayList.find(
              (order: orderType) =>
                +order.id === +orderData?.getOrderListing?.edges[0].node.order.id
            )
          ) {
            // 'Cette commande peut être expédiée'
            setInfo('')
            if (
              (orderToDisplayList.length > 0 &&
                orderType === orderToDisplayList[0].orderType) ||
              orderToDisplayList.length === 0
            ) {
              //order to display in shipping table
              // console.log(orderToDisplayList, eventTypeList);
              setOrderToDisplayList((prevList: orderType[]) => [
                ...prevList,
                orderData?.getOrderListing?.edges[0].node.order,
              ])
              if (orderType === 'panier')
                setParcelNumbers((prevState: parcelType) => {
                  let newObj = Object.assign({}, prevState)
                  let defaultCommValue = ''
                  if (
                    orderData?.getOrderListing?.edges[0].node.order.deliveryMode
                      .key === 'magasin_direct_optic'
                  )
                    defaultCommValue = 'Livraison magasin'
                  newObj[orderData?.getOrderListing?.edges[0].node.order.id] =
                    defaultCommValue
                  return newObj
                })
            }
          } else setInfo('La commande est déjà ajoutée')
        } else {
          setInfo("Cette commande n'a pas été préparée")
        }
      },
    })

  // mutation
  const [createDOOrderEventForOrders, { loading }] = useMutation(
    CREATE_DO_ORDER_EVENT_FOR_ORDERS,
    {
      onCompleted: async (data) => {
        // Displaying toast for successful orders
        if (
          data?.createDOOrderEventForOrders?.output?.creationSuccessOrderKeys
            .length > 0
        )
          toast({
            title: 'Mise en expédition :',
            message:
              data?.createDOOrderEventForOrders.output.creationSuccessOrderKeys
                .length +
              ' ' +
              t('message.enumerate_order_key_exp_plural', {
                count:
                  data?.createDOOrderEventForOrders.output
                    .creationSuccessOrderKeys.length,
              }) +
              ' ' +
              (orderType === 'panier'
                ? data?.createDOOrderEventForOrders.output.creationSuccessOrderCosiumIds.join(
                    ', '
                  )
                : data?.createDOOrderEventForOrders.output.creationSuccessOrderKeys.join(
                    ', '
                  )),
          })

        // Displaying error toast for failed orders
        if (
          data?.createDOOrderEventForOrders.output.creationFailOrderKeys
            .length > 0
        ) {
          toast({
            title: 'Erreur mise en expédition :',
            message:
              data?.createDOOrderEventForOrders.output.creationFailOrderKeys
                .length +
              ' ' +
              t('message.enumerate_order_key_exp_plural', {
                count:
                  data?.createDOOrderEventForOrders.output
                    .creationSuccessOrderKeys.length,
              }) +
              (orderType === 'panier'
                ? data?.createDOOrderEventForOrders.output.creationFailOrderCosiumIds.join(
                    ', '
                  )
                : data?.createDOOrderEventForOrders.output.creationFailOrderKeys.join(
                    ', '
                  )),
            manualClose: true,
          })
        }
        eraseAll()
      },
      onError: () => {
        eraseAll()
      },
    }
  )

  // function for radio button
  const onChangeOrderType = (filter: string) => {
    setOrderType(filter)
  }

  const onClickAdd = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await getOrderListing({
      variables: {
        filter:
          orderType === 'panier'
            ? `{"$and":[{"cosiumId":{"$like":"${searchInputText}"}}]}`
            : `{"$and":[{"o_key":{"$like":"${searchInputText}"}}]}`,
      },
    })
    //filter: `{"$and":[{"cosiumId":{"$like":"${searchInputText}"}},{"orderType":{"$like":"${orderType}"}}]}`,
  }
  const eraseAll = () => {
    setOrderToDisplayList([])
    setParcelNumbers([])
    setReturnParcelNumbers([])
    setSearchInputText('')
  }
  const onClickEraseAll = () => {
    if (window.confirm('Êtes-vous sûr(e) de tout effacer ?')) {
      eraseAll() 
    }
  }

  //function to get the ordersAndComment variable for mutation
  const getOrdersAndComments = () => {
    let ordersAndComments: orderAndComment[] = []
    for (const [orderId, value] of Object.entries(parcelNumbers)) {
      if (typeof value === 'string') {
        //if parcel number has a valid format get the tracking number
        let newParcelNumber =
          value.length >= 23 && value[0] === '%'
            ? getTrackingNumber(value)
            : value

        let newReturnParcelNumber = ''
        //if order is type essdom set the return parcel number
        //and if parcel number has a valid format get the tracking number
        if (
          orderType === 'essdom' &&
          returnParcelNumbers[orderId].length >= 23 &&
          returnParcelNumbers[orderId][0] === '%'
        ) {
          newReturnParcelNumber = getTrackingNumber(
            returnParcelNumbers[orderId]
          )
        } else {
          newReturnParcelNumber = returnParcelNumbers[orderId]
        }

        ordersAndComments.push({
          orderId: +orderId,
          mailInfo: newParcelNumber,
          comment:
            "N° d'aller : " +
            newParcelNumber +
            (orderType === 'panier'
              ? ''
              : ', N° de retour : ' + newReturnParcelNumber),
        })
      }
    }

    return ordersAndComments
  }

  const saveExpedition = async () => {
    //retrieve variable of mutation
    const orderAndCommentList = getOrdersAndComments()
    //mutation when ship orders
    await createDOOrderEventForOrders({
      variables: {
        eventTypeKey: constants.eventTypeKeys.ORDER_EVENT_TYPE_TO_SHIP,
        ordersAndComments: orderAndCommentList,
      },
    })
  }
  const checkIfInputsEmpty = () => {
    if (
      orderToDisplayList.length === 0 ||
      orderToDisplayList.length !== Object.keys(parcelNumbers).length ||
      (orderType === 'essdom' &&
        orderToDisplayList.length !== Object.keys(returnParcelNumbers).length)
    ) {
      return true
    }

    for (const comment of Object.values(parcelNumbers)) {
      if (!comment) {
        return true
      }
    }
    if (orderType === 'essdom')
      for (const comment of Object.values(returnParcelNumbers)) {
        if (!comment) {
          return true
        }
      }
    return false
  }
  const handleEnterKeyDown = (e: any, orderId: string, isReturn?: boolean) => {
    if (e.key === 'Enter') { 
      if (e.target.value[0] === '%' && e.target.value.length >= 23) {
        // If new id13 barcode
        let newParcelCode = getTrackingNumber(e.target.value)
        if (!isReturn) {
          setParcelNumbers((prevState: parcelType) => {
            let newObj = Object.assign({}, prevState)
            newObj[orderId] = newParcelCode
            return newObj
          })
        } else {
          setReturnParcelNumbers((prevState: parcelType) => {
            let newObj = Object.assign({}, prevState)
            newObj[orderId] = newParcelCode
            return newObj
          })
        }
      }
      if(e.target.name === "package-number-in" && orderType === 'panier'){
        saveExpedition()
      }
      else if(e.target.name === "package-number-in" && orderType === 'essdom'){
        inputPackageNumberOutRef.current?.focus()
      }
      else if(e.target.name === "package-number-out"){
        saveExpedition()  
      }
    }
  }
  const removeOrder = (order: orderType) => {
    const newList = orderToDisplayList.filter(
      (item: orderType) => item.id !== order.id
    )
    //remove the order from the shipping table
    setOrderToDisplayList(newList)
    //remove the parcel number from each list (parcel list and return parcel list)
    const newParcelNumber = { ...parcelNumbers }
    delete newParcelNumber[order.id]
    const newReturnParcelNumber = { ...returnParcelNumbers }
    delete newReturnParcelNumber[order.id]
    setParcelNumbers(newParcelNumber)
    setReturnParcelNumbers(newReturnParcelNumber)
  }

  useEffect(() => {
    if (searchInputText === '' || orderType) {
      inputSearchRef.current?.focus()
    }
  }, [searchInputText, orderType]);

  return (
    <Card>
      <p>
        Cette page permet d'enregistrer une expédition de commande et le numéro
        de colis associé.
      </p>
      <Form>
        <Form.Group>
          <Form.Label column sm="3">
            <strong>Type de commande :</strong>
          </Form.Label>
          <Form.Check
            inline
            label="Classique"
            name="panier"
            type="radio"
            id="panier"
            value="panier"
            checked={orderType === 'panier'}
            onChange={(e) => onChangeOrderType(e.target.value)}
            disabled={orderType === 'essdom' && orderToDisplayList.length > 0}
          />
          <Form.Check
            inline
            label="Essai à domicile"
            name="essdom"
            type="radio"
            id="essdom"
            value="essdom"
            checked={orderType === 'essdom'}
            onChange={(e) => onChangeOrderType(e.target.value)}
            disabled={orderType === 'panier' && orderToDisplayList.length > 0}
          />
        </Form.Group>
      </Form>
      <Form onSubmit={(e) => onClickAdd(e)}>
        <Form.Group as={Row} className="mb-3" controlId="cosium-id-input">
          <Form.Label column sm="4">
            Ajouter les commandes à traiter :
          </Form.Label>
          <Col sm="4">
            <Form.Control
              type="text"
              placeholder={
                orderType === 'panier' ? 'N° de Devis Cosium' : 'N° de commande'
              }
              value={searchInputText}
              onChange={(e) =>
                setSearchInputText(e.target.value.replace(/\s+/g, ''))
              }
              disabled={orderToDisplayList.length > 0}
              autoFocus
              ref={inputSearchRef}
            />
            {orderData && <p className="small text-danger">{info}</p>}
          </Col>
          <Col>
            <Button
              data-cy="addOrder"
              type="submit"
              disabled={!searchInputText}
            >
              {orderLoading ? (
                <Spinner
                  variant="light mx-3"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <>Ajouter</>
              )}
            </Button>
          </Col>
        </Form.Group>
      </Form>

      <Table striped>
        <thead>
          <tr>
            <th>
              {orderType === 'panier' ? 'N° de Devis Cosium' : 'N° de commande'}
            </th>
            <th>Date de cmd</th>
            <th> Nom Prénom</th>
            <th>Téléphone</th>
            <th>Email</th>
            <th>Numéro de colis aller</th>
            {orderType === 'essdom' && <th>Numéro de colis retour</th>}
          </tr>
        </thead>
        <tbody>
          {orderToDisplayList && orderToDisplayList.length > 0 ? (
            orderToDisplayList.map((order: orderType, key: number) => {
              return (
                <tr key={key}>
                  <td>
                    <a
                      href={`/bo/relation_client/gestion_commande_client/${order.id}`}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e: any) => e.stopPropagation()}
                    >
                      {orderType === 'panier' ? orderData?.getOrderListing?.edges[key].node.calculators.cosiumId : order.key}
                    </a>
                  </td>
                  <td>{getFormattedDate(order.creationDate * 1000)}</td>
                  <td>
                    {order.customer.contactName[0].lastName}{' '}
                    {order.customer.contactName[0].firstName}
                  </td>
                  <td>
                    {extractPhoneNumberFromContactInfo(
                      order.customer.contactInfo
                    )}
                  </td>
                  <td>{order.customer.contactInfo[0].email}</td>

                  <td>
                    <Form.Control
                      name="package-number-in"
                      data-cy="outward-parcel-input"
                      type="text"
                      placeholder="n° d'aller"
                      value={
                        parcelNumbers[order.id] ? parcelNumbers[order.id] : ''
                      }
                      disabled={
                        orderType === 'panier' &&
                        order.deliveryMode.key === 'magasin_direct_optic'
                      }
                      onChange={(e) => {
                        setParcelNumbers((prevState: parcelType) => {
                          let newObj = Object.assign({}, prevState)
                          newObj[order.id] = e.target.value
                          return newObj
                        })
                      }}
                      onKeyDown={(e) => handleEnterKeyDown(e, order.id)}
                      autoFocus
                    />
                  </td>
                  {orderType === 'essdom' && (
                    <td className="text-center">
                      {order.orderType === 'essdom' ? (
                        <Form.Control
                          name="package-number-out"
                          type="text"
                          placeholder="n° de retour"
                          value={
                            returnParcelNumbers[order.id]
                              ? returnParcelNumbers[order.id]
                              : ''
                          }
                          onChange={(e) => {
                            setReturnParcelNumbers((prevState: parcelType) => {
                              let newObj = Object.assign({}, prevState)
                              newObj[order.id] = e.target.value
                              return newObj
                            })
                          }}
                          onKeyDown={(e) =>
                            handleEnterKeyDown(e, order.id, true)
                          }
                          ref={inputPackageNumberOutRef}
                        />
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                  )}
                  <td className="align-middle">
                    <span
                      className="btn btn-close"
                      onClick={() => removeOrder(order)}
                    ></span>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr></tr>
          )}
          <tr className="alert-secondary">
            <td colSpan={orderType === 'panier' ? 6 : 7}>
              <span className="text-muted form-text">
                {orderToDisplayList.length} à enregistrer
              </span>
            </td>
          </tr>
        </tbody>
      </Table>

      <div className="d-flex justify-content-center mt-4">
        <Button
          data-cy="saveShipping"
          className="me-3"
          type="submit"
          disabled={loading || checkIfInputsEmpty()}
          onClick={saveExpedition}
        >
          {loading && (
            <Spinner
              variant="light me-1"
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Enregistrer expédition
        </Button>{' '}
        <Button
          variant="danger"
          onClick={onClickEraseAll}
          disabled={orderToDisplayList.length === 0}
        >
          Effacer tout
        </Button>
      </div>
    </Card>
  )
}

export default ExpeditionColisEssdom
