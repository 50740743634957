import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EventCard from './EventCard'
import { Button } from 'react-bootstrap'
import { gql, useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import EventForm from './EventForm'
import AuthContext from 'store/auth-context'
import { ToastContext } from 'store/toast-context'
import { capitalizeFirstLetter } from 'utils/functions'
import CM_CREATE_DO_ORDER_EVENT from 'graphql/mutations/CreateDOOrderEvent'
import { constants } from 'utils/constants'
interface Props {
  eventTypeKeyFilter: string
  events: [orderEvent]
}
const EventList: React.FC<Props> = ({ eventTypeKeyFilter, events }) => {
  const { id } = useParams<ParamType>()
  const { t } = useTranslation()

  const authCtx = useContext(AuthContext)

  const [displayedForm, setDisplayedForm] = useState('')
  const [newItemIdAdded, setNewItemIdAdded] = useState(0)

  const { toast } = useContext(ToastContext)

  const [createDOOrderEvent, { loading: createDOOrderEventLoading }] =
    useMutation(CM_CREATE_DO_ORDER_EVENT, {
      onCompleted: (data) => {
        setDisplayedForm('') // On masque le formulaire

        toast({
          title: 'Ajout effectué :',
          message: capitalizeFirstLetter(
            t(`orderEventType.${eventTypeKeyFilter}`)
          ),
        })
        setNewItemIdAdded(data.CMCreateDOOrderEvent.output.orderEvent.id)
      },
    })

  const UPDATE_DO_ORDER_EVENT = gql`
    mutation UPDATE_DO_ORDER_EVENT(
      $id: Int!
      $closureDate: String!
      $userLoginClosure: String!
    ) {
      updateDOOrderEvent(
        id: $id
        input: {
          closureDate: $closureDate
          userLoginClosure: $userLoginClosure
        }
      ) {
        success
        output {
          id
          comment
          creationDate
          closureDate
          userLoginClosure
          eventType {
            ... on object_DOOrderEventType {
              id
              key
              eventFamily
            }
          }
        }
      }
    }
  `
  const [updateDOOrderEvent] = useMutation(UPDATE_DO_ORDER_EVENT, {
    onCompleted: (data) => {
      toast({
        title: 'Evènement clôturé :',
        message: data?.updateDOOrderEvent.output.comment,
      })
    },
  })

  const createOrderEventHandler = async (comment: string) => {
    try {
      await createDOOrderEvent({
        variables: {
          comment,
          orderId: +id,
          eventTypeKey: eventTypeKeyFilter,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  const closeOrderEventHandler = async (orderEventId: number) => {
    try {
      await updateDOOrderEvent({
        variables: {
          id: +orderEventId,
          closureDate: `${new Date().toISOString()}`,
          userLoginClosure: authCtx.user.fullName,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div>
      {events
        .filter(
          (e: orderEvent) =>
            e.eventType?.key === eventTypeKeyFilter && e.closureDate === null
        )
        .sort(
          (eventA: orderEvent, eventB: orderEvent) =>
            eventB.creationDate - eventA.creationDate
        )
        .map((event: orderEvent) => {
          return (
            <EventCard
              key={event.id}
              orderEvent={event}
              onToggleClick={closeOrderEventHandler}
              newItemIdAdded={newItemIdAdded}
            ></EventCard>
          )
        })}

      {eventTypeKeyFilter !==
        constants.eventTypeKeys
          .ORDER_EVENT_TYPE_WIP_PRODUCT_TO_PUT_PHYSICALLY_BACK_TO_STOCK && (
        <div className="d-flex justify-content-center">
          {displayedForm !== `add-${eventTypeKeyFilter}` && (
            <Button
              variant="outline-primary p-1 px-3 m-2 btn-sm"
              onClick={() => setDisplayedForm(`add-${eventTypeKeyFilter}`)}
            >
              Ajouter
            </Button>
          )}
        </div>
      )}

      {displayedForm === `add-${eventTypeKeyFilter}` && (
        <EventForm
          createOrderEvent={createOrderEventHandler}
          hideForm={() => setDisplayedForm('')}
          mutationLoading={createDOOrderEventLoading}
        />
      )}
    </div>
  )
}

export default EventList
