import { gql } from '@apollo/client'

export const GET_ORDER_BENEFICIARIES = gql`
  query GET_ORDER_BENEFICIARIES($id: Int!) {
    getDOOrder(id: $id) {
      id
      customer {
        ... on object_DOCustomer {
          id
          beneficiaries {
            ... on object_DOBeneficiary {
              id
              name {
                ... on fieldcollection_DOContactNameFC {
                  firstName
                  lastName
                  civility
                }
              }
              birthDate
              prescriptions {
                ... on object_DOPrescription {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`
