import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router-dom'
import { BadgeCheckIcon, BanIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'react-bootstrap'

const BadgeCard: React.FC = () => {
  const { id } = useParams<ParamType>()
  const { t } = useTranslation()

  const GET_CUSTOMER_BADGE = gql`
    query GET_CUSTOMER_BADGE($id: Int!) {
      getDOOrder(id: $id) {
        id
        customer {
          ... on object_DOCustomer {
            id
            badge
          }
        }
      }
    }
  `

  const { loading, error, data } = useQuery(GET_CUSTOMER_BADGE, {
    variables: {
      id: +id,
    },
  })

  if (loading)
    return (
      <Spinner
        variant="primary ms-2"
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    )
  if (error) return <p>`Error! ${error.message}`</p>

  const customer = data.getDOOrder.customer

  return (
    <span className="d-flex align-items-center me-auto">
      {customer.badge === 'blacklist' ? (
        <>
          {' '}
          <BanIcon className={`header-title-icon ${customer.badge}`} />
          Client {t(customer.badge)}
        </>
      ) : (
        customer.badge && (
          <>
            <BadgeCheckIcon className={`header-title-icon ${customer.badge}`} />
            Client {t(customer.badge)}
          </>
        )
      )}
    </span>
  )
}

export default BadgeCard
