import React from 'react'

import GestionCommandeClient from '../containers/relation_client/GestionCommandeClient'
import GestionCommande from '../containers/relation_client/GestionCommande'
import GestionBDRTemplate from '../containers/bons_de_reduction/GestionBDRTemplate'
import GestionBDR from '../containers/bons_de_reduction/GestionBDR'
import GenerationCodesBDREnMasse from '../containers/bons_de_reduction/GenerationCodesBDREnMasse'
import DesactivationBon from '../containers/bons_de_reduction/DesactivationBon'
import UtilisationBon from '../containers/bons_de_reduction/UtilisationBon'
import ExportBonsUtilisesMagasins from '../containers/suivi/ExportBonsUtilisesMagasins'
import ExportDesVentes from '../containers/suivi/ExportDesVentes'
import ExportExpeditionKehl from '../containers/suivi/ExportExpeditionKehl'
import ExportRepartitionVentes from '../containers/suivi/ExportRepartitionVentes'
import PickingClients from '../containers/logistique_kehl/PickingClients'
import CommandeMagasin from '../containers/logistique_kehl/CommandeMagasin'
import BonDeFabricationElectronique from '../containers/logistique_kehl/BonDeFabricationElectronique'
import ColishipCSV from '../containers/logistique_kehl/ColishipCSV'
import CodeBarreEAN from '../containers/logistique_kehl/CodeBarreEAN'
import TachesLogistiquesKehl from '../containers/logistique_kehl/TachesLogistiquesKehl'
import PickingEssdom from '../containers/logistique_kehl/PickingEssdom'
import ExpeditionColisEssdom from '../containers/logistique_kehl/ExpeditionColisEssdom'
import VisualisationReassort from '../containers/gestion_stock/VisualisationReassort'
import EntreeSortieDeStock from '../containers/gestion_stock/EntreeSortieDeStock'
import GestionFluxDeStock from '../containers/gestion_stock/GestionFluxDeStock'
import TrackingStock from '../containers/gestion_stock/TrackingStock'
import ListeStock from '../containers/gestion_stock/ListeStock'
import RechercheParEmplacement from '../containers/gestion_stock/RechercheParEmplacement'
import ProduitsAGerer from '../containers/gestion_stock/ProduitsAGerer'
import InventaireKehl from '../containers/gestion_stock/InventaireKehl'
import LogsInventaire from '../containers/gestion_stock/LogsInventaire'
import AttenteDeProdNte from '../containers/sav_nantes/AttenteDeProdNte'
import TachesAtelierNte from '../containers/sav_nantes/TachesAtelierNte'
import CommandesAttenteRemboursement from '../containers/comptabilite/CommandesAttenteRemboursement'
import CommandesRestantDu from '../containers/comptabilite/CommandesRestantDu'
import Comptes from '../containers/comptabilite/Comptes'
import Balance from '../containers/comptabilite/Balance'
import BalanceTiers from '../containers/comptabilite/BalanceTiers'
import EcrituresComptables from '../containers/comptabilite/EcrituresComptables'
import GrandLivre from '../containers/comptabilite/GrandLivre'
import Journaux from '../containers/comptabilite/Journaux'
import LignesDEcritureComptable from '../containers/comptabilite/LignesDEcritureComptable'
import MoteurDeRecherche from '../containers/comptabilite/MoteurDeRecherche'
import Scenario from '../containers/comptabilite/Scenario'
import TypeDEcriture from '../containers/comptabilite/TypeDEcriture'
import ReceptionEnMagasin from '../containers/reception_commande/ReceptionEnMagasin'
import RetraitParLeClient from '../containers/reception_commande/RetraitParLeClient'
import ImportProduit from '../containers/produit/ImportProduit'
import GestionStock from '../containers/produit/GestionStock'
import TrackingStocks from '../containers/produit/TrackingStocks'
import ExportBDRUtilisesEnMagasin from '../containers/suivi/ExportBDRUtilisesEnMagasin'
import EtatDesStocks from '../containers/produit/EtatDesStocks'
import MonitoringProduits from 'containers/produit/MonitoringProduits'
import ImagesProduits from 'containers/produit/ImagesProduits'
import ExportProduitsEssayes from '../containers/suivi/ExportProduitsEssayes'
import CommandesNonPayees from 'containers/relation_client/CommandesNonPayees'
import ExportProduitsVtoEssayes from 'containers/suivi/ExportProduitsVtoEssayes'

// Liste des composants pour le menu
// Source : https://www.storyblok.com/tp/react-dynamic-component-from-json

const Components = {
  GestionCommandeClient,
  GestionCommande,
  GestionBDRTemplate,
  GestionBDR,
  GenerationCodesBDREnMasse,
  DesactivationBon,
  UtilisationBon,
  ExportBonsUtilisesMagasins,
  ExportDesVentes,
  ExportExpeditionKehl,
  ExportRepartitionVentes,
  PickingClients,
  CommandeMagasin,
  BonDeFabricationElectronique,
  ColishipCSV,
  CodeBarreEAN,
  TachesLogistiquesKehl,
  PickingEssdom,
  ExpeditionColisEssdom,
  VisualisationReassort,
  EntreeSortieDeStock,
  GestionFluxDeStock,
  TrackingStock,
  ListeStock,
  RechercheParEmplacement,
  ProduitsAGerer,
  InventaireKehl,
  LogsInventaire,
  AttenteDeProdNte,
  TachesAtelierNte,
  CommandesAttenteRemboursement,
  CommandesRestantDu,
  Comptes,
  Balance,
  BalanceTiers,
  EcrituresComptables,
  GrandLivre,
  Journaux,
  LignesDEcritureComptable,
  MoteurDeRecherche,
  Scenario,
  TypeDEcriture,
  ImportProduit,
  GestionStock,
  TrackingStocks,
  ReceptionEnMagasin,
  RetraitParLeClient,
  ExportBDRUtilisesEnMagasin,
  EtatDesStocks,
  MonitoringProduits,
  ImagesProduits,
  ExportProduitsEssayes,
  CommandesNonPayees,
  ExportProduitsVtoEssayes
}

// Récupération de l'objet pour l'afficher
const component = (name) => {
  // component does exist
  if (typeof Components[name] !== 'undefined') {
    return React.createElement(Components[name], {
      key: name._uid,
      block: name,
    })
  }
}

export default component
