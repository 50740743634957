import React, { Component } from "react";

export class UtilisationBon extends Component {
    render() {
        return (
            <div>
                <h1>Utilisation Bon</h1>
            </div>
        );
    }
}

export default UtilisationBon;
