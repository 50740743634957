import gql from 'graphql-tag'
import { DO_VOUCHER_FIELDS } from 'graphql/fragments/DOVoucherFields'

const SEARCH_VOUCHERS = gql`
  ${DO_VOUCHER_FIELDS}
  query SEARCH_VOUCHERS(
    $filter: String!
    $withoutNewVoucherCode: Boolean = false
  ) {
    getDOVoucherListing(filter: $filter) {
      edges {
        node {
          ...DOVoucherFields
        }
      }
      totalCount
    }
  }
`

export default SEARCH_VOUCHERS
