import { gql } from '@apollo/client'
import { DO_BENEFICIARY_FIELDS } from './DOBeneficiaryFields'
import { DO_VISION_CORRECTION_FC_FIELDS } from './DOVisionCorrectionFCFields'

export const DO_PRESCRIPTION_FIELDS = gql`
  ${DO_BENEFICIARY_FIELDS}
  ${DO_VISION_CORRECTION_FC_FIELDS}
  fragment DOPrescriptionFields on object_DOPrescription {
    id
    prescriptionDate
    prescriberNumber
    prescriberNIN
    prescriberFullname
    source
    pupillaryDistanceSource
    prescriptionVisionType
    pupillaryDistanceVisionType
    refund
    customerComment
    internalComment
    prescriptionDataLabel
    rightEyeCorrections {
      ...DOVisionCorrectionFCFields
    }
    leftEyeCorrections {
      ...DOVisionCorrectionFCFields
    }
    beneficiary {
      ...DOBeneficiaryFields
    }
    orderLines {
      ... on object_DOOrderLine {
        id
        order {
          ... on object_DOOrder {
            key
            id
          }
        }
      }
    }
  }
`
