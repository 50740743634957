import { gql } from '@apollo/client'

const GET_DO_VOUCHER_LISTING = gql`
  query GetVouchers(
    $dateBegin: String
    $dateEnd: String
    $marketingCampaign: String
    $storeId: Int
  ) {
    getDOVoucherWithDateFilterListing(
      dateBegin: $dateBegin
      dateEnd: $dateEnd
      marketingCampaign: $marketingCampaign
      storeId: $storeId
    ) {
      edges {
        node {
          totalAmount {
            value
            toString
          }
          id
          dateBegin
          dateEnd
          voucherState
          voucherCode
          voucherUsage {
            ... on fieldcollection_DOVoucherUsageFC {
              store {
                ... on object_DOStore {
                  label
                }
              }
              usageDate
              order {
                ... on object_DOOrder {
                  orderIP
                }
              }
            }
          }
        }
      }
    }
  }
`

export default GET_DO_VOUCHER_LISTING
