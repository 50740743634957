import React from 'react'
import { ClipboardListIcon } from '@heroicons/react/outline'
import { getAge } from 'utils/functions'
import HeaderTitle from '../HeaderTitle'
import PrescriptionForm from './Prescription/PrescriptionForm'
import PrescriptionFileViewer from './Prescription/PrescriptionFileViewer'
import './Prescription.css'

interface Props {
  isAlertOpen: boolean
  beneficiary: beneficiaryType
  prescription: prescriptionType
  numberOfPrescription: number
  orderLines: orderLineType[]
  order: orderType
  isGroupOff: boolean
}

const Prescription: React.FC<Props> = ({
  isAlertOpen,
  prescription,
  beneficiary,
  numberOfPrescription,
  orderLines,
  order,
  isGroupOff
}) => {

  const getTitle = () => {
    let beneficiaryFullName =
      beneficiary.name[0].firstName + ' ' + beneficiary.name[0].lastName
    let beneficiaryAge = beneficiary?.birthDate && getAge(beneficiary.birthDate)
    let beneficiaryCivilityString =
      beneficiary.name[0].civility === '3' ? 'NÉ' : 'NÉE'
    let beneficiaryBirthdate = beneficiary?.birthDate && new Intl.DateTimeFormat('fr', {
      dateStyle: 'short',
    }).format(
      new Date(beneficiary.birthDate.replace(/ - /g, '/').replace(/ /g, 'T'))
    )
    let prescriptionAge = prescription.prescriptionDate
      ? getAge(prescription.prescriptionDate)
      : ''

    return `Ordonnance n°${numberOfPrescription} ID ${
      prescription.id
      } - ${beneficiaryFullName} (${beneficiaryAge} ANS - ${beneficiaryCivilityString} LE 
              ${beneficiaryBirthdate} ${
        prescription.prescriptionDate && typeof prescriptionAge === "number"
          ? `- ordo a ${prescriptionAge} ${
              prescriptionAge > 1 ? ' ans)' : ' an)'
            }`
          : ')'
      }
    `
  }

  return (
    <div className={`Prescription mt-4 ${isGroupOff?'prescription-off':''}`}>
      <HeaderTitle
        children={<ClipboardListIcon className="header-title-icon ms-1" />}
        title={getTitle()}
      />
      {prescription && isAlertOpen ? (
        <div className="d-flex prescription-upload">
          <div className="xs">
          </div>
          <div className="d-flex flex-column">
            <PrescriptionForm
              prescription={prescription}
              orderLines={orderLines}
              order={order}
              beneficiary={beneficiary}
            />
          </div>
        </div>
      ) : (
        // CAS SANS RIGHTSIDEBAR
        prescription && (
        <>
          <div className={`d-flex ${isAlertOpen && 'prescription-upload'}`}>
            <div className="d-flex flex-column">
              <PrescriptionForm
                prescription={prescription}
                orderLines={orderLines}
                order={order}
                beneficiary={beneficiary}
              />
            </div>
            <div className="m-3">
              <PrescriptionFileViewer
                id={+prescription.id}
                width="300px"
                height="455px"
              />
            </div>
          </div>
        </>)
      )}
      {/* CAS SANS PRESCRIPTION FILE */}
      {!prescription && (
        <div className="d-flex prescription-info-ecart">
          <PrescriptionForm
            prescription={prescription}
            orderLines={orderLines}
            order={order}
            beneficiary={beneficiary}
          />
        </div>
      )}
    </div>
  )
}

export default Prescription
