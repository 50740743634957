import './Login.css'
import React, { useContext, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useLazyQuery } from '@apollo/client'
import LOGIN_USER from '../graphql/LoginUser'
import AuthContext from '../store/auth-context'
import { useHistory } from 'react-router-dom'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline'
import { GoogleLogin } from '@react-oauth/google'

interface Props {}
const Login: React.FC<Props> = () => {
  const authCtx = useContext(AuthContext)
  let history = useHistory()

  const inputUsernameRef = useRef<HTMLInputElement>(null)
  const inputPasswordRef = useRef<HTMLInputElement>(null)

  const [passwordVisible, setPasswordVisible] = useState(false)
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const [loginUser, { loading, data, error }] = useLazyQuery(LOGIN_USER, {
    onCompleted: (data) => {
      if (data?.getLoginData?.user?.token != null) {
        authCtx.login(data.getLoginData.user)
        authCtx.buildMenus(data.getLoginData.menuList)
        authCtx.setWebsiteSettings(data.getLoginData.websiteSettings)
        history.push('/')
      }
    },
  })

  const submitLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    // NB: les champs non vides sont gérés via le "required" sur les inputs --> les champs sont forcément non null
    loginUser({
      variables: {
        username: inputUsernameRef!.current!.value,
        password: btoa(
          inputUsernameRef!.current!.value.split('').reverse().join('') +
            '_login_' +
            inputPasswordRef!.current!.value +
            '_' +
            new Date().getTime() +
            '_' +
            inputUsernameRef!.current!.value
        ),
      },
    })
  }
  const handleGoogleSuccess = (credentialResponse: any) => {
    const { credential } = credentialResponse
    // call the loginUser with new token
    loginUser({
      variables: {
        googleToken: credential,
      },
    })
  }

  return (
    <>
      <div className="text-center p-5">
        <div>Bienvenue sur le site de gestion de Direct-Optic</div>
        {(error || (data && data.getLoginData?.status?.code !== 200)) && (
          <div id="error-login" className="alert alert-danger mt-4">
            Identifiant ou mot de passe incorrect.
          </div>
        )}
        <form onSubmit={submitLogin}>
          <div className="w-50 m-auto">
            <div className="form-group row pt-4">
              <label htmlFor="username" className="col-sm-3 col-form-label">
                Identifiant :
              </label>
              <div className="col-sm">
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  placeholder="Identifiant"
                  required={true}
                  ref={inputUsernameRef}
                />
              </div>
            </div>
            <div className="form-group row pt-4">
              <label htmlFor="password" className="col-sm-3 col-form-label">
                Mot de passe :
              </label>
              <div className="col-sm position-relative">
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  className="form-control pe-5"
                  id="password"
                  placeholder="Mot de passe"
                  required={true}
                  ref={inputPasswordRef}
                />
                {passwordVisible ? (
                  <EyeOffIcon
                    className="header-title-icon custom-blue-link position-absolute position-eye-password"
                    onClick={togglePasswordVisibility}
                  />
                ) : (
                  <EyeIcon
                    className="header-title-icon custom-blue-link position-absolute position-eye-password"
                    onClick={togglePasswordVisibility}
                  />
                )}
              </div>
            </div>
          </div>

          <Button className="mt-4 mb-4" variant="primary" type="submit">
            Valider
          </Button>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <GoogleLogin
              onSuccess={handleGoogleSuccess}
              onError={() => {
                console.log('Login Failed')
              }}
            />
          </div>
        </form>
        {loading && <div>Connexion en cours...</div>}
      </div>
    </>
  )
}

export default Login
