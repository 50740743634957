import { useLazyQuery } from '@apollo/client'
import { Redirect, useLocation } from 'react-router-dom'
import ResultsTable from 'components/UI/ResultsTable'
import SEARCH_CUSTOMERS from '../../graphql/queries/searchCustomers'
import SEARCH_BENEFICIARIES from '../../graphql/queries/searchBeneficiaries'
import SEARCH_VOUCHERS from '../../graphql/queries/searchVouchers'
import Card from '../../components/UI/Card'
import { getTokenFromLocalStorage } from '../../utils/functions'
import { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { stringsToJSXs } from 'utils/functions'
import { GET_ORDER_LISTING } from 'graphql/queries/getOrderListing'

const SearchResultsListing: React.FC<any> = ({ location }) => {
  const searchQueryParams = useLocation().search
  const { t } = useTranslation()

  const { firstName, lastName, email, id, key, cosiumId, type, code } =
    Object.fromEntries(new URLSearchParams(searchQueryParams))

  const [searchCustomers, { loading: customersLoading, data: customersData }] =
    useLazyQuery(SEARCH_CUSTOMERS, {
      variables: {
        token: getTokenFromLocalStorage(),
        firstName,
        lastName,
        email: email,
      },
    })

  const [
    searchBeneficiaries,
    { loading: beneficiariesLoading, data: beneficiariesData },
  ] = useLazyQuery(SEARCH_BENEFICIARIES, {
    variables: {
      token: getTokenFromLocalStorage(),
      firstName,
      lastName,
    },
  })

  const filter = cosiumId
    ? '{"cosiumId": {"$like" :"' + cosiumId + '"}}'
    : key
    ? '{"o_key": "' + key + '"}'
    : '{"o_id": "' + id + '"}'
  const [getOrder, { loading: ordersLoading, data: ordersData }] = useLazyQuery(
    GET_ORDER_LISTING,
    {
      variables: {
        filter,
      },
    }
  )

  const [searchVouchers, { loading: vouchersLoading, data: vouchersData }] =
    useLazyQuery(SEARCH_VOUCHERS, {
      variables: {
        filter: '{"voucherCode": {"$like" :"' + code + '"}}',
      },
    })

  useEffect(() => {
    switch (type) {
      case 'client':
        searchCustomers()
        searchBeneficiaries()
        break
      case 'email':
        searchCustomers()
        break
      case 'bon_reduction':
        searchVouchers()
        break
      case 'commande':
        getOrder()
        break
    }
  }, [type, searchCustomers, searchBeneficiaries, searchVouchers, getOrder])

  if (
    beneficiariesLoading ||
    customersLoading ||
    ordersLoading ||
    vouchersLoading
  ) {
    return (
      <div className="d-flex justify-content-center align-items-center ">
        <Spinner animation={'border'} />
      </div>
    )
  }

  if (type === 'commande') {
    if (
      !ordersLoading &&
      ordersData &&
      ordersData.getOrderListing &&
      ordersData.getOrderListing.totalCount === 1
    ) {
      const orderId = ordersData.getOrderListing.edges[0].node.order.id
      return (
        <Redirect
          to={{
            pathname: `/bo/relation_client/gestion_commande_client/${orderId}`,
          }}
        />
      ) // Redirect à favoriser pour rediriger vers un autre composant : history.push() génère un warning dans la console : Cannot update during an existing state transition (such as within `render`). Render methods should be a pure function of props and state.
    } else {
      return (
        <Card>
          <p>Aucune commande trouvée</p>
        </Card>
      )
    }
  }

  if (type === 'bon_reduction') {
    if (
      !vouchersLoading &&
      vouchersData &&
      vouchersData.getDOVoucherListing.totalCount === 1
    ) {
      const voucherId = vouchersData.getDOVoucherListing.edges[0].node.id
      return (
        <Redirect
          to={{
            pathname: `/bo/bons_de_reduction/gestion_bdr/${voucherId}`,
          }}
        />
      ) // Redirect à favoriser pour rediriger vers un autre composant : history.push() génère un warning dans la console : Cannot update during an existing state transition (such as within `render`). Render methods should be a pure function of props and state.
    } else {
      return (
        <Card>
          <p>Votre bon de réduction n'a pas été trouvé</p>
        </Card>
      )
    }
  }
  if (
    (type === 'client' || type === 'email') &&
    !customersData &&
    !beneficiariesData
  )
    return <Card>Aucun résultat</Card>

  //fonction quand on clique sur une ligne de SearchResulTable
  const onClickRowsHandler = (item: any) => {
    switch (item.__typename) {
      case 'DOBeneficiaryCustomNode':
        window.open(`/bo/edition_client/${item.customerId}`)
        break
      default:
        window.open(`/bo/edition_client/${item.id}`)
        break
    }
  }

  return (
    <div>
      {!customersLoading &&
        customersData &&
        (type === 'client' || type === 'email') && (
          <Card>
            <ResultsTable
              items={customersData.CQgetDOCustomerCustomListing}
              queryType="Clients"
              title="Résultats pour Clients"
              columns={stringsToJSXs(
                ['id', 'firstName', 'lastName', 'email'],
                t
              )}
              onClickRows={onClickRowsHandler}
            ></ResultsTable>
          </Card>
        )}
      {!beneficiariesLoading && beneficiariesData && type === 'client' && (
        <Card>
          <ResultsTable
            items={beneficiariesData.CQgetDOBeneficiaryCustomListing}
            queryType="Bénéficiaires"
            title="Résultats pour Bénéficiaires"
            columns={stringsToJSXs(
              ['id', 'firstName', 'lastName', 'birthDate'],
              t
            )}
            onClickRows={onClickRowsHandler}
          ></ResultsTable>
        </Card>
      )}
    </div>
  )
}

export default SearchResultsListing
