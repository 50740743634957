import gql from 'graphql-tag'

const GET_ORDER_BY_PAYMENT_EVENT_TYPE_LISTING = gql`
  query GET_ORDER_BY_PAYMENT_EVENT_TYPE_LISTING(
    $orderEventTypeKeys: [String]
  ) {
    getDOOrderByPaymentEventTypeListing(
      orderEventTypeKeys: $orderEventTypeKeys
    ) {
      edges {
        node {
          id
          eventCreationDate
          eventMailInfo
          eventTypeKey
          orderId
          orderKey
          orderCreationDate
          orderComment
          customerFullname
          customerEmail
          totalAmount
          paymentMethodLabel
        }
      }
    }
  }
`

export default GET_ORDER_BY_PAYMENT_EVENT_TYPE_LISTING