import { gql } from '@apollo/client'
const IMPORT_PRODUCTS = gql`
  mutation importProducts($jsonData: String!) {
    importProducts(jsonData: $jsonData) {
      success
      output {
        creationSuccessLines{
          line
          successInfo
        }
        creationFailInfo {
          line
          blockingInfo
        }
        creationWarningInfo {
          line
          warningInfo

        }
      }
    }
  }
`

export default IMPORT_PRODUCTS
