import { gql } from '@apollo/client'
import { DO_VISION_CORRECTION_FC_FIELDS } from 'graphql/fragments/DOVisionCorrectionFCFields'

const UPDATE_DO_PRESCRIPTION = gql`
  ${DO_VISION_CORRECTION_FC_FIELDS}
  mutation UPDATE_DO_PRESCRIPTION(
    $id: Int!
    $prescriptionDate: String
    $prescriberNIN: String
    $prescriberNumber: String
    $prescriberFullname: String
    $refund: Boolean
    $pupillaryDistanceRight: Float
    $pupillaryDistanceLeft: Float
    $rightSphere: Float
    $rightCylinder: Float
    $rightAxis: Float
    $rightAddition: Float
    $leftSphere: Float
    $leftCylinder: Float
    $leftAxis: Float
    $leftAddition: Float
    $pupillaryDistanceSource: String
    $prescriptionSource: String
    $internalComment: String
    $prescriptionVisionType: String
    $pupillaryDistanceVisionType: String
  ) {
    updateDOPrescription(
      id: $id
      input: {
        prescriptionDate: $prescriptionDate
        prescriberNIN: $prescriberNIN
        prescriberNumber: $prescriberNumber
        prescriberFullname: $prescriberFullname
        refund: $refund
        pupillaryDistanceSource: $pupillaryDistanceSource
        pupillaryDistanceVisionType: $pupillaryDistanceVisionType
        source: $prescriptionSource
        internalComment: $internalComment
        prescriptionVisionType: $prescriptionVisionType
        rightEyeCorrections: {
          items: {
            DOVisionCorrectionFC: {
              pupillaryDistance: $pupillaryDistanceRight
              sphere: $rightSphere
              cylinder: $rightCylinder
              axis: $rightAxis
              addition: $rightAddition
            }
          }
        }
        leftEyeCorrections: {
          items: {
            DOVisionCorrectionFC: {
              pupillaryDistance: $pupillaryDistanceLeft
              sphere: $leftSphere
              cylinder: $leftCylinder
              axis: $leftAxis
              addition: $leftAddition
            }
          }
        }
      }
    ) {
      success
      output {
        id
        prescriptionDate
        prescriberNIN
        prescriberNumber
        prescriberFullname
        refund
        source
        pupillaryDistanceSource
        prescriptionVisionType
        pupillaryDistanceVisionType
        internalComment

        rightEyeCorrections {
          ...DOVisionCorrectionFCFields
        }
        leftEyeCorrections {
          ...DOVisionCorrectionFCFields
        }
        orderLines {
          ... on object_DOOrderLine {
            id
            insuranceInvoiceInUse
            treatmentFormInUse
          }
        }
      }
    }
  }
`

export default UPDATE_DO_PRESCRIPTION
