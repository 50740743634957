import React from 'react';
import { Card, Spinner } from 'react-bootstrap';

interface IndicatorProps {
    label: string;
    value: number;
    loading: boolean;
}

const Indicator: React.FC<IndicatorProps> = ({ label, value, loading }) => {

    return (
        <Card className="border border-light-subtle p-1" style={{borderRadius: '10px', height: '140px'}}>
          <Card.Body className='d-flex flex-column justify-content-between'>
            <Card.Text className="text-dark mb-2">{label}</Card.Text>
            { 
              loading ? 
              <Spinner animation="grow" variant="secondary" size="sm" /> : 
              <Card.Text className="text-dark fs-5 fw-bold" style={{justifyContent: 'end'}}>{value}</Card.Text>
            }
          </Card.Body>
        </Card>
    );
};

export default Indicator;