import { gql } from '@apollo/client'

export const ADD_GENERIC_ITEM_TO_ORDER = gql`
  mutation($orderId: Int!, $key: String!, $label: String!, $quantity: Int!, $amount: Float!)
  {
    addGenericItemToOrder(
      orderId: $orderId, 
      key: $key,
      label: $label,
      quantity: $quantity,
      amount: $amount
    ) {
      success
      output {
        order {
          id
          events {
            ... on object_DOOrderEvent {
              id
              eventType {
                ... on object_DOOrderEventType {
                  id
                  key
                  eventFamily
                }
              }
            }
          }
        }
        orderLineItem {
          id
          label
          unitAmount {
            value
          }
          item {
            ... on fieldcollection_DOOLItemGenericFC {
              genericItem {
                ... on object_DOGenericItem {
                  id
                  label
                  itemType
                  amount {
                    value
                  }
                  isNegative
                }
              }
            }
          }
          orderLine {
            ... on object_DOOrderLine {
              id
              stockState
              status
            }
          }
        }
      }
    }
  }
`