import { gql } from '@apollo/client'

const LOGOUT_USER = gql`
  mutation LOGOUT_USER($token: String!) {
    logout(token: $token) {
      status {
        code
        message
      }
    }
  }
`

export default LOGOUT_USER
