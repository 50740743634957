import classes from './ColorEnclosedLabel.module.css'

export interface Props {
  label: string
  color: string
}

const ColorEnclosedLabel: React.FC<Props> = ({ label, color }) => {
  return <div className={classes.enclosure} style={{ borderColor: color }}>{label}</div>
}

export default ColorEnclosedLabel