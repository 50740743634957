import { gql } from '@apollo/client'

const BULK_CREATE_ORDER_EVENTS = gql`
  mutation BULK_CREATE_ORDER_EVENTS($orderId: Int!, $events: String!) {
    bulkCreateOrderEvents(orderId: $orderId, events: $events) {
      success
      output {
        creationSuccessEventTypeKeys
        creationFailEventTypeKeys
      }
    }
  }
`

export default BULK_CREATE_ORDER_EVENTS
