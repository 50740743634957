import React, { useContext, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { GET_DO_BRAND_LISTING } from 'graphql/queries/getDOBrandListing'
import { useMutation, useQuery } from '@apollo/client'
import CREATE_VOUCHER_TEMPLATE from 'graphql/mutations/CreateDOVoucherTemplate'
import UPDATE_VOUCHER_TEMPLATE from 'graphql/mutations/UpdateDOVoucherTemplate'
import { ToastContext } from 'store/toast-context'
import { GET_DO_VOUCHER_TEMPLATE_LISTING } from 'graphql/queries/getDOVoucherTemplateListing'
import { constants } from 'utils/constants'

interface Props {
  showModal: boolean
  closeModal: () => void
  bdrTemplate: any
}
const BDRModal: React.FC<Props> = ({ showModal, closeModal, bdrTemplate }) => {
  const { toast } = useContext(ToastContext)

  // hide/show modal
  const handleClose = () => {
    closeModal()
  }

  const isEditMode = !!Object.keys(bdrTemplate).length

  const [clientLabel, setClientLabel] = useState(bdrTemplate?.clientLabel || '')
  const [genericCode, setGenericCode] = useState(bdrTemplate?.genericCode || '')
  const [voucherPrefixedCode, setVoucherPrefixedCode] = useState(
    bdrTemplate?.voucherPrefixedCode || ''
  )
  const [comment, setComment] = useState(bdrTemplate?.comment || '')
  const [initialAmount, setInitialAmount] = useState(
    bdrTemplate?.initialAmount?.value || 0
  )
  const [duration, setDuration] = useState(bdrTemplate?.duration || 0)
  const [dateBegin, setDateBegin] = useState(bdrTemplate?.dateBegin || '')
  const [dateEnd, setDateEnd] = useState(bdrTemplate?.dateEnd || '')
  const [minAmount, setMinAmount] = useState(bdrTemplate?.minAmount?.value || 0)

  // state for all the complex inputs (multiselects, radios...)
  const [voucherType, setVoucherType] = useState(
    bdrTemplate.voucherType || 'generique'
  )
  const [selectedBrands, setSelectedBrands] = useState(
    bdrTemplate.brands || ['']
  )
  const [selectedSites, setSelectedSites] = useState(
    bdrTemplate.domainSites || ['']
  )
  const [productType, setProductType] = useState(
    bdrTemplate.productType || 'tout-produit'
  )
  const [isOnDiscount, setIsOnDiscount] = useState(bdrTemplate?.onDiscount || false)
  const [isOnlyFirstOrder, setIsOnlyFirstOrder] = useState(
    isEditMode ? bdrTemplate.onlyFirstOrder : false
  )
  const [isActive, setIsActive] = useState(true)

  const [createVoucherTemplate, { loading }] = useMutation(
    CREATE_VOUCHER_TEMPLATE,
    {
      variables: {
        label: clientLabel,
        voucherType,
        genericCode,
        voucherPrefixedCode,
        comment,
        initialAmount: +initialAmount,
        onDiscount: isOnDiscount,
        onlyFirstOrder: isOnlyFirstOrder,
        active: isActive,
        duration: +duration,
        dateBegin,
        dateEnd,
        minAmount: +minAmount,
        domainSites: JSON.stringify(selectedSites),
        brands: JSON.stringify(selectedBrands),
        productType,
      },
      refetchQueries: [
        // works only on success
        GET_DO_VOUCHER_TEMPLATE_LISTING,
      ],
      awaitRefetchQueries: true,
    }
  )

  const [updateVoucherTemplate, { loading: updateLoading }] = useMutation(
    UPDATE_VOUCHER_TEMPLATE,
    {
      variables: {
        id: +bdrTemplate.id,
        label: clientLabel,
        genericCode,
        voucherPrefixedCode,
        comment,
        initialAmount: +initialAmount,
        onDiscount: isOnDiscount,
        onlyFirstOrder: isOnlyFirstOrder,
        active: isActive,
        duration: +duration,
        dateBegin,
        dateEnd,
        minAmount: +minAmount,
        domainSites: JSON.stringify(selectedSites),
        brands: JSON.stringify(selectedBrands),
        productType,
      },
      refetchQueries: [
        // works only on success
        GET_DO_VOUCHER_TEMPLATE_LISTING,
      ],
      awaitRefetchQueries: true,
    }
  )

  // List of brands for the brands field
  const { data: brandData, loading: brandLoading } =
    useQuery(GET_DO_BRAND_LISTING)

  const submitForm = async (e: any) => {
    e.preventDefault()
    try {
      if (isEditMode) {
        await updateVoucherTemplate()
        toast({
          title: 'Modifications enregistrées :',
          message: 'Template de BDR modifié',
        })
      } else {
        await createVoucherTemplate()
        toast({
          title: 'Modifications enregistrées :',
          message: 'Nouveau template de BDR créé',
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      handleClose()
    }
  }

  return (
    <>
      <Modal size="lg" show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditMode ? 'Edition' : 'Ajout'} d'un BDR Template
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="BDRForm" onSubmit={submitForm}>
            <Form.Group as={Row} className="mb-3" controlId="clientLabel">
              <Form.Label column sm={4}>
                Libellé client*
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="clientLabel"
                  type="text"
                  placeholder=""
                  value={clientLabel}
                  onChange={(e) => setClientLabel(e.target.value)}
                  required
                />
              </Col>
            </Form.Group>
            <fieldset>
              <Form.Group as={Row} className="mb-3">
                <Form.Label as="legend" column sm={4}>
                  Type d'offre
                </Form.Label>
                <Col sm={8}>
                  <Form.Check
                    type="radio"
                    label="Générique"
                    name="voucherType"
                    id="generiqueRadio"
                    value="generique"
                    onChange={() => setVoucherType('generique')}
                    defaultChecked={
                      isEditMode
                        ? bdrTemplate.voucherType === 'generique'
                        : true
                    }
                    disabled={isEditMode}
                  />
                  <Form.Check
                    type="radio"
                    label="Personnel"
                    name="voucherType"
                    id="personnelRadio"
                    value="personnel"
                    onChange={() =>
                      setVoucherType(
                        constants.voucherTypes.VOUCHER_TYPE_PERSONNEL
                      )
                    }
                    defaultChecked={
                      isEditMode
                        ? bdrTemplate.voucherType ===
                          constants.voucherTypes.VOUCHER_TYPE_PERSONNEL
                        : false
                    }
                    disabled={isEditMode}
                  />
                  <Form.Check
                    type="radio"
                    label="Unique"
                    name="voucherType"
                    id="uniqueRadio"
                    value="unique"
                    onChange={() =>
                      setVoucherType(constants.voucherTypes.VOUCHER_TYPE_UNIQUE)
                    }
                    defaultChecked={
                      isEditMode
                        ? bdrTemplate.voucherType ===
                          constants.voucherTypes.VOUCHER_TYPE_UNIQUE
                        : false
                    }
                    disabled={isEditMode}
                  />
                </Col>
              </Form.Group>
            </fieldset>

            {voucherType === 'generique' && (
              <Form.Group as={Row} className="mb-3" controlId="genericCode">
                <Form.Label column sm={4}>
                  Code promo
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    name="genericCode"
                    type="text"
                    placeholder=""
                    value={genericCode}
                    onChange={(e) => setGenericCode(e.target.value)}
                    required
                  />
                </Col>
              </Form.Group>
            )}

            {[
              constants.voucherTypes.VOUCHER_TYPE_PERSONNEL,
              constants.voucherTypes.VOUCHER_TYPE_UNIQUE,
            ].includes(voucherType) && (
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="voucherPrefixedCode"
              >
                <Form.Label column sm={4}>
                  Préfixe
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    name="voucherPrefixedCode"
                    type="text"
                    placeholder=""
                    value={voucherPrefixedCode}
                    onChange={(e) => setVoucherPrefixedCode(e.target.value)}
                  />
                </Col>
              </Form.Group>
            )}

            <Form.Group as={Row} className="mb-3" controlId="comment">
              <Form.Label column sm={4}>
                Commentaires
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="comment"
                  as="textarea"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="initialAmount">
              <Form.Label column sm={4}>
                Montant en euros*
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="initialAmount"
                  type="number"
                  step=".01"
                  placeholder=""
                  value={initialAmount}
                  onChange={(e) => setInitialAmount(e.target.value)}
                  required
                />
              </Col>
            </Form.Group>
            <fieldset>
              <Form.Group as={Row} className="mb-3">
                <Form.Label as="legend" column sm={4}>
                  Valable sur produits soldés*
                </Form.Label>
                <Col sm={8}>
                  <Form.Check
                    type="radio"
                    label="Oui"
                    name="onDiscount"
                    id="isOnDiscountRadio"
                    onChange={() => setIsOnDiscount(true)}
                    defaultChecked={isOnDiscount}
                  />
                  <Form.Check
                    type="radio"
                    label="Non"
                    name="onDiscount"
                    id="isNotOnDiscountRadio"
                    onChange={() => setIsOnDiscount(false)}
                    defaultChecked={!isOnDiscount}
                  />
                </Col>
              </Form.Group>
            </fieldset>
            <fieldset>
              <Form.Group as={Row} className="mb-3">
                <Form.Label as="legend" column sm={4}>
                  Sur 1ère commande uniquement*
                </Form.Label>
                <Col sm={8}>
                  <Form.Check
                    type="radio"
                    label="Oui"
                    name="onlyFirstOrder"
                    id="isOnlyFirstOrderRadio"
                    onChange={() => setIsOnlyFirstOrder(true)}
                    defaultChecked={isOnlyFirstOrder}
                  />
                  <Form.Check
                    type="radio"
                    label="Non"
                    name="onlyFirstOrder"
                    id="isNotOnlyFirstOrderRadio"
                    onChange={() => setIsOnlyFirstOrder(false)}
                    defaultChecked={!isOnlyFirstOrder}
                  />
                </Col>
              </Form.Group>
            </fieldset>
            <fieldset>
              <Form.Group as={Row} className="mb-3">
                <Form.Label as="legend" column sm={4}>
                  Actif*
                </Form.Label>
                <Col sm={8}>
                  <Form.Check
                    type="radio"
                    label="Oui"
                    name="active"
                    id="isActiveRadio"
                    onChange={() => setIsActive(true)}
                    defaultChecked={
                      isEditMode ? bdrTemplate.active === 'oui' : true
                    }
                  />
                  <Form.Check
                    type="radio"
                    label="Non"
                    name="active"
                    id="isNotActiveRadio"
                    onChange={() => setIsActive(false)}
                    defaultChecked={
                      isEditMode ? bdrTemplate.active !== 'oui' : false
                    }
                  />
                </Col>
              </Form.Group>
            </fieldset>

            {['personnel', 'unique'].includes(voucherType) && (
              <Form.Group as={Row} className="mb-3" controlId="duration">
                <Form.Label column sm={4}>
                  Durée de validité en jours*
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    name="duration"
                    type="text"
                    placeholder=""
                    defaultValue={duration}
                    onChange={(e) => setDuration(e.target.value)}
                    required
                  />
                </Col>
              </Form.Group>
            )}

            <Form.Group as={Row} className="mb-3" controlId="dateBegin">
              <Form.Label column sm={4}>
                Date de début*
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="dateBegin"
                  type="date"
                  placeholder=""
                  defaultValue={dateBegin}
                  onChange={(e) => setDateBegin(e.target.value)}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="dateEnd">
              <Form.Label column sm={4}>
                Date de fin*
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="dateEnd"
                  type="date"
                  placeholder=""
                  defaultValue={dateEnd}
                  onChange={(e) => setDateEnd(e.target.value)}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="minAmount">
              <Form.Label column sm={4}>
                Montant minimum de la commande
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="minAmount"
                  type="number"
                  step=".01"
                  placeholder=""
                  value={minAmount}
                  onChange={(e) => setMinAmount(e.target.value)}
                />
              </Col>
            </Form.Group>

            {!brandLoading && (
              <Form.Group as={Row} className="mb-3" controlId="brands">
                <Form.Label column sm={4}>
                  Liste des marques
                </Form.Label>
                <Col sm={8}>
                  <Form.Select
                    multiple
                    defaultValue={selectedBrands}
                    onChange={(e) => {
                      setSelectedBrands(
                        Array.from(e.target.selectedOptions).map(
                          (e: any) => e.value
                        )
                      )
                    }}
                  >
                    {brandData.getDOBrandListing.edges.map(
                      ({ node: brand }: { node: brandType }) => {
                        return (
                          <option value={brand.id} key={brand.id}>
                            {brand.name}
                          </option>
                        )
                      }
                    )}
                  </Form.Select>
                  <Form.Text muted>
                    Maintenir "Ctrl" enfoncé pour sélectionner plusieurs
                  </Form.Text>
                </Col>
              </Form.Group>
            )}

            <Form.Group as={Row} className="mb-3" controlId="productType">
              <Form.Label column sm={4}>
                Produits autorisés
              </Form.Label>
              <Col sm={8}>
                <Form.Select
                  defaultValue={productType}
                  onChange={(e) => {
                    setProductType(e.target.value)
                  }}
                >
                  <option value="tout-produit">Tout produit</option>
                  <option value="toute-monture">Toute monture</option>
                  <option value="avec-verres-correcteurs">
                    Avec verres correcteurs
                  </option>
                  <option value="avec-verres-correcteurs-non-teintes">
                    Avec verres correcteurs non teintés
                  </option>
                  <option value="avec-verres-correcteurs-teintes">
                    Avec verres correcteurs teintés
                  </option>
                </Form.Select>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="domainSites">
              <Form.Label column sm={4}>
                Sites applicables
              </Form.Label>
              <Col sm={8}>
                <Form.Select
                  multiple
                  onChange={(e) => {
                    setSelectedSites(
                      Array.from(e.target.selectedOptions).map(
                        (e: any) => e.value
                      )
                    )
                  }}
                  defaultValue={bdrTemplate.domainSites || []}
                >
                  <option value="fr">FR</option>
                  <option value="it">IT</option>
                  <option value="esp">ESP</option>
                  <option value="mag">Magasins</option>
                </Form.Select>
                <Form.Text muted>
                  Maintenir "Ctrl" enfoncé pour sélectionner plusieurs
                </Form.Text>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={loading || updateLoading}
          >
            Annuler
          </Button>
          <Button
            type="submit"
            form="BDRForm"
            variant="primary"
            disabled={loading || updateLoading}
          >
            {(loading || updateLoading) && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ color: 'white' }}
              />
            )}
            {loading || updateLoading ? ' Enregistrement...' : 'Enregistrer'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default BDRModal
